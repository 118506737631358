import { CustomReactTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: CustomReactTheme) => ({
  root: {
    padding: '20px 40px 100px 40px',
  },
  tabsButtonsContainer: {
    width: 'fit-content',
    background: 'linear-gradient(to left, #775DA8, #F06B5D)',
    borderRadius: '20px',
    border: '1px solid transparent',
  },
  tabsButtons: {
    background: '#0C0C0C',
    minWidth: '177px',
    color: 'white',
    margin: '1px',
    '&.Mui-selected': {
      background: '#242424',
      color: 'white',
    },
  },
  //* TABLET - landScape
  [theme.breakpoints.down('lg')]: {
    root: {
      padding: '10px 40px 200px 40px',
    },
  },
  //* TABLET - portrait
  [theme.breakpoints.down('md')]: {},
  //* MOBILE
  [theme.breakpoints.down('sm')]: {
    root: {
      padding: '10px 20px 180px 20px',
    },
  },
}));

export { useStyles };
