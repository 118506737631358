/* eslint-disable react/jsx-indent, no-unused-vars */
import React, { FC, useMemo, useRef } from 'react';
// * 3rd party libs
// import { useNavigate } from 'react-router-dom';

import { Grid, IconButton, InputAdornment, Typography } from '@mui/material';
import { Clear } from '@mui/icons-material';

// * ASSETS
import { ReactComponent as SearchNotFound } from 'assets/imgs/search_results_notfound.svg';
import { ReactComponent as SearchEmpty } from 'assets/imgs/search_empty.svg';
import { ReactComponent as TabSearch } from 'assets/imgs/tab_search.svg';

// * COMPONENTS
import { SearchItemsCardItemProps } from 'components/Cards/SearchItems';
import HorizontalList from 'components/HorizontalList';
import { CssTextField } from 'components/forms/Input';

import { removeLSField } from 'utils/cookies';
import { Playlist } from 'store/@types/moods';
import useResponsive from 'hooks/useResponsive';
import { useTranslation } from 'react-i18next';

// * LOCAL IMPORTS
import { useStyles } from './styles';
import { ReduxProps } from '.';

// * TYPES
const SearchPage: FC<ReduxProps> = ({ moods, getSearch }) => {
  const { searchLoading, searchResults } = moods;
  const classes = useStyles();

  // eslint-disable-next-line no-undef
  const timeoutIdRef = useRef<NodeJS.Timeout>();

  const { search, setSearch, history, setHistory, reduceItemsInList } = useResponsive();

  const albums = useMemo(() => searchResults?.albums?.items || [], [searchResults]);
  const playlists = useMemo(() => searchResults?.playlists?.items || [], [searchResults]);

  const { t } = useTranslation();

  const handleSearchInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const searchTerm = event.target.value;
    setSearch(searchTerm);

    // Clear the timeout if it has been set. This is to prevent the previous
    // timeout from executing if the user types faster than 500ms.
    if (timeoutIdRef.current !== undefined) {
      clearTimeout(timeoutIdRef.current);
      // Set a new timeout to execute after 500ms.
    }
    timeoutIdRef.current = setTimeout(() => {
      // Perform the search and update the search results.
      getSearch(searchTerm);
    }, 400);
  };

  const loadingSongs = useMemo(
    () => (
      <HorizontalList
        title={t('searchpage_search_history')}
        // "Search history"
        list={reduceItemsInList(
          Array.from(Array(10).keys()).map(e => ({
            id: e,
            skeleton: true,
          })) as unknown as SearchItemsCardItemProps[],
          'searchItems',
        )}
        type="searchItems"
      />
    ),
    [],
  );

  const searchNotFound = useMemo(
    () => (
      <Grid item xs={12} className={classes.searchNotFound}>
        <SearchNotFound />
        <Typography variant="listAuthor">
          {t('searchpage_noresults') /* Your search didn’t match any results */}.
        </Typography>
      </Grid>
    ),
    [],
  );

  const albumPlaylist = albums.concat(playlists).map(e => ({
    ...e,
    additionalAction: (item: Playlist) => {
      const newHistory: Playlist[] = [item, ...history];
      setHistory(newHistory);
    },
  }));

  const hasAPIData = searchResults && albumPlaylist.length !== 0;

  return (
    <Grid
      container
      spacing={2}
      direction="row"
      justifyContent="flex-start"
      alignItems="flex-start"
      className={classes.root}>
      <Grid item xs={12} md={6}>
        <CssTextField
          value={search}
          onChange={handleSearchInputChange}
          fullWidth
          InputProps={{
            startAdornment: (
              <InputAdornment position="start" sx={{ color: '#BEBEBE' }}>
                <TabSearch />
              </InputAdornment>
            ),
            endAdornment: search.length !== 0 && (
              <InputAdornment position="start">
                <IconButton
                  sx={{ color: '#BEBEBE' }}
                  aria-label="toggle password visibility"
                  onClick={() => {
                    setSearch('');
                    getSearch();
                  }}
                  onMouseDown={() => {}}>
                  <Clear />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Grid>
      <Grid item xs={12} md={6} />
      {searchLoading && loadingSongs}
      {!searchLoading && hasAPIData && (
        <HorizontalList title={`Results with "${search}"`} list={albumPlaylist} type="searchPlaylist" />
      )}
      {!searchLoading && !hasAPIData && search !== '' && (
        <>
          <Typography className={classes.searchNotFoundTitle}>
            {t('searchpage_searchresults') /* Search Results */}
          </Typography>
          {searchNotFound}
        </>
      )}
      {!searchLoading &&
        !hasAPIData &&
        (history.length > 0 ? (
          <HorizontalList
            title={t('searchpage_searchhistory')}
            // "Search history"
            list={history.slice(0, 4).map(e => ({
              ...e,
              additionalAction: (item: Playlist) => {
                setSearch(item.name);
                getSearch(item.name);
              },
            }))}
            type="searchPlaylist"
            rightAction={{
              label: t('searchpage_clearhistory'),
              // 'Clear history',
              action: () => {
                removeLSField('searchHistory');
                window.location.reload();
              },
            }}
          />
        ) : (
          search === '' && (
            <Grid item xs={12} className={classes.searchNotFound}>
              <SearchEmpty />
              <Typography variant="listAuthor">
                {t('searchpage_history_empty') /* Your search history is empty */}
              </Typography>
            </Grid>
          )
        ))}
    </Grid>
  );
};

export default SearchPage;
