import { Dispatch, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { StoreState } from 'store/@types';
import { sendFavTrack } from 'store/moods/thunks';

import DefaultComponent from './MusicInteractionBar';

const mapStateToProps = ({ moods }: StoreState) => ({ moods });

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({ sendFavTrack }, dispatch);

export type ReduxProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(DefaultComponent);
