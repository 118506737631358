import i18next from 'i18next';
import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react';
import i18n from 'utils/i18n';

// Define the context type
type LanguageContextType = {
  language: 'es' | 'en';
  changeLanguage: (lang: 'es' | 'en') => void;
};

// Create a context with a default undefined state (will be set in the provider)
const LanguageContext = createContext<LanguageContextType | undefined>(undefined);

// Provider component props type
type LanguageProviderProps = {
  children: ReactNode;
};

// Provider component
export const LanguageProvider: React.FC<LanguageProviderProps> = ({ children }) => {
  // Load initial language from local storage or default to 'en'
  const [language, setLanguage] = useState<'es' | 'en'>((localStorage.getItem('appLanguage') as 'es' | 'en') || 'en');

  useEffect(() => {
    // Persist language changes to local storage
    localStorage.setItem('appLanguage', language);
  }, [language]);

  // Function to change language
  const changeLanguage = (lang: 'es' | 'en') => {
    i18n.changeLanguage(lang);
    setLanguage(lang);
  };
  // eslint-disable-next-line react/jsx-no-constructed-context-values
  return <LanguageContext.Provider value={{ language, changeLanguage }}>{children}</LanguageContext.Provider>;
};

// Custom hook to use the language context
export const useLanguage = (): LanguageContextType => {
  const context = useContext(LanguageContext);
  if (!context) {
    throw new Error('useLanguage must be used within a LanguageProvider');
  }
  return context;
};
