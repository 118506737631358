import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
// * LOCAL IMPORTS
import IndividualsFaq from './individualsFaq';
import LocationsFaq from './locationsFaq';
import PractitionersFaq from './practitionersFaq';
import TeamsFaq from './teamsFaq';

import { useStyles } from '../styles';

interface TeamsFaqProps {
  setModalOpen: (value: string | null) => void;
  mobileBackMenu: boolean | React.ReactElement;
  userType: string | undefined;
}

export enum SubscriptionType {
  PRACTITIONER = 'practitioner',
  LOCATIONS = 'locations',
  TEAMS = 'teams',
  INDIVIDUAL = 'individual',
}

const FaqsPage: FC<TeamsFaqProps> = ({ setModalOpen, mobileBackMenu, userType }) => {
  const classes = useStyles();

  const { t } = useTranslation();

  const faqItems = () => {
    const items = [
      {
        type: t(SubscriptionType.INDIVIDUAL),
        value: <IndividualsFaq setModalOpen={setModalOpen} key="individualsFaq" />,
      },
      {
        type: t(SubscriptionType.LOCATIONS),
        value: <LocationsFaq setModalOpen={setModalOpen} key="locationsFaq" />,
      },
      {
        type: t(SubscriptionType.PRACTITIONER),
        value: <PractitionersFaq setModalOpen={setModalOpen} key="practitionersFaq" />,
      },
      { type: t(SubscriptionType.TEAMS), value: <TeamsFaq setModalOpen={setModalOpen} key="teamsFaq" /> },
    ];

    return userType
      ? [items.find(item => item.type === userType), ...items.filter(item => item.type !== userType)].map(
          item => item?.value,
        ) /* eslint-disable-line indent */
      : items;
  };

  return (
    <span className={classes.faqsContainer}>
      {mobileBackMenu}
      {faqItems()}
    </span>
  );
};

export default FaqsPage;
