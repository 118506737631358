import React from 'react';
import { ToastContent, ToastOptions } from 'react-toastify';
import { Dispatch, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { StoreState } from 'store/@types';
import { payOrTrial } from 'store/user/thunks';
import { getAllFavorites } from 'store/moods/thunks';

import DefaultComponent from './Page';

const mapStateToProps = ({ user, moods }: StoreState) => ({ user, moods });

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({ payOrTrial, getAllFavorites }, dispatch);

export type ReduxProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(DefaultComponent);

export const notifications: {
  success: ToastOptions;
  error: ToastOptions;
  genericError: ToastContent;
} = {
  success: {
    position: 'top-center',
    autoClose: 7000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  },
  error: {
    position: 'top-center',
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  },
  genericError: (
    <>
      <h3 style={{ fontSize: '16px', fontWeight: 600, margin: '10px 0 0 0' }}>We’re having some issues</h3>
      <p style={{ fontSize: '16px', fontWeight: 400, margin: '5px 0 10px 0' }}>
        Something went wrong. Please try again.
      </p>
    </>
  ),
};
