import React, { FC, useEffect } from 'react';
import { useHistory } from 'react-router';
// * 3rd party libs

import { CircularProgress, Grid, Typography } from '@mui/material';
// * MUI
import { ReactComponent as SinesLogoMix } from 'assets/imgs/sinesLogoMix.svg';
import { ReactComponent as SinesLogo } from 'assets/imgs/sinesLogo.svg';
import { ReactComponent as SinesLogoStack } from 'assets/imgs/sines_logo_stack.svg';
// * ASSETS
import Loading from 'components/Loading';
// * COMPONENTS
// import Page from 'components/Page';
import { useNavigate } from 'react-router-dom';
// * TYPES

// * LOCAL IMPORTS
import { useStyles } from './styles';

const LandingPage: FC = () => {
  const navigate = useNavigate();

  const classes = useStyles();

  function getDeviceType() {
    const ua = navigator.userAgent;
    if (/iPad|iPhone|iPod/.test(ua)) {
      return 'iOS';
    }
    return 'Other';
  }

  const handleRedirect = () => {
    const deviceType = getDeviceType();
    if (deviceType === 'iOS') {
      window.location.href = 'https://apps.apple.com/app/id6443797659'; /// iOS native app
    } else {
      // Navigate to the sign-up page
      navigate('/signup');
    }
  };
  useEffect(() => {
    handleRedirect();
  }, []);

  return (
    <>
      <Grid item xs={12} className={classes.centeredContent}>
        <SinesLogoStack />
      </Grid>
      <Grid item xs={12} className={classes.centeredContent}>
        <CircularProgress />
      </Grid>
      <Grid item xs={12} className={classes.centeredContent}>
        <Typography variant="h6">
          {getDeviceType() === 'iOS'
            ? 'You will be redirected to the App Store to download the iOS application.'
            : 'You will be redirected to the sign-up page.'}
        </Typography>
        <Typography variant="loginSection" component="p">
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid,jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
          <a href="" style={{ color: '#B66481' }} onClick={() => handleRedirect}>
            Click here if you are not redirected automatically.
          </a>
        </Typography>
      </Grid>
    </>
  );
};

export default LandingPage;
