import React, { Dispatch, FC } from 'react';
// * 3rd party libs
import { useForm, useFieldArray } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Grid, IconButton, Typography } from '@mui/material';

// * ASSETS
import { ReactComponent as RemoveUser } from 'assets/imgs/remove_user.svg';
import { ReactComponent as AddRow } from 'assets/imgs/add_row.svg';
import { notifications } from 'components/Page';

// * TYPES
import { IManagersForm, UserInvitePayload, UserInviteResponse } from 'store/@types/b2b';

// * COMPONENTS
import Scrollbars from 'components/Scrollbars';
import Input from 'components/forms/Input';
import { useTranslation } from 'react-i18next';
// * LOCAL IMPORTS
import { toast } from 'react-toastify';
import { AnyAction } from 'redux';
import { defaultValues, schema } from './yupSchema';
import { useStyles } from './styles';

interface IInviteManagers {
  showZones?: boolean;
  numberOfMembers: number;
  availableDevices: number;
  closeModal: () => void;
  inviteUsers: (
    payload: UserInvitePayload[],
    success: (successResponse?: UserInviteResponse | undefined) => void,
  ) => (dispatch: Dispatch<AnyAction>) => Promise<void>;
}

const InviteManagers: FC<IInviteManagers> = ({
  showZones,
  availableDevices,
  numberOfMembers,
  closeModal,
  inviteUsers,
}) => {
  const classes = useStyles();

  const {
    handleSubmit,
    setValue,
    control,
    setError,
    formState: { errors, isValid },
  } = useForm<IManagersForm>({
    mode: 'onTouched',
    resolver: yupResolver(schema(availableDevices)),
    reValidateMode: 'onChange',
    defaultValues,
    shouldUnregister: false,
  });
  const { fields, append, remove } = useFieldArray<IManagersForm, 'managers'>({
    control,
    name: 'managers',
  });

  const { t } = useTranslation();

  const handleSubmitTeamMembers = (payl: IManagersForm | null): void => {
    if (payl) {
      inviteUsers &&
        inviteUsers(payl.managers, (successResponse?: UserInviteResponse) => {
          if (successResponse) {
            const successInvitesSent = payl.managers.length - successResponse.invitedManagers.length;

            if (successInvitesSent === 0) {
              toast(
                t('invitemanager_toast1'), // 'The invite was sent successfully!'
                {
                  ...notifications.success,
                  toastId: 'inviteSuccessfully',
                },
              );
              closeModal();
            } else {
              // eslint-disable-next-line no-plusplus
              for (let i = payl.managers.length - 1; i >= 0; i--) {
                if (successResponse.duplicateManagers.some(email => email === payl.managers[i].email)) {
                  setError(`managers.${i}.email`, {
                    type: 'manual',
                    message: t('invitemanager_error1'),
                    // 'This user is already registered.'
                  });
                } else if (successResponse.failedManagers.some(email => email === payl.managers[i].email)) {
                  setError(`managers.${i}.email`, {
                    type: 'manual',
                    message: t('invitemanager_error2'),
                    // 'Your invite was not sent, please try again.',
                  });
                } else {
                  remove(i);
                }
              }

              if (successResponse.invitedManagers.length > 0) {
                toast(
                  `${successInvitesSent} ${
                    successInvitesSent > 1 ? t('invites') : t('invite') /// invites : invite
                  } ${t('invitemanager_toast2')}`,
                  // sent successfully, but some errors occurred.`,
                  {
                    ...notifications.success,
                    toastId: 'inviteSuccessfullyWithErrors',
                  },
                );
              }
            }
          }
        });
    }
  };

  const handleInputZones = (
    zones: string,
    name: 'managers' | `managers.${number}` | `managers.${number}.email` | `managers.${number}.zones`,
  ): void => {
    if (+zones < 1) {
      setValue(name, '');
    }
  };
  return (
    <form
      id="invite-manager-form"
      onSubmit={handleSubmit(handleSubmitTeamMembers)}
      style={{ width: '100%' }}
      key="inviteManagerForm">
      <Grid container className={classes.mainGridContainer}>
        <IconButton className={classes.closeButton} onClick={() => closeModal()}>
          <RemoveUser />
        </IconButton>
        <Grid item xs={12}>
          <Typography gutterBottom component="p" variant="section">
            {t('Invite') /* Invite */}
          </Typography>
          <Typography component="p" variant="listAuthor">
            {!showZones
              ? `Your team has currently ${numberOfMembers} ${numberOfMembers === 1 ? 'person' : 'people'}, and `
              : `You currently have ${numberOfMembers} ${numberOfMembers === 1 ? 'location' : 'locations'} and `}
            {availableDevices}
            {/* eslint-disable-next-line no-nested-ternary */}
            {showZones
              ? availableDevices === 1
                ? ` ${t('zone')}`
                : ` ${t('zones')}`
              : availableDevices === 1
              ? ' seat'
              : ` ${t('seats')}`}
            {t('invite_managers_available') /* available. */}
          </Typography>
        </Grid>
        <Grid item xs={showZones ? 6 : 12} className={classes.emailTypoWrapper}>
          <Typography variant="listTrack">{t('Email') /* Email */}</Typography>
        </Grid>
        <Scrollbars height="400px">
          <Grid item xs={12} className={classes.fieldArrayWrapper}>
            {fields.map((field, index) => (
              <Grid container key={field.id} spacing={4}>
                <Grid item xs={12} md={showZones ? 6 : 12} className={classes.inviteItemContainer}>
                  <Input
                    required
                    name={`managers.${index}.email`}
                    type="email"
                    placeholder={
                      t('invitemanager_teamm_placeholder') // "Team Member's e-mail"
                    }
                    control={control}
                    errors={errors}
                  />
                  {!showZones && (
                    <IconButton onClick={() => remove(index)} sx={{ height: 60, margin: '0 20px' }}>
                      <RemoveUser />
                    </IconButton>
                  )}
                </Grid>
                {showZones && (
                  <Grid item xs={6} className={classes.zonesTypoWrapper}>
                    <Typography variant="listTrack">
                      {t('invitemanagers_nozones_assigned') /* Number of Zones assigned */}
                    </Typography>
                  </Grid>
                )}
                {showZones && (
                  <Grid item xs={12} md={6} className={classes.inviteItemContainer}>
                    <Input
                      required
                      name={`managers.${index}.zones`}
                      type="number"
                      placeholder={t('Zones')}
                      // "Zones"
                      control={control}
                      errors={errors}
                      additionalOnChange={value => handleInputZones(value, `managers.${index}.zones`)}
                    />
                    <IconButton onClick={() => remove(index)} sx={{ height: 60, margin: '0 20px' }}>
                      <RemoveUser />
                    </IconButton>
                  </Grid>
                )}
              </Grid>
            ))}
            <Box className={classes.addMoreButton}>
              {/* If we have to hide the add button just uncomment the line below  */}
              {/* {availableDevices - fields.length > 0 && ( */}
              <Button
                onClick={() => append({ email: '', zones: 1 })}
                endIcon={<AddRow />}
                className={classes.addMoreButtonText}>
                {t('Addmore') /* Add more */}
              </Button>
              {/* )} */}
            </Box>
          </Grid>
        </Scrollbars>
        <Button
          className={classes.inviteManagerButton}
          variant={isValid ? 'primary' : 'disabled'}
          type="submit"
          form="invite-manager-form">
          <Typography>{t('Invite') /* Invite */}</Typography>
        </Button>
      </Grid>
    </form>
  );
};

InviteManagers.defaultProps = { showZones: false };

export default InviteManagers;
