import React, { FC, useEffect } from 'react';
// * 3rd party libs
import { yupResolver } from '@hookform/resolvers/yup';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';

import { Button, Grid, Typography } from '@mui/material';
import ChevronLeft from '@mui/icons-material/ChevronLeft';

// * TYPES
import { RecoverPwPost } from 'store/@types/user';

// * ASSETS
import EmailSent from 'assets/imgs/email_sent.png';

// * COMPONENTS
import NoAuthPage from 'components/NoAuthPage/NoAuthPage';
import Input from 'components/forms/Input';
import Loading from 'components/Loading';
import { useTranslation } from 'react-i18next';
// * LOCAL IMPORTS
import { schema, defaultValues } from './yupSchema';
import { ReduxProps } from '.';
import { useStyles } from './styles';

const PasswordRecovery: FC<ReduxProps> = ({ user, recoverPw }) => {
  const { loading } = user;
  const [match] = useSearchParams();
  const classes = useStyles();
  const navigate = useNavigate();

  const successOperation = match.get('success') === 'true';

  const {
    handleSubmit,
    control,
    watch,
    formState: { errors, isValid },
  } = useForm<RecoverPwPost>({
    mode: 'onTouched',
    resolver: yupResolver(schema),
    reValidateMode: 'onChange',
    defaultValues,
    shouldUnregister: false,
  });

  const { t } = useTranslation();

  useEffect(() => {}, [successOperation]);

  const onSubmit = (payload: RecoverPwPost) =>
    recoverPw(payload, () => {
      navigate('/recover?success=true');
    });

  return (
    <>
      {loading && <Loading />}
      <form id="recover-pw" onSubmit={handleSubmit(onSubmit)}>
        <NoAuthPage>
          <Grid item xs={12} style={{ marginTop: '40px', padding: 0 }}>
            <Button
              variant="text"
              className={classes.backToLoginGrid}
              onClick={() => {
                navigate('/login');
              }}>
              <ChevronLeft />
              <Typography variant="carrouselItem">{t('passwordrec_backtosignin') /* Back to Sign in */}</Typography>
            </Button>
          </Grid>
          {successOperation ? (
            <>
              <Grid item xs={12}>
                <img src={EmailSent} style={{ width: 142, height: 142 }} alt="email-sent" />
              </Grid>
              <Grid item xs={12}>
                <Typography gutterBottom variant="heroTitle" component="p" textAlign="center">
                  {t('passwordrec_checkinbox') /* Check your inbox */}
                </Typography>
                <Typography variant="listAuthor" component="p" textAlign="center">
                  {t('passwordrec_sentinstr') /* We’ve sent password recovery instructions to your email address. */}
                </Typography>
              </Grid>
              <Grid item xs={12} marginTop="50px">
                <Typography variant="loginSection" component="p">
                  {t('passwordrec_didnotreceive') /* Didn’t receive the email? */}
                </Typography>
                <Typography variant="loginSection" component="p">
                  {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                  <a
                    href="#"
                    style={{ color: '#B66481' }}
                    onClick={e => {
                      e.preventDefault();
                      onSubmit(watch());
                    }}>
                    {/* Send again */}
                  </a>
                </Typography>
              </Grid>
            </>
          ) : (
            <>
              <Grid item xs={12}>
                <Typography gutterBottom variant="heroTitle" component="p">
                  {t('passwordrec_reset') /* Reset your password */}
                </Typography>
                <Typography variant="listAuthor" component="p">
                  {
                    t(
                      'passwordrec_email_instr',
                    ) /* Enter the email associated with your account and we’ll send an email with instructions to reset your
                  password. */
                  }
                </Typography>
              </Grid>
              <Input grid required name="email" placeholder="Email address" control={control} errors={errors} />
              <Grid item xs={12}>
                <Button variant={isValid ? 'primary' : 'disabled'} fullWidth type="submit">
                  {t('passwordrec_resetpass') /* Reset password */}
                </Button>
              </Grid>
            </>
          )}
        </NoAuthPage>
      </form>
    </>
  );
};

export default PasswordRecovery;
