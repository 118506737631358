import { restCall } from 'utils/restHandler';
import { GET, PATCH, DELETE, POST } from 'utils/requestTypes';
import { IBusinessInformationPost, UserBusiness, UserInvitePayload } from 'store/@types/b2b';

export const apiGetBusinessInfo = () => restCall<UserBusiness>(GET, '/user/me/business');

export const apiUpdateBusinessInfo = (payload: IBusinessInformationPost) =>
  restCall<UserBusiness>(PATCH, '/user/me/business', payload);

export const apiInviteUsers = (payload: UserInvitePayload[]) => restCall(POST, '/user/invite', payload);

export const apiUpdateUserZones = (id: string, devices: number) =>
  restCall(PATCH, `/user/me/business/business-user/${id}`, {
    devices,
  });

export const apiDeleteUserFromBusiness = (id: string) => restCall(DELETE, `/user/me/business/business-user/${id}`);
