import { action } from 'typesafe-actions';
import { IDispatchType } from 'store/@types';
import { B2BActions as Actions } from 'store/@types/b2b';

export const setLoading = (): IDispatchType => action(Actions.SET_LOADING);

export const setError = (payload: unknown | null): IDispatchType => action(Actions.SET_ERROR, payload);

export const setBusinessInfo = (payload: unknown): IDispatchType => action(Actions.SET_GET_BUSINESS_INFO, payload);

export const setUpdateBusinessInfo = (payload: unknown): IDispatchType =>
  action(Actions.SET_UPDATE_BUSINESS_INFO, payload);

export const setInviteUsers = (payload: unknown): IDispatchType => action(Actions.SET_INSERT_USERS, payload);
export const setUpdateUsers = (payload: unknown): IDispatchType => action(Actions.SET_UPDATE_USERS, payload);
export const setDeleteUsers = (payload: unknown): IDispatchType => action(Actions.SET_DETELE_B2B_USERS, payload);
