import { CustomReactTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: CustomReactTheme) => ({
  tabsButtonsContainer: {
    width: 'fit-content',
    background: 'linear-gradient(to right, #775DA8, #F06B5D)',
    borderRadius: '22px',
  },
  root: {
    padding: '20px 40px 100px 40px',
  },
  tabsButtons: {
    background: '#0C0C0C',
    margin: '1px',
    '&.Mui-selected': {
      background: '#242424',
      color: 'white',
    },
    textTransform: 'capitalize',
    color: '#F4F4F4',
    fontFamily: 'Montserrat',
    fontSize: '14px',
    lineHeight: '17px',
    textAlign: 'center',
  },
  navTabWrapper: { padding: '20px' },

  //* TABLET - landScape
  [theme.breakpoints.down('lg')]: {
    navTabWrapper: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
    },
    root: {
      padding: '10px 40px 200px 40px',
    },
  },
  //* TABLET - portrait
  [theme.breakpoints.down('md')]: {
    navTabWrapper: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
    },
  },
  //* MOBILE
  [theme.breakpoints.down('sm')]: {
    navTabWrapper: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
    },
    root: {
      padding: '10px 20px 180px 20px',
    },
  },
}));

export { useStyles };
