/* eslint-disable no-console */
import React, { FC } from 'react';
// * 3rd party libs

import { Typography, CardActionArea, Skeleton } from '@mui/material';

// * ASSETS

// * COMPONENTS
import useMusicPlayer from 'hooks/useMusicPlayer';
import DivImage from 'components/DivImage';

// * TYPES
import { Category, Playlist } from 'store/@types/moods';

// * LOCAL IMPORTS
import { useStyles } from './styles';

export interface MoodCardProps {
  item?: Category | Playlist;
  loading: boolean;
}

const isPlaylist = (item: Category | Playlist): Playlist | null => {
  if ((item as Playlist).tracks) return item as Playlist;
  return null;
};

const MoodCard: FC<MoodCardProps> = ({ item, loading }) => {
  const classes = useStyles();
  const { setIsMoodsResult, setIsPlaylist, setAlbumView } = useMusicPlayer();

  const clickHandler = (): void => {
    if (!item) return;
    if (isPlaylist(item)) {
      setAlbumView(item as Playlist);
      setIsPlaylist('open');
    } else {
      const { playlists } = item as Category;
      if (!playlists) return;

      if (playlists.length > 1) {
        setIsMoodsResult(item as Category);
      } else {
        setAlbumView(playlists[0]);
        setIsPlaylist('open');
      }
    }
  };

  if (loading)
    return (
      <>
        {[1, 2, 3, 4, 5, 6].map(e => (
          <div key={e} className={classes.square}>
            <Skeleton className={classes.skeletonBars} animation="wave" variant="text" />
          </div>
        ))}
      </>
    );

  if (!item) return null;
  return (
    <DivImage customClasses={classes.square} src={item.image} smallSrc={item.smallImage}>
      <CardActionArea className={classes.clickableMenu} onClick={clickHandler} />
      <Typography variant="h1" component="div" marginTop="7px" className={classes.title} onClick={clickHandler}>
        {item.name}
      </Typography>
    </DivImage>
  );
};

MoodCard.defaultProps = { item: undefined };

export default MoodCard;
