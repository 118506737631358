import { CustomReactTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: CustomReactTheme) => ({
  dialog: {
    '& .MuiDialog-paper': {
      width: '286px',
      minHeight: '286px',
      backgroundColor: '#181818',
      borderRadius: '20px',
      display: 'flex',
      flexDirection: 'row',
    },
  },
  menuContainer: {
    gap: '40px',
    color: 'white',
    display: 'flex',
    padding: '40px 30px 40px 270px',
    flexWrap: 'nowrap',
    height: '698px',
    maxHeight: '698px',
    width: '100%',
    maxWidth: '698px',
    textAlign: 'justify',
    alignItems: 'center',
    alignContent: 'center',
    flexDirection: 'column',
    justifyContent: 'flex-start',
  },
  faqsContainer: {
    gap: '20px',
    width: '100%',
    display: 'flex',
    flexWrap: 'nowrap',
    paddingBottom: '40px',
    flexDirection: 'column',
  },
  faqsSpan: {
    gap: '20px',
    width: '100%',
    display: 'flex',
    flexWrap: 'nowrap',
    paddingBottom: '40px',
    flexDirection: 'column',
    fontSize: '16px',
    fontWeight: '500',
    textAlign: 'left',
  },
  ethosTypo: {
    fontSize: '16px',
    fontWeight: '500',
    textAlign: 'left',
    paddingTop: '10px',
  },
  guidebookTypo: {
    fontSize: '16px',
    fontWeight: '500',
    textAlign: 'left',
    paddingTop: '10px',
  },
  qrCodeWrapper: {
    paddingTop: '10px',
    paddingLeft: '20px',
    display: 'flex',
    alignItems: 'end',
  },
  appStore: {
    width: '90px',
    height: '30px',
    marginLeft: '10px',
    cursor: 'pointer',
  },
  poweredWrapper: {
    paddingTop: '8px',
    paddingLeft: '20px',
  },
  poweredTypo: {
    color: '#FFFFFF',
    fontFamily: 'Montserrat',
    fontSize: '10px',
    lineHeight: '12px',
    textAlign: 'left',
  },
  nearTypo: {
    color: '#B66481',
    fontFamily: 'Montserrat',
    fontSize: '14px',
    lineHeight: '18px',
    textAlign: 'left',
  },
  //* TABLET - landScape
  [theme.breakpoints.down('lg')]: {},
  //* TABLET - portrait
  [theme.breakpoints.down('md')]: {},
  //* MOBILE
  [theme.breakpoints.down('sm')]: {
    menuContainer: {
      padding: '0 20px',
    },
  },
}));

export { useStyles };
