import React, { FC } from 'react';
// * 3rd party libs
import { Grid, Typography } from '@mui/material';

// * ASSETS

// * TYPES
import { ISubscription, StripeCheckout } from 'store/@types/user';
import { UserBusiness } from 'store/@types/b2b';
import { useTranslation } from 'react-i18next';
// * COMPONENTS

// * LOCAL IMPORTS

import { Dispatch, AnyAction } from 'redux';
import { useStyles } from './styles';

interface IProps {
  userBusiness: UserBusiness | null;
  plan: ISubscription;
  updatePlan: (
    success?: ((payload: StripeCheckout) => void) | undefined,
  ) => (dispatch: Dispatch<AnyAction>) => Promise<void>;
}

// eslint-disable-next-line no-unused-vars
const SubscriptionBilling: FC<IProps> = ({ plan, userBusiness, updatePlan }) => {
  const classes = useStyles();

  const { t } = useTranslation();

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} paddingBottom="20px">
        <Typography variant="section">{t('Yoursubscriptionplan') /* Your subscription plan */}</Typography>
      </Grid>
      <Grid container className={classes.bodyContainerWrapper}>
        <Grid item xs={12}>
          <Typography sx={{ textTransform: 'capitalize' }} variant="section">
            {plan.type}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="listAuthor">
            {t('subscriptionbilling_1') /* Your subscription has currently  */}
            {plan.devices} {plan.type === 'locations' ? t('zones') : t('teamseats') /* 'team member seats' */}.
          </Typography>
        </Grid>
        <Grid item xs={12} className={classes.updateButton}>
          <Typography
            variant="listTrack"
            style={{ cursor: 'pointer' }}
            onClick={() => {
              updatePlan(data => {
                // eslint-disable-next-line no-restricted-globals
                if (data.session.url) open(data.session.url);
              });
            }}>
            {t('Updateplan') /* Update plan */}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default SubscriptionBilling;
