import React, { FC, useEffect } from 'react';
// * 3rd party libs
import { useNavigate } from 'react-router-dom';
// import { useNavigate } from 'react-router-dom';

import { Grid } from '@mui/material';

// * ASSETS
// * TYPES

// * COMPONENTS
import HorizontalList from 'components/HorizontalList';
import Loading from 'components/Loading';
import useResponsive from 'hooks/useResponsive';
import useAuth from 'hooks/useAuth';
import { useTranslation } from 'react-i18next';
// * LOCAL IMPORTS
import { useStyles } from './styles';

import { ReduxProps } from '.';

const HomePage: FC<ReduxProps> = ({
  user,
  moods,
  getSpaces,
  getMoods,
  getGuided,
  getUserPlaylistHistory,
  getUserLastPlaylistHistory,
}) => {
  const { t } = useTranslation();
  const { error: userError, userHistory, userLastHistory, loading: userLoading } = user;
  const { loadingSpaces, loadingMoods, loadingGuided, error, moodsList, spacesList, guidedList } = moods;
  const navigate = useNavigate();
  const classes = useStyles();
  const { auth, user: userInfo } = useAuth();
  const { scrollRef } = useResponsive();
  const userHasSubscription = userInfo?.subscription.active;
  const spacesPlanType = auth?.subscription?.type === 'locations';
  useEffect(
    () => () => {
      getUserPlaylistHistory(true);
      scrollRef.current?.scrollToTop();
    },
    [],
  );

  useEffect(() => {
    if (!auth) return;
    if (!userHasSubscription) return;
    if (!spacesPlanType) return;
    if (!spacesList && !error) {
      getSpaces();
    }
  }, [spacesList, auth, userHasSubscription]);

  useEffect(() => {
    if (!auth) return;
    if (!userHasSubscription) return;
    if (!moodsList && !error) {
      getMoods();
    }
  }, [moodsList, auth, userHasSubscription]);

  useEffect(() => {
    if (!auth) return;
    if (!userHasSubscription) return;
    if (!guidedList && !error) {
      getGuided();
    }
  }, [guidedList, auth, userHasSubscription]);

  useEffect(() => {
    if (!auth) return;
    if (!userHasSubscription) return;
    if (!userHistory && !userError) {
      getUserPlaylistHistory();
    }
  }, [userHistory, auth, userHasSubscription]);

  useEffect(() => {
    if (!auth) return;
    if (!userHasSubscription) return;
    if (!userLastHistory && !userError) {
      getUserLastPlaylistHistory();
    }
  }, [userLastHistory, auth, userHasSubscription]);

  const { reduceItemsInList } = useResponsive();
  return (
    <>
      {(loadingSpaces || loadingMoods || loadingGuided || userLoading) && <Loading />}
      <Grid
        container
        spacing={2}
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
        className={classes.root}>
        {spacesPlanType &&
          (spacesList && spacesList?.length !== 0 ? (
            <HorizontalList title={t('Spaces')} list={spacesList ?? []} type="moodList" />
          ) : (
            loadingSpaces && <HorizontalList title={t('Spaces')} list={[]} loading type="moodList" />
          ))}
        {moodsList && moodsList?.length !== 0 ? (
          <HorizontalList
            title={t('MusicSounds')}
            // "Music + Sounds"
            list={moodsList}
            type="squareList"
            rightAction={{
              label: t('home_SeeAll'),
              // 'See all >'
              action: () => navigate('/moods'),
            }}
          />
        ) : (
          loadingMoods && <HorizontalList title={t('Moods')} list={[]} loading type="squareList" />
        )}
        {guidedList && guidedList?.length !== 0 ? (
          <HorizontalList title={t('Guided')} list={guidedList} type="moodList" />
        ) : (
          loadingGuided && <HorizontalList title={t('Guided')} list={[]} loading type="moodList" />
        )}
        {userLastHistory?.items?.length !== 0 ? (
          <HorizontalList
            title={t('home_recentlyplayed')}
            // "Recently played"
            list={userLastHistory?.items.map(e => e.track) || []}
            type="recentList"
            moodsList={moodsList}
            guidedList={guidedList}
          />
        ) : (
          userLoading && (
            <HorizontalList
              title={t('home_recentlyplayed')}
              // "Recently played"
              list={[]}
              loading
              type="recentList"
            />
          )
        )}
        {userHistory?.items?.length !== 0 && (
          <HorizontalList
            title={t('home_yourmostplayed')}
            // "Your most played"
            list={reduceItemsInList(userHistory?.items || [], 'type3', [6, 6, 5, 5, 5])}
            type="type3"
            moodsList={moodsList}
            guidedList={guidedList}
          />
        )}
      </Grid>
    </>
  );
};

export default HomePage;
