import { CustomReactTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: CustomReactTheme) => ({
  root: {
    zIndex: 2,
    height: '58px',
    maxHeight: '58px',
    width: '100%',
    display: 'flex',
    backgroundPosition: 'left',
    backgroundSize: 'cover',
    cursor: 'pointer',
    marginBottom: '10px',
    '&:hover': {
      background: '#363636',
      borderRadius: '10px',
    },
  },
  coverImageWrapper: {
    display: 'flex',
    alignSelf: 'center',
  },
  clickableMusicDisplay: {
    width: '100%',
    display: 'flex',
    backgroundPosition: 'left',
  },
  musicTextPlayer: {
    padding: '5px 0px 6px 9px',
    flexGrow: 1,
    justifyContent: 'space-between',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  playerButton: {
    color: '#fff',
  },
  coverImgMobile: {
    borderRadius: '10px',
    boxShadow: '0 0 10px 0 rgba(31, 31, 31, 0.5)',
    height: '58px',
    maxHeight: '58px',
    width: '58px',
    maxWidth: '58px',
    bottom: '20px',
  },
  coverImgMobileDefault: {
    height: '18px',
    maxHeight: '18px',
    width: '18px',
    maxWidth: '18px',
    bottom: '20px',
    background: '#292929',
    boxShadow: '0 0 10px 0 rgb(31 31 31 / 50%)',
    borderRadius: '10px',
    padding: '22px',
  },
  //* TABLET - landScape
  [theme.breakpoints.down('lg')]: {},
  //* TABLET - portrait
  [theme.breakpoints.down('md')]: {},
  //* MOBILE
  [theme.breakpoints.down('sm')]: {},
}));

export { useStyles };
