import React from 'react';
import { useTranslation } from 'react-i18next';
import { useRouteError } from 'react-router-dom';

const ErrorPage = () => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const error: any = useRouteError();

  const { t } = useTranslation();

  return (
    <main id="error-content">
      <h1>{t('error_occured') /* An error occurred! */}!</h1>
      <p>{error.statusText}</p>
    </main>
  );
};

export default ErrorPage;
