import { CustomReactTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: CustomReactTheme) => ({
  root: {
    overflow: 'hidden',
    padding: '20px 40px 100px 40px',
  },
  //* TABLET - landScape
  [theme.breakpoints.down('lg')]: {
    root: {
      padding: '10px 40px 200px 40px',
    },
  },
  //* TABLET - portrait
  [theme.breakpoints.down('md')]: {},
  //* MOBILE
  [theme.breakpoints.down('sm')]: {
    root: {
      padding: '10px 20px 180px 20px',
    },
  },
}));

export { useStyles };
