/* eslint-disable no-nested-ternary */
import React from 'react';
import { Grid, Typography, Button } from '@mui/material';
import { ReactComponent as SinesLogo } from 'assets/imgs/sinesLogo.svg';
import { useTranslation } from 'react-i18next';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const TrialOngoing = ({ days, classes, handleSubscribe, handleTrial, hours }: any) => {
  const { t } = useTranslation();

  return (
    <Grid
      container
      spacing={2}
      direction="row"
      flexWrap="wrap"
      alignContent="flex-start"
      justifyContent="center"
      justifyItems="center"
      className={classes.dialogContainer}>
      <Grid item xs={12}>
        <SinesLogo />
      </Grid>
      <Grid item xs={12}>
        <Typography className={classes.title}>
          {`${t('tunedto')}` /* Tuned to <br /> Wellness! */}
          <br />
          {`${t('wellness')}` /* Tuned to <br /> Wellness! */}
        </Typography>
      </Grid>
      <Grid item xs={12} className={classes.descriptionGrid}>
        <Typography className={classes.subTitle}>
          {
            t(
              'ongoingtrial_hopeyouenjoy',
            ) /* We hope you are enjoying SINES and feeling the best version of yourself! You have */
          }{' '}
          {
            hours > 24
              ? days > 1
                ? `${days} ${t('days')}`
                : `${days} ${t('day')}`
              : t('ongoingtrial_lessthanday') /* 'less than a day' */
          }{' '}
          {t('ongoingtrial_leftonyourfreetrial') /* left on your free trial. */}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Button variant="primary" className={classes.button1} onClick={handleSubscribe}>
          {t('Subscribe')}
        </Button>
      </Grid>
      <Grid item xs={12}>
        <Button variant="primary" className={classes.button2} onClick={handleTrial}>
          <span className={classes.buttonSpan1} />
          <span className={classes.buttonSpan2}>{t('continuetrial')}</span>
        </Button>
      </Grid>
    </Grid>
  );
};

export default TrialOngoing;
