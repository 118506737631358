/* eslint-disable react/require-default-props */
import React, { FC } from 'react';
import { useLocation, Navigate, Outlet } from 'react-router-dom';
import useAuth from 'hooks/useAuth';
import { getLSField } from 'utils/cookies';
import Page from 'components/Page';

const RequireAuth: FC = () => {
  const { auth } = useAuth();
  const location = useLocation();

  const userToken: string | null = getLSField('token');

  const payload = auth && Object.keys(auth).length === 0 ? userToken : auth;

  if (!auth) return null;

  if (!payload) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return (
    <Page>
      <Outlet />
    </Page>
  );
};
RequireAuth.defaultProps = {
  allowedRoles: undefined,
};

export default RequireAuth;
