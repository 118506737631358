import useAuth from 'hooks/useAuth';
import { FC, useEffect } from 'react';
import { Outlet } from 'react-router-dom';

import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { StoreState } from 'store/@types';
import { refreshToken } from 'store/user/thunks';
import { getLSField } from 'utils/cookies';

const RootLayout: FC<ReduxProps> = ({ user, storeRefreshToken }) => {
  const { userInfo: apiResponseUser } = user;
  const { auth, setAuth, setUser } = useAuth();
  const userToken = getLSField('token');

  useEffect(() => {
    const getToken = () => {
      if (userToken) {
        storeRefreshToken();
      }
    };

    if (!auth.name && userToken) {
      setAuth(userToken);
    } else if (auth) {
      getToken();
    }
  }, [auth]);

  useEffect(() => {
    setUser(apiResponseUser?.user || null);
  }, [apiResponseUser]);

  return (
    <main style={{ overflow: 'hidden' }}>
      <Outlet />
    </main>
  );
};

// eslint-disable-next-line react/require-default-props
const mapStateToProps = ({ user }: StoreState) => ({ user });

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({ storeRefreshToken: refreshToken }, dispatch);

export type ReduxProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(RootLayout);
