import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  customCheckboxLabel: {
    color: '#B3B3B3',
    fontSize: '14px',
    lineHeight: '17px',
    textAlign: 'justify',
  },
  customCheckboxRoot: {
    minWidth: '15rem',
  },
  helperText: {
    marginTop: '-5px',
    marginLeft: '13px',
  },
}));

export { useStyles };
