import { CustomReactTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: CustomReactTheme) => ({
  divider: {
    width: '100%',
    backgroundColor: '#353535',
  },
  bodyWrapper: {
    flexDirection: 'row',
    display: 'flex',
    justifyContent: 'space-between',
    paddingTop: '10px',
    paddingBottom: '10px',
  },
  userPhoto: {
    width: '48px',
    height: '48px',
    borderRadius: '10px',
  },
  typoWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '80%',
    alignSelf: 'center',
    paddingLeft: '10px',
  },
  //* TABLET - landScape
  [theme.breakpoints.down('lg')]: {},
  //* TABLET - portrait
  [theme.breakpoints.down('md')]: {},
  //* MOBILE
  [theme.breakpoints.down('sm')]: {},
}));

export { useStyles };
