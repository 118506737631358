export interface CardSizesItemProps {
  default: (string | number)[];
  xl: (string | number)[];
  lg: (string | number)[];
  md: (string | number)[];
  sm: (string | number)[];
}
interface CardSizesProps {
  [label: string]: CardSizesItemProps;
}
const SCROLL_BIGITEM_LIST = 20;
const SCROLL_SMALLITEM_LIST = 30;

export const cardSizes: CardSizesProps = {
  recent: {
    default: [8, 112],
    xl: [7, 100],
    lg: [6, 97],
    md: [5, 95],
    sm: [4, 93],
  },
  recentList: {
    default: [SCROLL_SMALLITEM_LIST, 112],
    xl: [SCROLL_SMALLITEM_LIST, 100],
    lg: [SCROLL_SMALLITEM_LIST, 97],
    md: [SCROLL_SMALLITEM_LIST, 95],
    sm: [SCROLL_SMALLITEM_LIST, 93],
  },
  square: {
    default: ['auto-fill', 133],
    xl: ['auto-fill', 114],
    lg: ['auto-fill', 108],
    md: ['auto-fill', 106],
    sm: ['auto-fill', 104],
  },
  squareList: {
    default: [SCROLL_BIGITEM_LIST, 133],
    xl: [SCROLL_BIGITEM_LIST, 114],
    lg: [SCROLL_BIGITEM_LIST, 108],
    md: [SCROLL_BIGITEM_LIST, 106],
    sm: [SCROLL_BIGITEM_LIST, 104],
  },
  mood: {
    default: [5, 200],
    xl: [5, 187],
    lg: [4, 174],
    md: [3, 162],
    sm: [2, 150],
  },
  moodList: {
    default: [SCROLL_BIGITEM_LIST, 235],
    xl: [SCROLL_BIGITEM_LIST, 235],
    lg: [SCROLL_BIGITEM_LIST, 220],
    md: [SCROLL_BIGITEM_LIST, 220],
    sm: [SCROLL_BIGITEM_LIST, 220],
  },
  moodMenu: {
    default: ['auto-fill', 160],
    xl: ['auto-fill', 160],
    lg: ['auto-fill', 160],
    md: ['auto-fill', 160],
    sm: ['auto-fill', 98],
  },
  searchPlaylist: {
    default: [3, '1fr'],
    xl: [3, '1fr'],
    lg: [2, '1fr'],
    md: [2, '1fr'],
    sm: [1, '1fr'],
  },
  type3: {
    default: [3, '1fr'],
    xl: [2, '1fr'],
    lg: [1, '1fr'],
    md: [1, '1fr'],
    sm: [1, '1fr'],
  },
  searchItems: {
    default: [5, '1fr'],
    xl: [4, '1fr'],
    lg: [2, '1fr'],
    md: [2, '1fr'],
    sm: [1, '1fr'],
  },
  // - HALF CARD SIZES -
  recentHalf: {
    default: [5, 112],
    xl: [4, 100],
    lg: [5, 97],
    md: [5, 95],
    sm: [4, 93],
  },
  moodHalf: {
    default: [3, 200],
    xl: [3, 187],
    lg: [2, 174],
    md: [2, 162],
    sm: [2, 150],
  },
  type3Half: {
    default: [2, '1fr'],
    xl: [1, '1fr'],
    lg: [1, '1fr'],
    md: [1, '1fr'],
    sm: [1, '1fr'],
  },
  searchItemsHalf: {
    default: [3, '1fr'],
    xl: [2, '1fr'],
    lg: [2, '1fr'],
    md: [1, '1fr'],
    sm: [1, '1fr'],
  },
};
