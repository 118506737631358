import { restCall } from 'utils/restHandler';
import { GET } from 'utils/requestTypes';
import { Category } from 'store/@types/moods';

export const sendGetMood = () => restCall<Category>(GET, '/category/moods');

export const sendGetSpaces = () => restCall<Category>(GET, '/category/spaces');

export const sendGetGuided = () => restCall<Category>(GET, '/category/meditation');

export const sendGetCategoryPlaylists = (mood: string) => restCall<Category>(GET, `/category/${mood}`);
