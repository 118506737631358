import React, { FC } from 'react';
import Box from '@mui/material/Box';

interface TabPanelProps {
  index: number;
  value: number;
}

const TabPanel: FC<TabPanelProps> = ({ children, value, index, ...other }) => (
  <div
    role="tabpanel"
    hidden={value !== index}
    id={`simple-tabpanel-${index}`}
    aria-labelledby={`simple-tab-${index}`}
    {...other}>
    {value === index && <Box sx={{ pt: '40px', maxWidth: 708 }}>{children}</Box>}
  </div>
);

export default TabPanel;
