import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  w100: {
    width: '100%',
  },
  input: {
    '&:-webkit-autofill': {
      WebkitBoxShadow: '0 0 0 1000px #2A2A2A inset',
      WebkitTextFillColor: '#FFFFFF !important',
    },
  },
}));

export { useStyles };
