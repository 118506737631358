import { CustomReactTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: CustomReactTheme) => ({
  mainGridWrapper: {
    padding: '20px 10px',
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'justify',
    alignContent: 'flex-start',
  },
  alignContentCenter: {
    display: 'flex',
    justifyContent: 'center',
  },
  infoWrapper: {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: '40px',
  },
  buttonWrapper: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '30px',
  },
  dividerWrapper: {
    justifyContent: 'end',
    alignItems: 'end',
    display: 'flex',
    orientation: 'column',
  },
  divider: {
    backgroundColor: '#B3B3B3',
    height: '20px',
    alignSelf: 'end',
  },
  removeButton: {
    cursor: 'pointer',
  },
  cancelButton: {
    cursor: 'pointer',
    color: '#B66481',
  },
  //* TABLET - landScape
  [theme.breakpoints.down('lg')]: {},
  //* TABLET - portrait
  [theme.breakpoints.down('md')]: {},
  //* MOBILE
  [theme.breakpoints.down('sm')]: {},
}));

export { useStyles };
