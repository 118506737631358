import { action } from 'typesafe-actions';
import { IDispatchType } from 'store/@types';
import { UserActions as Actions } from 'store/@types/user';

export const setLoading = (payload = true): IDispatchType => action(Actions.SET_LOADING, payload);

export const setError = (payload: unknown): IDispatchType => action(Actions.SET_ERROR, payload);

export const setLogin = (payload: unknown): IDispatchType => action(Actions.SET_LOGIN, payload);

export const setMostPlayedPlaylists = (payload: unknown): IDispatchType => action(Actions.SET_USER_HISTORY, payload);

export const setLastPlayedPlaylists = (payload: unknown): IDispatchType =>
  action(Actions.SET_USER_LAST_HISTORY, payload);
