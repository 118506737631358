import { CustomReactTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: CustomReactTheme) => ({
  root: {
    width: '100%',
    maxWidth: '500px',
    padding: '30px',
    textAlign: 'center',
    margin: '0 auto',
  },
  logoAuth: {
    width: '122px',
    height: '84px',
    cursor: 'pointer',
  },
  //* TABLET - landScape
  [theme.breakpoints.down('lg')]: {
    root: {
      maxWidth: '400px',
      padding: '30px',
      textAlign: 'center',
    },
  },
  //* TABLET - portrait
  [theme.breakpoints.down('md')]: {
    root: {
      padding: '20px',
      textAlign: 'center',
    },
  },
  //* MOBILE
  [theme.breakpoints.down('sm')]: {
    logoAuth: {
      width: '93px',
      height: '64px',
    },
  },
}));

export { useStyles };
