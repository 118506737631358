import React from 'react';
import { createBrowserRouter, Route, createRoutesFromElements, redirect, RouterProvider } from 'react-router-dom';

/* SYSTEM */
import RootLayout from 'components/RootLayout';
import ErrorPage from 'components/ErrorPage';

/* PAGES */
import LandingPage from 'pages/LandingPage';
import Login, { loader as loginLoader } from 'pages/NoAuthPages/Login';
import PasswordRecovery from 'pages/NoAuthPages/PasswordRecovery';
import NewPassword from 'pages/NoAuthPages/NewPassword';
import SignUp from 'pages/NoAuthPages/SignUp';

import SearchPage from 'pages/Player/SearchPage';
import MoodsPage from 'pages/Player/MoodsPage';
import HomePage from 'pages/Player/HomePage';
import Library from 'pages/Player/Library';
import AppleAuth from 'pages/NoAuthPages/AppleAuth';
import CustomerPanel from 'pages/Customer/CustomerPanelPage';
import RequireAuth from './RequireAuth';

async function landingPageLoader() {
  return redirect('/login');
}

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<RootLayout />} errorElement={<ErrorPage />}>
      <Route index element={<LandingPage />} loader={landingPageLoader} />
      <Route path="/login" element={<Login />} loader={loginLoader} />
      <Route path="/signup" element={<SignUp />} loader={loginLoader} />
      <Route path="/appleLogin" element={<AppleAuth />} />

      <Route path="/recover" element={<PasswordRecovery />} />
      <Route path="/newpassword" element={<NewPassword />} />
      <Route path="/landing" element={<LandingPage />} />

      {/* User logged validation */}
      <Route element={<RequireAuth />}>
        <Route path="/home" element={<HomePage />} />
        <Route path="/moods" element={<MoodsPage />} />
        <Route path="/search" element={<SearchPage />} />
        <Route path="/library" element={<Library />} />
        <Route path="/customer" element={<CustomerPanel />} />
      </Route>
      <Route path="/*" loader={landingPageLoader} />
    </Route>,
  ),
);

const Routes: React.FunctionComponent = () => <RouterProvider router={router} />;

export default Routes;
