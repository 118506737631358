import * as yup from 'yup';

import { usernameOrEmail, passwordSecure } from 'utils/formUtils';

export const schema = yup.object().shape({
  username: usernameOrEmail('username'),
  password: passwordSecure,
});

export const defaultValues = {
  username: '',
  password: '',
};
