import { CustomReactTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: CustomReactTheme) => ({
  mainGridWrapper: {
    padding: '60px 40px',
  },
  closeButton: {
    position: 'absolute',
    top: '20px',
    right: '20px',
  },
  inputWrapper: {
    marginTop: '60px',
  },
  saveChangesButton: {
    width: '152px',
    height: '44px',
    position: 'absolute',
    bottom: '40px',
    right: '40px',
  },
  //* TABLET - landScape
  [theme.breakpoints.down('lg')]: {},
  //* TABLET - portrait
  [theme.breakpoints.down('md')]: {},
  //* MOBILE
  [theme.breakpoints.down('sm')]: {},
}));

export { useStyles };
