import { CustomReactTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { cardSizes } from 'utils/cards';

const { square } = cardSizes;

const useStyles = makeStyles((theme: CustomReactTheme) => ({
  square: {
    borderRadius: '20px',
    boxShadow: '0 0 10px 0 rgba(31, 31, 31, 0.5)',
    width: `${square.default[1]}px`,
    height: `${square.default[1]}px`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
  },
  skeletonBars: {
    width: '100%',
    height: '100%',
    borderRadius: '20px',
    margin: '5px 0',
    transform: 'none',
  },
  squareTitle: {
    width: '100%',
    maxWidth: `${square.default[1]}px`,
  },
  squareImage: {
    width: '101%',
    objectFit: 'contain',
    height: '101%',
  },
  clickableMenu: {
    width: '100%',
    height: '100%',
    borderRadius: '20px',
  },
  //* TABLET - landScape
  [theme.breakpoints.down('xl')]: {
    square: {
      width: `${square.xl[1]}px`,
      height: `${square.xl[1]}px`,
    },
    squareTitle: {
      maxWidth: `${square.xl[1]}px`,
    },
  },
  //* TABLET - landScape
  [theme.breakpoints.down('lg')]: {
    square: {
      width: `${square.lg[1]}px`,
      height: `${square.lg[1]}px`,
    },
    squareTitle: {
      maxWidth: `${square.lg[1]}px`,
    },
  },
  //* TABLET - portrait
  [theme.breakpoints.down('md')]: {
    square: {
      width: `${square.md[1]}px`,
      height: `${square.md[1]}px`,
    },
    squareTitle: {
      maxWidth: `${square.md[1]}px`,
    },
  },
  //* MOBILE
  [theme.breakpoints.down('sm')]: {
    square: {
      width: `${square.sm[1]}px`,
      height: `${square.sm[1]}px`,
    },
    squareTitle: {
      maxWidth: `${square.sm[1]}px`,
    },
  },
}));

export { useStyles };
