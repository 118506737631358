import React from 'react';

const SVG = ({ disabled }: { disabled: boolean }) => (
  <svg
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink">
    <g id="Album-and-Player" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" opacity="1">
      <g id="Desktop-Large_Minified-Playing" transform="translate(-432.000000, -1023.000000)">
        <g id="Group-14" transform="translate(320.000000, 920.000000)">
          <g id="music_controls" transform="translate(44.000000, 96.000000)">
            <g id="group" transform="translate(68.000000, 7.000000)">
              <path
                d="M3,17.9799576 L5.54998779,17.9899673 C6.45998782,17.9899673 7.30999756,17.5399551 7.80999756,16.7899551 L14.2000122,7.20999861 C14.7000122,6.45999861 15.5499914,5.99998645 16.4599915,6.00998645 L21.0100098,6.03000598"
                id="Vector"
                stroke={disabled ? '#8d949a' : '#FFFFFF'}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeDasharray="0,0"
                fillRule="nonzero"
              />
              <line
                x1="19"
                y1="19.9799805"
                x2="21"
                y2="17.9799805"
                id="Vector"
                stroke={disabled ? '#8d949a' : '#FFFFFF'}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeDasharray="0,0"
                fillRule="nonzero"
              />
              <path
                d="M8.89001465,8.61998653 L7.80999756,7.11998665 C7.29999757,6.40998667 6.47998536,5.98999154 5.60998535,5.99999154 L3,6.0100013"
                id="Vector"
                stroke={disabled ? '#8d949a' : '#FFFFFF'}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeDasharray="0,0"
                fillRule="nonzero"
              />
              <path
                d="M12.9702148,15.3800049 L14.1902161,16.9500122 C14.7002161,17.6100122 15.50021,18 16.34021,18 L21.0102234,17.9799805"
                id="Vector"
                stroke={disabled ? '#8d949a' : '#FFFFFF'}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeDasharray="0,0"
                fillRule="nonzero"
              />
              <line
                x1="21"
                y1="6.02001953"
                x2="19"
                y2="4.02001953"
                id="Vector"
                stroke={disabled ? '#8d949a' : '#FFFFFF'}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeDasharray="0,0"
                fillRule="nonzero"
              />
              <path d="M24,0 L24,24 L0,24 L0,0 L24,0 Z" id="Vector" opacity="0" />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default SVG;
