import { Dispatch, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { StoreState } from 'store/@types';
import { login, glogin } from 'store/user/thunks';

import { redirect } from 'react-router-dom';
import { getLSField } from 'utils/cookies';
import DefaultComponent from './AppleAuth';

const mapStateToProps = ({ user }: StoreState) => ({ user });

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({ login, glogin }, dispatch);

export type ReduxProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(DefaultComponent);
