/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { FC, useCallback, useEffect, useMemo } from 'react';
// * 3rd party libs

import { Button, Grid, Typography } from '@mui/material';

// * ASSETS
import { ReactComponent as BackChevron } from 'assets/imgs/backchevron.svg';
import Heart from 'assets/imgs/player/heart';

import FallbackImage from 'assets/imgs/missing_image.svg';

// * HOOKS - COMPONENTS
import AlbumViewSong from 'components/Cards/AlbumViewSong';
import MobileMusicPlayer from 'components/PlayerComponents/MobileMusicPlayer';
import MusicInteractionBar from 'components/PlayerComponents/MusicInteractionBar';
import AlbumPlayer from 'components/PlayerComponents/AlbumPlayer';

import useMusicPlayer from 'hooks/useMusicPlayer';
import useResponsive from 'hooks/useResponsive';

// * TYPES
import { Track } from 'store/@types/moods';
// * LOCAL IMPORTS
import { capitalizeFirstLetter } from 'utils/utils';
import { ReduxProps } from '.';
import { useStyles } from './styles';

interface IPlaylistTime {
  hours: number;
  minutes: number;
}

const AlbumView: FC<ReduxProps> = ({ moods, sendFavPlaylist }) => {
  const classes = useStyles();

  const { favorites } = moods;
  const {
    albumView,
    isPlaylist,
    isFullScreen,
    setIsPlaylist,
    playing,
    playAlbumFromSong,
    currentSong,
    pause,
    play,
    setIsFullScreen,
  } = useMusicPlayer();

  const { isMobile, scrollRef } = useResponsive();

  const isFavorite = useMemo(
    () => (isPlaylist && favorites?.playlists?.some(e => e.id === albumView?.id)) || null,
    [isPlaylist, favorites, albumView],
  );

  const playlistTime = useMemo(() => {
    if (!albumView)
      return {
        hours: 0,
        minutes: 0,
      };

    let seconds = 0;
    albumView.tracks?.forEach(song => {
      seconds += song.duration;
    });

    const time: IPlaylistTime = {
      hours: Math.floor(seconds / 3600),
      minutes: Math.floor((seconds % 3600) / 60),
    };
    return time;
  }, [albumView]);

  const setIsMusicView = useCallback(() => {
    setIsFullScreen(false);
  }, [setIsFullScreen]);

  if (!albumView) return null;

  const backArrowHandler = (): void => {
    setIsPlaylist(null);
  };

  const selectMusicHandler = (song: Track): void => {
    if (!currentSong || currentSong !== song) {
      playAlbumFromSong(song);
    } else if (currentSong && playing) {
      pause();
    } else {
      play();
    }
  };

  useEffect(() => {
    scrollRef.current?.scrollToTop();
  }, []);

  if (isFullScreen && isMobile) {
    return (
      <>
        <div className={classes.mobileHeaderBox}>
          <div className={classes.mobileHeaderBox1} style={{ backgroundImage: `url(${albumView?.image})` }} />
          <div className={classes.mobileFade}>
            <MobileMusicPlayer />
          </div>
        </div>
        <MusicInteractionBar isMusicView={isFullScreen} setIsMusicView={setIsMusicView} />
      </>
    );
  }

  return (
    <div className={classes.root}>
      <div className={classes.headerBox}>
        {!albumView.favoritesPlaylist && (
          <div className={classes.headerBox1} style={{ backgroundImage: `url(${albumView?.image})` }} />
        )}
        <Grid container direction="row" alignItems="center" className={classes.headerBox2}>
          <Grid item xs={12} className={classes.previousPageWrapper}>
            <Button
              className={classes.previousPageText}
              onClick={backArrowHandler}
              sx={{ padding: '10px', margin: '10px -10px' }}>
              <BackChevron className={classes.backArrow} />
            </Button>
          </Grid>
          {albumView.favoritesPlaylist ? (
            <Grid item xs={12} className={classes.musicDetailsWrapper}>
              <div className={classes.musicTextWrapper}>
                <Typography variant="listTrack" style={{ fontSize: '22px', paddingBottom: '8px' }}>
                  {albumView?.name}
                </Typography>
                <Typography variant="listAuthor" style={{ fontSize: '22px', paddingBottom: '17px' }}>
                  {albumView?.artist?.name}
                </Typography>
                {albumView && (
                  <Typography variant="listAuthor" style={{ fontSize: '14px', paddingBottom: '18px' }}>
                    {albumView?.tracks?.length === 1
                      ? `${albumView?.tracks?.length} Track`
                      : `${albumView?.tracks?.length} Tracks`}{' '}
                    - {playlistTime.hours > 0 ? `${playlistTime.hours}h` : null}{' '}
                    {playlistTime.minutes > 0 ? `${playlistTime.minutes}m` : null}
                  </Typography>
                )}
              </div>
            </Grid>
          ) : (
            <Grid item xs={12} className={classes.musicDetailsWrapper}>
              {albumView?.image ? (
                <object className={classes.coverImgHeader} data={albumView.image} type="image/png">
                  <img className={classes.coverImgHeaderDefault} src={FallbackImage} alt="coverImage" />
                </object>
              ) : (
                <object className={classes.coverImgHeader}>
                  <img className={classes.coverImgHeaderDefault} src={FallbackImage} alt="coverImage" />
                </object>
              )}
              <div className={classes.musicTextWrapper}>
                <Typography variant="listTrack" className={classes.trackNameText}>
                  {albumView?.name}
                </Typography>
                <Typography variant="listAuthor" className={classes.artistNameText}>
                  {albumView?.artist?.name}
                </Typography>
                {albumView && (
                  <Typography variant="listAuthor" style={{ fontSize: '14px', paddingBottom: '18px' }}>
                    {albumView?.tracks?.length === 1
                      ? `${albumView?.tracks?.length} Track`
                      : `${albumView?.tracks?.length} Tracks`}{' '}
                    - {playlistTime.hours > 0 ? `${playlistTime.hours}h` : null}{' '}
                    {playlistTime.minutes > 0 ? `${playlistTime.minutes}m` : null}
                  </Typography>
                )}
                {!isMobile && albumView && !albumView.isSingleSong && (
                  <Button
                    className={classes.heartDesktop}
                    id="button-favorite"
                    onClick={() => {
                      sendFavPlaylist(albumView.id, !isFavorite);
                    }}>
                    <Heart fill={Boolean(isFavorite)} />
                  </Button>
                )}
              </div>
            </Grid>
          )}
          <Grid item xs={12}>
            <div className={classes.fade} />
          </Grid>
        </Grid>
      </div>

      <Grid container direction="row" alignItems="center" className={classes.songsContainer}>
        {isMobile && <AlbumPlayer moods={moods} />}
        {!isMobile && (
          <Grid item xs={12} className={classes.listOfSongs}>
            <Typography className={classes.playlistHeader}>{capitalizeFirstLetter(albumView.type)} tracks</Typography>
          </Grid>
        )}
        <Grid item xs={12} className={classes.listOfSongs}>
          {albumView?.tracks?.map(song => (
            <AlbumViewSong playing={playing} selectMusicHandler={selectMusicHandler} song={song} key={song.id} />
          ))}
        </Grid>
      </Grid>
    </div>
  );
};

export default AlbumView;
