import React, { FC } from 'react';
// * 3rd party libs
import { IconButton, Typography, Slider, Grid } from '@mui/material';
import clsx from 'clsx';
// * ASSETs
import { ReactComponent as BackChevron } from 'assets/imgs/backchevron.svg';
import FallbackImage from 'assets/imgs/missing_image.svg';
import Backwards from 'assets/imgs/player/backward';
import PlayButton from 'assets/imgs/player/play';
import Pause from 'assets/imgs/player/pause';
import Forward from 'assets/imgs/player/forward';
import Shuffle from 'assets/imgs/player/shuffle';
import Repeat from 'assets/imgs/player/repeatIcon';
import RepeatNoneIcon from 'assets/imgs/player/repeatNone';
import RepeatOneIcon from 'assets/imgs/player/repeatOne';

// * HOOKS - COMPONENTS
import useMusicPlayer from 'hooks/useMusicPlayer';

// * TYPES

// * LOCAL IMPORTS
import { useStyles } from './styles';

const MobileMusicPlayer: FC = () => {
  const classes = useStyles();

  const {
    duration,
    currentTime,
    setNewTime,
    durationOriginal,
    currentTimeOriginal,
    prevSong,
    play,
    pause,
    nextSong,
    currentSong,
    setIsFullScreen,
    playing,
    disableButton,
    repeat,
    setRepeat,
    shuffle,
    setShuffle,
    setIsPlaylist,
    isOffline,
  } = useMusicPlayer();
  const isInPWA = window.matchMedia('(display-mode: standalone)').matches;

  const handleChange = (event: Event, newValue: number | number[]) => {
    if (typeof newValue === 'number') {
      setNewTime(newValue);
    }
  };
  const backArrowHandler = (): void => {
    setIsFullScreen(false);
    if (isInPWA && isOffline) setIsPlaylist(null);
  };

  if (!currentSong) {
    return null;
  }

  return (
    <Grid container direction="row" spacing={2} alignItems="center" className={classes.playlistPlayerWrapper}>
      <Grid item xs={12}>
        <div className={classes.backArrowMobile}>
          <BackChevron onClick={backArrowHandler} />
        </div>
      </Grid>
      <Grid item xs={12} className={classes.contentCenter}>
        <object className={classes.coverImgSmall} data={currentSong?.image || undefined} type="image/png">
          <img className={classes.coverImgSmallDefault} src={FallbackImage} alt="coverImage" />
        </object>
      </Grid>
      <Grid item xs={12}>
        <div id="song-title-player-mobile">
          <Typography
            textAlign="center"
            component="p"
            variant="listTrack"
            width="fit-content"
            className={clsx(classes.textTitle, {
              animateText: currentSong.name.length > 40,
            })}>
            {currentSong?.name}
          </Typography>
          <Typography textAlign="center" component="p" variant="listAuthor">
            {currentSong?.artist?.name}
          </Typography>
        </div>
      </Grid>
      <Grid item xs={6}>
        <IconButton
          className={classes.playerButton}
          onClick={() => {
            setShuffle(!shuffle);
          }}>
          <Shuffle disabled={!shuffle} />
        </IconButton>
      </Grid>
      <Grid item xs={6} className={classes.contentRight}>
        <IconButton
          className={classes.playerButton}
          onClick={() => {
            setRepeat();
          }}>
          {repeat === 'all' && <Repeat />}
          {repeat === 'none' && <RepeatNoneIcon />}
          {repeat === 'one' && <RepeatOneIcon />}
        </IconButton>
      </Grid>
      <Grid item xs={12} className={classes.timerWrapper}>
        <Slider
          aria-label="current-time"
          defaultValue={0}
          className={classes.sliderPlaylist}
          min={0}
          max={durationOriginal || 0}
          value={currentTimeOriginal || 0}
          onChange={handleChange}
          color="primary"
          size="small"
        />
      </Grid>
      <Grid item xs={6} className={classes.timeWrapperLeft}>
        <Typography variant="carrouselItem">{currentTime}</Typography>
      </Grid>
      <Grid item xs={6} className={classes.timeWrapperRight}>
        <Typography variant="carrouselItem">{duration}</Typography>
      </Grid>
      <Grid item xs={4} className={classes.contentRight}>
        <IconButton className={classes.playerButton} disabled={disableButton.previous} onClick={prevSong}>
          <Backwards disabled={disableButton.previous} />
        </IconButton>
      </Grid>
      <Grid item xs={4} className={classes.contentCenter}>
        <IconButton
          className={classes.playerButton}
          disabled={disableButton.playPause}
          onClick={() => {
            if (playing) {
              pause();
            } else {
              play();
            }
          }}>
          {playing ? <Pause disabled={disableButton.playPause} /> : <PlayButton disabled={disableButton.playPause} />}
        </IconButton>
      </Grid>
      <Grid item xs={4} className={classes.contentLeft}>
        <IconButton className={classes.playerButton} disabled={disableButton.next} onClick={nextSong}>
          <Forward disabled={disableButton.next} />
        </IconButton>
      </Grid>
    </Grid>
  );
};

export default MobileMusicPlayer;
