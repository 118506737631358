import { restCall } from 'utils/restHandler';
import { GET, PUT, DELETE } from 'utils/requestTypes';
import { Playlist, UserFavPlaylistsPayload, UserFavTracksPayload } from 'store/@types/moods';

export const sendGetPLaylistDetails = (playlistId?: string) => restCall<Playlist>(GET, `/playlist/${playlistId}`);

export const apiGetAllTracksFavorites = (limit = 900) =>
  restCall<UserFavTracksPayload>(GET, '/favorite/track', { params: { limit, page: 1 } });

export const apiGetAllPlaylistsFavorites = (limit = 900) =>
  restCall<UserFavPlaylistsPayload>(GET, '/favorite/playlist', { params: { limit, page: 1 } });

export const apiGetFavAlbums = (limit = 20) =>
  restCall<Playlist>(GET, '/favorite/playlist?type=ALBUM', { params: { limit, page: 1 } });

export const apiGetFavPlaylists = (limit = 20) =>
  restCall<Playlist>(GET, '/favorite/playlist?type=PLAYLIST', { params: { limit, page: 1 } });

export const apiSendFavTrack = (assetId: string, update: boolean) =>
  restCall<Playlist>(update ? PUT : DELETE, `/favorite/track/${assetId}`, {});

export const apiSendFavPlaylist = (assetId: string, update: boolean) =>
  restCall<Playlist>(update ? PUT : DELETE, `/favorite/playlist/${assetId}`, {});

export const apiGetSearh = (search: string, limit = 30) =>
  restCall<Playlist>(GET, '/playlist/search', {
    params: { search, albumsLimit: limit, playlistsLimit: limit },
  });

// albumsLimit
// albumsPage
// playlistsLimit
// playlistsPage
// curl -X GET \
//   /playlist/search?search=update&albumsLimit=2'
