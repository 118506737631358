/* eslint-disable no-console */
import React, { FC } from 'react';
// * 3rd party libs
import { Typography, Grid, Box, IconButton } from '@mui/material';

// * ASSETS
import { ReactComponent as ProfileSVG } from 'assets/imgs/profile_nophoto.svg';

// * COMPONENTS

// * TYPES
import { useTranslation } from 'react-i18next';
// * LOCAL IMPORTS
import { BusinessUser } from 'store/@types/b2b';
import { useStyles } from './styles';

export interface ManagerCardProps {
  removeAction: () => void;
  user: BusinessUser;
}

const ManagerCard: FC<ManagerCardProps> = ({ user, removeAction }) => {
  const classes = useStyles();

  const { t } = useTranslation();

  return (
    <Grid item xs={12} className={classes.mainWrapper}>
      <Box style={{ display: 'flex', flexDirection: 'row' }}>
        <ProfileSVG className={classes.photo} />
        <Box className={classes.cardBody}>
          <Typography variant="listTrack">{user.user.name}</Typography>
          <Typography variant="listAuthor">
            {user.devices} {t('zones')}
          </Typography>
        </Box>
      </Box>
      <Box className={classes.removeButtonWrapper}>
        {user.role !== 'app-admin' ? (
          <IconButton className={classes.removeButton} onClick={removeAction} disabled>
            {/* <RemoveUserPink /> */}
          </IconButton>
        ) : (
          <Box className={classes.removeButton} sx={{ width: '40px' }} />
        )}
      </Box>
    </Grid>
  );
};

export default ManagerCard;
