import { FC, useEffect, useState } from 'react';
// * 3rd party libs
// import { useNavigate } from 'react-router-dom';

import { Grid, Typography } from '@mui/material';
// * ASSETS
import AlbumViewSong from 'components/Cards/AlbumViewSong';
import useMusicPlayer from 'hooks/useMusicPlayer';
import { ReactComponent as NoData } from 'assets/imgs/library_empty.svg';

import { ReactComponent as SinesLogo } from 'assets/imgs/sinesLogo.svg';
// * COMPONENTS
import { getAllDownloadedSongs } from 'utils/saveOffline';

// * TYPES
import { Track } from 'store/@types/moods';
import { useTranslation } from 'react-i18next';
// * LOCAL IMPORTS

import { useStyles } from './styles';
import { ReduxProps } from '.';

const OfflinePage: FC<ReduxProps> = () => {
  const classes = useStyles();
  const [downloadedSongs, setDownloadedSongs] = useState<Track[]>();
  const [error, setError] = useState<string>('');

  const { playing, playAlbumFromSong, currentSong, pause, play } = useMusicPlayer();

  const { t } = useTranslation();

  useEffect(() => {
    const fetchSongs = async () => {
      try {
        const songs = await getAllDownloadedSongs();
        setDownloadedSongs(songs);
      } catch (err) {
        setError('Cannot fetch downloaded songs, please try again!');
      }
    };

    fetchSongs();
  }, []);
  const selectMusicHandler = (song: Track): void => {
    if (!currentSong || currentSong !== song) {
      playAlbumFromSong(song, true);
    } else if (currentSong && playing) {
      pause();
    } else {
      play();
    }
  };

  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
        <SinesLogo />
      </div>
      <Grid item xs={12} className={classes.offlineNotification}>
        <Typography variant="h6" className={classes.offlineText}>
          {t('offline_title1') /* You are currently offline. Here are your downloaded tracks. */}
        </Typography>
        {error ? <Typography variant="h6">{error}</Typography> : null}
      </Grid>

      <Grid
        container
        spacing={2}
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
        className={classes.root}>
        {!downloadedSongs || downloadedSongs.length === 0 ? (
          <Grid item xs={12} className={classes.noData}>
            <NoData />
            <Typography variant="subtitle1" className={classes.noDataText}>
              {t('offline_notracks') /* No downloaded tracks available. */}
            </Typography>
          </Grid>
        ) : (
          <Grid item xs={12} className={classes.listOfSongs}>
            {downloadedSongs.map(song => (
              <AlbumViewSong song={song} playing={playing} selectMusicHandler={selectMusicHandler} />
            ))}
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default OfflinePage;
