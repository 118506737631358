import { CustomReactTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: CustomReactTheme) => ({
  mainWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingTop: '10px',
    paddingBottom: '10px',
    alignItems: 'center',
    borderBottom: '1px solid #353535',
    borderTop: '1px solid #353535',
    marginTop: '40px',
  },
  photo: {
    width: '48px',
    height: '48px',
    borderRadius: '10px',
  },
  cardBody: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '80%',
    alignSelf: 'center',
    paddingLeft: '10px',
  },
  removeButtonWrapper: {
    alignSelf: 'center',
    paddingRight: '10px',
  },
  removeButton: {
    paddingRight: '20px',
  },
  //* TABLET - landScape
  [theme.breakpoints.down('lg')]: {},
  //* TABLET - portrait
  [theme.breakpoints.down('md')]: {},
  //* MOBILE
  [theme.breakpoints.down('sm')]: {},
}));

export { useStyles };
