import React from 'react';
import type {} from '@mui/lab/themeAugmentation';

import { createTheme, ThemeOptions } from '@mui/material/styles';
import { TypographyOptions } from '@mui/material/styles/createTypography';

export interface ExtendedTypographyOptions extends TypographyOptions {
  section: React.CSSProperties;
  sectionDetails: React.CSSProperties;
  carrouselItem: React.CSSProperties;
  listTrack: React.CSSProperties;
  listAuthor: React.CSSProperties;
}

export const themeStyles = {
  drawerWidth: 320,
  navbar: {
    bgDarker: '#121212',
    bg: '#181818',
  },
  bottomPlayerBar: {
    height: '74px',
  },
  navBottomBar: {
    height: '74px',
  },
  typography: {
    fontFamily: [
      'Montserrat',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    h1: {
      color: '#F4F4F4',
      fontSize: '28px',
      lineHeight: '34px',
      fontWeight: 600,
      textAlign: 'left',
    },
    heroTitle: {
      color: '#F4F4F4',
      fontSize: '28px',
      lineHeight: '34px',
      fontWeight: 600,
      textAlign: 'left',
    },
    loginSection: {
      color: '#fff',
      fontSize: '16px',
      lineHeight: '19px',
      textAlign: 'center',
    },
    section: {
      color: '#F4F4F4',
      fontSize: '18px',
      lineHeight: '22px',
      fontWeight: '400',
      textAlign: 'left',
      '& .MuiListItemIcon-root': {
        color: '#F4F4F4',
        fontSize: '18px',
        lineHeight: '22px',
        textAlign: 'left',
        minWidth: '50px',
      },
    },
    sectionDetails: {
      color: '#B3B3B3',
      fontSize: '16px',
      lineHeight: '19px',
      textAlign: 'right',
    },
    carrouselItem: {
      color: '#F4F4F4',
      fontSize: '14px',
      lineHeight: '17px',
      textAlign: 'center',
    },
    listTrack: {
      color: '#F4F4F4',
      fontSize: '14px',
      fontWeight: 600,
      lineHeight: '23px',
      textAlign: 'left',

      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
    },
    listAuthor: {
      color: '#B3B3B3',
      fontSize: '14px',
      lineHeight: '17px',
      textAlign: 'left',
    },
  } as ExtendedTypographyOptions,
  palette: {
    mainColor: {
      main: '#FFF',
      contrastText: '#000',
    },
    accentColor: {
      main: '##B66481',
      contrastText: '#000',
    },
  },
  components: {
    MuiButton: {
      variants: [
        {
          props: { variant: 'text' },
          style: {
            fontSize: '17px',
            fontWeight: 600,
            color: '#FFF',
            textTransform: 'initial',
          },
        },
        {
          props: { variant: 'primary' },
          style: () => ({
            backgroundImage: 'linear-gradient(68.02deg, #775DA8 0%, #F06B5D 100%)',
            borderRadius: '26px',
            padding: '16px',
            lineHeight: '20px',
            fontSize: '17px',
            fontWeight: 600,
            color: '#FFF',
            textAlign: 'center',
            textTransform: 'initial',
          }),
        },
        {
          props: { variant: 'disabled' },
          style: () => ({
            backgroundImage: 'linear-gradient(68.02deg, #797483 0%, #907775 100%)',
            borderRadius: '26px',
            padding: '16px',
            lineHeight: '20px',
            fontSize: '17px',
            fontWeight: 600,
            textTransform: 'initial',
            color: '#B3B3B3 !important',
          }),
        },
      ],
    },
  },
};

const theme = createTheme(themeStyles as unknown as ThemeOptions);

export default theme;
