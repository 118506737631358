import { restCall } from 'utils/restHandler';
// eslint-disable-next-line no-unused-vars
import {
  GoogleLoginPost,
  GoogleSignupPost,
  LoginPost,
  LoginResponse,
  RecoverPwPost,
  SendNewPwPost,
  SignUpPost,
  StripeCheckout,
  UserProps,
} from 'store/@types/user';
import { DELETE, POST } from 'utils/requestTypes';

export const apiLogin = (payload: LoginPost) => restCall<LoginResponse>(POST, '/auth', payload);

export const gApiLogin = (payload: GoogleLoginPost) => restCall<LoginResponse>(POST, '/auth/google', payload);

export const apiLogout = () => restCall(DELETE, '/auth/logout');

export const refreshApiLogin = () => {
  const refreshToken = localStorage.getItem('refreshToken');

  return restCall<LoginResponse>(POST, '/auth/refresh', {
    refreshToken,
  });
};

export const apiSignUp = (payload: SignUpPost) => restCall<UserProps>(POST, '/user', payload);

export const apiGSignup = (payload: GoogleSignupPost) => restCall<LoginResponse>(POST, '/user/google', payload);

export const apiGetPrincingPlans = () => restCall<UserProps>(POST, '/pricings');

export const apiDeleteUser = () => restCall(DELETE, '/user/me', {});

export const apiRecoverPw = (payload: RecoverPwPost) => restCall(POST, '/auth/send-recover-password-email', payload);

export const apiSetNewPassword = (payload: SendNewPwPost, token: string) =>
  restCall(POST, '/auth/recover-password', payload, {
    params: {
      token,
    },
  });

export const apiStartTrial = () =>
  restCall<StripeCheckout>(POST, '/payment/create-checkout-session', {
    isTrial: true,
  });

export const apiSubscribePlan = () =>
  restCall<StripeCheckout>(POST, '/payment/create-checkout-session', {
    isTrial: false,
  });
export const updatePlanUrl = () => restCall<StripeCheckout>(POST, 'payment/create-customer-portal-session');
