import React, { SVGProps } from 'react';

const SVG = ({ fill, svgProps = {} }: { fill?: boolean; svgProps?: SVGProps<any> }) => (
  <svg
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    {...svgProps}>
    <g id="Album-and-Player" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Desktop-Large_Minified-Playing" transform="translate(-790.000000, -1023.000000)">
        <g id="Group-14" transform="translate(320.000000, 920.000000)">
          <g id="music_controls" transform="translate(44.000000, 96.000000)">
            <g id="heart" transform="translate(426.000000, 7.000000)">
              <path
                d="M10.9058334,19.9430094 C8.12666668,18.9942594 1.91666667,15.0363433 1.91666667,8.32800994 C1.91666667,5.36676003 4.30291668,2.97092692 7.24499995,2.97092692 C8.98916666,2.97092692 10.5320833,3.81426048 11.5,5.11759382 C12.4679167,3.81426048 14.0204172,2.97092692 15.7550005,2.97092692 C18.6970838,2.97092692 21.0833333,5.36676003 21.0833333,8.32800994 C21.0833333,15.0363433 14.8733333,18.9942594 12.0941666,19.9430094 C11.7683332,20.0580094 11.2316668,20.0580094 10.9058334,19.9430094 Z"
                id="Vector"
                stroke={fill ? '#FFFFFF' : '#FFFFFF'}
                fill={fill ? '#FFFFFF' : 'transparent'}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeDasharray="0,0"
                fillRule="nonzero"
              />
              <path d="M24,0 L24,24 L0,24 L0,0 L24,0 Z" id="Vector" opacity="0" />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

SVG.defaultProps = {
  fill: true,
  svgProps: {},
};

export default SVG;
