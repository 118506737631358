import { CustomReactTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: CustomReactTheme) => ({
  square: {
    position: 'relative',
    borderRadius: '20px',
    width: '100%',
    height: '133px',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
  },
  squareImage: {
    width: '101%',
    objectFit: 'contain',
    height: '101%',
  },
  playButton: {
    position: 'absolute',
    bottom: 0,
    right: 0,
  },
  skeletonBars: {
    width: '100%',
    height: '100%',
    borderRadius: '20px',
    margin: '5px 0',
    transform: 'none',
  },
  title: {
    top: 0,
    zIndex: 1, // card title
    fontSize: '22px',
    fontWeight: 600,
    margin: '11px',
    position: 'absolute',
    width: 'calc(100% - 1rem)',
    maxWidth: '100%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    WebkitUserSelect: 'none',
    KhtmlUserSelect: 'none',
    MozUserSelect: 'none',
    OUserSelect: 'none',
    userSelect: 'none',
    cursor: 'pointer',
    letterSpacing: '-0.5px',
  },
  subtitle: {
    top: 0,
    zIndex: 1, // card sub-title
    fontSize: '14px',
    margin: '11px',
    position: 'absolute',
    maxWidth: '100%',
    marginTop: '49px',
  },
  clickableMenu: {
    width: '100%',
    height: '100%',
    borderRadius: '20px',
  },
  //* TABLET - landScape
  [theme.breakpoints.down('lg')]: {
    square: {
      height: '114px',
    },
    subTitle: {
      fontSize: '16px',
    },
  },
  //* TABLET - portrait
  [theme.breakpoints.down('md')]: {
    square: {
      height: '120px',
    },

    subTitle: {
      fontSize: '20px',
    },
  },
  //* MOBILE
  [theme.breakpoints.down('sm')]: {
    subTitle: {
      fontSize: '15px',
    },
  },
}));

export { useStyles };
