/* eslint-disable @typescript-eslint/no-explicit-any, no-prototype-builtins, no-console */
import MediaSession from '@mebtte/react-media-session';
import { FC, createContext, useEffect, useMemo, useRef, useState, useCallback } from 'react';
import ReactHlsPlayer from 'react-hls-player';

import { sendNewUserHistory } from 'api/history';
import { Category, Playlist, Track } from 'store/@types/moods';

import { getCookie, setCookie } from 'utils/cookies';
import { IPlayerButtonStates, formatTime } from './musicPlayerUtils';

export type RepeatType = 'none' | 'all' | 'one';

const MusicPlayerContext = createContext<IMusicPlayerContext>({} as IMusicPlayerContext);

const shufflePlaylist = (playlist: Track[]) => {
  const newPlaylist = [...playlist];
  // eslint-disable-next-line no-plusplus
  for (let i = newPlaylist.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [newPlaylist[i], newPlaylist[j]] = [newPlaylist[j], newPlaylist[i]];
  }
  return newPlaylist;
};

export const MusicPlayerProvider: FC = ({ children }) => {
  const playerRef = useRef<any>();
  const initialVolume = getCookie('playerVolume') || 1;
  const initialRepeat = getCookie('userRepeat') || 'none';

  // time managment on player
  const [volume, setVolume] = useState<number>(+initialVolume || 1);
  const [durationOriginal, setDurationOriginal] = useState<number>(0);
  const [currentTimeOriginal, setCurrentTimeOriginal] = useState<number>(0);
  // visual time representation on player
  const [duration, setDuration] = useState<string>('0:00');
  const [currentTime, setCurrentTime] = useState<string>('0:00');

  /* Setting the state of the current playlist and current song. */
  const [playingList, setPlayingList] = useState<Playlist | null>(null);
  const [isAlbumPlaylist, setIsAlbumPlaylist] = useState<boolean>(false);
  const [albumView, setAlbumView] = useState<Playlist | null>(null);
  const [currentSong, setCurrentSong] = useState<Track | null>(null);

  const [shuffle, setShuffle] = useState<boolean>(false);
  const [repeat, setRepeat] = useState<RepeatType>((initialRepeat as RepeatType) || 'none');

  const [error, setError] = useState<string | null>(null);

  /* Used to determine if the player is playing a playlist or a moods result. */
  const [isPlaylist, setIsPlaylist] = useState<string | null>(null);
  const [isOffline, setIsOffline] = useState<boolean>(!navigator.onLine);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [isMoodsResult, setIsMoodsResult] = useState<Category | null>(null);

  const setIsFullScreenPlayer = useCallback(
    val => {
      setIsPlaylist('open');
      setIsFullScreen(val);
    },
    [setIsPlaylist],
  );

  /* This is setting the state of the player. */
  const [playing, setPlaying] = useState(false);
  const [disableButton, setDisableButton] = useState<IPlayerButtonStates>({
    airplay: true,
    shuffle: true,
    previous: false,
    playPause: false,
    next: false,
    repeat: true,
    heart: true,
  });

  const toggleDisabled = (options: { [key: string]: boolean }) => setDisableButton(prev => ({ ...prev, ...options }));

  /**
   * `play()` is a function that sets the `playing` state to `true`, disables the `playPause` button,
   * plays the audio, and then either enables the `playPause` button or sets the `error` state to the
   * error message
   */
  const play = () => {
    setPlaying(true);
    toggleDisabled({ playPause: true });
    console.log(
      'Playing song',
      currentSong,
      'isPlaylist',
      isPlaylist,
      'playingList:',
      playingList,
      'albumView:',
      albumView,
      'PlayerRef',
      playerRef,
    );
    if (playerRef.current) {
      playerRef.current.playing = true;
      playerRef.current
        .play()
        .then(() => {
          toggleDisabled({ playPause: false });
        })
        .catch((err: { message: string }) => {
          setError(err.message);
          toggleDisabled({ playPause: false });
        });
    }
  };

  /**
   * It sets the `playing` state to `false`, disables the `playPause` button, pauses the player, and then
   * re-enables the `playPause` button
   */
  const pause = async () => {
    setPlaying(false);
    toggleDisabled({ playPause: true });

    if (playerRef.current) {
      playerRef.current.playing = false;
      await playerRef.current.pause();
      toggleDisabled({ playPause: false });
    }
  };

  /**
   * It takes a url as an argument, and if the playerRef is not null, it pauses the player, sets the src
   * to the url, sets the currentTime to 0, loads the player, and then plays the player
   * @param {undefined | string} url - The url of the song to be played.
   * @returns A function that takes a url and sets the playerRef.current.src to the url.
   */
  const changePlayerSong = (url: undefined | string = undefined) => {
    if (!playerRef?.current) return;
    playerRef.current.pause();
    playerRef.current.src = url;
    playerRef.current.currentTime = 0;

    playerRef.current.load();

    setTimeout(() => {
      const disableState = { playPause: false, next: false, previous: false };
      if (playingList?.tracks?.length === 1) {
        disableState.next = true;
      }
      playerRef.current
        .play()
        .then(() => {
          setPlaying(true);
          toggleDisabled(disableState);
        })
        .catch((e: any) => {
          toggleDisabled(disableState);
          setError(e.message);
        });
    }, 50);
  };

  /**
   * SetNewTime is a function that takes a number as an argument and sets the currentTime of the
   * playerRef to that number.
   * @param {number} time - number - The time in seconds to set the player to.
   */
  const setNewTime = (time: number) => {
    playerRef.current.currentTime = time;
  };

  /**
   * It takes a song as an argument, sets the current song to the song, changes the player song to the
   * song's uri, and sets the playing state to true
   * @param {Track | null} song - Track | null
   */
  const playNewSong = (song: Track | null) => {
    setCurrentSong(song);
    if (song) {
      changePlayerSong(song.uri);
    }
    setPlaying(true);
  };

  const createMockedPlaylistFromSong = (track: Track): Playlist => ({
    id: track.id,
    name: track.name,
    type: 'PLAYLIST',
    imagePath: track.imagePath,
    smallImagePath: track.smallImagePath,
    createdAt: track.createdAt,
    updatedAt: track.updatedAt,
    duration: track.duration,
    image: track.image && track.image.endsWith('Track.png') ? null : track.image || null,
    smallImage: track.smallImage && track.smallImage.endsWith('SMALL_Track.png') ? null : track.smallImage || null,
    uri: track.uri,
    uriPath: track.uriPath,
    tracks: [track as Track],
    originalList: [track as Track],
    isSingleSong: true,
  });

  const returnFavPlaylist = (track: Track, list: Track[], favoritesPlaylist: boolean): Playlist => ({
    id: track.id,
    name: favoritesPlaylist ? 'Favorites' : list[0].playlistInfo?.name || track.name,
    type: 'PLAYLIST',
    imagePath: track.imagePath,
    smallImagePath: track.smallImagePath,
    createdAt: track.createdAt,
    updatedAt: track.updatedAt,
    duration: track.duration,
    image: track.image && track.image.endsWith('Track.png') ? null : track.image || null,
    smallImage: track.smallImage && track.smallImage.endsWith('SMALL_Track.png') ? null : track.smallImage || null,
    uri: track.uri,
    uriPath: track.uriPath,
    tracks: list,
    originalList: list,
    isSingleSong: false,
    favoritesPlaylist,
  });

  const playAlbumFromSong = (song: Track, mockedPlaylist = false) => {
    if (mockedPlaylist) {
      setIsAlbumPlaylist(false);
    } else {
      setIsAlbumPlaylist(true);
    }

    if (mockedPlaylist) {
      setPlayingList(createMockedPlaylistFromSong(song));
    } else if (JSON.stringify(albumView) !== JSON.stringify(playingList)) {
      setPlayingList(albumView);
      toggleDisabled({ next: albumView?.tracks?.length === 1 });
    }
    playNewSong(song);
  };

  const playFavoritesFromSong = (song: Track, list: Track[], favoritesPlaylist: boolean) => {
    const favPlaylist = returnFavPlaylist(song, list, favoritesPlaylist);
    setPlayingList(favPlaylist);
    setIsAlbumPlaylist(false);
    toggleDisabled({ next: favPlaylist?.tracks?.length === 1 });
    playNewSong(song);
  };

  /**
   * It gets the previous song in the playlist and plays it
   * @returns the current song.
   */
  const prevSong = () => {
    if (!currentSong) return;
    if (!playingList?.tracks) return;

    // if the currentTime of the song is greater than 5 seconds, it will set the currentTime to 0
    if (currentTimeOriginal > 5 || playingList.tracks.length === 1) {
      playerRef.current.currentTime = 0;
      return;
    }

    toggleDisabled({ previous: true });
    const currentIndex = playingList.tracks.indexOf(currentSong as Track);

    if (currentIndex === 0) {
      setNewTime(0);
      play();
      toggleDisabled({ previous: false });
    } else if (playingList && currentIndex) {
      playNewSong(playingList.tracks[currentIndex - 1]);
    }
  };

  const stopPlayer = () => {
    if (playerRef.current) {
      playerRef.current.pause();
      playerRef.current.currentTime = 0;
      playerRef.current.src = '';
      toggleDisabled({ playPause: true, next: true, previous: true });
      setPlaying(false);
      setCurrentSong(null);
    }
  };

  /**
   * It takes the current song, checks if it's the last song in the playlist, and if it is, it sets the
   * next song to the first song in the playlist
   * @returns the next song in the playlist.
   */
  const nextSong = (ended = false) => {
    if (!currentSong) return;
    if (!playingList?.tracks) return;

    const currentRepeat = getCookie('userRepeat') || 'none';
    const currentIndex = playingList.tracks.indexOf(currentSong as Track);

    if (!ended && currentRepeat === 'one') {
      setRepeat('none');
      setCookie('userRepeat', 'none');
    }
    if (!ended || currentRepeat === 'all') {
      toggleDisabled({ next: true });

      if (currentIndex === playingList.tracks.length - 1) {
        playNewSong(playingList.tracks[0]);
      } else if (playingList.tracks) {
        playNewSong(playingList.tracks[currentIndex + 1]);
      }
    } else {
      // eslint-disable-next-line no-lonely-if
      if (currentRepeat === 'none') {
        toggleDisabled({ next: true });
        if (currentIndex === playingList.tracks.length - 1) {
          stopPlayer();
        } else if (playingList.tracks) {
          playNewSong(playingList.tracks[currentIndex + 1]);
        }
      }
      if (currentRepeat === 'one') {
        // start the song over
        setNewTime(0);
        play();
      }
    }
  };

  /* It's setting the volume of the player to the volume state. */
  useEffect(() => {
    if (!playerRef?.current) return;
    playerRef.current.volume = volume;
    setCookie('playerVolume', `${volume}`);
  }, [volume]);

  useEffect(() => {
    setCookie('userRepeat', repeat);
  }, [repeat]);

  /* It's setting the volume of the player to 0.1, and then it's setting the onloadedmetadata and ontimeupdate
	events to update the duration and currentTime of the player. */

  useEffect(() => {
    currentSong &&
      playingList &&
      playingList.id !== currentSong.id &&
      sendNewUserHistory(currentSong.id, playingList.id);
  }, [currentSong]);

  useEffect(() => {
    if (!playingList?.tracks) return;
    if (shuffle) {
      setPlayingList({ ...playingList, tracks: shufflePlaylist(playingList.tracks) });
    } else {
      setPlayingList({ ...playingList, tracks: playingList.originalList });
    }
  }, [shuffle]);

  // https://www.w3schools.com/tags/ref_av_dom.asp
  const Player = useMemo(
    () =>
      currentSong &&
      playingList && (
        <MediaSession
          title={currentSong.name}
          artist={currentSong.artist?.name}
          artwork={
            currentSong.image
              ? [
                  {
                    src: currentSong.image,
                    sizes: '512x512',
                  },
                ]
              : []
          }
          album={playingList.name}
          onPlay={play}
          onPause={pause}
          onPreviousTrack={() => prevSong()}
          onNextTrack={() => nextSong()}>
          <ReactHlsPlayer
            controls={false}
            autoPlay={false}
            playerRef={playerRef}
            hlsConfig={{
              enableWorker: true,
              maxLoadingDelay: 2,
              minAutoBitrate: 0,
              progressive: true,
              maxMaxBufferLength: 30,
              maxBufferLength: 30,
              maxBufferSize: 4 * 1000 * 300,
              testBandwidth: true,
              appendErrorMaxRetry: 1,
              startLevel: -1,
              maxBufferHole: 2.5,
              lowLatencyMode: true,
              autoStartLoad: true,
              debug: false,
            }}
            src={currentSong?.uri || ''}
            style={{ position: 'absolute' }}
            width="0"
            // onAbort={() => console.info('onAbort')}
            // onAbortCapture={() => console.info('onAbortCapture')}
            // onCanPlay={() => console.info('onCanPlay')}
            // onCanPlayCapture={() => console.info('onCanPlayCapture')}
            // onCanPlayThrough={() => console.info('onCanPlayThrough')}
            // onCanPlayThroughCapture={() => console.info('onCanPlayThroughCapture')}
            // onDurationChange={() => console.info('onDurationChange')}
            // onDurationChangeCapture={() => console.info('onDurationChangeCapture')}
            // onEmptied={() => console.info('onEmptied')}
            // onEmptiedCapture={() => console.info('onEmptiedCapture')}
            // onEncrypted={() => console.info('onEncrypted')}
            // onEncryptedCapture={() => console.info('onEncryptedCapture')}
            onEnded={() => {
              nextSong(true);
            }}
            // onEndedCapture={() => console.info('onEndedCapture')}
            // onLoadedData={() => console.info('onLoadedData')}
            // onLoadedDataCapture={() => console.info('onLoadedDataCapture')}
            onLoadedMetadata={() => {
              if (!playerRef.current) return;
              playerRef.current.play();

              const newValO = +playerRef.current.duration.toFixed(1);
              const newVal = playerRef.current.duration.toFixed(0);

              newValO !== durationOriginal && setDurationOriginal(+`${newValO}`.replace('NaN', '0'));
              newVal !== duration && setDuration(`-${formatTime(newVal)}`.replace('NaN', '0'));
            }}
            // onLoadedMetadataCapture={() => console.info('onLoadedMetadataCapture')}
            // onLoadStart={() => {
            // 	console.info('onLoadStart');
            // }}
            // onLoadStartCapture={() => console.info('onLoadStartCapture')}
            onPause={() => {
              setPlaying(false);
            }}
            onPauseCapture={e => {
              if ((e.target as any).playing === undefined) {
                nextSong(true);
              }
              // eslint-disable-next-line no-useless-return
              return;
            }}
            onPlay={() => {
              if (!playerRef.current) return;
              setPlaying(true);
            }}
            // onPlayCapture={() => console.info('onPlayCapture')}
            // onPlaying={() => console.info('onPlaying')}
            // onRateChange={() => console.info('onRateChange')}
            // onRateChangeCapture={() => console.info('onRateChangeCapture')}
            // onSeeked={() => {
            // 	console.info('onSeeked');
            // }}
            // onSeekedCapture={() => console.info('onSeekedCapture')}
            // onSeeking={() => {
            // 	console.info('onSeeking');
            // }}
            // onSeekingCapture={() => console.info('onSeekingCapture')}
            // onStalled={() => console.info('onStalled')}
            // onStalledCapture={() => console.info('onStalledCapture')}
            // onSuspend={() => console.info('onSuspend')}
            // onSuspendCapture={() => console.info('onSuspendCapture')}
            onTimeUpdate={() => {
              if (!playerRef.current) return;

              const currentVolume = getCookie('playerVolume') || 1;
              playerRef.current.volume = +currentVolume;

              //* duration
              const value = Number(playerRef.current.currentTime) - +playerRef.current.duration;
              const newValO = +playerRef.current.duration.toFixed(1);
              const newVal = value.toFixed(0);
              newValO !== durationOriginal && setDurationOriginal(newValO);
              newVal !== duration && setDuration(`-${formatTime(+newVal * -1)}`);

              //* currentTime
              const newCurrentTimeO = +playerRef.current.currentTime.toFixed(1);
              const newCurrentTime = playerRef.current.currentTime.toFixed(0);
              newCurrentTimeO !== durationOriginal && setCurrentTimeOriginal(+`${newCurrentTimeO}`.replace('NaN', '0'));
              newCurrentTime !== duration && setCurrentTime(formatTime(+newCurrentTime.replace('NaN', '0')));
            }}
            // onTimeUpdateCapture={() => console.info('onTimeUpdateCapture')}
            // onVolumeChange={() => console.info('onVolumeChange')}
            // onVolumeChangeCapture={() => console.info('onVolumeChangeCapture')}
            // onWaiting={() => console.info('onWaiting')}
            // onWaitingCapture={() => console.info('onWaitingCapture')}
            height="0"
          />
        </MediaSession>
      ),
    [currentSong],
  );

  const handleSongCoverImage = (playlist: Playlist, track: Track): Track => {
    const newTack = { ...track };
    if (!track.image) {
      newTack.image = playlist.image;
    }
    if (!track.smallImage) {
      newTack.smallImage = playlist.smallImage;
    }

    return newTack;
  };

  const handlesetAlbumView = (value: Playlist, isTrack = false) => {
    let newVal = { ...value };
    if (isTrack) {
      newVal = createMockedPlaylistFromSong(value);
    }
    const newTracks = newVal.tracks.map(track => handleSongCoverImage(newVal, track));

    setAlbumView({ ...newVal, originalList: newTracks, tracks: newTracks });
  };

  const handleSetRepeat = () => {
    let newRepeat: RepeatType = 'none';
    if (repeat === 'none') newRepeat = 'all';
    if (repeat === 'all') newRepeat = 'one';
    if (repeat === 'one') newRepeat = 'none';

    setRepeat(newRepeat);
  };

  // eslint-disable-next-line react/jsx-no-constructed-context-values
  const contextValue: IMusicPlayerContext = {
    error,
    play,
    pause,
    prevSong,
    nextSong: () => nextSong(),
    currentSong,
    setCurrentSong: (value: Track | null) => setCurrentSong(value),
    albumView,
    setAlbumView: (value: Playlist, isTrack?: boolean) => handlesetAlbumView(value, isTrack),
    duration,
    currentTime,
    durationOriginal,
    currentTimeOriginal,
    setNewTime,
    formatTime,
    isOffline,
    setIsOffline: (val: boolean) => setIsOffline(val),
    isPlaylist,
    setIsPlaylist: (value: string | null) => setIsPlaylist(value),
    isFullScreen,
    setIsFullScreen: setIsFullScreenPlayer,
    playNewSong,
    playAlbumFromSong,
    playFavoritesFromSong,
    isMoodsResult,
    setIsMoodsResult: (value: Category | null) => setIsMoodsResult(value),
    playing,
    setPlaying: (value: boolean) => setPlaying(value),
    volume,
    setVolume: (value: number) => setVolume(value),
    disableButton,
    setDisableButton: (value: IPlayerButtonStates) => setDisableButton(value),
    shuffle,
    setShuffle: (value: boolean) => setShuffle(value),
    repeat,
    setRepeat: handleSetRepeat,
    playingList,
    isAlbumPlaylist,
  };

  return (
    <MusicPlayerContext.Provider value={contextValue}>
      {Player}
      {children}
    </MusicPlayerContext.Provider>
  );
};

export interface IMusicPlayerContext {
  error: string | null;
  play: () => void;
  pause: () => void;
  prevSong: () => void;
  nextSong: () => void;
  setNewTime: (newTime: number) => void;
  currentSong: Track | null;
  setCurrentSong: (value: Track | null) => void;
  albumView: Playlist | null;
  setAlbumView: (value: Playlist, isTrack?: boolean) => void;
  duration: string;
  currentTime: string;
  durationOriginal: number;
  currentTimeOriginal: number;
  formatTime: (time: number) => string;
  playNewSong: (song: Track | null) => void;
  playAlbumFromSong: (song: Track, mockedPlaylist?: boolean) => void;
  playFavoritesFromSong: (song: Track, list: Track[], favoritesPlaylist: boolean) => void;
  isPlaylist: string | null;
  setIsPlaylist: (val: string | null) => void;
  isOffline: boolean;
  setIsOffline: (val: boolean) => void;
  isFullScreen: boolean;
  setIsFullScreen: (val: boolean) => void;
  isMoodsResult: Category | null;
  setIsMoodsResult: (value: Category | null) => void;
  playing: boolean;
  setPlaying: (val: boolean) => void;
  volume: number;
  setVolume: (val: number) => void;
  disableButton: IPlayerButtonStates;
  setDisableButton: (value: IPlayerButtonStates) => void;
  shuffle: boolean;
  setShuffle: (value: boolean) => void;
  repeat: RepeatType;
  setRepeat: () => void;
  playingList: Playlist | null;
  isAlbumPlaylist: boolean;
}

export default MusicPlayerContext;
