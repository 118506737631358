import { Button, Dialog, Grid, Typography } from '@mui/material';
import { ReactComponent as SinesLogo } from 'assets/imgs/sinesLogo.svg';
import ConfirmActionModal from 'components/ConfirmActionModal';
import DeleteBody from 'components/DeleteBody';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const ExpiredTrial = ({ classes, handleSubscribe, handleDeleteUser, handleFeedback }: any) => {
  const [deleteAccount, setDeleteAccount] = useState(false);

  const { t } = useTranslation();

  return (
    <>
      <Grid
        container
        spacing={2}
        direction="row"
        flexWrap="wrap"
        alignContent="flex-start"
        justifyContent="center"
        justifyItems="center"
        className={classes.dialogContainer}>
        <Grid item xs={12}>
          <SinesLogo />
        </Grid>
        <Grid item xs={12}>
          <Typography className={classes.title}>
            {t('expiredtrial_thankyou') /* Thank you for <br /> trying SINES. */} <br />
            {t('expiredtrial_tryingsines')}.
          </Typography>
        </Grid>
        <Grid item xs={12} className={classes.expiredDescription}>
          <Typography className={classes.subTitle}>
            {
              t(
                'expiredtrial_trialended',
              ) /* Your free trial has ended. If you enjoyed your experience we would love to have you as part of our
            community. */
            }
            .
          </Typography>
        </Grid>
        <Grid item xs={12} className={classes.expiredButtonGrid}>
          <Button variant="primary" className={classes.button1} onClick={handleSubscribe}>
            {t('expiredtrial_subscribe') /* Subscribe */}
          </Button>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography className={classes.feedback1}>Didn’t like SINES? </Typography>
          <Typography className={classes.feedback2} onClick={handleFeedback}>
            {t('expiredtrial_sharefeedback') /* Share your feedback with us. */}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Button
            className={classes.button2}
            onClick={() => {
              setDeleteAccount(true);
            }}>
            {t('expiredtrial_deleteaccount') /* Delete account */}
          </Button>
        </Grid>
      </Grid>
      {deleteAccount && (
        <Dialog
          fullWidth
          className={classes.dialogDeleteAccount}
          open={deleteAccount}
          onClose={() => setDeleteAccount(false)}>
          <ConfirmActionModal
            title={
              // "We’re sorry to see you go"
              t('expiredtrial_sorry')
            }
            body={<DeleteBody />}
            cancelAction={() => setDeleteAccount(false)}
            confirmText={t('Delete')}
            confirmAction={handleDeleteUser}
          />
        </Dialog>
      )}
    </>
  );
};

export default ExpiredTrial;
