import { Dispatch, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { StoreState } from 'store/@types';
import { getCategoryPlaylists } from 'store/moods/thunks';

import DefaultComponent from './MoodResults';

const mapStateToProps = ({ moods }: StoreState) => ({ moods });

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({ getCategoryPlaylists }, dispatch);

export type ReduxProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(DefaultComponent);
