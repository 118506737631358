//* EXTERNAL LIBS
import React, { useMemo, useState } from 'react';
import { Controller } from 'react-hook-form';
import { styled } from '@mui/material/styles';

//* EXTERNAL LIBS --> MUI
import { Checkbox as MuiCheckbox, FormControl, FormControlLabel, FormHelperText } from '@mui/material';

//* LOCAL COMPONENT IMPORTS
import { ReactComponent as CBoxOff } from 'assets/imgs/CBoxOff.svg';
import { ReactComponent as CBoxOn } from 'assets/imgs/CBoxOn.svg';
// import { ReactComponent as PasswordSee } from 'assets/imgs/passwordSee.svg';
import { InfoOutlined } from '@mui/icons-material';
import { parseErrorMessage, parseIfError } from '../utils/formHelpers';
import { useStyles } from './styles';
import { ExtendedInputFields, TName } from '../@types';

const StyledFormControl = styled(FormControl)({
  '& .MuiFormHelperText-root': {
    margin: '0',
    padding: '0',
    marginBottom: '-10px',
  },
  '& .MuiFormHelperText-root.Mui-error': {
    color: '#E78989',
  },
});

//* COMPONENT INTERFACES
export interface ICheckbox
  extends Omit<ExtendedInputFields, 'label' | 'additionalOnChange' | 'value' | 'required' | 'onChange'> {
  /**
   * The color of the checkbox
   */
  color?: 'default' | 'error' | 'primary' | 'secondary' | 'info' | 'success' | 'warning' | undefined;
  /**
   * The value of the checkbox
   */
  value?: boolean;
  /**
   * Whether the checkbox is disabled or not
   */
  disabled?: boolean;
  classes?: { [x: string]: unknown };
  /**
   * Additional to be performed whenever the checkbox's value changes
   */
  additionalOnChange?: (checked: boolean) => void;
  /**
   * TODO: add description to field
   */
  [x: string]: string | number | React.ReactNode | unknown;
}

//* COMPONENT DEFAULT PROPS
const defaultProps = {
  // react-hook-form
  control: undefined,
  errors: undefined,

  classes: {},
  // field display fields
  name: undefined,
  value: false,
  defaultValue: false,
  helperText: ' ',
  lPrev: null,
  lEnd: null,

  showErrorAndHelperText: false,
  // handlers
  additionalOnChange: undefined,

  // component specific
  color: 'primary',
};

//* COMPONENT
const Checkbox = ({
  name,
  label,
  value,
  classes,
  color,
  control,
  disabled,
  defaultValue,
  helperText,
  errors,
  additionalOnChange,
}: ICheckbox) => {
  const styles = useStyles();
  const hasError = errors && name && Object.keys(errors).length > 0 ? parseIfError(errors, name) : null;
  const errorMessage = hasError && parseErrorMessage(hasError);
  const [checked, setChecked] = useState<boolean>(value || false);

  const customClasses = { ...styles, ...classes };

  const helperTextMessage = useMemo(() => {
    if (!hasError && (!helperText || helperText === ' ')) return ' ';

    return (
      <span style={{ display: 'flex' }}>
        <InfoOutlined fontSize="small" style={{ marginRight: '10px' }} />
        {hasError ? errorMessage : helperText}
      </span>
    );
  }, [errorMessage, helperText]);

  return (
    <StyledFormControl error={Boolean(hasError)}>
      {control && (
        <Controller
          name={name as TName}
          // type="checkbox"
          // valueName="checked"
          control={control}
          render={({ field }) => (
            <FormControlLabel
              label={(label as string) || ''}
              disabled={disabled}
              classes={{
                root: customClasses.customCheckboxRoot,
                label: customClasses.customCheckboxLabel,
              }}
              control={
                <MuiCheckbox
                  icon={<CBoxOff />}
                  checkedIcon={<CBoxOn />}
                  color={color}
                  onChange={() => {
                    field.onChange(!field.value);
                    additionalOnChange && additionalOnChange(!field.value);
                  }}
                  value={(field.value !== null ? field.value : defaultValue) || false}
                  checked={(field.value !== null ? field.value : defaultValue) || false}
                />
              }
            />
          )}
        />
      )}
      {!control && (
        <FormControlLabel
          label={(label as string) || ''}
          disabled={disabled}
          classes={{
            root: customClasses.customCheckboxRoot,
            label: customClasses.customCheckboxLabel,
          }}
          control={
            <MuiCheckbox
              icon={<CBoxOff />}
              checkedIcon={<CBoxOn />}
              color={color}
              onChange={() => {
                setChecked(!checked);
                additionalOnChange && additionalOnChange(!checked);
              }}
              value={checked}
              checked={checked}
            />
          }
        />
      )}
      <FormHelperText>{helperTextMessage}</FormHelperText>
    </StyledFormControl>
  );
};

Checkbox.defaultProps = { ...defaultProps };

export { Checkbox };
