import React, { FC, useMemo } from 'react';
// * 3rd party libs
import { IconButton, Grid, Button } from '@mui/material';
// * ASSETs
import PlayButton from 'assets/imgs/player/play';
import Pause from 'assets/imgs/player/pause';
import Shuffle from 'assets/imgs/player/shuffle';

// * HOOKS - COMPONENTS
import useMusicPlayer from 'hooks/useMusicPlayer';
import { MoodsState } from 'store/@types/moods';
import { sendFavPlaylist } from 'store/moods/thunks';
import { useDispatch } from 'react-redux';

// * TYPES
// * LOCAL IMPORTS
import { useStyles } from './styles';
import Heart from '../../../assets/imgs/player/heart';

const AlbumPlayer: FC<{ moods: MoodsState }> = ({ moods }) => {
  const classes = useStyles();
  const { favorites } = moods;

  const dispatch = useDispatch();
  const {
    albumView,
    play,
    pause,
    isPlaylist,
    playing,
    disableButton,
    shuffle,
    setShuffle,
    currentSong,
    playAlbumFromSong,
  } = useMusicPlayer();

  const isFavorite = useMemo(
    () => (isPlaylist && favorites?.playlists?.some(e => e.id === albumView?.id)) || null,
    [isPlaylist, favorites, albumView],
  );

  if (!albumView) {
    return null;
  }

  return (
    <Grid
      container
      direction="row"
      spacing={2}
      alignItems="center"
      justifyContent="space-between"
      className={classes.playlistPlayerWrapper}>
      <Grid>
        <Button
          className={classes.heartDesktop}
          id="button-favorite"
          onClick={() => {
            dispatch(sendFavPlaylist(albumView.id, !isFavorite));
          }}>
          <Heart svgProps={{ width: '36px', height: '36px' }} fill={Boolean(isFavorite)} />
        </Button>
      </Grid>
      <Grid>
        <IconButton
          className={classes.playerButton}
          onClick={() => {
            setShuffle(!shuffle);
          }}>
          <Shuffle disabled={!shuffle} />
        </IconButton>
        <IconButton
          className={classes.playerButton}
          disabled={disableButton.playPause}
          onClick={() => {
            if (playing) {
              pause();
            } else if (!currentSong) playAlbumFromSong(albumView.tracks[0]);
            else play();
          }}>
          {playing ? <Pause disabled={disableButton.playPause} /> : <PlayButton disabled={disableButton.playPause} />}
        </IconButton>
      </Grid>
    </Grid>
  );
};

export default AlbumPlayer;
