/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { FC, useState, useEffect } from 'react';
import { IconButton, Stack, Slider, Typography, Grid } from '@mui/material';
import clsx from 'clsx';

import useMusicPlayer from 'hooks/useMusicPlayer';
import useResponsive from 'hooks/useResponsive';

import FallbackImage from 'assets/imgs/missing_image.svg';
import { ReactComponent as VolumeIcon } from 'assets/imgs/player/volume_icon.svg';
import { ReactComponent as MinPlayIcon } from 'assets/imgs/player/minified-playcircle.svg';
import { ReactComponent as MinPauseIcon } from 'assets/imgs/player/minified-pausecircle.svg';

import Shuffle from 'assets/imgs/player/shuffle';
import Backwards from 'assets/imgs/player/backward';
import PlayButton from 'assets/imgs/player/play';
import Pause from 'assets/imgs/player/pause';
import Forward from 'assets/imgs/player/forward';
import Repeat from 'assets/imgs/player/repeatIcon';
import RepeatNoneIcon from 'assets/imgs/player/repeatNone';
import RepeatOneIcon from 'assets/imgs/player/repeatOne';

import Heart from 'assets/imgs/player/heart';

import DivImage from 'components/DivImage';
import { useStyles } from './styles';
import { ReduxProps } from '.';

interface BottomPlayerProps extends ReduxProps {
  isOffline?: boolean;
}

const BottomPlayer: FC<BottomPlayerProps> = ({ moods, sendFavTrack, isOffline = false }) => {
  const classes = useStyles();
  const { favorites } = moods;
  const { isMobile, isTablet, isLarge } = useResponsive();

  const {
    currentSong,
    duration,
    currentTime,
    setNewTime,
    durationOriginal,
    currentTimeOriginal,
    prevSong,
    play,
    pause,
    nextSong,
    setIsFullScreen,
    isFullScreen,
    playing,
    disableButton,
    volume,
    setVolume,
    shuffle,
    setShuffle,
    repeat,
    setRepeat,
    setAlbumView,
    playingList,
    isAlbumPlaylist,
  } = useMusicPlayer();

  const handleChange = (event: Event, newValue: number | number[]) => {
    if (typeof newValue === 'number') {
      setNewTime(newValue);
    }
  };
  const handleChangeVolume = (event: Event, newValue: number | number[]) => {
    if (typeof newValue === 'number') {
      setVolume(newValue);
    }
  };

  const [image, setImage] = useState<React.ReactNode | null>(null);
  const [clearImage, setClearImage] = useState<boolean>(false);

  useEffect(() => {
    if (!currentSong) return;
    if (clearImage) {
      setImage(null);
      setClearImage(false);
    } else {
      const imageSource = currentSong?.image || playingList?.image;

      setImage(
        imageSource ? (
          <object className={isTablet ? classes.coverImgMobile : classes.coverImg} data={imageSource} type="image/png">
            <img
              className={isTablet ? classes.coverImgMobileDefault : classes.coverImgDefault}
              src={FallbackImage}
              alt="coverImage"
            />
          </object>
        ) : (
          <div className={isTablet ? classes.coverImgMobile : classes.coverImg}>
            <img
              className={isTablet ? classes.coverImgMobileDefault : classes.coverImgDefault}
              src={FallbackImage}
              alt="coverImage"
            />
          </div>
        ),
      );
    }
  }, [clearImage, isTablet]);

  useEffect(() => {
    currentSong && setClearImage(true);
  }, [currentSong]);

  let playerContent = null;

  if (!currentSong || (isFullScreen && isMobile)) {
    return null;
  }

  const handlePlayPause = () => {
    if (playing) {
      pause();
    } else {
      play();
    }
  };

  const isFavorite = favorites?.tracks?.some(e => e.id === currentSong.id);

  if (isMobile) {
    playerContent = (
      <Grid
        style={{ width: '100%' }}
        onClick={(e: React.MouseEvent<HTMLElement>) => {
          // get id of the element clicked
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const targetId = (e.target as any).id;
          // if the element clicked is the button, do nothing

          if (targetId.includes('button-') || targetId.includes('Vector')) return;

          if (!isAlbumPlaylist && !isOffline) return;
          playingList && setAlbumView(playingList);
          setIsFullScreen(true);
        }}>
        <div>{image}</div>
        <div className={classes.musicInfoWrapperMobile}>
          <div id="song-title-bottom-player-mobile">
            <Typography
              variant="listTrack"
              component="p"
              width="fit-content"
              className={clsx(classes.text, { animateText: currentSong?.name.length > 31 })}>
              {currentSong?.name}
            </Typography>
            <Typography variant="listAuthor" component="p" className={classes.text}>
              {currentSong.artist?.name}
            </Typography>
          </div>
        </div>
        <IconButton
          id="button-play-pause"
          className={classes.playPauseMobileButton}
          disabled={disableButton.playPause}
          onClick={handlePlayPause}>
          {playing ? <MinPauseIcon id="button-pause" /> : <MinPlayIcon id="button-play" />}
        </IconButton>
        <Slider
          defaultValue={0}
          className={classes.sliderMobile}
          min={0}
          max={durationOriginal || 0}
          value={currentTimeOriginal || 0}
          color="primary"
        />
      </Grid>
    );
  } else {
    playerContent = (
      <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start">
        <Grid
          item
          id="open-playlist"
          xs={4}
          onClick={(e: React.MouseEvent<HTMLElement>) => {
            // get id of the element clicked
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const asd = (e.target as any).id;
            // if the element clicked is the button, do nothing
            if (asd === 'Vector' || asd === 'button-favorite') return;
            if (!isAlbumPlaylist) return;
            playingList && setAlbumView(playingList);
            setIsFullScreen(true);
          }}>
          <div className={classes.musicInfoWrapper}>
            <div style={{ display: 'flex' }}>
              {!isTablet && (
                <IconButton id="button-favorite" onClick={() => sendFavTrack(currentSong.id, !isFavorite)}>
                  <Heart fill={isFavorite} />
                </IconButton>
              )}
              <div id="song-title-bottom-player">
                <Typography
                  variant="listTrack"
                  component="p"
                  width="fit-content"
                  className={clsx(classes.textTitle, { animateText: currentSong?.name.length > 35 })}>
                  {currentSong?.name}
                </Typography>
                <Typography variant="listAuthor" component="p" className={classes.text}>
                  {currentSong.artist?.name}
                </Typography>
              </div>
            </div>
          </div>
          <div>{image}</div>
        </Grid>
        <Grid item xs={4}>
          <Stack direction="row" spacing={isTablet ? 0 : 2} justifyContent="center">
            {isTablet && (
              <IconButton className={classes.playerButton} onClick={() => sendFavTrack(currentSong.id, !isFavorite)}>
                <Heart fill={isFavorite} />
              </IconButton>
            )}
            <IconButton className={classes.playerButton} onClick={() => setShuffle(!shuffle)}>
              <Shuffle disabled={!shuffle} />
            </IconButton>
            <IconButton className={classes.playerButton} disabled={disableButton.previous} onClick={prevSong}>
              <Backwards disabled={disableButton.previous} />
            </IconButton>
            <IconButton className={classes.playerButton} disabled={disableButton.playPause} onClick={handlePlayPause}>
              {playing ? (
                <Pause disabled={disableButton.playPause} />
              ) : (
                <PlayButton disabled={disableButton.playPause} />
              )}
            </IconButton>
            <IconButton className={classes.playerButton} disabled={disableButton.next} onClick={nextSong}>
              <Forward disabled={disableButton.next} />
            </IconButton>
            <IconButton className={classes.playerButton} onClick={setRepeat}>
              {repeat === 'all' && <Repeat />}
              {repeat === 'none' && <RepeatNoneIcon />}
              {repeat === 'one' && <RepeatOneIcon />}
            </IconButton>
            {/* {isLarge && (
							<IconButton
								className={classes.playerButton}
								disabled={disableButton.airplay}
								onClick={() => {}}>
								<Airplay disabled={disableButton.airplay} />
							</IconButton>
						)} */}
          </Stack>
          <Stack direction="row" spacing={2} justifyContent="center">
            <Typography variant="carrouselItem" style={{ marginTop: '-4px', fontSize: '12px', width: 38 }}>
              {currentTime}
            </Typography>
            <Slider
              aria-label="current-time"
              defaultValue={0}
              className={classes.slider}
              min={0}
              max={durationOriginal || 0}
              value={currentTimeOriginal || 0}
              onChange={handleChange}
              color="primary"
            />
            <Typography variant="carrouselItem" style={{ marginTop: '-6px', fontSize: '12px', width: 38 }}>
              {duration}
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={4}>
          <Stack direction="row" spacing={2} justifyContent="center" className={classes.volumeContainer}>
            {/* {!isLarge && (
							<IconButton
								className={classes.playerButton}
								disabled={disableButton.airplay}
								onClick={() => {}}>
								<Airplay disabled={disableButton.airplay} />
							</IconButton>
						)} */}
            <Slider
              aria-label="volume-slider"
              defaultValue={0}
              className={classes.volumeSlider}
              min={0}
              max={1}
              step={0.01}
              value={volume}
              onChange={handleChangeVolume}
              color="primary"
            />
            <IconButton disabled className={classes.playerButton} onClick={() => {}}>
              <VolumeIcon />
            </IconButton>
          </Stack>
        </Grid>
      </Grid>
    );
  }

  return (
    <div className={classes.root} style={{ bottom: isOffline ? 0 : '' }}>
      <DivImage
        customClasses={clsx(classes.containerImage, 'animation', {
          show: Boolean(currentSong),
        })}
        src={currentSong?.image || playingList?.image || null}
        smallSrc={currentSong?.smallImage || playingList?.smallImage || null}
      />

      <div
        className={classes.container}
        onClick={(e: React.MouseEvent<HTMLElement>) => {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const hasId = (e.target as any).id;
          if (!isLarge || !hasId || (hasId && (hasId.includes('button-') || hasId.includes('Vector')))) return;
          if (!isAlbumPlaylist) return;
          playingList && setAlbumView(playingList);
          setIsFullScreen(true);
        }}>
        {playerContent}
      </div>
    </div>
  );
};

BottomPlayer.defaultProps = {
  isOffline: false,
};

export default BottomPlayer;
