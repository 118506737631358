import React, { createContext, useState, useEffect } from 'react';

interface PwaInstallPromptContextType {
  isInstallable: boolean;
  deferredPrompt: any;
}

// Create the context with a default value
const PwaInstallPromptContext = createContext<PwaInstallPromptContextType>({} as PwaInstallPromptContextType);

// Export a custom hook to use the context

export const PwaInstallPromptProvider: React.FC = ({ children }) => {
  const [isInstallable, setIsInstallable] = useState(false);
  const [deferredPrompt, setDeferredPrompt] = useState(null);

  useEffect(() => {
    const handleBeforeInstallPrompt = (e: any) => {
      e.preventDefault();
      setDeferredPrompt(e);
      setIsInstallable(true);
    };

    window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);

    return () => {
      window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
    };
  }, []);

  // eslint-disable-next-line react/jsx-no-constructed-context-values
  const contextValue: PwaInstallPromptContextType = {
    isInstallable,
    deferredPrompt,
  };
  return <PwaInstallPromptContext.Provider value={contextValue}>{children}</PwaInstallPromptContext.Provider>;
};

export default PwaInstallPromptContext;
