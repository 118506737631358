import { CustomReactTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: CustomReactTheme) => ({
  card: {
    background: '#121212',
    borderRadius: '20px',
    minWidth: '373px',
    width: '100%',
    height: '71px',
    position: 'relative',
  },
  rectangle: {
    gap: '10px',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexGrow: 1,
  },
  square: {
    borderRadius: '20px 20px 20px',
    boxShadow: '0 0 10px 0 rgba(31, 31, 31, 0.5)',
    width: '71px',
    height: '71px',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
  },
  title: {
    color: '#F4F4F4',
    fontSize: '14px',
    lineHeight: '17px',
    width: '75%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  author: {
    color: '#B3B3B3;',
    fontSize: '14px',
    lineHeight: '17px',
    width: '75%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  playButton: {
    position: 'absolute',
    top: '0',
    right: '5px',
    bottom: '0',
    background: '#121212',
    zIndex: 1,
    '&:hover': {
      background: '#121212',
    },
  },
  clickableMenu: {
    width: '100%',
    height: '100%',
    borderRadius: '20px',
  },
  //* TABLET - landScape
  [theme.breakpoints.down('lg')]: {
    // card: {
    // 	maxWidth: '370px'
    // },
    // playButton: {
    // 	marginLeft: '280px'
    // }
  },
  //* TABLET - portrait
  [theme.breakpoints.down('md')]: {},
  //* MOBILE
  [theme.breakpoints.down('sm')]: {
    card: {
      minWidth: '270px',
    },
  },
}));

export { useStyles };
