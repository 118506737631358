import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

// * 3rd party libs
import { Typography } from '@mui/material';

// * LOCAL IMPORTS
import { menuItem } from '../UserMenuPage';

interface TeamsFaqProps {
  setModalOpen: (value: string | null) => void;
}

const TeamsFaq: FC<TeamsFaqProps> = ({ setModalOpen }) => {
  const { t } = useTranslation();
  return (
    <>
      <Typography variant="heroTitle" sx={{ marginLeft: '15px', marginTop: '15px', width: '350px' }}>
        {t('Teams')}
      </Typography>
      <span style={{ paddingLeft: '15px' }}>
        {menuItem(
          t('faq-teams-1'), // 'Can I change who is on my Team in the middle of a subscription or add new members?'
          {
            action: () => {
              setModalOpen('faq-teams-1');
            },
          },
        )}
        {menuItem(
          t('faq-teams-2'), // 'What happens when my free trial ends?'
          {
            action: () => {
              setModalOpen('faq-teams-2');
            },
          },
        )}
        {menuItem(
          t('faq-teams-3'), // 'What happens if I cancel my subscription during the trial?'
          {
            action: () => {
              setModalOpen('faq-teams-3');
            },
          },
        )}
        {menuItem(
          t('faq-teams-4'), // 'What happens if I cancel my subscription?'
          {
            action: () => {
              setModalOpen('faq-teams-4');
            },
          },
        )}
        {menuItem(
          t('faq-teams-5'), // 'What does the renewal process look like?'
          {
            action: () => {
              setModalOpen('faq-teams-5');
            },
          },
        )}
        {menuItem(
          t('faq-teams-6'), // 'How do I cancel or change billing information?'
          {
            action: () => {
              setModalOpen('faq-teams-6');
            },
          },
        )}
      </span>
    </>
  );
};

export default TeamsFaq;
