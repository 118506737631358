import { Box, Button, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import sines from 'assets/imgs/sinesLogo.svg';
import QrCodeBottom from 'components/QrCodeBottom';
import { useStyles } from './styles';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const ThankYou = ({ handleSubscribe }: any) => {
  const { t } = useTranslation();
  const classesThanks = useStyles();
  return (
    <Box className={classesThanks.thankYou}>
      <Box className={classesThanks.topBox}>
        <Box className={classesThanks.sinesLogo}>
          <img src={sines} alt="Sines" />
        </Box>
        <Typography className={classesThanks.title}>
          {t('thankyou_thanksforjoin') /* Thank you for joining us! */}
        </Typography>
        <Typography className={classesThanks.subTitle}>
          {t('thankyou_enjoysines') /* Please enjoy SINES from our available platforms. */}
        </Typography>
      </Box>
      <Box className={classesThanks.bottomBox}>
        <Button variant="primary" className={classesThanks.buttonContinue} onClick={handleSubscribe}>
          {t('thankyou_continuetoweb') /* Continue to Web App */}
        </Button>
        <QrCodeBottom />
      </Box>
    </Box>
  );
};

export default ThankYou;
