import React, { FC, useState, useEffect, useRef } from 'react';

import useMusicPlayer from 'hooks/useMusicPlayer';
import useResponsive from 'hooks/useResponsive';
import FallbackImageSvg from 'assets/imgs/missing_image_medium.svg';

interface DivImageProps {
  src: string | null;
  smallSrc?: string | null;
  [x: string]: unknown;
}

const DivImage: FC<DivImageProps> = ({ customClasses, src, smallSrc, children, ...others }) => {
  const imageRef = useRef(null);
  const { isMobile, isLarge } = useResponsive();
  const { isPlaylist } = useMusicPlayer();

  const [source, setSource] = useState({ src: smallSrc, endImage: false });

  useEffect(() => {
    if (!src) return;
    const img = new Image();
    img.src = src || FallbackImageSvg;
    img.onload = () => setSource({ src, endImage: true });
    img.onerror = () => {
      if (!imageRef.current) return;
      (imageRef.current as HTMLLinkElement).style.backgroundImage = `url(${FallbackImageSvg})`;
    };
  }, [src, smallSrc]);

  return (
    <div
      ref={imageRef}
      {...others}
      className={` ${customClasses ? (customClasses as string) : ''}`}
      style={{
        ...(src && { backgroundImage: `url(${source.src})` }),
        ...(!src && { backgroundImage: `url(${FallbackImageSvg})` }),
        ...(!isPlaylist && !isLarge && !isMobile && { bottom: 0 }),
      }}>
      {children}
    </div>
  );
};

DivImage.defaultProps = {
  smallSrc: null,
};

export default DivImage;
