import { CustomReactTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: CustomReactTheme) => ({
  square: {
    position: 'relative',
    borderRadius: '20px',
    width: '100%',
    height: '48px',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
  },

  skeletonBars: {
    width: '100%',
    height: '100%',
    borderRadius: '20px',
    margin: '5px 0',
    transform: 'none',
  },

  root: {
    zIndex: 2,
    height: '58px',
    maxHeight: '58px',
    width: '100%',
    display: 'flex',
    backgroundPosition: 'left',
    backgroundSize: 'cover',
    cursor: 'pointer',
    marginBottom: '10px',
    '&:hover': {
      background: '#363636',
      borderRadius: '10px',
    },
  },
  coverImageWrapper: {
    display: 'flex',
    alignSelf: 'center',
  },
  clickableMusicDisplay: {
    width: '100%',
    display: 'flex',
    backgroundPosition: 'left',
  },
  musicTextPlayer: {
    padding: 9,
    flexGrow: 1,
  },
  playerButton: {
    color: '#fff',
    marginRight: '10px',
  },
  coverImgMobile: {
    borderRadius: '10px',
    boxShadow: '0 0 10px 0 rgba(31, 31, 31, 0.5)',
    height: '58px',
    maxHeight: '58px',
    width: '58px',
    maxWidth: '58px',
    bottom: '20px',
  },
  coverImgMobileDefault: {
    height: '18px',
    maxHeight: '18px',
    width: '18px',
    maxWidth: '18px',
    bottom: '20px',
    background: '#292929',
    boxShadow: '0 0 10px 0 rgb(31 31 31 / 50%)',
    borderRadius: '10px',
    padding: '22px',
  },
  //* TABLET - landScape
  [theme.breakpoints.down('lg')]: {
    square: {
      height: '114px',
    },
  },
  //* TABLET - portrait
  [theme.breakpoints.down('md')]: {
    square: {
      height: '100px',
    },
  },
  //* MOBILE
  [theme.breakpoints.down('sm')]: {},
}));

export { useStyles };
