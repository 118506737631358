import { createReducer } from 'typesafe-actions';
import { MoodsActions as Actions, MoodsState } from 'store/@types/moods';

const INITIAL_STATE: MoodsState = {
  loading: false,
  loadingSpaces: false,
  loadingMoods: false,
  loadingGuided: false,
  searchLoading: false,
  error: null,
  paginationMeta: null,
  moodsList: null,
  spacesList: null,
  guidedList: null,
  categoryPlaylist: {},
  playlistDetails: {},
  moodsAction: null,
  favoritesTracks: null,
  favoritesAlbums: null,
  favoritesPlaylists: null,
  favorites: null,
  searchResults: null,
};

const moodsReducer = createReducer(INITIAL_STATE, {
  [Actions.SET_LOADING]: state => ({ ...state, loading: true }),
  [Actions.SET_LOADING_SPACES]: state => ({ ...state, loadingSpaces: true }),
  [Actions.SET_LOADING_MOODS]: state => ({ ...state, loadingMoods: true }),
  [Actions.SET_LOADING_GUIDED]: state => ({ ...state, loadingGuided: true }),
  [Actions.SET_SEARCH_LOADING]: state => ({ ...state, searchLoading: true }),
  [Actions.SET_ERROR]: (state, { payload }) => ({ ...state, loading: false, error: payload }),
  [Actions.SET_SPACES]: (state, { payload }) => ({
    ...state,
    loadingSpaces: false,
    spacesList: payload?.children || payload,
  }),
  [Actions.SET_MOODS]: (state, { payload }) => ({
    ...state,
    loadingMoods: false,
    moodsList: payload?.children || payload,
  }),
  [Actions.SET_GUIDED]: (state, { payload }) => ({
    ...state,
    loadingGuided: false,
    guidedList: payload?.children || payload,
  }),
  [Actions.SET_CATEGORY_PLAYLISTS]: (state, { payload }) => ({
    ...state,
    loading: false,
    categoryPlaylist: { ...state.categoryPlaylist, ...payload },
  }),
  [Actions.SET_PLAYLIST_DETAILS]: (state, { payload }) => ({
    ...state,
    loading: false,
    playlistDetails: { ...state.categoryPlaylist, ...payload },
  }),
  [Actions.SET_FAVORITE_TRACKS]: (state, { payload }) => ({
    ...state,
    loading: false,
    favoritesTracks: payload,
  }),
  [Actions.SET_FAVORITE_ALBUMS]: (state, { payload }) => ({
    ...state,
    loading: false,
    favoritesAlbums: payload,
  }),
  [Actions.SET_FAVORITE_PLAYLISTS]: (state, { payload }) => ({
    ...state,
    loading: false,
    favoritesPlaylists: payload,
  }),
  [Actions.SET_NEW_FAVORITE_TRACK]: (state, { payload }) => {
    const favorites = state.favorites ? { ...state.favorites } : null;
    if (payload.deleted && favorites) {
      favorites.tracks = favorites.tracks.filter(track => track.id !== payload.track.id);
      return {
        ...state,
        loading: false,
        favorites,
      };
    }
    const newTrack = { id: payload.track.id, name: 'track' };
    if (favorites) {
      favorites.tracks.push(newTrack);
    }
    return {
      ...state,
      loading: false,
      favorites,
    };
  },
  [Actions.SET_NEW_FAVORITE_PLAYLIST]: (state, { payload }) => {
    const favorites = state.favorites ? { ...state.favorites } : null;
    if (payload.deleted && favorites) {
      favorites.playlists = favorites.playlists.filter(playlist => playlist.id !== payload.playlist.id);
      return {
        ...state,
        loading: false,
        favorites,
      };
    }
    const newTrack = { id: payload.playlist.id, name: 'playlist' };
    if (favorites) {
      favorites.playlists.push(newTrack);
    }
    return {
      ...state,
      loading: false,
      favorites,
    };
  },
  [Actions.SET_ALL_USER_FAVORITES]: (state, { payload }) => ({
    ...state,
    loading: false,
    favorites: payload,
  }),
  [Actions.SET_SEARCH]: (state, { payload }) => ({
    ...state,
    searchLoading: false,
    searchResults: payload,
  }),
});

export default moodsReducer;
