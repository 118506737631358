import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
// * 3rd party libs
import { Typography } from '@mui/material';

// * LOCAL IMPORTS
import { menuItem } from '../UserMenuPage';

interface LocationsFaqProps {
  setModalOpen: (value: string | null) => void;
}

const LocationsFaq: FC<LocationsFaqProps> = ({ setModalOpen }) => {
  const { t } = useTranslation();
  return (
    <>
      <Typography variant="heroTitle" sx={{ marginLeft: '15px', marginTop: '15px', width: '350px' }}>
        {t('Locations')}
      </Typography>
      <span style={{ paddingLeft: '15px' }}>
        {menuItem(
          t('faq-locations-1'), // 'Does my subscription work for multiple locations?'
          {
            action: () => {
              setModalOpen('faq-locations-1');
            },
          },
        )}
        {menuItem(
          t('faq-locations-2'), // 'Can I play more than one stream at the same time in my business?'
          {
            action: () => {
              setModalOpen('faq-locations-2');
            },
          },
        )}
        {menuItem(
          t('faq-locations-3'), // 'Can more than one team member or administrator use the account?'
          {
            action: () => {
              setModalOpen('faq-locations-3');
            },
          },
        )}
        {menuItem(
          t('faq-locations-4'), // 'What happens when my free trial ends?'
          {
            action: () => {
              setModalOpen('faq-locations-4');
            },
          },
        )}
        {menuItem(
          t('faq-locations-5'), // 'What happens if I cancel my subscription during the trial?'
          {
            action: () => {
              setModalOpen('faq-locations-5');
            },
          },
        )}
        {menuItem(
          t('faq-locations-6'), // 'What happens if I cancel my subscription?'
          {
            action: () => {
              setModalOpen('faq-locations-6');
            },
          },
        )}
        {menuItem(
          t('faq-locations-7'), // 'What does the renewal process look like?'
          {
            action: () => {
              setModalOpen('faq-locations-7');
            },
          },
        )}
        {menuItem(
          t('faq-locations-8'), // 'How do I cancel or change billing information?'
          {
            action: () => {
              setModalOpen('faq-locations-8');
            },
          },
        )}
        {menuItem(
          t('faq-locations-9'), // 'Does my SINES subscription give me all the licenses I need to play background music in my business?'
          {
            action: () => {
              setModalOpen('faq-locations-9');
            },
          },
        )}
        {menuItem(
          t('faq-locations-10'), // 'Can I use SINES to play music on my website or in advertisements?'
          {
            action: () => {
              setModalOpen('faq-locations-10');
            },
          },
        )}
        {menuItem(
          t('faq-locations-11'), // 'Can I use SINES to play music in my gym or fitness center?'
          {
            action: () => {
              setModalOpen('faq-locations-11');
            },
          },
        )}
        {menuItem(
          t('faq-locations-12'), // 'Can I use SINES in a DJ set or other live music performance?'
          {
            action: () => {
              setModalOpen('faq-locations-12');
            },
          },
        )}
      </span>
    </>
  );
};

export default LocationsFaq;
