import React, { FC, useEffect } from 'react';
// * 3rd party libs
import { useForm, SubmitHandler } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Grid, Typography } from '@mui/material';

// * ASSETS

// * TYPES
import { UserBusiness, IBusinessInformationPost } from 'store/@types/b2b';
import { useTranslation } from 'react-i18next';
// * COMPONENTS
import Input from 'components/forms/Input';

// * LOCAL IMPORTS
import { schema, defaultValues } from './yupSchema';
import { useStyles } from './styles';

interface IProps {
  userBusiness: UserBusiness | null;
  updateBusinessInfo: (data: IBusinessInformationPost) => void;
}

const BusinessInformation: FC<IProps> = ({ userBusiness, updateBusinessInfo }) => {
  const classes = useStyles();
  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm<IBusinessInformationPost>({
    mode: 'onTouched',
    resolver: yupResolver(schema),
    reValidateMode: 'onChange',
    defaultValues,
    shouldUnregister: false,
  });

  const { t } = useTranslation();

  useEffect(() => {
    if (!userBusiness) return;
    const { businessUsers, id, ...formData } = userBusiness;
    reset(formData);
  }, [userBusiness]);

  const formSubmitHandler: SubmitHandler<IBusinessInformationPost> = payload => {
    updateBusinessInfo({ ...payload, phoneNumber: `${payload.phoneNumber}` });
  };
  return (
    <form id="business-information-form" onSubmit={handleSubmit(formSubmitHandler)} key="businessInformation">
      <Grid container spacing={2} justifyContent="right">
        <Grid item xs={12}>
          <Typography variant="section">{`${t('Business')} ${t('information')}`}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Input required name="name" placeholder="Company name" control={control} errors={errors} />
        </Grid>
        <Grid item xs={12} className={classes.saveChangesButton}>
          <Button variant="primary" type="submit" sx={{ width: '176px' }}>
            <Typography>{t('SaveChanges') /* Save Changes */}</Typography>
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};
export default BusinessInformation;
