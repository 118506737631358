import { Button, Dialog, Grid, Typography } from '@mui/material';
import { ReactComponent as SinesLogo } from 'assets/imgs/sinesLogo.svg';
import ConfirmActionModal from 'components/ConfirmActionModal';
import DeleteBody from 'components/DeleteBody';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const LockedAccount = ({ classes, handleDeleteUser, handleSignout }: any) => {
  const [deleteAccount, setDeleteAccount] = useState(false);
  const { t } = useTranslation();

  return (
    <>
      <Grid
        container
        spacing={2}
        direction="row"
        flexWrap="wrap"
        alignContent="flex-start"
        justifyContent="center"
        justifyItems="center"
        className={classes.dialogContainer}>
        <Grid item xs={12}>
          <SinesLogo />
        </Grid>
        <Grid item xs={12} sx={{ marginBottom: '20px' }}>
          <Typography className={classes.title}>
            {t('weareunabletosignin') /* We are unable to sign you in. */}
          </Typography>
        </Grid>
        <Grid item xs={12} className={classes.subtitleGrid}>
          <Typography className={classes.subTitle}>
            {
              t(
                'apologizeinconvenience',
              ) /* We apologize for the inconvenience, but there is an issue with your account. */
            }
          </Typography>
        </Grid>
        <Grid item xs={12} className={classes.subtitleGrid} style={{ marginBottom: '30px' }}>
          <Typography className={classes.subTitle}>
            {t('pleasecontactadmin') /* Please contact your administrator.  */}
            <br />
            {t('Thank you!') /* Thank you! */}
          </Typography>
        </Grid>

        <Grid item xs={12} sx={{ marginBottom: '52px' }}>
          <Button className={classes.buttonLogout} onClick={handleSignout}>
            {t('Signout') /* Sign out */}
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Typography
            onClick={() => {
              setDeleteAccount(true);
            }}
            className={classes.buttonDelete}>
            {t('deleteaccount') /* Delete account */}
          </Typography>
        </Grid>
      </Grid>
      {deleteAccount && (
        <Dialog
          fullWidth
          className={classes.dialogDeleteAccount}
          open={deleteAccount}
          onClose={() => setDeleteAccount(false)}>
          <ConfirmActionModal
            title={
              t('sorrytoseeyougo')
              // "We’re sorry to see you go"
            }
            body={<DeleteBody />}
            cancelAction={() => setDeleteAccount(false)}
            confirmText={t('Delete')}
            confirmAction={handleDeleteUser}
          />
        </Dialog>
      )}
    </>
  );
};

export default LockedAccount;
