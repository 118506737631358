import { ApiError } from 'utils/services/api';
import { UserProps } from '../user';

export enum B2BActions {
  SET_LOADING = '@@b2b/SET_LOADING',
  SET_ERROR = '@@b2b/SET_ERROR',
  SET_GET_BUSINESS_INFO = '@@b2b/SET_GET_BUSINESS_INFO',
  SET_UPDATE_BUSINESS_INFO = '@@b2b/SET_UPDATE_BUSINESS_INFO',
  SET_INSERT_USERS = '@@b2b/SET_INSERT_USERS',
  SET_UPDATE_USERS = '@@b2b/SET_UPDATE_USERS',
  SET_DETELE_B2B_USERS = '@@b2b/SET_DETELE_B2B_USERS',
}

export type B2BState = {
  readonly loading: boolean;
  readonly error: ApiError | null;
  readonly userBusiness: UserBusiness | null;
  readonly invitedUsers: unknown | null;
  readonly updateUserAction: unknown | null;
  readonly deleteUserAction: unknown | null;
};

export interface UserBusiness {
  id: string;
  name: string;
  address: string;
  zipCode: string;
  location: string;
  email: string;
  phoneNumber: string;
  businessUsers: BusinessUser[];
}

export interface BusinessUser {
  id: string;
  role: string;
  devices: number;
  user: UserProps;
}

export interface IBusinessInformationPost {
  name: string;
  address: string;
  zipCode: string;
  location: string;
  email: string;
  phoneNumber: string;
}

export interface UserInvitePayload {
  email: string;
  zones: number;
}

export interface UserInviteResponse {
  duplicateManagers: Array<string>;
  failedManagers: Array<string>;
  invitedManagers: Array<string>;
}
export interface IInviteManagersPost {
  email: string;
  zones: number;
}

export interface IManagersForm {
  managers: IInviteManagersPost[];
}

export interface IEditManagerPost {
  userId: string;
  zones: number;
}
