import { action } from 'typesafe-actions';
import { IDispatchType } from 'store/@types';
import { MoodsActions as Actions } from 'store/@types/moods';

export const setLoading = (): IDispatchType => action(Actions.SET_LOADING);
export const setLoadingSpaces = (): IDispatchType => action(Actions.SET_LOADING_SPACES);
export const setLoadingMoods = (): IDispatchType => action(Actions.SET_LOADING_MOODS);
export const setLoadingGuided = (): IDispatchType => action(Actions.SET_LOADING_GUIDED);
export const setLoadingSearch = (): IDispatchType => action(Actions.SET_SEARCH_LOADING);

export const setError = (payload: unknown | null): IDispatchType => action(Actions.SET_ERROR, payload);

export const setSpaces = (payload: unknown): IDispatchType => action(Actions.SET_SPACES, payload);

export const setMoods = (payload: unknown): IDispatchType => action(Actions.SET_MOODS, payload);

export const setGuided = (payload: unknown): IDispatchType => action(Actions.SET_GUIDED, payload);

export const setCategoryPlaylists = (payload: unknown): IDispatchType =>
  action(Actions.SET_CATEGORY_PLAYLISTS, payload);

export const setPLaylistDetails = (payload: unknown): IDispatchType => action(Actions.SET_PLAYLIST_DETAILS, payload);

export const setFavAlbums = (payload: unknown): IDispatchType => action(Actions.SET_FAVORITE_ALBUMS, payload);

export const setFavPlaylists = (payload: unknown): IDispatchType => action(Actions.SET_FAVORITE_PLAYLISTS, payload);

export const setFavSongs = (payload: unknown): IDispatchType => action(Actions.SET_FAVORITE_TRACKS, payload);

export const addFavTrack = (payload: unknown): IDispatchType => action(Actions.SET_NEW_FAVORITE_TRACK, payload);

export const addFavPlaylist = (payload: unknown): IDispatchType => action(Actions.SET_NEW_FAVORITE_PLAYLIST, payload);

export const setAllUserFavs = (payload: unknown): IDispatchType => action(Actions.SET_ALL_USER_FAVORITES, payload);

export const setSearch = (payload: unknown): IDispatchType => action(Actions.SET_SEARCH, payload);
