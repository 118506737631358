import { CustomReactTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: CustomReactTheme) => ({
  timeWrapperLeft: {
    display: 'flex',
    justifyContent: 'left',
    marginTop: '-20px',
  },
  timeWrapperRight: {
    display: 'flex',
    justifyContent: 'right',
    marginTop: '-20px',
  },
  contentRight: {
    display: 'flex',
    justifyContent: 'right',
  },
  contentLeft: {
    display: 'flex',
    justifyContent: 'left',
  },
  contentCenter: {
    display: 'flex',
    justifyContent: 'center',
  },
  playlistPlayerWrapper: {
    padding: '30px',
  },
  timerWrapper: {
    width: '100%',
  },
  sliderPlaylist: {
    width: '100%',
    color: 'white',
    '& .MuiSlider-rail': {
      height: 1,
    },
    '& .MuiSlider-track': {
      height: 2,
    },
    padding: 0,
    height: 0,
  },
  playerButton: {
    color: '#fff',
  },
  coverImgSmall: {
    borderRadius: '20px',
    boxShadow: '0 0 10px 0 rgba(31, 31, 31, 0.5)',
    width: '166px',
    height: '166px',
    alignSelf: 'center',
  },
  coverImgSmallDefault: {
    width: '66px',
    background: '#292929',
    height: '166px',
    boxShadow: '0 0 10px 0 rgb(31 31 31 / 50%)',
    borderRadius: '10px',
    padding: '0px 50px',
    alignSelf: 'center',
  },
  textTitle: {
    textOverflow: 'clip',
    whiteSpace: 'nowrap',
    overflowX: 'auto',
    position: 'relative',
    margin: '0 auto',
  },

  //* TABLET - landScape
  [theme.breakpoints.down('lg')]: {},
  //* TABLET - portrait
  [theme.breakpoints.down('md')]: {},
  //* MOBILE
  [theme.breakpoints.down('sm')]: {
    textTitle: {
      width: '100%',
    },
  },
}));

export { useStyles };
