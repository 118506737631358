import { ApiError } from 'utils/services/api';
import { IBusinessInformationPost } from '../b2b';
import { Playlist, Track } from '../moods';

export enum UserActions {
  SET_LOADING = '@@user/SET_LOADING',
  SET_ERROR = '@@user/SET_ERROR',
  SET_LOGIN = '@@user/SET_LOGIN',
  SET_REFRESH = '@@user/SET_REFRESH',
  SET_USER_HISTORY = '@@user/SET_USER_HISTORY',
  SET_USER_LAST_HISTORY = '@@user/SET_USER_LAST_HISTORY',
}

export type UserState = {
  readonly loading: boolean;
  readonly error: ApiError | null;
  readonly userHistory: UserHistoryResponse | null;
  readonly userLastHistory: UserHistoryResponse | null;
  readonly userInfo: { user: UserProps } | null;
};

export interface LoginPost {
  username: string;
  password: string;
}

export interface GoogleLoginPost {
  credential: string;
  clientId: string;
  select_by: string;
}

export interface GoogleSignupPost {
  credential: string;
  clientId: string;
  select_by: string;
  lmData?: string;
}

export interface StripeCheckout {
  session: {
    url: string;
  };
}

export interface LoginResponse {
  access_token: string;
  refresh_token: string;
}

export interface ErrorResponse {
  statusCode: number;
  error: string;
  data?: ErrorData | ErrorDataAxios;
  message: string;
}
export interface ErrorData {
  errorMessage: string;
}
export interface ErrorDataAxios {
  error: string;
  error_description: string;
}
export interface TokenPayload {
  realm_access: { roles: string[] };
  resource_access: { [realmId: string]: { roles: string[] } };
  subscription: ISubscription;
  email_verified: boolean;
  name: string;
  given_name: string;
  preferred_username: string;
  email: string;
  sub: string;
  admin: boolean;
}

export interface SignUpPost {
  name: string;
  username: string;
  email: string;
  password: string;
  password2?: string;
  terms?: boolean;
  policy?: boolean;
  priceId?: string;
  devices?: string | number;
  lmData?: any;
}

export interface UserProps {
  id: string;
  name: string;
  email: string;
  userPicture: string;
  emailVerified: boolean;
  notificationsEnabled: boolean;
  subscription: ISubscription;
  businessUser: IBusinessUser;
}

export interface RecoverPwPost {
  email: string;
}

export interface SendNewPwPost {
  newPassword: string;
}

export interface UserHistoryGetProps {
  limit?: number;
  page: number;
  sort?: 'count' | 'updatedAt';
  requiredPlaylist?: boolean;
}

export interface UserHistory {
  id: string;
  count: number;
  track: Track;
  user: UserProps;
  playlist?: Playlist;
}

export interface UserHistoryResponse {
  items: UserHistory[];
}

export interface ISubscription {
  id: string;
  active: boolean;
  priceId: string;
  type: string;

  createdAt: string;
  updatedAt: string;
  trialEnd?: string;
  trialStart?: string;

  eventId?: string;
  appUserId?: string;
  customer?: string;
  description?: string;
  cancelAtPeriodEnd?: string;
  currency?: string;
  priceCurrency?: string;
  productId?: string;
  subscriptionId?: string;
  latestInvoiceId?: string;
  recurringInterval?: string;
  recurringIntervalCount?: string;
  recurringUsageType?: string;
  quantity?: string;
  devices: number;
  liveMode?: string;
  apiEnv?: string;
  status?: string;
  startDate?: string;
  endedAt?: string;
  currentPeriodStart?: string;
  currentPeriodEnd?: string;
  cancelAt?: string;
  canceledAt?: string;
}

export interface IBusinessUser {
  devices: number;
  id: string;
  role: string;
  business: IBusinessInformationPost;
}
