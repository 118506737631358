import { Dispatch, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { StoreState } from 'store/@types';
import { refreshToken, updatePlan } from 'store/user/thunks';
import { getBusinessInfo, updateBusinessInfo, inviteUsers } from 'store/b2b/thunks';

import DefaultComponent from './CustomerPanel';

const mapStateToProps = ({ b2b, user }: StoreState) => ({ b2b, user });

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ refreshToken, getBusinessInfo, updateBusinessInfo, inviteUsers, updatePlan }, dispatch);

export type ReduxProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(DefaultComponent);
