/* eslint-disable indent */
import { Dispatch } from 'redux';
import { IBusinessInformationPost, UserBusiness, UserInvitePayload, UserInviteResponse } from 'store/@types/b2b';

import {
  apiGetBusinessInfo,
  apiUpdateBusinessInfo,
  apiInviteUsers,
  apiUpdateUserZones,
  apiDeleteUserFromBusiness,
} from 'api/b2b';

import {
  setLoading,
  setError,
  setBusinessInfo,
  setUpdateBusinessInfo,
  setInviteUsers,
  setUpdateUsers,
  setDeleteUsers,
} from './actions';

export const getBusinessInfo =
  () =>
  async (dispatch: Dispatch): Promise<void> => {
    try {
      dispatch(setLoading());
      // api request
      const { data } = await apiGetBusinessInfo();
      dispatch(setBusinessInfo(data));
    } catch (error) {
      dispatch(setError(error));
    }
  };

export const updateBusinessInfo =
  (payload: IBusinessInformationPost, success: (success: UserBusiness) => void) =>
  async (dispatch: Dispatch): Promise<void> => {
    try {
      dispatch(setLoading());
      // api request
      const { data } = await apiUpdateBusinessInfo(payload);
      success(data);
      dispatch(setUpdateBusinessInfo(data));
    } catch (error) {
      dispatch(setError(error));
    }
  };

export const inviteUsers =
  (payload: UserInvitePayload[], success: (successResponse?: UserInviteResponse) => void) =>
  async (dispatch: Dispatch): Promise<void> => {
    try {
      dispatch(setLoading());
      // api request
      const { data } = await apiInviteUsers(payload);
      success(data);
      dispatch(setInviteUsers(data));
    } catch (error) {
      dispatch(setError(error));
    }
  };

export const updateUserInfo =
  (id: string, devices: number) =>
  async (dispatch: Dispatch): Promise<void> => {
    try {
      dispatch(setLoading());
      // api request
      const { data } = await apiUpdateUserZones(id, devices);
      dispatch(setUpdateUsers(data));
    } catch (error) {
      dispatch(setError(error));
    }
  };

export const deleteB2BUser =
  (payload: string) =>
  async (dispatch: Dispatch): Promise<void> => {
    try {
      dispatch(setLoading());
      // api request
      const { data } = await apiDeleteUserFromBusiness(payload);
      dispatch(setDeleteUsers(data));
    } catch (error) {
      dispatch(setError(error));
    }
  };
