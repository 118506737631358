import React, { FC } from 'react';

import { ScrollbarProps, Scrollbars as OGScrollbars } from 'react-custom-scrollbars-2';
import useResponsive from 'hooks/useResponsive';

const CustomScrollbars = OGScrollbars as unknown as React.FC<ScrollbarProps>;

interface ScrollProps extends ScrollbarProps {
  style?: { [css: string]: string };
  customClasses?: string;
  horizontal?: boolean;
}

const Scrollbars: FC<ScrollProps> = ({ horizontal, width, height, style, customClasses, ...componentProps }) => {
  const { isMobile } = useResponsive();
  const heightVal = (Boolean(height) && height) || (isMobile ? '100%' : 680);

  if (horizontal) {
    return (
      <CustomScrollbars
        autoHide
        style={{
          width: width || '100%',
          height: heightVal,
          overflowY: 'hidden',
          ...style,
        }}
        className={customClasses}
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        renderTrackVertical={(props: any) => <div {...props} style={{ display: 'none' }} className="track-vertical" />}
        {...componentProps}
      />
    );
  }

  return (
    <CustomScrollbars
      autoHide
      style={{
        width: width || '100%',
        height: heightVal,
        overflowX: 'hidden',
        ...style,
      }}
      className={customClasses}
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      renderTrackHorizontal={(props: any) => (
        <div {...props} style={{ display: 'none' }} className="track-horizontal" />
      )}
      {...componentProps}
    />
  );
};

Scrollbars.defaultProps = {
  customClasses: '',
  style: {},
  horizontal: false,
};

export default Scrollbars;
