import Cookies from 'js-cookie';

export const getCookie = (key: string): string | undefined => Cookies.get(key);

export const setCookie = (key: string, value: string): string | undefined => {
  const now = new Date();
  const currentYear = now.getFullYear();
  const currentMonth = now.getMonth();
  const currentDate = now.getDate();
  const midnight = new Date(currentYear, currentMonth, currentDate + 1, 0, 0, 0);
  return Cookies.set(key, value, {
    expires: midnight,
  });
};

export const removeCookie = (key: string): void => Cookies.remove(key);

export const getLSField = (key: string): string | null => localStorage.getItem(key);

export const setLSField = (key: string, value: string): void => localStorage.setItem(key, value);

export const removeLSField = (key: string): void => localStorage.removeItem(key);
