import { CustomReactTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: CustomReactTheme) => ({
  windowMenu: {
    width: '240px',
    border: '1px solid #4E4E4E',
    height: '698px',
    position: 'fixed',
    padding: '90px 0',
    maxWidth: '240px',
    minWidth: '240px',
    boxShadow: '0 0 10px 0 rgb(0 0 0 / 50%)',
    textAlign: 'left',
    zIndex: theme.zIndex.drawer,
    borderRadius: '20px',
    backgroundColor: '#303030',
  },
  userMenu: {
    zIndex: theme.zIndex.drawer,
    padding: '10px 0',
    backgroundColor: '#303030',
    border: '1px solid #4E4E4E',
    borderRadius: '20px',
    boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.5)',
    width: '320px',
    minWidth: '320px',
    maxWidth: '320px',
    height: '544px',
    textAlign: 'right',
  },
  menuItem: {
    color: 'white',
    display: 'flex',
    textAlign: 'right',
    flexDirection: 'column',
    flexWrap: 'wrap',
    height: '52px',
    fontSize: '16px',
    justifyContent: 'center',
    alignContent: 'flex-end',
  },
  activeItem: {
    backgroundColor: '#282828',
  },
  windowItem: {
    textAlign: 'left',
    alignContent: 'flex-start',
  },
  qrCodeWrapper: {
    paddingTop: '40px',
    paddingLeft: '20px',
    display: 'flex',
    alignItems: 'end',
  },
  appStore: {
    width: '90px',
    height: '30px',
    marginLeft: '10px',
    cursor: 'pointer',
  },
  poweredWrapper: {
    paddingTop: '8px',
    paddingLeft: '20px',
  },
  poweredTypo: {
    color: '#FFFFFF',
    fontFamily: 'Montserrat',
    fontSize: '10px',
    lineHeight: '12px',
    textAlign: 'left',
  },
  nearTypo: {
    color: '#B66481',
    fontFamily: 'Montserrat',
    fontSize: '14px',
    lineHeight: '18px',
    textAlign: 'left',
  },
  poweredWrapperSmall: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'right',
    paddingRight: '20px',
    paddingTop: '5px',
  },
  qrCodeWrapperSmall: {
    display: 'flex',
    flexDirection: 'row-reverse',
    alignItems: 'end',
    paddingRight: '20px',
    paddingTop: '10px',
  },
  //* TABLET - landScape
  [theme.breakpoints.down('lg')]: {},
  //* TABLET - portrait
  [theme.breakpoints.down('md')]: {},
  //* MOBILE
  [theme.breakpoints.down('sm')]: {},
}));

export { useStyles };
