/* eslint-disable no-restricted-globals */
import Scrollbars from 'components/Scrollbars';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { usePwaInstallPrompt } from 'hooks/usePwaInstallPrompt';
import {
  AppBar,
  Box,
  Button,
  Dialog,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  Toolbar,
  Typography,
} from '@mui/material';
import Offline from 'pages/Player/Offline';
import { ReactComponent as ProfileNoPhoto } from 'assets/imgs/profile_nophoto.svg';
import { ReactComponent as SinesLogoMix } from 'assets/imgs/sinesLogoMix.svg';
import { ReactComponent as TabLibrary } from 'assets/imgs/tab_library.svg';
import { ReactComponent as TabMoods } from 'assets/imgs/tab_moods.svg';
import { ReactComponent as TabSearch } from 'assets/imgs/tab_search.svg';
import { ReactComponent as TabWelcome } from 'assets/imgs/tab_welcome.svg';
import useMusicPlayer from 'hooks/useMusicPlayer';
import useResponsive from 'hooks/useResponsive';

import BottomPlayer from 'components/PlayerComponents/BottomPlayer';

import UserMenu from 'components/UserMenu';
import UserMenuPage from 'components/UserMenuPage';
import { useLanguage } from 'context/LanguageProvider';
import { useTranslation } from 'react-i18next';
import useAuth, { Roles } from 'hooks/useAuth';
import Playlist from 'pages/Player/AlbumView';
import MoodResults from 'pages/Player/MoodResults';
import { ReduxProps } from '.';
import { useStyles } from './styles';

const items = [
  {
    id: '/home',
    title: 'Home',
    icon: <TabWelcome />,
  },
  {
    id: '/moods',
    title: 'Music + Sounds',
    icon: <TabMoods />,
  },
  {
    id: '/search',
    title: 'Search',
    icon: <TabSearch />,
  },
  {
    id: '/library',
    title: 'Library',
    icon: <TabLibrary />,
  },
];

const Page: FC<ReduxProps> = ({ children, payOrTrial, moods, getAllFavorites, user: reduxUser }) => {
  const classes = useStyles();

  const { favorites } = moods;
  const { loading } = reduxUser;
  const [openModal, setModalOpen] = useState<string | null>(null);
  const installabeBox = React.useRef<HTMLDivElement>(null);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const { language } = useLanguage();

  const { i18n } = useTranslation();

  const {
    isPlaylist,
    setIsPlaylist,
    isMoodsResult,
    setIsMoodsResult,
    isFullScreen,
    currentSong,
    albumView,

    setIsOffline,
  } = useMusicPlayer();
  const { theme, isLarge, isTablet, isMobile, setSearch } = useResponsive();
  const { pathname } = useLocation();
  const goToPage = useNavigate();
  const { user } = useAuth();
  const { isInstallable, deferredPrompt } = usePwaInstallPrompt();

  const { t } = useTranslation();

  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const isInPWA = window.matchMedia('(display-mode: standalone)').matches;

  useEffect(() => {
    const goOnline = () => {
      setIsOnline(true);
      setIsOffline(false);
      window.location.reload();
    };
    const goOffline = () => {
      setIsOnline(false);
      setIsOffline(true);
    };

    window.addEventListener('online', goOnline);
    window.addEventListener('offline', goOffline);

    return () => {
      window.removeEventListener('online', goOnline);
      window.removeEventListener('offline', goOffline);
    };
  }, []);

  const navigate = (url: string) => {
    setIsMoodsResult(null);
    setIsPlaylist(null);
    setSearch('');
    goToPage(url);
  };

  const handleCloseModal = () => {
    setModalOpen(null);
    setAnchorEl(null);
  };

  useEffect(() => {
    if (!favorites) {
      getAllFavorites();
    }
  }, [favorites]);

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    if (isMobile) {
      setModalOpen('menu');
    } else {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const isBusiness = useMemo(() => {
    if (!user) return false;
    if (isMobile) return false;
    if (
      user?.businessUser?.role === Roles.APP_ADMIN &&
      (user?.subscription?.type === 'locations' || user?.subscription?.type === 'teams')
    )
      return true;
    return false;
  }, [user, isMobile, isTablet, isLarge, isOnline]);

  const subscribeButton = useMemo(() => {
    if (!user) return false;
    if (isMobile) return false;
    if (isTablet && isBusiness) return false;
    if (
      user?.subscription?.status !== 'active' &&
      (user?.businessUser?.role === Roles.APP_ADMIN || user?.subscription.type === 'individual')
    )
      return true;
    return false;
  }, [user, isMobile, isTablet, isBusiness, isOnline]);

  const handleInstallClick = () => {
    (deferredPrompt as any).prompt();
  };

  const navbar = useMemo(
    () => (
      <AppBar
        component="header"
        elevation={0}
        position="fixed"
        sx={{
          backgroundColor: isTablet ? theme.navbar.bg : theme.navbar.bgDarker,
          zIndex: () => theme.zIndex.drawer - 1,
          maxHeight: '65px',
        }}>
        <Toolbar disableGutters variant="regular">
          <Typography
            variant="h6"
            component="div"
            sx={{ flexGrow: isMobile ? 1 : ((subscribeButton || isBusiness) && 1) || 1 }}>
            <SinesLogoMix
              onClick={() => {
                navigate('/');
              }}
              className={classes.logo}
            />
          </Typography>
          {!isMobile && subscribeButton && (
            <Button
              variant="primary"
              onClick={() => {
                payOrTrial(false, data => {
                  if (data?.session?.url) window.location.href = data.session.url;
                });
              }}
              sx={{ width: '170px', marginRight: '50px' }}>
              <Typography className={classes.businessText}>{t('Subscribe') /* Subscribe */}</Typography>
            </Button>
          )}
          {isMobile && isInstallable && !isInPWA && (
            <Box className={classes.businessDiv} ref={installabeBox}>
              <Box className={classes.businessWrapper}>
                <Button
                  className={classes.businessInsideWrapper}
                  onClick={e => {
                    installabeBox.current?.setAttribute('style', 'display: none');
                    handleInstallClick();
                  }}>
                  <Typography className={classes.businessText}>{t('INSTALLAPP') /* INSTALL APP */}</Typography>
                </Button>
              </Box>
            </Box>
          )}
          {!isMobile && isBusiness && (
            <Box className={classes.businessDiv}>
              <Box className={classes.businessWrapper}>
                <Button
                  className={classes.businessInsideWrapper}
                  onClick={() => {
                    navigate('/customer');
                  }}>
                  <Typography className={classes.businessText}>
                    {user?.businessUser?.business?.name || t('MyBusiness')}
                  </Typography>
                </Button>
              </Box>
            </Box>
          )}

          {!isMobile && (
            <Typography variant="section" component="div" marginRight="10px" marginLeft="10px">
              {t('Hi')} {user?.name !== 'N/A' ? user?.name?.split(' ')[0] : ''}!
            </Typography>
          )}
          <div>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
              color="inherit">
              <ProfileNoPhoto />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorEl)}
              className={classes.menuRoot}
              onClose={handleClose}>
              <UserMenu window={false} setModalOpen={setModalOpen} handleClose={handleClose} />
            </Menu>
          </div>
        </Toolbar>
      </AppBar>
    ),
    [
      isLarge,
      isTablet,
      isMobile,
      anchorEl,
      user,
      isBusiness,
      subscribeButton,
      isInstallable,
      pathname,
      isOnline,
      language,
    ],
  );
  const homeBar = useMemo(() => {
    if (isTablet) return null;
    return (
      <Box sx={{ overflow: 'hidden', padding: '15px 10px' }}>
        <List>
          {items.map(item => (
            <Typography key={`drawer-${item.id}`} variant="section">
              <ListItem disablePadding className={pathname.includes(item.id) ? classes.activeItem : classes.item}>
                <ListItemButton
                  onClick={() => {
                    navigate(item.id);
                  }}>
                  <ListItemIcon sx={{ paddingLeft: '7px' }}>{item.icon}</ListItemIcon>
                  <ListItemText primary={t(item.title)} />
                </ListItemButton>
              </ListItem>
            </Typography>
          ))}
        </List>
      </Box>
    );
  }, [isTablet, language, pathname]);

  const drawer = useMemo(
    () =>
      isTablet ? null : (
        <Drawer
          variant="permanent"
          elevation={0}
          PaperProps={{
            square: false,
            style: {
              backgroundColor: theme.navbar.bg,
              width: theme.drawerWidth,
              top: '65px',
              borderRadius: '20px 20px 0 0',
            },
          }}
          sx={{
            marginRight: 1.5,
            width: theme.drawerWidth,
          }}>
          {homeBar}
        </Drawer>
      ),
    [isTablet, pathname, isOnline, language],
  );

  const navBottomBar = useMemo(() => {
    if (isLarge && isMobile && isFullScreen) return null;
    if (!isLarge) return null;

    if (isTablet)
      return (
        <div className={classes.navBottomBarRoot}>
          {items.map(item => (
            <Typography key={`drawer-${item.id}`} variant="section">
              <ListItem disablePadding className={pathname.includes(item.id) ? classes.activeItem : classes.item}>
                <ListItemButton
                  className={classes.navBottomBarItem}
                  onClick={() => {
                    navigate(item.id);
                  }}>
                  <ListItemIcon
                    sx={{
                      padding: '0 3px',
                      flex: 'none',
                      msFlex: 'none',
                      WebkitFlex: 'none',
                      justifyContent: isMobile ? 'center' : '',
                    }}>
                    {item.icon}
                  </ListItemIcon>
                  {!isMobile && (
                    <ListItemText
                      sx={{
                        flex: 'none',
                        msFlex: 'none',
                        WebkitFlex: 'none',
                      }}
                      primary={t(item.title)}
                    />
                  )}
                </ListItemButton>
              </ListItem>
            </Typography>
          ))}
        </div>
      );
    return null;
  }, [isLarge, isMobile, pathname, isTablet, isFullScreen, isOnline, language]);

  const playlistOnTopComponent = isPlaylist && <Playlist />;
  const moodsResultOnTopComponent = isMoodsResult && <MoodResults />;

  if (!isOnline && isMobile && isInPWA)
    return (
      <Box
        sx={{
          display: 'flex',
          position: 'relative',
          height: 'calc(100vh)',
          backgroundColor: theme.navbar.bgDarker,
        }}>
        <Box
          component="main"
          className={classes.pageMainOffline}
          sx={{ backgroundColor: theme.navbar.bg, zIndex: isMobile || isTablet ? 0 : theme.zIndex.drawer }}>
          <Scrollbars height={`calc(100vh - ${currentSong && !isPlaylist ? theme.bottomPlayerBar.height : '0px'})`}>
            <div className={classes.pagesContentOffline}>{playlistOnTopComponent || <Offline />}</div>
          </Scrollbars>
        </Box>
        <BottomPlayer isOffline />
      </Box>
    );

  return (
    <>
      <Dialog
        fullWidth
        fullScreen={isTablet}
        maxWidth="md"
        open={Boolean(openModal)}
        className={classes.dialog}
        onClose={handleCloseModal}>
        <UserMenu window setModalOpen={setModalOpen} activeItem={openModal as string} />
        <UserMenuPage setModalOpen={setModalOpen} activeItem={openModal as string} />
      </Dialog>
      <Box
        sx={{
          display: 'flex',
          position: 'relative',
          height: 'calc(100vh)',
          backgroundColor: theme.navbar.bgDarker,
        }}
        className={classes.outerBox}>
        {drawer}
        {navbar}
        <Box
          component="main"
          className={classes.pageMain}
          sx={{ backgroundColor: theme.navbar.bg, zIndex: isMobile || isTablet ? 0 : theme.zIndex.drawer }}>
          <Scrollbars
            height={`calc(100vh - ${
              (currentSong && !isPlaylist) || (!isMobile && currentSong) ? theme.bottomPlayerBar.height : '0px'
            } - ${isMobile && !isPlaylist ? theme.navBottomBar.height : '0px'})`}>
            <div className={classes.pagesContent}>
              {playlistOnTopComponent || moodsResultOnTopComponent || children}
            </div>
          </Scrollbars>
        </Box>
        <BottomPlayer />
        {navBottomBar}
      </Box>
    </>
  );
};

Page.defaultProps = {};

export default Page;
