import { CustomReactTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: CustomReactTheme) => ({
  mainGridContainer: {
    padding: '60px 40px',
    alignContent: 'flex-start',
  },
  closeButton: {
    position: 'absolute',
    top: '20px',
    right: '20px',
  },
  emailTypoWrapper: {
    borderBottom: '1px solid #353535',
    height: '30px',
    marginBottom: '28px',
    marginTop: '40px',
  },
  zonesTypoWrapper: {
    borderBottom: '1px solid #353535',
    height: '30px',
    marginBottom: '28px',
    marginTop: '40px',
  },
  fieldArrayWrapper: {
    overflow: 'hidden',
  },
  addMoreButton: {
    display: 'flex',
    justifyContent: 'end',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
    paddingBottom: '75px',
  },
  inviteItemContainer: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    alignItems: 'flex-start',
  },
  inviteManagerButton: {
    width: '176px',
    height: '44px',
    position: 'absolute',
    bottom: '40px',
    right: '40px',
  },
  addMoreButtonText: {
    color: '#F4F4F4',
    fontFamily: 'Montserrat',
    fontSize: '14px',
    lineHeight: '17px',
    textAlign: 'center',
  },
  //* TABLET - landScape
  [theme.breakpoints.down('lg')]: {},
  //* TABLET - portrait
  [theme.breakpoints.down('md')]: {},
  //* MOBILE
  [theme.breakpoints.down('sm')]: {},
}));

export { useStyles };
