/* eslint-disable @typescript-eslint/ban-ts-comment */
import { FC, useEffect } from 'react';
// * 3rd party libs

import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

// * ASSETS

import { ReactComponent as SinesLogoStack } from 'assets/imgs/sines_logo_stack.svg';

import { LoginResponse } from 'store/@types/user';
import { setError } from 'store/user/actions';
import useAuth from 'hooks/useAuth';
// * LOCAL IMPORTS
import { CircularProgress, Grid, Typography } from '@mui/material';
import { useStyles } from './styles';

import { ReduxProps } from '.';

// * TYPES

const AppleAuth: FC<ReduxProps> = ({ user }) => {
  const { loading } = user;
  const classes = useStyles();

  const { setAuth, setTokenData } = useAuth();
  const [searchParams] = useSearchParams();

  const accessToken = searchParams.get('access_token');
  const refreshToken = searchParams.get('refresh_token');

  const isError = searchParams.get('error') === 'true';
  const navigate = useNavigate();

  const login = async () => {
    try {
      setTokenData({ access_token: accessToken, refresh_token: refreshToken } as LoginResponse);
      setAuth(accessToken);

      navigate('/home');
    } catch (err: any) {
      setError('Token cannot be validated');
    }
  };

  const handleRedirect = () => {
    navigate('/home');
  };

  useEffect(() => {
    if (!isError) login();
  }, []);

  return (
    <>
      <Grid item xs={12} className={classes.centeredContent}>
        <SinesLogoStack />
      </Grid>

      <Grid item xs={12} className={classes.centeredContent}>
        {isError ? (
          <>
            <Typography variant="h6">There was an error processing your request. Please try again.</Typography>
            <Typography variant="loginSection" component="p">
              If the issue persists, please contact support.
            </Typography>
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid,jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
            <a href="" style={{ color: '#B66481' }} onClick={() => navigate('/login')}>
              Back to login
            </a>
          </>
        ) : (
          <>
            <Grid item xs={12} className={classes.centeredContent}>
              <CircularProgress />
            </Grid>
            <Typography variant="h6">
              You will be logged in. If you're not redirected automatically, please refresh the page or sign-in again.
            </Typography>
            <Typography variant="loginSection" component="p">
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid,jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
              <a href="" style={{ color: '#B66481' }} onClick={() => handleRedirect}>
                Click here if you are not redirected automatically.
              </a>
            </Typography>
          </>
        )}
      </Grid>
    </>
  );
};

export default AppleAuth;
