import { CustomReactTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: CustomReactTheme) => ({
  root: {
    paddingBottom: '80px',
  },
  mobileHeaderBox: {
    zIndex: '1',
    position: 'relative',

    height: 'calc(100vh)',
  },
  mobileHeaderBox1: {
    position: 'absolute',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    filter: 'blur(6px)',
    width: '100%',
    height: '70%',
  },

  mobileFade: {
    width: '100%',
    position: 'absolute',
    boxShadow: 'inset 0 0 0 500px rgb(18 18 18 / 30%)',
    backgroundImage: 'linear-gradient(180deg, rgba(24, 24, 24, 0) 0%, #181818 60%)',
    height: '100%',
  },

  headerBox: {
    zIndex: '1',
    position: 'relative',
    height: '360px',
  },

  headerBox1: {
    position: 'absolute',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    boxShadow: 'inset 0 0 0 500px rgb(18 18 18 / 70%)',
    width: '100%',
    filter: 'blur(6px)',
    height: '100%',
    flexDirection: 'column',
    display: 'flex',
  },
  headerBox2: {
    position: 'absolute',
    height: '360px',
  },

  songsContainer: {
    background: '#181818',
    position: 'relative',
    zIndex: '3',
  },
  coverImgHeader: {
    borderRadius: '20px',
    boxShadow: '0 0 10px 0 rgba(31, 31, 31, 0.5)',
    width: '207px',
    height: '207px',
    marginLeft: '88px',
    marginTop: '17px',
  },
  coverImgHeaderDefault: {
    width: '60px',
    height: '60px',
    background: '#292929',
    boxShadow: '0 0 10px 0 rgb(31 31 31 / 50%)',
    borderRadius: '10px',
    padding: '74px',
  },
  previousPageWrapper: {
    paddingLeft: '40px',
    paddingTop: '13px',
    display: 'flex',
    flexDirection: 'row',
    cursor: 'pointer',
  },
  previousPageText: {
    color: '#F4F4F4',
    fontFamily: 'Montserrat',
    fontSize: '17px',
    lineHeight: '22px',
    textAlign: 'left',
  },
  musicDetailsWrapper: {
    display: 'flex',
    flexDirection: 'row',
  },
  musicTextWrapper: {
    paddingLeft: '44px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    justifyContent: 'end',
  },

  fade: {
    backgroundImage: 'linear-gradient(180deg, rgba(24, 24, 24, 0) 0%, #181818 100%)',
    height: '70px',
  },
  playlistHeader: {
    color: '#F4F4F4',
    fontFamily: 'Montserrat',
    fontSize: '18px',
    lineHeight: '22px',
  },
  listOfSongs: {
    padding: '20px 44px ',
  },

  cardWrapperTitle: {
    color: '#F4F4F4',
    fontFamily: 'Montserrat',
    fontSize: '18px',
    lineHeight: '22px',
    textAlign: 'left',
    marginLeft: '44px',
  },
  artistProtfile: {
    color: '#B3B3B3',
    fontFamily: 'Montserrat',
    fontSize: '16px',
    lineHeight: '19px',
    textAlign: 'right',
    marginRight: '10px',
    alignSelf: 'center',
  },

  cardOptions: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingTop: '41px',
  },
  artistProfile: {
    display: 'flex',
    flexDirection: 'row',
    cursor: 'pointer',
    marginRight: '44px',
  },
  HorizontalListWrapper: {
    padding: '16px 44px 35px 44px',
  },
  coverImg: {
    borderRadius: '10px',
    boxShadow: '0 0 10px 0 rgba(31, 31, 31, 0.5)',
    width: '140px',
    height: '140px',
    bottom: '20px',
    right: '24px',
  },
  heartDesktop: {
    display: 'inline-flex',
    minWidth: '0',
    minHeight: '0',
    padding: ' 0 0 0 0',
    '&:hover': {
      cursor: 'pointer',
    },
    paddingBottom: '4px',
  },
  trackNameText: {
    fontSize: '22px',
    paddingBottom: '8px',
  },
  artistNameText: {
    fontSize: '22px',
    paddingBottom: '17px',
  },
  backArrow: {
    width: '40px',
    height: '40px',
  },
  //* TABLET - landScape
  [theme.breakpoints.down('lg')]: {},
  //* TABLET - portrait
  [theme.breakpoints.down('md')]: {
    root: {
      paddingBottom: '140px',
    },
  },
  //* MOBILE
  [theme.breakpoints.down('sm')]: {
    root: {
      paddingBottom: '140px',
    },
    coverImgHeader: {
      marginLeft: '0px',
      marginTop: '34px',
    },
    musicTextWrapper: {
      marginTop: '20px',
      paddingLeft: '0px',
      alignItems: 'center',
    },
    previousPageWrapper: {
      position: 'absolute',
      top: 0,
    },
    musicDetailsWrapper: {
      flexDirection: 'column',
      alignItems: 'center',
    },
    artistNameText: {
      paddingBottom: '6px',
    },
    headerBox: {
      height: '360px',
    },
    headerBox1: {
      height: '250px',
    },
    headerBox2: {
      height: '360px',
    },
    listOfSongs: {
      padding: '0px 20px 20px 20px',
      marginBottom: '148px',
    },
  },
}));

export { useStyles };
