/* eslint-disable no-console */
import React, { FC } from 'react';
// * 3rd party libs

import { Typography, Skeleton, Grid } from '@mui/material';

// * ASSETS
import FallbackImage from 'assets/imgs/missing_image.svg';
// * COMPONENTS
import useMusicPlayer from 'hooks/useMusicPlayer';

// * TYPES
import { Category, Playlist } from 'store/@types/moods';

// * LOCAL IMPORTS
import { useStyles } from './styles';

export interface MoodCardProps {
  item?: Category | Playlist;
  loading: boolean;
}

const isPlaylist = (item: Category | Playlist): Playlist | null => {
  if ((item as Playlist).tracks) return item as Playlist;
  return null;
};

const SearchPlaylistCard: FC<MoodCardProps> = ({ item, loading }) => {
  const classes = useStyles();
  const { setIsMoodsResult, setIsPlaylist, setAlbumView } = useMusicPlayer();

  const clickHandler = (): void => {
    if (!item) return;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const { additionalAction, tracks, playlistTracks, ...newItem } = item as any;

    additionalAction && additionalAction(newItem);

    if (isPlaylist(item)) {
      setAlbumView(item as Playlist);
      setIsPlaylist('open');
    } else {
      const { playlists } = item as Category;
      if (!playlists) return;

      if (playlists.length > 1) {
        setIsMoodsResult(item as Category);
      } else {
        setAlbumView(playlists[0]);
        setIsPlaylist('open');
      }
    }
  };

  if (loading)
    return (
      <>
        {[1, 2, 3, 4, 5, 6].map(e => (
          <div key={e} className={classes.square}>
            <Skeleton className={classes.skeletonBars} animation="wave" variant="text" />
          </div>
        ))}
      </>
    );

  if (!item) return null;

  return (
    <Grid item xs={12} className={classes.root} key={item.id}>
      <div className={classes.coverImageWrapper}>
        {item?.image ? (
          <object className={classes.coverImgMobile} data={item.image} type="image/png">
            <img className={classes.coverImgMobileDefault} src={FallbackImage} alt="coverImage" />
          </object>
        ) : (
          <object className={classes.coverImgMobile}>
            <img className={classes.coverImgMobileDefault} src={FallbackImage} alt="coverImage" />
          </object>
        )}
      </div>
      <Grid container onClick={clickHandler}>
        <Grid item xs={12} className={classes.clickableMusicDisplay}>
          <div className={classes.musicTextPlayer}>
            <Typography variant="listTrack" component="p">
              {item.name}
            </Typography>
            <Typography variant="listAuthor" component="p">
              {(item as Playlist).artist?.name}
            </Typography>
          </div>
        </Grid>
      </Grid>
    </Grid>
  );
};

SearchPlaylistCard.defaultProps = { item: undefined };

export default SearchPlaylistCard;
