export interface IAuth {
  user: unknown;
  roles?: string[];
}

export interface IPlayerButtonStates {
  airplay: boolean;
  shuffle: boolean;
  previous: boolean;
  playPause: boolean;
  next: boolean;
  repeat: boolean;
  heart: boolean;
}

export const formatTime = (seconds: number) => {
  const h = Math.floor(seconds / 3600);
  const m = Math.floor((seconds % 3600) / 60);
  const s = seconds % 60;

  // eslint-disable-next-line no-nested-ternary
  return [h, m > 9 ? m : h ? `0${m}` : m || '0', s > 9 ? s : `0${s}`]
    .filter(a => a)
    .join(':')
    .replace('NaN', '0');
};
