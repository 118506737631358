/* eslint-disable react/require-default-props */
import React, { FC } from 'react';
// * 3rd party libs
import { Typography, CardActionArea, Skeleton } from '@mui/material';

// * ASSETS

// * TYPES
import { Category, Playlist, Track } from 'store/@types/moods';

// * COMPONENTS
import useMusicPlayer from 'hooks/useMusicPlayer';
import DivImage from 'components/DivImage';

// * LOCAL IMPORTS
import { useStyles } from './styles';

export interface IRecentCardItemProps {
  id: string;
  src: string;
  title: string;
}

export interface IRecentCardProps {
  item?: Track | Playlist;
  loading: boolean;
  moodsList?: Category[] | null | undefined;
  guidedList?: Category[] | null | undefined;
}

const RecentCard: FC<IRecentCardProps> = ({ item, loading, moodsList, guidedList }) => {
  const classes = useStyles();
  const { setAlbumView, playFavoritesFromSong, setIsPlaylist } = useMusicPlayer();

  let foundPlaylist: Playlist;

  const findPlaylistInList = (list: Category[] | null | undefined, searchItem: Playlist | Track | undefined) => {
    if (list && searchItem) {
      return list.some(category =>
        category.playlists.some(playlist => {
          const trackIsInPlaylist = playlist.tracks.some(track => track.id === searchItem.id);
          if (trackIsInPlaylist) {
            foundPlaylist = playlist;
          }
          return trackIsInPlaylist;
        }),
      );
    }
    return false;
  };

  findPlaylistInList(moodsList, item);
  findPlaylistInList(guidedList, item);

  const clickHandler = (): void => {
    if (!item) return;

    if ((item as Playlist).tracks) {
      setAlbumView(item as Playlist);
      setIsPlaylist('open');
    } else {
      playFavoritesFromSong(item, foundPlaylist.tracks, false);
    }
  };

  if (loading)
    return (
      <>
        {[1, 2, 3, 4, 5, 6].map(e => (
          <div key={e} className={classes.square}>
            <Skeleton className={classes.skeletonBars} animation="wave" variant="text" />
          </div>
        ))}
      </>
    );

  if (!item) return null;
  return (
    <span>
      <DivImage customClasses={classes.square} src={item.image} smallSrc={item.smallImage}>
        <CardActionArea className={classes.clickableMenu} onClick={clickHandler} />
      </DivImage>
      <Typography className={classes.squareTitle} variant="carrouselItem" component="div" marginTop="7px">
        {item.name}
      </Typography>
    </span>
  );
};

RecentCard.defaultProps = { item: undefined };

export default RecentCard;
