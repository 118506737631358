declare global {
  interface Window {
    __RUNTIME_CONFIG__: EnvConfig;
  }
}

interface EnvConfig {
  REACT_APP_BACKEND_URL: string;
  REACT_APP_APIKEY: string;
  REACT_APP_AUTHDOMAIN: string;
  REACT_APP_PROJECTID: string;
  REACT_APP_STORAGEBUCKET: string;
  REACT_APP_MSGSENDER: string;
  REACT_APP_APPID: string;
  REACT_APP_MEASUREMENTID: string;
  REACT_APP_WEBSITE_URL: string;
  REACT_APP_GTM_ID: string;
  REACT_APP_GTM_PREVIEW: string;
  REACT_APP_GTM_AUTH: string;
  REACT_APP_GAUTH_CLIENTID: string;
  REACT_APP_APPLE_CLIENTID: string;
}

const EMPTY_VALUE = 'x';

const convertConfigValue = (value: string) => {
  if (value === EMPTY_VALUE) return '';
  return value;
};

const mapEmptyValues = (config: EnvConfig) => {
  const keys = Object.keys(config) as Array<keyof EnvConfig>;
  const result: Partial<EnvConfig> = {};
  keys.forEach(key => {
    result[key] = convertConfigValue(config[key]);
  });
  return result as EnvConfig;
};

export const envConfig: EnvConfig = mapEmptyValues({
  ...process.env,
  ...window.__RUNTIME_CONFIG__,
});
