import { Dispatch, bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import OfflinePage from './Offline';

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({}, dispatch);

export type ReduxProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(OfflinePage);
