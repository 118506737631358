import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ThemeProvider, CssBaseline, StyledEngineProvider } from '@mui/material';
import { ThemeProvider as ThemeProviderLegacy } from '@mui/styles';
import { ToastContainer } from 'react-toastify';
import '@fontsource/montserrat';
import TagManager from 'react-gtm-module';
import { PwaInstallPromptProvider } from 'context/PwaInstall';
import { ConnectedAuthProvider } from 'context/AuthProvider';
import { ResponsiveProvider } from 'context/ResponsiveProvider';
import { MusicPlayerProvider } from 'context/MusicPlayer';
import { envConfig } from 'config/config';
import { GoogleOAuthProvider } from '@react-oauth/google';
import 'react-toastify/dist/ReactToastify.css';
import 'assets/theme/main.css';
import 'utils/i18n';
import { LanguageProvider } from 'context/LanguageProvider';
import { swInit } from 'utils/swInit';
import theme from 'assets/theme/mui';
import Routes from './routes';
import { store } from './store';

TagManager.initialize({
  gtmId: envConfig.REACT_APP_GTM_ID,
  preview: envConfig.REACT_APP_GTM_PREVIEW || undefined,
  auth: envConfig.REACT_APP_GTM_AUTH || undefined,
});

swInit(); /// initialize service worker for PWA

ReactDOM.render(
  <React.StrictMode>
    <meta name="apple-itunes-app" content="app-id=6443797659" />
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <ThemeProviderLegacy theme={theme}>
          <GoogleOAuthProvider clientId={envConfig.REACT_APP_GAUTH_CLIENTID}>
            <StyledEngineProvider injectFirst>
              <CssBaseline />
              <ResponsiveProvider>
                <ConnectedAuthProvider>
                  <LanguageProvider>
                    <MusicPlayerProvider>
                      <PwaInstallPromptProvider>
                        <ToastContainer />
                        <Routes />
                      </PwaInstallPromptProvider>
                    </MusicPlayerProvider>
                  </LanguageProvider>
                </ConnectedAuthProvider>
              </ResponsiveProvider>
            </StyledEngineProvider>
          </GoogleOAuthProvider>
        </ThemeProviderLegacy>
      </ThemeProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root'),
);
