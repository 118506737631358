import { CustomReactTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: CustomReactTheme) => ({
  squareContainer: {
    marginBottom: '20px',
  },
  square: {
    borderRadius: '20px',
    boxShadow: '0 0 10px 0 rgba(31, 31, 31, 0.5)',
    width: '100%',
    height: '160px',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
  },
  squareTitle: {
    width: '160px',
  },
  squareImage: {
    width: '101%',
    objectFit: 'contain',
    height: '101%',
  },
  clickableMenu: {
    width: '100%',
    height: '100%',
    borderRadius: '20px',
  },
  //* TABLET - landScape
  [theme.breakpoints.down('lg')]: {},
  //* TABLET - portrait
  [theme.breakpoints.down('md')]: {},
  //* MOBILE
  [theme.breakpoints.down('sm')]: {
    square: {
      width: '98px',
      height: '98px',
    },
    squareTitle: {
      width: '98px',
    },
  },
}));

export { useStyles };
