import { FC } from 'react';
// * ASSETS
import LanguagePicker from 'components/LanguagePicker';
import { ReactComponent as SinesLogoMix } from 'assets/imgs/sines_logo_stack.svg';
import { Grid } from '@mui/material';

import { useStyles } from './styles';

const NoAuthPage: FC = ({ children }) => {
  const classes = useStyles();
  const handleClick = (): void => {
    window.location.assign('https://sines.app/home');
  };
  return (
    <div style={{ padding: '12px 0' }}>
      <div style={{ textAlign: 'center', paddingTop: '40px' }}>
        <SinesLogoMix onClick={handleClick} className={classes.logoAuth} />
      </div>
      <Grid container spacing={2} direction="row" justifyContent="center" alignItems="center" className={classes.root}>
        {children}
        <div style={{ marginLeft: '15px' }}>
          <LanguagePicker />
        </div>
      </Grid>
    </div>
  );
};

export default NoAuthPage;
