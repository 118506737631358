import React, { FC, useEffect } from 'react';
// * 3rd party libs
import { Grid, IconButton } from '@mui/material';

// * ASSETS

import { ReactComponent as PlaylistIcon } from 'assets/imgs/player/playlist_view.svg';
// import Airplay from 'assets/imgs/player/airplay';
import Heart from 'assets/imgs/player/heart';
import { DeleteForever, Download, DownloadDone, Downloading } from '@mui/icons-material';
import { ReactComponent as AlbumViewIcon } from 'assets/imgs/player/album-view.svg';
import {
  downloadAndCacheTrackFiles,
  fetchAndParseM3U,
  getAllDownloadedSongs,
  removeCachedAssetsByUID,
  removeDownloadedSongByUID,
  saveDownloadedSong,
  savePhoto,
} from 'utils/saveOffline';

// * HOOKS - COMPONENTS
import useMusicPlayer from 'hooks/useMusicPlayer';

// * TYPES
import { Track } from 'store/@types/moods';

// * LOCAL IMPORTS
import { useStyles } from './styles';
import { ReduxProps } from '.';

interface IMusicInteractionBar extends ReduxProps {
  isMusicView: boolean;
  setIsMusicView: React.Dispatch<React.SetStateAction<boolean>>;
}
const MusicInteractionBar: FC<IMusicInteractionBar> = ({ moods, isMusicView, setIsMusicView, sendFavTrack }) => {
  const classes = useStyles();
  const { favorites } = moods;

  const { currentSong, isOffline, setIsPlaylist, setCurrentSong, setIsFullScreen, setPlaying } = useMusicPlayer();
  const [isLoading, setIsLoading] = React.useState(false);
  const [isDownloaded, setIsDownloaded] = React.useState(false);

  const playlistController: React.MouseEventHandler<HTMLButtonElement> = ({ currentTarget }): void => {
    if (currentTarget.id === 'playlist') {
      setIsMusicView(false);
    } else {
      setIsMusicView(true);
    }
  };

  const handleDeleteSong = (song: Track) => {
    removeCachedAssetsByUID(song.id);
    removeDownloadedSongByUID(song.id);
    setIsFullScreen(false);
    setIsPlaylist(null);
    setPlaying(false);
    setCurrentSong(null);
  };

  useEffect(() => {
    getAllDownloadedSongs().then(songs => {
      setIsDownloaded(songs.some(song => song.id === currentSong?.id));
    });
  }, []);

  if (!currentSong) return null;

  const isFavorite = favorites?.tracks.some(e => e.id === currentSong.id);

  const isInPWA = window.matchMedia('(display-mode: standalone)').matches;

  if (isOffline && isInPWA)
    return (
      <Grid container className={classes.actionControlersMobile}>
        <Grid item xs={4} style={{ display: 'flex', justifyContent: 'center' }}>
          <IconButton
            className={classes.playerButton}
            onClick={() => {
              handleDeleteSong(currentSong);
            }}>
            <DeleteForever />
          </IconButton>
        </Grid>
      </Grid>
    );
  return (
    <Grid container className={classes.actionControlersMobile}>
      <Grid item xs={4} style={{ display: 'flex', justifyContent: 'center' }}>
        {isInPWA && (
          <IconButton
            hidden={false}
            className={classes.playerButton}
            onClick={async () => {
              if (isDownloaded || isLoading) return;
              setIsLoading(true);

              try {
                const cache = await caches.open('sines-download-cache');
                const result = await fetchAndParseM3U(cache, currentSong.uri);

                await Promise.all([
                  downloadAndCacheTrackFiles(cache, result, currentSong),
                  saveDownloadedSong(currentSong),
                  savePhoto(cache, currentSong),
                ]);
              } finally {
                setIsLoading(false);
                setIsDownloaded(true);
              }
            }}>
            {
              // eslint-disable-next-line no-nested-ternary
              !isDownloaded ? isLoading ? <Downloading /> : <Download /> : <DownloadDone />
            }
          </IconButton>
        )}
      </Grid>
      <Grid item xs={4} style={{ display: 'flex', justifyContent: 'center' }}>
        <IconButton
          className={classes.playerButton}
          onClick={() => {
            sendFavTrack(currentSong.id, !isFavorite);
          }}>
          <Heart fill={isFavorite} />
        </IconButton>
      </Grid>
      <Grid item xs={4} style={{ display: 'flex', justifyContent: 'center' }}>
        {isMusicView ? (
          <IconButton className={classes.playerButton} id="playlist" onClick={playlistController}>
            <PlaylistIcon id="playlist-icon" />
          </IconButton>
        ) : (
          <IconButton className={classes.playerButton} id="album" onClick={playlistController}>
            <AlbumViewIcon id="album-icon" />
          </IconButton>
        )}
      </Grid>
    </Grid>
  );
};

export default MusicInteractionBar;
