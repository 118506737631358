//* EXTERNAL LIBS
import React, { useState, useMemo } from 'react';
import { Controller } from 'react-hook-form';
import { styled } from '@mui/material/styles';

//* EXTERNAL LIBS --> MUI
import { TextField, InputAdornment, Grid, IconButton, GridProps } from '@mui/material';
import { InfoOutlined } from '@mui/icons-material';

import { ReactComponent as PasswordSee } from 'assets/imgs/passwordSee.svg';
import { ReactComponent as PasswordHide } from 'assets/imgs/password_hide.svg';

//* LOCAL COMPONENT IMPORTS
import { useStyles } from './styles';
import InputLabel from '../InputLabel';
import { parseErrorMessage, parseIfError } from '../utils/formHelpers';
import { ExtendedInputFields, TName } from '../@types';

export const CssTextField = styled(TextField)({
  '& label.Mui-focused': {
    color: '#FFFFFF !important',
  },
  '& .MuiFormHelperText-root': {
    color: '#757575',
  },
  '& .Mui-disabled': {
    color: '#757575',
    WebkitTextFillColor: '#757575 !important',
  },
  '& .MuiFormHelperText-root.Mui-error': {
    color: '#E78989',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: '#97a0ba',
  },
  '& .MuiOutlinedInput-input': {
    padding: '0 !important',
    '&:focus': {
      color: '#FFFFFF !important',
    },
  },
  '& .MuiOutlinedInput-root': {
    backgroundColor: '#2A2A2A',
    borderRadius: '26px',
    padding: '18px',
    color: '#B3B3B3',
    '& fieldset': {
      border: 'none',
      fontSize: '16px',
      lineHeight: '19px',
    },
    // '&:hover fieldset': {
    // },
    '&.Mui-focused fieldset': {
      border: '1px solid #FFFFFF',
    },
    '&.Mui-error fieldset': {
      border: '1px solid #E78989',
    },
    '& textarea': {
      padding: '0 10px  !important',
    },
  },
});

//* COMPONENT INTERFACES
export interface IInput extends Omit<ExtendedInputFields, 'onChange' | 'label' | 'required'> {
  //* ** handlers
  /**
   * Helper method to perform an additional action on change
   */
  additionalOnChange?: (optionSelected: string, inputName?: string) => void;
  /**
   * Helper method to perform an additional change on blur
   */
  additionalOnBlur?: (optionSelected: string) => void;

  //* ** component specific
  /**
   * Placeholder value for the Input
   */
  placeholder?: string;
  /**
   * The type of the Input
   */
  type?: string;
  label?: string;
  /**
   * Whether the error and helper text should be displayed or not
   */
  showErrorAndHelperText?: boolean;
  noLabel?: boolean;
  required?: boolean;

  grid?: boolean;
  gridProps?: GridProps;
  /**
   * Extra property required to use any other property of the native component
   */
  [x: string]: string | number | React.ReactNode | unknown;
}

//* COMPONENT DEFAULT PROPS
const defaultProps = {
  // react-hook-form
  control: null,
  errors: null,

  // field display fields
  name: null,
  defaultValue: '',
  label: '',
  value: null,
  helperText: ' ',
  required: false,
  noLabel: true,
  lPrev: null,
  lEnd: null,

  // handlers
  additionalOnChange: undefined,
  additionalOnBlur: undefined,

  // component specific
  placeholder: '',
  type: 'text',
  showErrorAndHelperText: false,

  // GRID
  grid: false,
  gridProps: undefined,
};

//* COMPONENT
const Input = ({
  name,
  label,
  placeholder,
  errors,
  control,
  required,
  additionalOnChange,
  additionalOnBlur,
  lPrev,
  noLabel,
  lEnd,
  defaultValue,
  type,
  helperText,
  showErrorAndHelperText = false,
  grid,
  gridProps,
  ...others
}: IInput) => {
  const classes = useStyles();
  const hasError = errors && name && Object.keys(errors).length > 0 ? parseIfError(errors, name) : null;
  const errorMessage = hasError && parseErrorMessage(hasError);

  // Add these variables to your component to track the state
  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  const helperTextMessage = useMemo(() => {
    if (showErrorAndHelperText) {
      return hasError ? (
        <>
          {errorMessage} {' - '}
          {helperText}
        </>
      ) : (
        helperText
      );
    }

    if (!hasError && (!helperText || helperText === ' ')) return ' ';

    return (
      <span style={{ display: 'flex' }}>
        <InfoOutlined fontSize="small" style={{ marginRight: '10px' }} />
        {hasError ? errorMessage : helperText}
      </span>
    );
  }, [errorMessage, helperText]);

  const component = (
    <>
      {!noLabel && (
        <InputLabel error={Boolean(hasError)} prev={lPrev} end={lEnd} required={required}>
          {label}
        </InputLabel>
      )}
      <Controller
        name={name as TName}
        control={control}
        render={({ field }) => (
          <CssTextField
            {...field}
            error={Boolean(hasError)}
            type={(type === 'password' && (showPassword ? 'text' : 'password')) || type} // <-- This is where the magic happens
            placeholder={placeholder || (label as string)}
            helperText={helperTextMessage}
            size="small"
            classes={{ root: classes.w100 }}
            variant="outlined"
            {...others}
            onChange={event => {
              field.onChange(event.target.value);
              additionalOnChange && additionalOnChange(event.target.value, name);
            }}
            onBlur={event => {
              additionalOnBlur && additionalOnBlur(event.target.value);
              field.onBlur();
            }}
            value={field.value || defaultValue || ''}
            InputProps={{
              classes: { input: classes.input },
              ...((type === 'password' && {
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}>
                      {showPassword ? <PasswordHide /> : <PasswordSee />}
                    </IconButton>
                  </InputAdornment>
                ),
              }) ||
                {}),
            }}
          />
        )}
      />
    </>
  );

  return grid ? (
    <Grid item xs={12} {...gridProps}>
      {component}
    </Grid>
  ) : (
    component
  );
};

Input.defaultProps = { ...defaultProps };

export { Input };
