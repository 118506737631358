import { CustomReactTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: CustomReactTheme) => ({
  thankYou: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  topBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    color: '#fff',
    textAlign: 'center',
  },
  title: {
    color: '#FFFFFF',
    fontFamily: 'Montserrat',
    fontSize: '38px',
    fontWeight: '700',
    textAlign: 'center',
    marginBottom: 20,
    marginTop: 10,
  },
  subTitle: {
    color: '#FFFFFF',
    fontFamily: 'Montserrat',
    fontSize: '16px',
    textAlign: 'center',
  },
  bottomBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  buttonContinue: {
    width: '100%',
    maxWidth: 275,
  },
  sinesLogo: {},
  //* TABLET - landScape
  [theme.breakpoints.down('lg')]: {},
  //* TABLET - portrait
  [theme.breakpoints.down('md')]: {},
  //* MOBILE
  [theme.breakpoints.down('sm')]: {
    title: {
      fontSize: 30,
    },
    sinesLogo: {
      width: '50%',
      '& img': {
        width: '100%',
      },
    },
  },
}));

export { useStyles };
