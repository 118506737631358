import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

// * 3rd party libs
import { Typography } from '@mui/material';

// * LOCAL IMPORTS
import { menuItem } from '../UserMenuPage';

interface PractitionersFaqProps {
  setModalOpen: (value: string | null) => void;
}

const PractitionersFaq: FC<PractitionersFaqProps> = ({ setModalOpen }) => {
  const { t } = useTranslation();
  return (
    <>
      <Typography variant="heroTitle" sx={{ marginLeft: '15px', marginTop: '15px', width: '350px' }}>
        {t('Practitioners') /* Practitioners */}
      </Typography>
      <span style={{ paddingLeft: '15px' }}>
        {menuItem(
          t('faq-practitioner-1'), // 'Does my subscription work for multiple practitioners?'
          {
            action: () => {
              setModalOpen('faq-practitioner-1');
            },
          },
        )}
        {menuItem(
          t('faq-practitioner-2'), // 'Can I sign in on multiple devices?'
          {
            action: () => {
              setModalOpen('faq-practitioner-2');
            },
          },
        )}
        {menuItem(
          t('faq-practitioner-3'), // 'I’m a traveling practitioner, can I use SINES in all the locations I practice in?'
          {
            action: () => {
              setModalOpen('faq-practitioner-3');
            },
          },
        )}
        {menuItem(
          t('faq-practitioner-4'), // 'What happens when my free trial ends?'
          {
            action: () => {
              setModalOpen('faq-practitioner-4');
            },
          },
        )}
        {menuItem(
          t('faq-practitioner-5'), // 'What happens if I cancel my subscription during the trial?'
          {
            action: () => {
              setModalOpen('faq-practitioner-5');
            },
          },
        )}
        {menuItem(
          t('faq-practitioner-6'), // 'What happens if I cancel my subscription?'
          {
            action: () => {
              setModalOpen('faq-practitioner-6');
            },
          },
        )}
        {menuItem(
          t('faq-practitioner-7'), // 'What does the renewal process look like?'
          {
            action: () => {
              setModalOpen('faq-practitioner-7');
            },
          },
        )}
        {menuItem(
          t('faq-practitioner-8'), // 'How do I cancel or change billing information?'
          {
            action: () => {
              setModalOpen('faq-practitioner-8');
            },
          },
        )}
      </span>
    </>
  );
};

export default PractitionersFaq;
