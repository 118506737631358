import React from 'react';

const SVG = ({ disabled }: { disabled: boolean }) => (
  <svg
    width="26px"
    height="26px"
    viewBox="0 0 26 26"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink">
    <g id="Album-and-Player" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Desktop-Large_Minified-Playing" transform="translate(-500.000000, -1022.000000)">
        <g id="Group-14" transform="translate(320.000000, 920.000000)">
          <g id="music_controls" transform="translate(44.000000, 96.000000)">
            <g id="Group-12" transform="translate(136.000000, 0.000000)">
              <g id="previous" transform="translate(0.000000, 6.000000)">
                <g
                  id="Group"
                  transform="translate(4.073344, 5.053197)"
                  stroke={disabled ? '#8d949a' : '#FFFFFF'}
                  strokeDasharray="0,0"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="1.5">
                  <path
                    d="M17.8532853,13.1359663 C17.8532853,15.2592997 15.5457999,16.5917904 13.7041332,15.5301237 L9.20830699,12.9409412 L4.71244721,10.3409478 C2.87078049,9.2792811 2.87078049,6.62511102 4.71244721,5.56344434 L9.20830699,2.96345095 L13.7041332,0.374301409 C15.5457999,-0.687365279 17.8532853,0.634292185 17.8532853,2.76845888 L17.8532853,13.1359663 Z"
                    id="Vector"
                    fillRule="nonzero"
                  />
                  <line x1="0" y1="14.6417948" x2="0" y2="1.25181071" id="Vector" />
                </g>
                <path
                  d="M26,0 L26,26 L0,26 L0,0 L26,0 Z"
                  id="Vector"
                  opacity="0"
                  transform="translate(13.000000, 13.000000) rotate(-180.000000) translate(-13.000000, -13.000000) "
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default SVG;
