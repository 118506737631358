import { combineReducers, Reducer } from 'redux';

import userReducer from './user/reducer';
import moodsReducer from './moods/reducer';
import b2bReducer from './b2b/reducer';

const createRootReducer = (): Reducer =>
  combineReducers({
    // APP Reducers
    user: userReducer,
    moods: moodsReducer,
    b2b: b2bReducer,
  });

export default createRootReducer;
