import React, { FC } from 'react';
// * 3rd party libs
import { useNavigate } from 'react-router-dom';

import { Card, CardActionArea, CardMedia, Typography, Skeleton } from '@mui/material';

// * ASSETS

// * COMPONENTS
import FallbackImageSvg from 'assets/imgs/missing_image_medium.svg';

// * TYPES

// * LOCAL IMPORTS
import { useStyles } from './styles';

export interface SearchItemsCardItemProps {
  id: string;
  src?: string;
  title?: string;
  author?: string;
  url?: string;
  skeleton?: boolean;
}

export interface SearchItemsCardProps {
  item: SearchItemsCardItemProps;
}

const SearchItemsCard: FC<SearchItemsCardProps> = ({ item }) => {
  const [imageSrc, setImageSrc] = React.useState(item.src || FallbackImageSvg);

  const classes = useStyles();
  const { skeleton } = item;

  const navigate = useNavigate();

  const clickCardHandler = () => {
    item.url && navigate(item.url);
  };

  return (
    <Card className={classes.card}>
      <CardActionArea className={classes.rectangle} onClick={clickCardHandler}>
        {skeleton ? (
          <Skeleton className={classes.square} variant="rectangular" animation="wave" />
        ) : (
          <CardMedia
            className={classes.square}
            component="img"
            height="71"
            width="71"
            image={imageSrc}
            onError={() => setImageSrc(FallbackImageSvg)}
            alt={item.title}
          />
        )}
        <div>
          {skeleton ? (
            <>
              <Skeleton className={classes.skeletonBars} animation="wave" variant="text" width={140} height={25} />
              <Skeleton className={classes.skeletonBars} animation="wave" variant="text" width={200} height={25} />
            </>
          ) : (
            <>
              <Typography className={classes.title}>item.title</Typography>
              <Typography className={classes.author}>item.author</Typography>
            </>
          )}
        </div>
      </CardActionArea>
    </Card>
  );
};

export default SearchItemsCard;
