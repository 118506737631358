import * as yup from 'yup';

import { stringRequired, string, regex } from 'utils/formUtils';

export const schema = yup.object().shape({
  name: stringRequired.required('Please insert the company name'),
  zipCode: string,
  location: string,
  email: string.matches(regex.email, 'Invalid e-mail.'),
  phoneNumber: string,
});

export const defaultValues = {
  name: '',
  address: '',
  zipCode: '',
  location: '',
  email: '',
  phoneNumber: '',
};
