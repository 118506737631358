import { FC, useEffect, useState } from 'react';

import { Button, Dialog, Divider, Grid, Typography } from '@mui/material';

// * ASSETS
// * TYPES
import { BusinessUser, IEditManagerPost, UserBusiness } from 'store/@types/b2b';
import { ISubscription } from 'store/@types/user';
import { useTranslation } from 'react-i18next';
// * COMPONENTS
import TeamMemberCard from 'components/Cards/TeamMemberCard';
import ConfirmActionModal from 'components/ConfirmActionModal';

// * LOCAL IMPORTS

import InviteManagers from './Modals/InviteManagers/InviteManagers';
import ManagerDetails from './Modals/ManagerDetails/ManagerDetails';

import { ReduxProps } from '.';
import { useStyles } from './styles';

const modalInitState = {
  isModalOpen: false,
  isEditMode: false,
  isRemoveManager: false,
  isInviteManager: false,
};

export interface IModalControler {
  isModalOpen: BusinessUser | boolean;
  isEditMode: BusinessUser | boolean;
  isRemoveManager: BusinessUser | boolean;
  isInviteManager: BusinessUser | boolean;
}

export interface ITeamManagement extends ReduxProps {
  maxDevices: number | undefined;
  userBusiness: UserBusiness | null;
  plan: ISubscription;
}

const TeamManagement: FC<ITeamManagement> = ({
  plan,
  userBusiness,
  maxDevices,
  updateUserInfo,
  deleteB2BUser,
  updatePlan,
  inviteUsers,
}) => {
  const classes = useStyles();
  const [activeDevices, setActiveDevices] = useState(0);
  const [updatePlanUrl, setUpdatePlanUrl] = useState('');

  const { t } = useTranslation();

  useEffect(() => {
    if (userBusiness) {
      const totalDevices = userBusiness.businessUsers.reduce((acc, user) => acc + user.devices, 0);
      setActiveDevices(totalDevices);
    }
  }, [userBusiness]);
  useEffect(() => {
    if (updatePlanUrl === '') {
      updatePlan(data => {
        if (data.session.url) setUpdatePlanUrl(data.session.url);
      });
    }
  }, []);
  const [{ isModalOpen, isEditMode, isRemoveManager, isInviteManager }, setModalControler] = useState<IModalControler>({
    isModalOpen: false,
    isEditMode: false,
    isRemoveManager: false,
    isInviteManager: false,
  });
  const availableDevices = (maxDevices as number) - activeDevices;
  // eslint-disable-next-line no-unused-vars
  const handleEditManager = (userIndex: number, user: BusinessUser): void => {
    setModalControler(prev => ({ ...prev, isEditMode: user, isModalOpen: true }));
  };
  // eslint-disable-next-line no-unused-vars
  const handleDeleteManager = (userIndex: number, user: BusinessUser): void => {
    setModalControler(prev => ({ ...prev, isRemoveManager: user, isModalOpen: true }));
  };

  const handleCloseModal = (event?: any, reason?: any): void => {
    if (reason === 'backdropClick') {
      return;
    }
    setModalControler(modalInitState);
  };

  const handleUpdateUserInfo = (data: IEditManagerPost | null): void => {
    if (data) {
      updateUserInfo(data.userId, data.zones);
    }
    setModalControler(modalInitState);
  };
  const handleDeleteUser = (confirm: boolean, user: BusinessUser): void => {
    confirm && (deleteB2BUser as (id: string) => void)(user.user.id);
    setModalControler(modalInitState);
  };

  const numberOfMembers = userBusiness?.businessUsers.length || 0;
  const showZones = plan?.type === 'locations';
  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography variant="section">{showZones ? 'Locations' : 'Team Members'}</Typography>
      </Grid>
      <Grid item xs={12}>
        {showZones ? (
          <Typography className={classes.inviteSubtitle} variant="listAuthor">
            {t('Youcurrentlyhave') /* You currently have */}
            {numberOfMembers} {numberOfMembers === 1 ? t('location') : t('locations')},{t('and') /* and  */}
            {availableDevices}
            {availableDevices === 1 ? 'zone' : 'zones'} {t('available')}
            .
            <br />
            {t('Team_mng_morezones') /* If you need more zones please{' '} */}
            <a href={updatePlanUrl} style={{ cursor: 'pointer' }} target="_blank" rel="noreferrer">
              {t('Team_mng_updatesub') /* update your subscription */}
            </a>{' '}
            {t('Team_mng_reassign') /* or re-assign them between your locations. */}
          </Typography>
        ) : (
          <Typography variant="listAuthor">
            {t('Yourteamhas') /* Your team has currently */}
            {numberOfMembers} {numberOfMembers === 1 ? 'person' : 'people'}, {t('and')} {availableDevices} {t('vacant')}{' '}
            {availableDevices === 1 ? t('seat') : t('seats')} {t('available')}.
          </Typography>
        )}
      </Grid>
      <Grid container paddingTop="20px" paddingBottom="10px">
        <Grid item xs={6}>
          <Typography variant="listTrack">{t('Name') /* Name */}</Typography>
        </Grid>
        <Grid item xs={6} className={classes.operationsTypoWrapper} />
      </Grid>
      <Divider className={classes.divider} />
      {userBusiness?.businessUsers.map(user => (
        <TeamMemberCard
          showZones={showZones}
          key={user.id}
          user={user}
          handleDeleteManager={handleDeleteManager}
          handleEditManager={handleEditManager}
        />
      ))}

      <Grid item xs={12} className={classes.inviteManagerButtonWrapper}>
        <Button
          variant={availableDevices === 0 ? 'disabled' : 'primary'}
          className={classes.inviteManagerButton}
          onClick={() => setModalControler(prev => ({ ...prev, isModalOpen: true, isInviteManager: true }))}>
          <Typography className={classes.inviteManagerButtonText}>{t('Invite') /* Invite */}</Typography>
        </Button>
      </Grid>

      <Dialog
        fullWidth
        className={isRemoveManager ? classes.dialogRemoveManager : classes.dialog}
        open={Boolean(isModalOpen)}
        onClose={handleCloseModal}>
        {isRemoveManager && (
          <ConfirmActionModal
            title="Remove manager?"
            body={
              <>
                {t('remove_mng_line1') /* This action cannot be undone. */}
                <br />
                {t('remove_mng_line2') /* All linked zones will be unpaired from this */}
                <br />
                {t('manager') /* manager */}.
              </>
            }
            cancelAction={() => handleDeleteUser(false, isRemoveManager as BusinessUser)}
            confirmText={t('Remove')}
            // "Remove"
            confirmAction={() => handleDeleteUser(true, isRemoveManager as BusinessUser)}
          />
        )}
        {isEditMode && (
          <ManagerDetails
            showZones={showZones}
            availableDevices={availableDevices}
            user={isEditMode as BusinessUser}
            deleteUser={() => handleDeleteUser(false, isEditMode as BusinessUser)}
            closeModal={data => handleUpdateUserInfo(data)}
          />
        )}
        {isInviteManager && (
          <InviteManagers
            showZones={showZones}
            availableDevices={availableDevices}
            numberOfMembers={numberOfMembers}
            closeModal={handleCloseModal}
            inviteUsers={inviteUsers as any}
          />
        )}
      </Dialog>
    </Grid>
  );
};

export default TeamManagement;
