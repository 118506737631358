/* eslint-disable no-console */
import React, { FC } from 'react';
// * 3rd party libs
import { Typography, Grid, Box, IconButton, Divider } from '@mui/material';
// * ASSETS
import { ReactComponent as RemoveUser } from 'assets/imgs/remove_user.svg';
import { ReactComponent as EditUser } from 'assets/imgs/edit_user.svg';
import { ReactComponent as ProfileSVG } from 'assets/imgs/profile_nophoto.svg';

// * TYPES
import { BusinessUser } from 'store/@types/b2b';

// * COMPONENTS
import { useTranslation } from 'react-i18next';
// * LOCAL IMPORTS
import { useStyles } from './styles';

export interface TeamMemberCardProps {
  showZones?: boolean;
  user: BusinessUser;
  handleDeleteManager: (userIndex: number, user: BusinessUser) => void;
  handleEditManager: (userIndex: number, user: BusinessUser) => void;
}
const TeamMemberCard: FC<TeamMemberCardProps> = ({ showZones, user, handleDeleteManager, handleEditManager }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  if (!user || !user.user) return null;
  return (
    <Grid item xs={12}>
      <Grid item xs={12} className={classes.bodyWrapper}>
        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
          <ProfileSVG className={classes.userPhoto} />
          <Box className={classes.typoWrapper}>
            <Typography variant="listTrack">
              {user.user.name === user.user.email.split('@')[0] ? user.user.email : user.user.name}
            </Typography>
            {showZones ? (
              <Typography variant="listAuthor">
                {`${user.devices} `} {user.devices === 1 ? t('zone') : t('zones')} {t('assigned')}
              </Typography>
            ) : (
              user.user.name !== user.user.email.split('@')[0] && (
                <Typography variant="listAuthor">{user.user.email}</Typography>
              )
            )}
          </Box>
        </Box>
        <Box sx={{ alignSelf: 'center', paddingRight: '10px' }}>
          {user.role !== 'app-admin' && (
            <IconButton onClick={() => handleDeleteManager(1, user)} sx={{ paddingRight: '20px' }}>
              <RemoveUser />
            </IconButton>
          )}
          {showZones && (
            <IconButton style={{ paddingLeft: '20px' }} onClick={() => handleEditManager(1, user)}>
              <EditUser />
            </IconButton>
          )}
        </Box>
      </Grid>
      <Divider className={classes.divider} />
    </Grid>
  );
};

TeamMemberCard.defaultProps = {
  showZones: false,
};

export default TeamMemberCard;
