import { useTranslation } from 'react-i18next';

const DeleteBody = () => {
  const { t } = useTranslation();
  return (
    <>
      {t('confirm_delete') /* Are you certain you wish to delete your SINES account? */}
      <br /> <br />
      {
        t(
          'confirm_delete2',
        ) /* This action cannot be undone, and you will lose all data and any time remaining in your current subscription. */
      }
    </>
  );
};

export default DeleteBody;
