import React, { FC } from 'react';
// * 3rd party libs

import { Typography, CardActionArea, Skeleton } from '@mui/material';

// * ASSETS

// * TYPES
import { Category, Playlist } from 'store/@types/moods';

// * COMPONENTS
import useMusicPlayer from 'hooks/useMusicPlayer';
import DivImage from 'components/DivImage';

// * LOCAL IMPORTS
import { useStyles } from './styles';

export interface IRecentCardItemProps {
  id: string;
  src: string;
  title: string;
}

export interface IRecentCardProps {
  item?: Category | Playlist;
  loading: boolean;
}

const isPlaylist = (item: Category | Playlist): Playlist | null => {
  if ((item as Playlist).tracks) return item as Playlist;
  return null;
};

const Square: FC<IRecentCardProps> = ({ item, loading }) => {
  const classes = useStyles();
  const { setIsMoodsResult, setIsPlaylist, setAlbumView } = useMusicPlayer();

  const clickHandler = (): void => {
    if (!item) return;
    if (isPlaylist(item)) {
      setAlbumView(item as Playlist);
      setIsPlaylist('open');
    } else {
      const { playlists } = item as Category;
      if (!playlists) return;

      if (playlists.length > 1) {
        setIsMoodsResult(item as Category);
      } else {
        setAlbumView(playlists[0]);
        setIsPlaylist('open');
      }
    }
  };
  if (loading)
    return (
      <>
        {[1, 2, 3, 4, 5, 6].map(e => (
          <div key={e} className={classes.square}>
            <Skeleton className={classes.skeletonBars} animation="wave" variant="text" />
          </div>
        ))}
      </>
    );

  if (!item) return null;
  return (
    <span>
      <DivImage customClasses={classes.square} src={item.image}>
        <CardActionArea className={classes.clickableMenu} onClick={clickHandler} />
      </DivImage>
      <Typography className={classes.squareTitle} variant="carrouselItem" component="div" marginTop="7px">
        {item.name}
      </Typography>
    </span>
  );
};
Square.defaultProps = { item: undefined };

export default Square;
