import { CustomReactTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: CustomReactTheme) => ({
  dialog: {
    '& .MuiDialog-paper': {
      height: '698px',
      maxHeight: '698px',
      width: '100%',
      overflow: 'hidden',
      maxWidth: '698px',
      backgroundColor: '#181818',
      borderRadius: '20px',
      display: 'flex',
      flexDirection: 'row',
    },
  },
  dialogRemoveManager: {
    '& .MuiDialog-paper': {
      width: '286px',
      minHeight: '286px',
      backgroundColor: '#181818',
      borderRadius: '20px',
      display: 'flex',
      flexDirection: 'row',
    },
  },
  operationsTypoWrapper: {
    flexDirection: 'row',
    alignItems: 'right',
    display: 'flex',
    justifyContent: 'end',
    paddingRight: '27px',
  },
  divider: {
    width: '100%',
    backgroundColor: '#353535',
  },
  inviteManagerButtonWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    paddingTop: '40px',
  },
  inviteManagerButton: {
    width: '176px',
    height: '44px',
  },
  inviteManagerButtonText: {
    color: '#FFFFFF',
    fontSize: '16px',
    lineHeight: '19px',
    textAlign: 'left',
  },
  inviteSubtitle: {
    '& a': {
      color: 'rgb(182, 100, 129)',
      textDecoration: 'none',
    },
    '& a:hover': {
      color: 'rgb(182, 100, 129)',
      textDecoration: 'underline',
    },
  },
  //* TABLET - landScape
  [theme.breakpoints.down('lg')]: {},
  //* TABLET - portrait
  [theme.breakpoints.down('md')]: {},
  //* MOBILE
  [theme.breakpoints.down('sm')]: {
    dialog: {
      '& .MuiDialog-paper': {
        height: 'calc(100vh)',
        maxHeight: 'calc(100vh)',
        borderRadius: 'unset',
      },
    },
  },
}));

export { useStyles };
