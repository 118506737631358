import { createReducer } from 'typesafe-actions';
import { B2BActions as Actions, B2BState } from 'store/@types/b2b';

const INITIAL_STATE: B2BState = {
  loading: false,
  error: null,
  userBusiness: null,
  invitedUsers: null,
  updateUserAction: null,
  deleteUserAction: null,
};

const b2bReducer = createReducer(INITIAL_STATE, {
  [Actions.SET_LOADING]: state => ({ ...state, loading: true }),
  [Actions.SET_ERROR]: (state, { payload }) => ({ ...state, loading: false, error: payload }),
  [Actions.SET_GET_BUSINESS_INFO]: (state, { payload }) => ({
    ...state,
    loading: false,
    userBusiness: payload,
  }),
  [Actions.SET_UPDATE_BUSINESS_INFO]: (state, { payload }) => ({
    ...state,
    loading: false,
    userBusiness: payload,
  }),
  [Actions.SET_INSERT_USERS]: (state, { payload }) => ({
    ...state,
    loading: false,
    userBusiness: null,
    invitedUsers: payload,
  }),
  [Actions.SET_UPDATE_USERS]: (state, { payload }) => ({
    ...state,
    loading: false,
    userBusiness: null,
    updateUserAction: payload,
  }),
  [Actions.SET_DETELE_B2B_USERS]: (state, { payload }) => ({
    ...state,
    loading: false,
    userBusiness: null,
    deleteUserAction: payload,
  }),
});

export default b2bReducer;
