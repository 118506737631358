import { Button, Dialog, Grid, Typography } from '@mui/material';
import { ReactComponent as SinesLogo } from 'assets/imgs/sinesLogo.svg';
import ConfirmActionModal from 'components/ConfirmActionModal';
import DeleteBody from 'components/DeleteBody';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const InvalidPaymentDetails = ({ classes, handleSubscribe, handleDeleteUser }: any) => {
  const [deleteAccount, setDeleteAccount] = useState(false);

  const { t } = useTranslation();

  return (
    <>
      <Grid
        container
        spacing={2}
        direction="row"
        flexWrap="wrap"
        alignContent="flex-start"
        justifyContent="center"
        justifyItems="center"
        className={classes.dialogContainer}>
        <Grid item xs={12}>
          <SinesLogo />
        </Grid>
        <Grid item xs={12}>
          <Typography className={classes.title}>
            {t('invalidpaymentdetails_issue') /* {There is an issue}  */}
            <br />
            {t('invalidpaymentdetails_withyouraccount') /* with your account */}
          </Typography>
        </Grid>
        <Grid item xs={12} className={classes.descriptionGrid}>
          <Typography className={classes.subTitle}>
            {t('invalidpaymentdetails_sorryinvalid') /* We're sorry, but your payment details appear to be invalid. */}
            <br />
            {
              t(
                'invalidpaymentdetails_verify',
              ) /* Please verify that you have entered the card number and billing information correctly. */
            }
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Button variant="primary" className={classes.button1} onClick={handleSubscribe}>
            {t('updatepaymentdetails') /* Update payment details */}
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Typography
            onClick={() => {
              setDeleteAccount(true);
            }}
            className={classes.buttonDelete}>
            {t('deleteaccount') /* Delete account */}
          </Typography>
        </Grid>
      </Grid>
      {deleteAccount && (
        <Dialog
          fullWidth
          className={classes.dialogDeleteAccount}
          open={deleteAccount}
          onClose={() => setDeleteAccount(false)}>
          <ConfirmActionModal
            title={
              t('sorrytoseeyougo')
              // "We’re sorry to see you go"
            }
            body={<DeleteBody />}
            cancelAction={() => setDeleteAccount(false)}
            confirmText={t('Delete')}
            confirmAction={handleDeleteUser}
          />
        </Dialog>
      )}
    </>
  );
};

export default InvalidPaymentDetails;
