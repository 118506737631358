import React, { FC, useEffect, useState } from 'react';
// * 3rd party libs
import { yupResolver } from '@hookform/resolvers/yup';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';

import { Box, Button, Grid, LinearProgress, LinearProgressProps, Typography } from '@mui/material';

// * ASSETS
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import SuccessGeneric from 'assets/imgs/success_heart.png';

// * TYPES
import { SendNewPwPost } from 'store/@types/user';

// * COMPONENTS
import NoAuthPage from 'components/NoAuthPage/NoAuthPage';
import Input from 'components/forms/Input';
import Loading from 'components/Loading';
import { useTranslation } from 'react-i18next';
// * LOCAL IMPORTS
import { schema, defaultValues } from './yupSchema';
import { ReduxProps } from '.';
import { useStyles, useStylesNewPassword } from './styles';

export const LinearProgressWithLabel = (props: LinearProgressProps & { value: number }) => {
  const classes = useStyles({ progress: props.value });

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '100%' }}>
        <LinearProgress classes={{ root: classes.root, bar: classes.bar }} variant="determinate" {...props} />
      </Box>
    </Box>
  );
};

const PasswordRecovery: FC<ReduxProps> = ({ user, setNewPassword }) => {
  const { loading } = user;
  const [match] = useSearchParams();
  const navigate = useNavigate();
  const [progress, setProgress] = useState(0);
  const [successOperation, setSuccessOperation] = useState(match.get('success') === 'true');
  const classPassword = useStylesNewPassword();
  const operationToken = match.get('token');
  const business = match.get('business');

  const { t } = useTranslation();

  const {
    handleSubmit,
    control,
    formState: { errors, isValid },
  } = useForm({
    mode: 'onTouched',
    resolver: yupResolver(schema),
    reValidateMode: 'onChange',
    defaultValues,
    shouldUnregister: false,
  });

  useEffect(() => {
    if (successOperation) {
      setTimeout(() => {
        navigate('/login');
      }, 5000);
    }
  }, [successOperation]);

  useEffect(() => {
    if (progress !== 0) {
      setTimeout(() => {
        setProgress(progress + 10);
      }, 500);
    }
  }, [progress]);

  useEffect(() => {
    if (successOperation) {
      setTimeout(() => {
        navigate('/login');
      }, 5000);
    }
  }, [successOperation]);

  if (!operationToken) {
    navigate('/login');
  }

  const onSubmit = (payload: { password: string }) =>
    setNewPassword({ newPassword: payload.password } as SendNewPwPost, operationToken as string, () => {
      setSuccessOperation(true);
      setProgress(1);
    });

  return (
    <>
      {loading && <Loading />}
      <Box sx={{ width: '100%' }}>{progress !== 0 && <LinearProgressWithLabel value={progress} />}</Box>
      <form id="new-password-form" onSubmit={handleSubmit(onSubmit)}>
        <NoAuthPage>
          <Grid item xs={12} style={{ marginTop: '40px', padding: 0 }}>
            <Button
              variant="text"
              className={classPassword.backToLoginGrid}
              onClick={() => {
                navigate('/login');
              }}>
              <ChevronLeft />
              <Typography variant="carrouselItem">{t('newpassword_signin') /* Back to Sign in */}</Typography>
            </Button>
          </Grid>
          {successOperation ? (
            <>
              <Grid item xs={12}>
                <img src={SuccessGeneric} style={{ width: 142, height: 142 }} alt="success-img" />
              </Grid>
              <Grid item xs={12}>
                <Typography gutterBottom variant="heroTitle" component="p" textAlign="center">
                  {t('Success') /* Success */}!
                </Typography>
                <Typography variant="listAuthor" component="p" textAlign="center">
                  {
                    t(
                      'newpassword_setup_correctly',
                    ) /* Your new password was set up correctly. You will be re-directed to Sign in shortly. */
                  }
                </Typography>
              </Grid>
            </>
          ) : (
            <>
              <Grid item xs={12}>
                <Typography paragraph gutterBottom variant="heroTitle" component="p">
                  {business ? `${t('Welcome')}!` : `${t('Welcome back')}!`}
                </Typography>
                <Typography paragraph gutterBottom variant="listAuthor" component="p">
                  {
                    business
                      ? t('newpassword_gain_access') // 'To gain access to your account you will need to set a password. Make sure it has at least 8 characters.'
                      : t('newpassword_regain_access')
                    // 'To regain access to your account please create a new password.'
                  }
                </Typography>
              </Grid>
              <Input
                grid
                gridProps={{
                  marginTop: '15px',
                }}
                required
                name="password"
                type="password"
                placeholder={t('Password') /* Password */}
                helperText={t('newpassword_at_least_8') /* Use at least 8 characters. */}
                control={control}
                errors={errors}
              />
              <Input
                grid
                required
                name="password2"
                type="password"
                placeholder={t('Repeat your password') /* Repeat your password */}
                // "Repeat your password"
                control={control}
                errors={errors}
              />
              <Grid item xs={12}>
                <Button variant={isValid ? 'primary' : 'disabled'} fullWidth type="submit">
                  {t('newpassword_set_newpass') /* Set new password */}
                </Button>
              </Grid>
            </>
          )}
        </NoAuthPage>
      </form>
    </>
  );
};

export default PasswordRecovery;
