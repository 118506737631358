/* eslint-disable no-param-reassign */
/* eslint-disable indent */
import {
  apiDeleteUser,
  apiGSignup,
  apiLogin,
  apiLogout,
  apiRecoverPw,
  apiSetNewPassword,
  apiSignUp,
  apiStartTrial,
  apiSubscribePlan,
  gApiLogin,
  refreshApiLogin,
  updatePlanUrl,
} from 'api/user';
import { Dispatch } from 'redux';
import {
  ErrorResponse,
  GoogleLoginPost,
  GoogleSignupPost,
  LoginPost,
  LoginResponse,
  RecoverPwPost,
  SendNewPwPost,
  SignUpPost,
  StripeCheckout,
  UserProps,
} from 'store/@types/user';
import { removeCookie, removeLSField, setLSField } from 'utils/cookies';

import { sendGetUserHistory, sendGetUserLastHistory } from 'api/history';
import { setError, setLastPlayedPlaylists, setLoading, setLogin, setMostPlayedPlaylists } from './actions';
// import { performanceAnalytics } from '..';

export const login =
  (payload: LoginPost, success: (successResponse?: LoginResponse, errorResponse?: ErrorResponse) => void) =>
  async (dispatch: Dispatch): Promise<void> => {
    try {
      dispatch(setLoading());
      // api request
      const { data } = await apiLogin(payload);

      success(data);

      dispatch(setLogin(data));
    } catch (error: unknown) {
      success(undefined, error as ErrorResponse);
      dispatch(setError(error));
    }
  };

export const glogin =
  (payload: GoogleLoginPost, success: (successResponse?: LoginResponse, errorResponse?: ErrorResponse) => void) =>
  async (dispatch: Dispatch): Promise<void> => {
    try {
      dispatch(setLoading());
      // api request
      const { data } = await gApiLogin(payload);

      success(data);

      dispatch(setLogin(data));
    } catch (error: unknown) {
      success(undefined, error as ErrorResponse);
      dispatch(setError(error));
    }
  };

export const logout =
  () =>
  async (dispatch: Dispatch): Promise<void> => {
    try {
      dispatch(setLoading());

      await apiLogout();

      removeLSField('token');
      removeLSField('refreshToken');
      removeCookie('modalTrial');
      removeCookie('userRepeat');

      window.location.href = '/login';
      dispatch(setLoading(false));
    } catch (error) {
      dispatch(setError(error));
    }
  };

export const refreshToken =
  (success?: () => void) =>
  async (dispatch: Dispatch): Promise<void> => {
    try {
      const { data } = await refreshApiLogin();

      if (data) {
        setLSField('token', data.access_token);
        setLSField('refreshToken', data.refresh_token);
      }

      success && success();
      dispatch(setLogin(data));
    } catch (error) {
      dispatch(setError(error));
    }
  };

export const signUp =
  (payload: SignUpPost, success: (res?: UserProps, errorResponse?: ErrorResponse) => void) =>
  async (dispatch: Dispatch): Promise<void> => {
    try {
      dispatch(setLoading());
      // api request
      const { data } = await apiSignUp(payload);

      success(data);
      dispatch(setLoading(false));
    } catch (error: unknown) {
      success(undefined, error as ErrorResponse);
      dispatch(setError(error));
    }
  };

export const gSignUp =
  (payload: GoogleSignupPost, success: (res?: LoginResponse, errorResponse?: ErrorResponse) => void) =>
  async (dispatch: Dispatch): Promise<void> => {
    try {
      dispatch(setLoading());
      // api request
      const { data } = await apiGSignup(payload);

      success(data);
      dispatch(setLoading(false));
    } catch (error: unknown) {
      success(undefined, error as ErrorResponse);
      dispatch(setError(error));
    }
  };

export const deleteUser =
  (id: string, success: () => void) =>
  async (dispatch: Dispatch): Promise<void> => {
    try {
      dispatch(setLoading());
      await apiDeleteUser();

      await success();
      dispatch(setLoading(false));
    } catch (error) {
      dispatch(setError(error));
    }
  };

export const recoverPw =
  (payload: RecoverPwPost, success: () => void) =>
  async (dispatch: Dispatch): Promise<void> => {
    try {
      dispatch(setLoading());
      await apiRecoverPw(payload);

      success();
      dispatch(setLoading(false));
    } catch (error) {
      dispatch(setError(error));
    }
  };

export const setNewPassword =
  (payload: SendNewPwPost, token: string, success: () => void) =>
  async (dispatch: Dispatch): Promise<void> => {
    try {
      dispatch(setLoading());
      await apiSetNewPassword(payload, token);

      success();
      dispatch(setLoading(false));
    } catch (error) {
      dispatch(setError(error));
    }
  };

export const clearError =
  () =>
  async (dispatch: Dispatch): Promise<void> => {
    dispatch(setError(null));
  };

export const getUserPlaylistHistory =
  (clear = false) =>
  async (dispatch: Dispatch): Promise<void> => {
    if (clear) {
      dispatch(setMostPlayedPlaylists(null));
      dispatch(setLastPlayedPlaylists(null));
    } else {
      try {
        dispatch(setLoading());
        const { data } = await sendGetUserHistory({ limit: 6, page: 1, sort: 'count' });

        if (data) {
          const newData = data.items.map(history => {
            if (history.track && history.playlist) {
              if (!history.track.imagePath || !history.track.image || history.track.image.endsWith('Track.png')) {
                history.track.image = history.playlist.image;
              }
              if (
                !history.track.smallImagePath ||
                !history.track.smallImage ||
                history.track.smallImage.endsWith('SMALL_Track.png')
              ) {
                history.track.smallImage = history.playlist.smallImage;
              }
            }

            return history;
          });
          dispatch(setMostPlayedPlaylists({ ...data, items: newData }));
        }
      } catch (error) {
        dispatch(setError(error));
      }
    }
  };

export const getUserLastPlaylistHistory =
  () =>
  async (dispatch: Dispatch): Promise<void> => {
    try {
      dispatch(setLoading());
      const { data } = await sendGetUserLastHistory({ limit: 30, page: 1, sort: 'updatedAt' });
      const newItems = data.items.map(item => ({
        ...item,
        track: {
          ...item.track,
          image: item.playlist ? item.playlist.image : item.track.image,
          smallImage: item.playlist ? item.playlist.smallImage : item.track.smallImage,
        },
      }));
      dispatch(setLastPlayedPlaylists({ ...data, items: newItems }));
    } catch (error) {
      dispatch(setError(error));
    }
  };

export const payOrTrial =
  (trial = false, success?: (payload: StripeCheckout) => void) =>
  async (dispatch: Dispatch): Promise<void> => {
    try {
      dispatch(setLoading());

      let res = null;

      if (trial) {
        res = await apiStartTrial();
      } else {
        res = await apiSubscribePlan();
      }

      success && success(res?.data);
      dispatch(setLoading(false));
    } catch (error) {
      dispatch(setError(error));
    }
  };
export const updatePlan =
  (success?: (payload: StripeCheckout) => void) =>
  async (dispatch: Dispatch): Promise<void> => {
    try {
      dispatch(setLoading());

      let res = null;

      res = await updatePlanUrl();

      success && success(res?.data);
      dispatch(setLoading(false));
    } catch (error) {
      dispatch(setError(error));
    }
  };
