import * as yup from 'yup';

import { stringRequired, regex } from 'utils/formUtils';

export const schema = yup.object().shape({
  email: stringRequired.matches(regex.email, 'Invalid e-mail.'),
});

export const defaultValues = {
  email: '',
};
