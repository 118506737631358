import { CustomReactTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: CustomReactTheme) => ({
  dialog: {
    '& .MuiDialog-paper': {
      height: '698px',
      overflow: 'hidden',
      maxHeight: '698px',
      width: '100%',
      maxWidth: '698px',
      backgroundColor: '#181818',
      borderRadius: '20px',
      display: 'flex',
      flexDirection: 'row',
    },
  },
  bodyContainerWrapper: {
    backgroundColor: '#2A2A2A',
    borderRadius: '26px',
    width: '100%',
    height: '108px',
    marginBottom: '80px',
    justifyContent: 'center',
    padding: '20px',
  },
  updateButton: {
    display: 'flex',
    justifyContent: 'end',
  },

  //* TABLET - landScape
  [theme.breakpoints.down('lg')]: {},
  //* TABLET - portrait
  [theme.breakpoints.down('md')]: {},
  //* MOBILE
  [theme.breakpoints.down('sm')]: {
    dialog: {
      '& .MuiDialog-paper': {
        height: 'calc(100vh)',
        background: 'top',
        maxHeight: 'calc(100vh)',
        borderRadius: 'unset',
      },
    },
  },
}));

export { useStyles };
