import { CustomReactTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import backGroundImg from 'assets/imgs/splash_background.png';

const useStyles = makeStyles((theme: CustomReactTheme) => ({
  pagesContent: {
    flexGrow: 1,
    maxWidth: `calc(100vw - ${theme.drawerWidth}px)`,
  },
  pagesContentOffline: {
    flexGrow: 1,
    maxWidth: 'calc(100vw)',
  },

  pageMain: {
    marginTop: '65px',
    borderRadius: '20px 20px 0 0',
    flexGrow: 1,
    height: 'calc(100vh - 74px)',
  },

  pageMainOffline: {
    borderRadius: '20px 20px 0 0',
    flexGrow: 1,
    marginTop: '20px',
  },

  navBottomBarItem: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    alignContent: 'normal',
    alignItems: 'center',
    justifyContent: 'center',
  },
  navBottomBarRoot: {
    zIndex: 3,
    padding: '8px 40px',
    height: '74px',
    maxHeight: '74px',
    width: '100%',
    position: 'absolute',
    bottom: 0,
    right: 0,
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    alignContent: 'normal',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: theme.navbar.bgDarker,
  },
  navBottomBarRootOffline: {
    zIndex: 3,
    padding: '8px 40px',
    height: '74px',
    maxHeight: '74px',
    width: '100%',
    position: 'absolute',
    bottom: 0,
    right: 0,
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    alignContent: 'normal',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: theme.navbar.bgDarker,
  },
  menuRoot: {
    '& .MuiMenu-root': {
      backgroundColor: 'transparent !important',
      padding: '0 !important',
    },
    '& .MuiMenu-paper': {
      backgroundColor: 'transparent !important',
      padding: '0 !important',
    },
    '& .MuiMenu-list': {
      backgroundColor: 'transparent !important',
      padding: '0 !important',
    },
  },
  dialog: {
    '& .MuiDialog-paper': {
      height: '698px',
      maxHeight: '698px',
      width: '100%',
      maxWidth: '698px',
      backgroundColor: 'transparent !important',
      backgroundImage: `url(${backGroundImg})`,
      backgroundAttachment: 'fixed',
      backgroundSize: 'cover',
      borderRadius: '20px',
      display: 'flex',
      flexDirection: 'row',
    },
  },
  activeItem: {
    paddingBottom: '5px',
    /* gradient can be an image */
    background: `linear-gradient(
			to left, 
			#775DA8 0%,
			#F06B5D 100%
			)
			left 
			bottom
			transparent    
			no-repeat`,
    backgroundSize: '98% 2px' /* if linear-gradient, we need to resize it */,
  },
  item: {
    paddingBottom: '5px',
  },
  logo: {
    cursor: 'pointer',
  },
  businessWrapper: {
    backgroundColor: '#242424',
    borderRadius: '26px',
    width: '205px',
    height: '50px',
    justifyContent: 'center',
    display: 'flex',
    background: 'linear-gradient(to right, #775DA8, #F06B5D)',
    marginRight: '50px',
  },
  businessInsideWrapper: {
    backgroundColor: '#242424',
    borderRadius: '26px',
    width: '203px',
    height: '48px',
    justifyContent: 'center',
    display: 'flex',
    alignSelf: 'center',
  },
  businessText: {
    color: '#FFFFFF',
    fontFamily: 'Montserrat',
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: '19px',
    textAlign: 'left',
    alignSelf: 'center',
  },
  //* TABLET - landScape
  [theme.breakpoints.down('lg')]: {
    pageMain: {
      height: 'calc(100vh - 148px)',
    },
    navBottomBarRoot: {
      padding: '8px 30px',
    },
    activeItem: {
      minWidth: '200px',
    },
    item: {
      minWidth: '200px',
    },
    pagesContent: {
      maxWidth: 'calc(100vw)',
    },
    businessDiv: {
      flexGrow: 1,
      justifyContent: 'center',
      display: 'flex',
      alignItems: 'center',
    },
    businessWrapper: {
      marginRight: 0,
    },
  },
  //* TABLET - portrait
  [theme.breakpoints.down('md')]: {
    pageMain: {
      borderRadius: '0 0 0 0',
    },
    navBottomBarRoot: {
      padding: '8px 20px',
      position: 'fixed',
    },
    navBottomBarRootOffline: {
      position: 'fixed',
    },
    activeItem: {
      minWidth: '130px',
    },
    item: {
      minWidth: '130px',
    },
    businessDiv: {
      flexGrow: 1,
      justifyContent: 'center',
      display: 'flex',
      alignItems: 'center',
    },
    businessWrapper: {
      marginRight: 0,
    },
  },
  //* MOBILE
  [theme.breakpoints.down('sm')]: {
    pageMain: {
      borderRadius: '0 0 0 0',
    },
    outerBox: {
      height: 'calc(100vh - 74px)',
    },
    scrollBars: {},
    navBottomBarRoot: {
      bottom: 0,
      padding: '8px 10px',
      position: 'fixed',
    },
    activeItem: {
      minWidth: '10px',
    },
    item: {
      minWidth: '10px',
    },
    dialog: {
      '& .MuiDialog-paper': {
        height: 'calc(100svh)',
        background: 'top',
        maxHeight: 'calc(100svh)',
        backgroundImage: `url(${backGroundImg})`,
        backgroundAttachment: 'fixed',
        backgroundSize: 'cover',
        borderRadius: 'unset',
      },
    },
    businessDiv: {
      flexGrow: 1,
      justifyContent: 'center',
      display: 'flex',
      alignItems: 'center',
    },
    businessWrapper: {
      marginRight: 0,
    },
  },
}));

export { useStyles };
