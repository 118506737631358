/* eslint-disable no-undef */
/* eslint-disable react/require-default-props */
import React, { FC, useMemo } from 'react';
// * 3rd party libs
import clsx from 'clsx';
import Scrollbars from 'components/Scrollbars';

import { Box, Button, ClassNameMap, Divider, Grid, Typography } from '@mui/material';

// * TYPES
import {
  Category,
  Playlist,
  Track,
  UserFavPlaylistsItemPayload,
  UserFavTracksItemPayload,
  UserFavTracksPayload,
} from 'store/@types/moods';
import { UserHistory } from 'store/@types/user';

// * COMPONENTS
import MoodCard from 'components/Cards/MoodCard';
import MoodsMenuCard from 'components/Cards/MoodsMenuCard';
import RecentCard from 'components/Cards/RecentCard';
import SearchItemsCard, { SearchItemsCardItemProps } from 'components/Cards/SearchItems';
import SearchPlaylistCard from 'components/Cards/SearchPlaylistCard';
import Square from 'components/Cards/Square';
import Type3Card from 'components/Cards/Type3';

// * LOCAL IMPORTS
import { useStyles } from './styles';

const isRecent = (
  classes: ClassNameMap<string>,
  halfSize: boolean,
  list: unknown[],
  type: string,
  loading: boolean,
): React.ReactNode => {
  const thisMood = 'recent';
  if (type === 'recent') {
    return (
      <ul
        className={clsx(classes.gridContainer, classes[`${thisMood}Container`], {
          [classes[`${thisMood}ContainerHalf`]]: halfSize,
        })}>
        {(list as Playlist[]).map(item => (
          <RecentCard loading={false} key={item.id} item={item} />
        ))}
        {loading && list.length === 0 && <RecentCard loading />}
      </ul>
    );
  }
  return null;
};
const isRecentList = (
  classes: ClassNameMap<string>,
  halfSize: boolean,
  list: unknown[],
  type: string,
  moodsList: Category[] | null | undefined,
  guidedList: Category[] | null | undefined,
  loading: boolean,
): React.ReactNode => {
  const thisMood = 'recentList';
  if (type === 'recentList') {
    return (
      <Scrollbars horizontal height="170px">
        <ul
          className={clsx(classes.gridContainer, classes[`${thisMood}Container`], {
            [classes[`${thisMood}ContainerHalf`]]: halfSize,
          })}>
          {(list as Playlist[]).map(item => (
            <RecentCard loading={false} key={item.id} item={item} guidedList={guidedList} moodsList={moodsList} />
          ))}
          {loading && list.length === 0 && <RecentCard loading />}
        </ul>
      </Scrollbars>
    );
  }
  return null;
};

const isSquare = (
  classes: ClassNameMap<string>,
  halfSize: boolean,
  list: unknown[],
  type: string,
  loading: boolean,
): React.ReactNode => {
  const thisMood = 'square';
  if (type === 'square') {
    return (
      <ul
        className={clsx(classes.gridContainer, classes[`${thisMood}Container`], {
          [classes[`${thisMood}ContainerHalf`]]: halfSize,
        })}
        style={{ justifyContent: 'space-around' }}>
        {(list as Category[]).map(item => (
          <Square loading={false} key={item.id} item={item} />
        ))}
        {loading && list.length === 0 && <Square loading />}
      </ul>
    );
  }
  return null;
};

const isSquareList = (
  classes: ClassNameMap<string>,
  halfSize: boolean,
  list: unknown[],
  type: string,
  loading: boolean,
): React.ReactNode => {
  const thisMood = 'squareList';
  if (type === thisMood) {
    return (
      <Scrollbars horizontal height="180px">
        <ul
          className={clsx(classes.gridContainer, classes[`${thisMood}Container`], {
            [classes[`${thisMood}ContainerHalf`]]: halfSize,
          })}>
          {(list as Category[]).map(item => (
            <Square loading={false} key={item.id} item={item} />
          ))}
          {loading && list.length === 0 && <Square loading />}
        </ul>
      </Scrollbars>
    );
  }
  return null;
};

const isSearchPlaylist = (
  classes: ClassNameMap<string>,
  halfSize: boolean,
  list: unknown[],
  type: string,
  // eslint-disable-next-line no-unused-vars
  loading: boolean,
): React.ReactNode => {
  const thisMood = 'searchPlaylist';
  if (type === thisMood && list.length > 0) {
    return (
      list.length > 0 && (
        <ul
          className={clsx(classes.gridContainer, classes[`${thisMood}Container`], {
            [classes[`${thisMood}ContainerHalf`]]: halfSize,
          })}>
          {(list as Category[]).map(item => (
            <SearchPlaylistCard loading={false} key={item.id} item={item} />
          ))}
        </ul>
      )
    );
  }
  return null;
};

const isMoodList = (
  classes: ClassNameMap<string>,
  halfSize: boolean,
  list: unknown[],
  type: string,
  loading: boolean,
): React.ReactNode => {
  const thisMood = 'moodList';
  if (type === thisMood) {
    return (
      <Scrollbars horizontal height="150px">
        <ul
          className={clsx(classes.gridContainer, classes[`${thisMood}Container`], {
            [classes[`${thisMood}ContainerHalf`]]: halfSize,
          })}>
          {(list as Playlist[]).map(item => (
            <MoodCard loading={false} key={item.id} item={item} />
          ))}
          {loading && list.length === 0 && <MoodCard loading />}
        </ul>
      </Scrollbars>
    );
  }
  return null;
};

const isMood = (
  classes: ClassNameMap<string>,
  halfSize: boolean,
  list: unknown[],
  type: string,
  loading: boolean,
): React.ReactNode => {
  const thisMood = 'mood';
  if (type === thisMood) {
    return (
      <ul
        className={clsx(classes.gridContainer, classes[`${thisMood}Container`], {
          [classes[`${thisMood}ContainerHalf`]]: halfSize,
        })}>
        {(list as Playlist[]).map(item => (
          <MoodCard loading={false} key={item.id} item={item} />
        ))}
        {loading && list.length === 0 && <MoodCard loading />}
      </ul>
    );
  }
  return null;
};

const isType3 = (
  classes: ClassNameMap<string>,
  halfSize: boolean,
  list: unknown[],
  type: string,
  favoriteList: UserFavTracksPayload | null | undefined,
  guidedList: Category[] | null | undefined,
  moodsList: Category[] | null | undefined,
  // eslint-disable-next-line no-unused-vars
  loading: boolean,
): React.ReactNode => {
  const thisMood = 'type3';
  if (type === thisMood && list.length > 0) {
    return (
      list.length > 0 && (
        <ul
          className={clsx(classes.gridContainer, classes[`${thisMood}Container`], {
            [classes[`${thisMood}ContainerHalf`]]: halfSize,
          })}>
          {(list as UserHistory[]).map(item => (
            <Type3Card
              key={item.id}
              item={item}
              favoriteList={favoriteList}
              guidedList={guidedList}
              moodsList={moodsList}
            />
          ))}
        </ul>
      )
    );
  }
  return null;
};

const isSearchItem = (
  classes: ClassNameMap<string>,
  halfSize: boolean,
  list: unknown[],
  type: string,
  // eslint-disable-next-line no-unused-vars
  loading: boolean,
): React.ReactNode => {
  const thisMood = 'searchItems';
  if (type === thisMood && list.length > 0) {
    return (
      list.length > 0 && (
        <ul
          className={clsx(classes.gridContainer, classes[`${thisMood}Container`], {
            [classes[`${thisMood}ContainerHalf`]]: halfSize,
          })}>
          {(list as SearchItemsCardItemProps[]).map(item => (
            <SearchItemsCard key={item.id} item={item} />
          ))}
        </ul>
      )
    );
  }
  return null;
};

const isMoodsMenuItem = (
  classes: ClassNameMap<string>,
  halfSize: boolean,
  list: unknown[],
  type: string,
  // eslint-disable-next-line no-unused-vars
  loading: boolean,
): React.ReactNode => {
  const thisMood = 'moodMenu';
  if (type === thisMood && list.length > 0) {
    return (
      list.length > 0 && (
        <ul
          className={clsx(classes.gridContainer, classes[`${thisMood}Container`], {
            [classes[`${thisMood}ContainerHalf`]]: halfSize,
          })}
          style={{ justifyContent: 'space-around' }}>
          {(list as Category[]).map(item => (
            <MoodsMenuCard key={item.id} item={item} />
          ))}
        </ul>
      )
    );
  }
  return null;
};

export interface HorizontalListProps {
  title?: string;
  rightAction?: { label: string; action: () => void };
  hasDivider?: boolean;
  loading?: boolean;
  halfSize?: boolean;
  list:
    | Category[]
    | Playlist[]
    | Track[]
    | UserHistory[]
    | UserFavTracksItemPayload[]
    | SearchItemsCardItemProps[]
    | UserFavPlaylistsItemPayload[];
  type: string;
  gridSizes?: { xs?: number; sm?: number; md?: number; lg?: number; xl?: number };
  favoriteList?: UserFavTracksPayload | null | undefined;
  moodsList?: Category[] | null | undefined;
  guidedList?: Category[] | null | undefined;
}

const defaultProps = {
  title: undefined,
  rightAction: undefined,
  gridSizes: { xs: 12, sm: 12, md: 12, lg: 12, xl: 12 },
  halfSize: false,
  loading: false,
  hasDivider: false,
};

// eslint-disable-next-line no-unused-vars
const HorizontalList: FC<HorizontalListProps> = ({
  title,
  halfSize,
  hasDivider,
  list,
  type,
  loading,
  rightAction,
  gridSizes,
  favoriteList,
  moodsList,
  guidedList,
}) => {
  const classes: { [className: string]: string } = useStyles();

  const recentContent = useMemo(
    () => isRecent(classes, halfSize || false, list, type, Boolean(loading)),
    [list, type, halfSize],
  );
  const recentListContent = useMemo(
    () => isRecentList(classes, halfSize || false, list, type, moodsList, guidedList, Boolean(loading)),
    [list, type, halfSize],
  );
  const squareContent = useMemo(
    () => isSquare(classes, halfSize || false, list, type, Boolean(loading)),
    [list, type, halfSize],
  );
  const squareListContent = useMemo(
    () => isSquareList(classes, halfSize || false, list, type, Boolean(loading)),
    [list, type, halfSize],
  );
  const searchPlaylistContent = useMemo(
    () => isSearchPlaylist(classes, halfSize || false, list, type, Boolean(loading)),
    [list, type, halfSize],
  );
  const moodContent = useMemo(
    () => isMood(classes, halfSize || false, list, type, Boolean(loading)),
    [list, type, halfSize],
  );
  const moodListContent = useMemo(
    () => isMoodList(classes, halfSize || false, list, type, Boolean(loading)),
    [list, type, halfSize],
  );
  const type3Content = useMemo(
    () => isType3(classes, halfSize || false, list, type, favoriteList, guidedList, moodsList, Boolean(loading)),
    [list, type, halfSize],
  );
  const searchItemsContent = useMemo(
    () => isSearchItem(classes, halfSize || false, list, type, Boolean(loading)),
    [list, type, halfSize],
  );
  const moodMenuContent = useMemo(
    () => isMoodsMenuItem(classes, halfSize || false, list, type, Boolean(loading)),
    [list, type, halfSize],
  );

  return (
    <>
      {title && (
        <Grid item xs={rightAction ? 8 : 12}>
          <Typography variant="section" component="div" sx={{ fontSize: '22px ', fontWeight: 600 }}>
            {list.length === 0 ? '' : title}
          </Typography>
        </Grid>
      )}
      {rightAction && (
        <Grid item xs={4} style={{ textAlign: 'right' }}>
          <Button onClick={rightAction.action} style={{ textAlign: 'end', minWidth: 100 }}>
            <Typography variant="section">{`${rightAction.label}`}</Typography>
          </Button>
        </Grid>
      )}
      <Grid item xs={12} {...gridSizes}>
        <Box className={classes.root}>
          {recentContent}
          {recentListContent}
          {squareContent}
          {squareListContent}
          {searchPlaylistContent}
          {moodContent}
          {moodListContent}
          {type3Content}
          {moodMenuContent}
          {searchItemsContent}
        </Box>
      </Grid>
      {hasDivider && (
        <Grid item xs={12}>
          <Divider sx={{ height: 10, borderColor: 'transparent', backgroundColor: 'transparent' }} />
        </Grid>
      )}
    </>
  );
};

HorizontalList.defaultProps = defaultProps;

export default HorizontalList;
