import { CustomReactTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: CustomReactTheme) => ({
  root: {
    zIndex: '3',
    padding: '20px 40px 100px 40px',
  },
  headerBox: {
    zIndex: '1',
    position: 'relative',
    height: '340px',
  },

  headerBox1: {
    zIndex: '-1',
    position: 'absolute',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    filter: 'blur(25px)',
    width: '500%',
    height: '500%',
    maxWidth: 'calc(70vw)',
    maxHeight: 'calc(70vw)',
    flexDirection: 'column',
    display: 'flex',
  },
  searchNotFound: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column',
    alignContent: 'center',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    gap: '25px',
    margin: '40px',
  },
  //* TABLET - landScape
  [theme.breakpoints.down('lg')]: {
    root: {
      padding: '10px 40px 200px 40px',
    },
  },
  //* TABLET - portrait
  [theme.breakpoints.down('md')]: {},
  //* MOBILE
  [theme.breakpoints.down('sm')]: {
    root: {
      padding: '10px 20px 180px 20px',
    },
  },
}));

export { useStyles };
