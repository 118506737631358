import React from 'react';

const SVG = ({ disabled }: { disabled: boolean }) => (
  <svg
    width="38px"
    height="38px"
    viewBox="0 0 38 38"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink">
    <g id="Album-and-Player" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Desktop-Large_Minified-Playing" transform="translate(-570.000000, -1016.000000)">
        <g id="Group-14" transform="translate(320.000000, 920.000000)">
          <g id="music_controls" transform="translate(44.000000, 96.000000)">
            <g id="Group-12" transform="translate(136.000000, 0.000000)">
              <g id="pause" transform="translate(70.000000, 0.000000)">
                <path
                  d="M16.8624994,7.74249979 C16.8624994,5.60499975 15.9600006,4.75 13.6800005,4.75 L7.93250036,4.75 C5.65250027,4.75 4.75,5.60499975 4.75,7.74249979 L4.75,30.257501 C4.75,32.395001 5.65250027,33.25 7.93250036,33.25 L13.6800005,33.25 C15.9600006,33.25 16.8624994,32.395001 16.8624994,30.257501 L16.8624994,7.74249979 Z"
                  id="Vector"
                  stroke={disabled ? '#8d949a' : '#FFFFFF'}
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeDasharray="0,0"
                  fillRule="nonzero"
                />
                <path
                  d="M33.250154,7.74249979 C33.250154,5.60499975 32.3476537,4.75 30.0676537,4.75 L24.3201535,4.75 C22.0559869,4.75 21.1376546,5.60499975 21.1376546,7.74249979 L21.1376546,30.257501 C21.1376546,32.395001 22.0401534,33.25 24.3201535,33.25 L30.0676537,33.25 C32.3476537,33.25 33.250154,32.395001 33.250154,30.257501 L33.250154,7.74249979 Z"
                  id="Vector"
                  stroke={disabled ? '#8d949a' : '#FFFFFF'}
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeDasharray="0,0"
                  fillRule="nonzero"
                />
                <path d="M38,0 L38,38 L0,38 L0,0 L38,0 Z" id="Vector" opacity="0" />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default SVG;
