/* eslint-disable no-param-reassign */
import axios, { AxiosError } from 'axios';

import { envConfig } from '../../config/config';

export interface ApiError {
  statusCode: number;
  message: string;
  data: unknown;
}

const api = axios.create({
  baseURL: envConfig.REACT_APP_BACKEND_URL,
});

api.interceptors.request.use(async config => {
  const token = localStorage.getItem('token');

  if (token && config.headers) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
});

api.interceptors.response.use(
  response => response,
  (error: AxiosError) => Promise.reject(error.response),
);

export default api;
