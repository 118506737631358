import * as yup from 'yup';

import { numberPositiveRequired, regex, stringRequired } from 'utils/formUtils';

export const schema = (availableDevices: number) =>
  yup.object().shape({
    managers: yup
      .array(
        yup.object().shape({
          email: stringRequired.matches(regex.email, 'Invalid e-mail.'),
          zones: numberPositiveRequired,
        }),
      )
      .min(1, 'This field is required')
      .test('max-number-of-zones-reached', (currentArray, { createError }) => {
        if (currentArray) {
          const availableDevicesAfter = currentArray?.reduce(
            (accumulator, object) => accumulator + (object?.zones || 0),
            0,
          );
          if (availableDevicesAfter > availableDevices) {
            return createError({
              message: 'Max number of zones reached.',
              path: `managers.${currentArray.length - 1}.zones`,
            });
          }
        }
        return true;
      }),
  });

export const defaultValues = {
  managers: [{ email: '', zones: 1 }],
};
