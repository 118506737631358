import { CustomReactTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: CustomReactTheme) => ({
  backToLoginGrid: {
    display: 'flex',
    justifyContent: 'flex-start',
    color: 'white',
    '&:hover': {
      backgroundColor: '#181818',
    },
  },
  checkboxLabel: {
    color: '#B3B3B3',
    fontFamily: 'Montserrat',
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: '17px',
    textAlign: 'left',
    '& a': {
      color: 'rgb(182, 100, 129)',
      textDecoration: 'none',
    },
    '& a:hover': {
      color: 'rgb(182, 100, 129)',
      textDecoration: 'underline',
    },
  },

  //* TABLET - landScape
  [theme.breakpoints.down('lg')]: {},
  //* TABLET - portrait
  [theme.breakpoints.down('md')]: {},
  //* MOBILE
  [theme.breakpoints.down('sm')]: {},
}));

export { useStyles };
