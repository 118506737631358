import React, { FC, useEffect } from 'react';

// * 3rd party libs

import { Grid, Typography } from '@mui/material';

// * ASSETS

// * COMPONENTS
import Loading from 'components/Loading';
import HorizontalList from 'components/HorizontalList';

// * TYPES
import { useTranslation } from 'react-i18next';
// * LOCAL IMPORTS
import useResponsive from 'hooks/useResponsive';
import { useStyles } from './styles';
import { ReduxProps } from '.';

const MoodsPage: FC<ReduxProps> = ({ moods, getMoods }) => {
  const { loading, error, moodsList } = moods;
  const classes = useStyles();

  const { scrollRef } = useResponsive();

  const { t } = useTranslation();

  useEffect(() => {
    if (!moodsList && !error) {
      getMoods();
    }
  }, [moodsList]);

  useEffect(() => {
    scrollRef.current?.scrollToTop();
  }, []);

  return (
    <>
      {loading && <Loading />}
      <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start" className={classes.root}>
        <Typography variant="heroTitle" marginBottom="30px">
          {t('moodspage_musicsounds') /* Music + Sounds */}
        </Typography>
        {moodsList && <HorizontalList list={moodsList} type="moodMenu" />}
      </Grid>
    </>
  );
};

export default MoodsPage;
