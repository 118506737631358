/* eslint-disable arrow-body-style */
import { useContext } from 'react';
import AuthContext, { AuthProps } from '../context/AuthProvider';

const useAuth = () => {
  return useContext<AuthProps>(AuthContext);
};

export enum Roles {
  APP_ADMIN = 'app-admin',
  APP_SUPER_USER = 'app-super-user',
  APP_MANAGER = 'app-manager',
  APP_NORMAL_USER = 'app-user',
}

export default useAuth;
