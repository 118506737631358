import * as yup from 'yup';

import { stringRequired, passwordSecure } from 'utils/formUtils';

export const schema = yup.object().shape({
  password: passwordSecure,
  password2: stringRequired.oneOf([yup.ref('password'), null], "Passwords don't match"),
});

export const defaultValues = {
  password: '',
  password2: '',
};
