import { AxiosResponse } from 'axios';
import { toast } from 'react-toastify';
import { Track } from 'store/@types/moods';
import { restCall } from './restHandler';

const fetchAndCacheAsset = async (url: string, cache: Cache) => {
  const response = await fetch(url);
  const cacheResponse = response.clone();
  await cache.put(url, cacheResponse);
};
export const openDB = async () =>
  new Promise((resolve, reject) => {
    const request = indexedDB.open('MusicDatabase', 1);

    request.onerror = ({ target }) => reject((target as IDBOpenDBRequest).error);

    request.onupgradeneeded = ({ target }) => {
      const dbReq = target as IDBOpenDBRequest;
      const db = dbReq.result;
      if (!db.objectStoreNames.contains('downloadedSongs')) {
        db.createObjectStore('downloadedSongs', { keyPath: 'id' });
      }
    };
    const resValue = (target: any) => {
      const dbReq = target as IDBOpenDBRequest;
      const db = dbReq.result;

      return db;
    };
    request.onsuccess = ({ target }) => resolve(resValue(target));
  });

export const saveDownloadedSong = async (song: Track) => {
  const dbUnk = await openDB();
  const db = dbUnk as IDBDatabase;

  const transaction = db.transaction(['downloadedSongs'], 'readwrite');
  const store = transaction.objectStore('downloadedSongs');
  store.put(song);
};

export function convertAxiosResponseToFetchResponse(axiosResponse: AxiosResponse) {
  const { data, status, statusText, headers } = axiosResponse;

  // Converting the Axios response headers to a Headers object for the Fetch API.
  const fetchHeaders = new Headers();
  Object.entries(headers).forEach(([key, value]) => {
    // Check if the header value is an array. If so, join it into a string.
    const headerValue = Array.isArray(value) ? value.join('; ') : value;
    fetchHeaders.append(key, headerValue);
  });

  // Creating a new Blob from the response data, which could be a JSON, Blob, or text.
  const blob = new Blob([JSON.stringify(data)], { type: headers['content-type'] });

  // Creating a new Response object with the Blob and other response details.
  return new Response(blob, {
    status,
    statusText,
    headers: fetchHeaders,
  });
}

export const fetchAndParseM3U = async (cache: Cache, m3uUrl: string) => {
  try {
    const response = await restCall(1, m3uUrl);
    const fetchResponse = await fetch(m3uUrl);

    const m3uContent = response.data as string;

    await cache.put(m3uUrl, fetchResponse);

    const lines = m3uContent.split('\n');
    const fileUrls = lines.filter(line => line.trim() && !line.startsWith('#'));
    return fileUrls;
  } catch (error) {
    toast.error('Error downloading files');
    return [];
  }
};
export const downloadAndCacheTrackFiles = async (cache: Cache, fileUrls: any[], currentSong: Track) => {
  fileUrls.forEach(async (fileUrl, index) => {
    try {
      const responseArray = await fetchAndParseM3U(
        cache,
        currentSong.uri.split('/playlist.m3u8')[0].concat('/', fileUrl),
      );

      if (responseArray && index === fileUrls.length - 1) {
        responseArray.forEach(async mediaUrl => fetchAndCacheAsset(mediaUrl, cache));
      }
    } catch (error) {
      toast.error('Error downloading files');
    }
  });
};

export const savePhoto = async (cache: Cache, currentSong: Track) => {
  const urlToDownload = currentSong.image;
  const smallPhotoUrlToDownload = currentSong.smallImage;

  if (urlToDownload) {
    fetchAndCacheAsset(urlToDownload, cache).catch(() => {
      toast.error('Error downloading main track cover');
    });
  }

  if (smallPhotoUrlToDownload) {
    fetchAndCacheAsset(smallPhotoUrlToDownload, cache).catch(() => {
      toast.error('Error downloading small track cover');
    });
  }
};

export const getAllDownloadedSongs = async () => {
  const dbUnk = await openDB();
  const db = dbUnk as IDBDatabase;
  return new Promise<Track[]>((resolve, reject) => {
    const transaction = db.transaction(['downloadedSongs'], 'readonly');
    const store = transaction.objectStore('downloadedSongs');
    const request = store.getAll();

    request.onerror = ({ target }) => reject((target as IDBOpenDBRequest).error);

    request.onsuccess = () => resolve(request.result);
  });
};

export const removeCachedAssetsByUID = async (uid: string) => {
  const cacheNames = await caches.keys();
  await Promise.all(
    cacheNames.map(async cacheName => {
      const cache = await caches.open(cacheName);
      const requests = await cache.keys();

      const deletePromises = requests
        .filter(request => request.url.includes(uid))
        .map(request => cache.delete(request));

      await Promise.all(deletePromises);
    }),
  );
};

export const removeDownloadedSongByUID = async (uid: string) => {
  const db = (await openDB()) as IDBDatabase;
  const transaction = db.transaction(['downloadedSongs'], 'readwrite');
  const store = transaction.objectStore('downloadedSongs');
  store.delete(uid);
  removeCachedAssetsByUID(uid);
};
