/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { store } from 'store';
import { LoginResponse } from 'store/@types/user';
import { setLogin } from 'store/user/actions';
import { setLSField } from 'utils/cookies';
import { DELETE, GET, PATCH, POST, PUT } from './requestTypes';
import axios from './services/api';

const reloadPage = () => {
  localStorage.removeItem('token');
  localStorage.removeItem('refreshToken');
  window.location.reload();
};

const refreshToken = async () => {
  const refreshTokenAux = localStorage.getItem('refreshToken');
  if (refreshTokenAux) {
    return axios
      .post<LoginResponse>('/auth/refresh', { refreshToken: refreshTokenAux })
      .then(async response => {
        const { data: dataRes } = response;
        if (dataRes) {
          setLSField('token', dataRes.access_token);
          setLSField('refreshToken', dataRes.refresh_token);
          store.dispatch(setLogin(dataRes));
          return true;
        }
        return false;
      })
      .catch(() => {
        reloadPage();
        return false;
      });
  }
  reloadPage();
  return false;
};

/** Axios request helper
 *
 * handles the GET POST PUT DELETE EXPORT methods
 * already with toast notification seamlessly with the API response
 * and with java error messages
 */
const restCall = async <T = never, R = AxiosResponse<T>>(
  type: number,
  path: string,
  reqPayload: any = undefined,
  config: AxiosRequestConfig<T> | undefined = undefined,
  getOriginalResponse = false,
): Promise<R> => {
  let data = null;
  const token = localStorage.getItem('token');
  const success = (response: AxiosResponse<T>) => {
    data = response;
    if (getOriginalResponse) {
      data = response;
    }
  };
  const error = (err: any) => {
    if (err.data.statusCode === 401 && window.location.href.indexOf('login') === -1 && !token) {
      reloadPage();
    } else {
      throw err;
    }
  };

  const REQUESTS = {
    [`${GET}`]: async () => axios.get<T>(`${path}`, reqPayload).then(success).catch(error),
    [`${PUT}`]: async () => axios.put<T>(`${path}`, reqPayload, config).then(success).catch(error),
    [`${POST}`]: async () => axios.post<T>(`${path}`, reqPayload, config).then(success).catch(error),
    [`${DELETE}`]: async () => axios.delete<T>(`${path}`, { data: { reqPayload } }).then(success).catch(error),
    [`${PATCH}`]: async () => axios.patch<T>(`${path}`, reqPayload, config).then(success).catch(error),
  };

  try {
    await REQUESTS[type]();
  } catch (err: any) {
    const errorData = err?.data;
    if ((errorData.statusCode === 401 || errorData.statusCode === 403) && path !== '/auth') {
      const tokenRes = await refreshToken();
      if (tokenRes) {
        await REQUESTS[type]();
      }
    }
    if (path === '/auth' || path === '/user' || path === '/user/google') {
      throw errorData;
    }
  }

  if (!data) {
    throw new Error('Api internal Error');
  }

  return data;
};

export { restCall };
