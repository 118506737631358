// EXTERNAL LIBS
import useResponsive from 'hooks/useResponsive';
import jwt from 'jwt-decode';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import TagManager from 'react-gtm-module';
import debounce from 'lodash.debounce';

import React, { FC, useEffect, useMemo, useState } from 'react';

// EXTERNAL LIBS --> MUI
import { Dialog } from '@mui/material';

// TYPES
import { StoreState } from 'store/@types';
import { ISubscription, LoginResponse, TokenPayload, UserProps } from 'store/@types/user';

// COMPONENTS | HOOKS | OTHERS
import { deleteUser, logout, payOrTrial, updatePlan } from 'store/user/thunks';
import { getCookie, setCookie, setLSField } from 'utils/cookies';

// LOCAL IMPORTS
import ExpiredTrial from 'components/Modals/ExpiredTrial';
import FirstLogin from 'components/Modals/FirstLogin';
import InvalidPaymentDetails from 'components/Modals/InvalidPaymentDetails';
import LockedAccount from 'components/Modals/LockedAccount';
import ThankYou from 'components/Modals/ThankYou';
import TrialOngoing from 'components/Modals/TrialOngoing';
import { useStyles } from './styles';

// COMPONENT
const AuthContext = React.createContext<AuthProps>({} as AuthProps);

const modalInit = {
  firstLogin: false,
  inTrial: null,
  endTrial: false,
  invalidPayment: false,
  lockedAccount: false,
  hoursLeft: null,
  paymentSuccess: false,
};

const getNumDays = (givenDate: string) => {
  // Get today's date
  const today = new Date();

  // Get the date for the given date
  const given = new Date(givenDate);

  // Calculate the difference in milliseconds
  const differenceInMilliseconds = Math.abs(today.getTime() - given.getTime());

  // Calculate the number of days by dividing the difference in milliseconds by the number of milliseconds in a day
  const numDays = Math.round(differenceInMilliseconds / (1000 * 60 * 60 * 24));

  return numDays;
};

const getNumHours = (givenDate: string) => {
  const today = new Date();
  const given = new Date(givenDate);
  const differenceInMilliseconds = Math.abs(today.getTime() - given.getTime());
  const numHours = Math.floor(differenceInMilliseconds / (1000 * 60 * 60));

  return numHours;
};

// FIXME: hardcoded prices
const priceMap = {
  price_1MFHLBB0ruFPWUHjlauQr2Iw: 59.88,
  price_1MFHLBB0ruFPWUHjrM7QjeYH: 6.39,
  price_1MFdOCB0ruFPWUHjgtiyKlfu: 5.99,
  price_1MFHt2B0ruFPWUHjB1o4HLPs: 5.99,
  price_1MFgwjB0ruFPWUHjTB2LhVwQ: 33.99,
  price_1MFgpHB0ruFPWUHjYmKbH4wC: 29.99,
  price_1MFI0SB0ruFPWUHjYxM1dAiI: 359.88,
  price_1MFHxGB0ruFPWUHjhc6H1dan: 33.99,
  price_1MpxFyB0ruFPWUHjpB8FtFIZ: 59.99,
  price_1Mpx5ZB0ruFPWUHjzwFAlEDK: 6.39,
  price_1MpxLfB0ruFPWUHjb3Tc2oCo: 33.99,
  price_1MH9KPB0ruFPWUHjvzsjYFzb: 359.88,
  price_1MZIxMB0ruFPWUHjlpQYCf2N: 5.99,
  price_1MXSlbB0ruFPWUHjcLoRHQvm: 58.68,
  price_1MZHnzB0ruFPWUHj67kRWOmI: 33.99,
  price_1MXR9JB0ruFPWUHj4CweD4HZ: 359.88,
};

const trackPurchase = debounce((subscription: ISubscription) => {
  const price = priceMap[subscription.priceId as keyof typeof priceMap] || 0;
  TagManager.dataLayer({
    dataLayer: {
      event: 'purchase',
      subscription,
      product: {
        value: price,
        currency: subscription.currency,
        items: [
          {
            item_id: subscription.priceId,
            item_name: subscription.type,
            price,
          },
        ],
      },
    },
  });
}, 500);

const trackTrial = debounce((subscription: ISubscription) => {
  const price = priceMap[subscription.priceId as keyof typeof priceMap] || 0;
  TagManager.dataLayer({
    dataLayer: {
      event: 'trial',
      subscription,
      product: {
        value: price,
        currency: subscription.currency,
        items: [
          {
            item_id: subscription.priceId,
            item_name: subscription.type,
            price,
          },
        ],
      },
    },
  });
}, 500);

const AuthProvider: FC<ReduxProps> = ({
  children,
  payOrTrial: payOrTrialThunks,
  logout: logOutThunks,
  deleteUser: deleteUserThunks,
  updatePlan: updatePlanThunks,
}) => {
  const classes = useStyles();

  const { isTablet } = useResponsive();
  const [auth, setAuth] = useState<TokenPayload>({} as TokenPayload);
  const [user, setUser] = useState<UserProps | null>(null);
  const [subModal, setSubModal] = useState<{
    firstLogin: boolean;
    paymentSuccess: boolean;
    inTrial: null | number;
    endTrial: boolean;
    invalidPayment: boolean;
    lockedAccount: boolean;
    hoursLeft: null | number;
  }>(modalInit);

  useEffect(() => {
    // setSubModal({
    //	...modalInit,
    //	firstLogin: true
    // });
    // return;
    if (!user) return;
    const { subscription, businessUser } = user;
    if (!subscription) return;
    const userResponsibleForPayment = !businessUser || businessUser?.role === 'app-admin';

    // app-manager / app-user in trial account, return bc they don't pay
    if (!userResponsibleForPayment && subscription.active) return;

    const { status, trialEnd } = subscription;

    /**	//* FIRST LOGIN
			validation:
				status = null
		*/

    const isDuringTrial = status === 'trialing';
    const paymentSuccess = window.location.search.includes('payment_success=true');
    if (paymentSuccess) {
      if (isDuringTrial) {
        trackTrial(subscription);
      } else {
        trackPurchase(subscription);
      }
      setSubModal({
        ...modalInit,
        paymentSuccess,
      });
      return;
    }

    const firstLogin = !status;

    if (firstLogin) {
      setSubModal({
        ...modalInit,
        firstLogin,
      });
      return;
    }

    const missingDays = (trialEnd && getNumDays(trialEnd)) || 0;
    const missingHours = (trialEnd && getNumHours(trialEnd)) || 0;
    const disableTrialModalDuringOneDay = getCookie('modalTrial');

    /**	//* ON GOING TRIAL
			validation:
				status === 'trialing'
		*/
    if (isDuringTrial && !disableTrialModalDuringOneDay) {
      setSubModal({
        ...modalInit,
        inTrial: missingDays,
        hoursLeft: missingHours,
      });
      return;
    }

    /**	//* EXPIRED TRIAL
			validation:
				status === 'canceled'  || 'past_due'
				userResponsibleForPayment
		*/
    const endTrial = userResponsibleForPayment && (status === 'canceled' || status === 'past_due');

    if (endTrial) {
      setSubModal({
        ...modalInit,
        endTrial,
      });
      return;
    }

    /**	//* ACCOUNT LOCKED
			validation:
				!( status === 'trialing'  || 'active')
				!userResponsibleForPayment
		*/

    const lockedAccount =
      (!userResponsibleForPayment && !(status === 'trialing' || status === 'active')) ||
      status === 'incomplete_expired';

    if (lockedAccount) {
      setSubModal({
        ...modalInit,
        lockedAccount,
      });
      return;
    }
    /**	//* INVALID PAYMENT
			validation:
				userResponsibleForPayment
				status === 'incomplete'  || 'incomplete_expired' || 'unpaid'
		*/
    const invalidPayment =
      userResponsibleForPayment && (status === 'incomplete' || status === 'incomplete_expired' || status === 'unpaid');

    if (invalidPayment) {
      setSubModal({
        ...modalInit,
        invalidPayment,
      });
    }
  }, [auth, user]);

  const setTokenData = (loginResponse: LoginResponse) => {
    setLSField('token', loginResponse.access_token);
    setLSField('refreshToken', loginResponse.refresh_token);
  };

  const setAuthHelper = (token: string | null) => {
    if (token) {
      const userInfo = jwt<TokenPayload>(token);
      const userRoles = userInfo?.realm_access?.roles || [];

      userInfo && setAuth({ ...userInfo, admin: userRoles.includes('app-super-user') });
    } else {
      setAuth({} as TokenPayload);
    }
  };

  const handleSubscribe = () => {
    setSubModal(modalInit);
    payOrTrialThunks(false, data => {
      if (data?.session?.url) window.location.href = data.session.url;
    });
  };
  const handleManageSubscription = () => {
    setSubModal(modalInit);
    updatePlanThunks(data => {
      if (data.session.url) {
        // eslint-disable-next-line no-restricted-globals
        if (data.session.url) open(data.session.url);
      }
    });
  };

  const handleTrial = () => {
    if (subModal.inTrial) {
      setCookie('modalTrial', 'noshow');
    }
    if (subModal.firstLogin) {
      payOrTrialThunks(true, data => {
        if (data?.session?.url) window.location.href = data.session.url;
      });
    }
    setSubModal(modalInit);
  };

  const handleSignout = () => {
    logOutThunks();
  };

  const handleDeleteUser = () => {
    deleteUserThunks(auth.sub, logOutThunks);
  };

  const handleFeedback = async () => {
    // eslint-disable-next-line no-console
    window.open('mailto:support@sines.app', '_blank');
  };

  const contextValue = useMemo<AuthProps>(
    () => ({
      auth,
      setAuth: setAuthHelper,
      setTokenData,
      user,
      setUser: (data: UserProps | null) => setUser(data),
    }),
    [auth, user],
  );

  return (
    <AuthContext.Provider value={contextValue}>
      <Dialog
        fullWidth
        fullScreen={isTablet}
        maxWidth="md"
        open={Boolean(
          subModal.paymentSuccess ||
            subModal.lockedAccount ||
            subModal.firstLogin ||
            Boolean(subModal.inTrial) ||
            subModal.endTrial,
        )}
        className={classes.dialog}
        onClose={() => {}}>
        <div className={classes.dialogBg}>
          {subModal.paymentSuccess && (
            <ThankYou
              handleSubscribe={() => {
                setCookie('modalTrial', 'noshow');
                const [newUrl] = window.location.href.split('?');
                window.location.href = newUrl;
              }}
            />
          )}
          {subModal.firstLogin && (
            <FirstLogin classes={classes} handleSubscribe={handleSubscribe} handleTrial={handleTrial} />
          )}
          {Boolean(subModal.inTrial) && (
            <TrialOngoing
              days={subModal.inTrial}
              hours={subModal.hoursLeft}
              classes={classes}
              handleSubscribe={handleSubscribe}
              handleTrial={handleTrial}
            />
          )}
          {subModal.endTrial && (
            <ExpiredTrial
              classes={classes}
              handleSubscribe={handleSubscribe}
              handleFeedback={handleFeedback}
              handleDeleteUser={handleDeleteUser}
            />
          )}
          {subModal.invalidPayment && (
            <InvalidPaymentDetails
              classes={classes}
              handleSubscribe={handleManageSubscription}
              handleDeleteUser={handleDeleteUser}
            />
          )}
          {subModal.lockedAccount && (
            <LockedAccount
              classes={classes}
              handleFeedback={handleFeedback}
              handleSignout={handleSignout}
              handleDeleteUser={handleDeleteUser}
            />
          )}
        </div>
      </Dialog>
      {children}
    </AuthContext.Provider>
  );
};

export interface AuthProps {
  auth: TokenPayload;
  setAuth: (token: string | null) => void;
  setTokenData: (loginResponse: LoginResponse) => void;
  user: UserProps | null;
  setUser: (data: UserProps | null) => void;
}

export default AuthContext;

// eslint-disable-next-line react/require-default-props, react/no-unused-prop-types
const mapStateToProps = ({ user }: StoreState) => ({ user });

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ payOrTrial, deleteUser, logout, updatePlan }, dispatch);

export type ReduxProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

export const ConnectedAuthProvider = connect(mapStateToProps, mapDispatchToProps)(AuthProvider);
