/* eslint-disable @typescript-eslint/no-explicit-any */
import { CustomReactTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { cardSizes } from 'utils/cards';

const repeat = (size: (string | number)[], unit = 'px') => `repeat(${size[0]}, ${size[1]}${unit || ''})`;

const initContainers = () => {
  let styles: any = {};
  const dados = [
    'recent',
    'recentList',
    'square',
    'squareList',
    'mood',
    'moodList',
    'searchPlaylist',
    'moodMenu',
    'type3',
    'searchItems',
  ];
  const res = ['default', 'xl', 'lg', 'md', 'sm'];

  res.forEach(resol => {
    styles[resol] = {};
    dados.forEach(item => {
      styles = {
        ...styles,
        [resol]: {
          ...styles[resol],
          [`${item}Container`]: {
            gridTemplateColumns: repeat((cardSizes as any)[item][resol]),
          },
        },
      };
    });
  });

  return styles;
};

const useStyles = makeStyles((theme: CustomReactTheme) => {
  const containers = initContainers();
  return {
    root: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    gridContainer: {
      width: '100%',
      display: 'grid',
      gridGap: '1rem',
      justifyContent: 'space-between',
      listStyle: 'none',
      padding: '0',
      margin: '0 auto',
    },
    ...containers.default,
    searchPlaylistContainer: {
      gridTemplateColumns: repeat(cardSizes.searchPlaylist.default, ''),
    },
    type3Container: {
      gridTemplateColumns: repeat(cardSizes.type3.default, ''),
    },
    searchItemsContainer: {
      gridTemplateColumns: repeat(cardSizes.searchItems.default, ''),
    },
    recentContainerHalf: {
      gridTemplateColumns: 'repeat(5, 112px)',
    },
    moodContainerHalf: {
      gridTemplateColumns: 'repeat(auto-fill, 200px)',
    },
    //* TABLET - landScape
    [theme.breakpoints.down('xl')]: {
      ...containers.xl,
      type3Container: {
        gridTemplateColumns: repeat(cardSizes.type3.xl, ''),
      },
      searchPlaylistContainer: {
        gridTemplateColumns: repeat(cardSizes.searchPlaylist.xl, ''),
      },
      searchItemsContainer: {
        gridTemplateColumns: repeat(cardSizes.searchItems.xl, ''),
      },
    },
    //* TABLET - landScape
    [theme.breakpoints.down('lg')]: {
      ...containers.lg,
      recentContainerHalf: {
        gridTemplateColumns: 'repeat(5, 112px)',
      },
      moodContainerHalf: {
        gridTemplateColumns: 'repeat(auto-fill, 192px)',
      },
      type3Container: {
        gridTemplateColumns: repeat(cardSizes.type3.lg, ''),
      },
      searchPlaylistContainer: {
        gridTemplateColumns: repeat(cardSizes.searchPlaylist.lg, ''),
      },
      searchItemsContainer: {
        gridTemplateColumns: repeat(cardSizes.searchItems.lg, ''),
      },
    },
    //* TABLET - portrait
    [theme.breakpoints.down('md')]: {
      ...containers.md,
      recentContainerHalf: {
        gridTemplateColumns: 'repeat(5, 106px)',
      },
      moodContainerHalf: {
        gridTemplateColumns: 'repeat(auto-fill, 170px)',
      },
      type3Container: {
        gridTemplateColumns: repeat(cardSizes.type3.md, ''),
      },
      searchPlaylistContainer: {
        gridTemplateColumns: repeat(cardSizes.searchPlaylist.md, ''),
      },
      searchItemsContainer: {
        gridTemplateColumns: repeat(cardSizes.searchItems.md, ''),
      },
    },
    //* MOBILE
    [theme.breakpoints.down('sm')]: {
      ...containers.sm,
      recentContainerHalf: {
        gridTemplateColumns: 'repeat(4, 93px)',
      },
      moodContainerHalf: {
        gridTemplateColumns: 'repeat(auto-fill, 150px)',
      },
      type3Container: {
        gridTemplateColumns: repeat(cardSizes.type3.sm, ''),
      },
      searchPlaylistContainer: {
        gridTemplateColumns: repeat(cardSizes.searchPlaylist.sm, ''),
      },
      searchItemsContainer: {
        gridTemplateColumns: repeat(cardSizes.searchItems.sm, ''),
      },
    },
  };
});

export { useStyles };
