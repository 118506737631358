import React from 'react';
import { Grid, Typography, Button } from '@mui/material';
import { ReactComponent as SinesLogo } from 'assets/imgs/sinesLogo.svg';
import { useTranslation } from 'react-i18next';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const FirstLogin = ({ classes, handleSubscribe, handleTrial }: any) => {
  const { t } = useTranslation();
  return (
    <Grid
      container
      spacing={2}
      direction="row"
      flexWrap="wrap"
      alignContent="flex-start"
      justifyContent="center"
      justifyItems="center"
      className={classes.dialogContainer}>
      <Grid item xs={12}>
        <SinesLogo />
      </Grid>
      <Grid item xs={12}>
        <Typography className={classes.title}>
          {`${t('tunedto')}` /* Tuned to <br /> Wellness! */}
          <br />
          {`${t('wellness')}` /* Tuned to <br /> Wellness! */}
        </Typography>
      </Grid>
      <Grid item xs={12} className={classes.descriptionGrid}>
        <Typography className={classes.subTitle}>
          {
            t(
              'firstlogin_subtitle',
            ) /* {We are pleased to provide you a fully-featured 7-day free trial so you may experience the power of SINES to
          help elevate your wellbeing.} */
          }
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Button variant="primary" className={classes.button1} onClick={handleSubscribe}>
          <span>
            {t('firstlogin_skiptrial')}
            {/* Skip free trial */}
          </span>
          <span style={{ fontWeight: '400' }}>
            {t('firstlogin_subscribe')}
            {/* Subscribe me right away */}
          </span>
        </Button>
      </Grid>
      <Grid item xs={12}>
        <Button variant="primary" className={classes.button2} onClick={handleTrial}>
          <span className={classes.buttonSpan1} />
          <span className={classes.buttonSpan2}>
            {t('firstlogin_start7day')}
            {/* Start 7-day free trial */}
          </span>
        </Button>
      </Grid>
    </Grid>
  );
};

export default FirstLogin;
