import { CustomReactTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: CustomReactTheme) => ({
  card: {
    background: 'transparent',
    boxShadow: 'none',
    width: '100%',
    minWidth: '280px',
    // maxWidth: '310px',
    height: '71px',
  },
  rectangle: {
    gap: '10px',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexGrow: 1,
  },
  square: {
    borderRadius: '20px',
    boxShadow: '0 0 10px 0 rgba(31, 31, 31, 0.5)',
    width: '71px',
    height: '71px',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
  },
  skeletonBars: {
    borderRadius: '20px',
    margin: '5px 0',
    transform: 'none',
  },
  title: {
    color: '#F4F4F4',
    fontSize: '14px',
    lineHeight: '17px',
  },
  author: {
    color: '#B3B3B3;',
    fontSize: '14px',
    lineHeight: '17px',
  },
  clickableMenu: {
    width: '100%',
    height: '100%',
    borderRadius: '20px',
  },
  //* TABLET - landScape
  [theme.breakpoints.down('lg')]: {
    // card: {
    // 	maxWidth: '370px'
    // },
    // playButton: {
    // 	marginLeft: '280px'
    // }
  },
  //* TABLET - portrait
  [theme.breakpoints.down('md')]: {},
  //* MOBILE
  [theme.breakpoints.down('sm')]: {},
}));

export { useStyles };
