import { ApiError } from 'utils/services/api';

export enum MoodsActions {
  SET_LOADING = '@@Moods/SET_LOADING',
  SET_LOADING_SPACES = '@@Moods/SET_LOADING_SPACES',
  SET_LOADING_MOODS = '@@Moods/SET_LOADING_MOODS',
  SET_LOADING_GUIDED = '@@Moods/SET_LOADING_GUIDED',
  SET_SEARCH_LOADING = '@@Moods/SET_SEARCH_LOADING',
  SET_ERROR = '@@Moods/SET_ERROR',
  SET_MOODS = '@@Moods/SET_MOODS',
  SET_SPACES = '@@Moods/SET_SPACES',
  SET_GUIDED = '@@Moods/SET_GUIDED',
  SET_CATEGORY_PLAYLISTS = '@@Moods/SET_CATEGORY_PLAYLISTS',
  SET_PLAYLIST_DETAILS = '@@Moods/SET_PLAYLIST_DETAILS',
  SET_FAVORITE_ALBUMS = '@@Moods/SET_FAVORITE_ALBUMS',
  SET_FAVORITE_PLAYLISTS = '@@Moods/SET_FAVORITE_PLAYLISTS',
  SET_FAVORITE_TRACKS = '@@Moods/SET_FAVORITE_TRACKS',
  SET_NEW_FAVORITE_TRACK = '@@Moods/SET_NEW_FAVORITE_TRACK',
  SET_NEW_FAVORITE_PLAYLIST = '@@Moods/SET_NEW_FAVORITE_PLAYLIST',
  SET_ALL_USER_FAVORITES = '@@Moods/SET_ALL_USER_FAVORITES',
  SET_SEARCH = '@@Moods/SET_SEARCH',
}
export type MoodsState = {
  readonly loading: boolean;
  readonly loadingSpaces: boolean;
  readonly loadingMoods: boolean;
  readonly loadingGuided: boolean;
  readonly searchLoading: boolean;
  readonly error: ApiError | null;
  readonly moodsAction: null;
  readonly moodsList: Category[] | null;
  readonly spacesList: Category[] | null;
  readonly guidedList: Category[] | null;
  readonly playlistDetails: { [plsylistId: string]: Playlist };
  readonly categoryPlaylist: { [plsylistId: string]: Category };
  readonly paginationMeta: PaginationMeta | null;
  readonly favoritesTracks: UserFavTracksPayload | null;
  readonly favoritesAlbums: UserFavPlaylistsPayload | null;
  readonly favoritesPlaylists: UserFavPlaylistsPayload | null;
  readonly favorites: { tracks: ExistingFavs[]; playlists: ExistingFavs[] } | null;
  readonly searchResults: SearchResults | null;
};

interface ExistingFavs {
  id: string;
  name: string;
}

export interface PaginationMeta {
  currentPage: number;
  itemCount: number;
  itemsPerPage: number;
  totalItems: number;
  totalPages: number;
}

export interface Playlist {
  id: string;
  name: string;
  type: string;
  imagePath: string | null;
  smallImagePath: string | null;
  createdAt: string;
  updatedAt: string;
  duration: number;
  isSingleSong?: boolean;
  artist?: Artist;
  tracks: Track[];
  originalList: Track[];
  image: string | null;
  smallImage: string | null;
  uri: string;
  uriPath: string;
  favoritesPlaylist?: boolean;
}

export interface RecentPlaylist {
  id: string;
}

export interface Artist {
  id: string;
  name: string;
  imagePath: string | null;
  smallImagePath: string | null;
  image: string;
  smallImage: string;
}

export interface TrackOrder {
  order: number;
  playlistId: string;
  track: Track;
}

export interface Track {
  artist?: Artist;
  index?: number;
  playlistInfo?: Playlist;
  playlistId?: string;
  id: string;
  name: string;
  duration: number;
  createdAt: string;
  updatedAt: string;
  image: string | null;
  imagePath: string | null;
  smallImage: string | null;
  smallImagePath: string | null;
  uri: string;
  uriPath: string;
}

export interface Category {
  id: string;
  name: string;
  subtitle: string | null;
  imagePath: string | null;
  smallImagePath: string | null;
  createdAt: string;
  updatedAt: string;
  playlists: Playlist[];
  children: [];
  image: string;
  smallImage: string;
}

export interface UserFavTracksItemPayload {
  userId: string;
  trackId: string;
  createdAt: string;
  updatedAt: string;
  track: Track;
}

export interface UserFavTracksPayload {
  items: UserFavTracksItemPayload[];
}

export interface UserFavPlaylistsItemPayload {
  userId: string;
  playlistId: string;
  createdAt: string;
  updatedAt: string;
  playlist: Playlist;
}

export interface UserFavPlaylistsPayload {
  items: UserFavPlaylistsItemPayload[];
}

export interface SearchResults {
  albums: {
    items: Playlist[];
  };
  playlists: {
    items: Playlist[];
  };
}
