/* eslint-disable react/require-default-props */
import React, { FC } from 'react';
import { Card, CardActionArea, CardMedia, IconButton, Typography } from '@mui/material';
import { ReactComponent as PlayButton } from 'assets/imgs/PlayButton.svg';
import { ReactComponent as PauseButton } from 'assets/imgs/PauseButton.svg';

import FallbackImageSvg from 'assets/imgs/missing_image_medium.svg';
import useMusicPlayer from 'hooks/useMusicPlayer';
import { Category, Playlist, Track, UserFavTracksItemPayload, UserFavTracksPayload } from 'store/@types/moods';
import { UserHistory } from 'store/@types/user';
import { useLocation } from 'react-router-dom';
import { useStyles } from './styles';

export interface Type3CardProps {
  item: UserHistory;
  favoriteList: UserFavTracksPayload | null | undefined;
  moodsList?: Category[] | null | undefined;
  guidedList?: Category[] | null | undefined;
}

const Type3Card: FC<Type3CardProps> = ({ item, favoriteList, moodsList, guidedList }) => {
  const location = useLocation();
  const classes = useStyles();
  const [imageSrc, setImageSrc] = React.useState(item.track?.image || FallbackImageSvg);

  const {
    setIsPlaylist,
    setAlbumView,
    playAlbumFromSong,
    playFavoritesFromSong,
    playingList,
    currentSong,
    pause,
    playing,
    play,
  } = useMusicPlayer();
  let foundPlaylist: Playlist;

  const findPlaylistInList = (searchingList: Category[] | null | undefined, searchItem: Track | undefined) => {
    if (searchingList && searchItem) {
      return searchingList.some(category =>
        category.playlists.some(playlist => {
          const trackIsInPlaylist = playlist.tracks.some(track => track.id === searchItem.id);
          if (trackIsInPlaylist) {
            foundPlaylist = playlist;
          }
          return trackIsInPlaylist;
        }),
      );
    }
    return false;
  };

  findPlaylistInList(moodsList, item.track);
  findPlaylistInList(guidedList, item.track);

  const clickHandler = (): void => {
    if (!item) return;
    if (foundPlaylist) {
      setAlbumView(foundPlaylist, false);
    } else {
      setAlbumView(item.track as unknown as Playlist, true);
    }
    setIsPlaylist('open');
  };

  const clickHandlerPlay = (): void => {
    if (currentSong?.id === item.track.id && playing) {
      pause();
    } else if (currentSong?.id === item.track.id && !playing) {
      play();
    } else if (!playingList || playingList.id !== item.track.id) {
      if (location.pathname === '/library' && favoriteList && favoriteList.items) {
        const listAux = favoriteList.items
          .map((favSong: UserFavTracksItemPayload) => {
            if (typeof favSong === 'object' && favSong !== null) {
              return favSong.track;
            }
            return null;
          })
          .filter((nullFavSong: Track | null) => nullFavSong !== null) as Track[];

        playFavoritesFromSong(item.track, listAux, true);
      } else if (location.pathname === '/home' && item.playlist) {
        playFavoritesFromSong(item.track, foundPlaylist.tracks, false);
      } else {
        playAlbumFromSong(item.track, true);
      }
    }
  };

  return (
    <Card className={classes.card}>
      <CardActionArea className={classes.rectangle} onClick={clickHandler}>
        <CardMedia
          className={classes.square}
          component="img"
          height="71"
          width="71"
          image={imageSrc}
          onError={() => setImageSrc(FallbackImageSvg)}
          alt="item.imagePath"
        />
        <div style={{ width: '100%' }}>
          <Typography className={classes.title}>{item.track?.name}</Typography>
          <Typography className={classes.author}>{item.track.artist?.name}</Typography>
        </div>
      </CardActionArea>
      <IconButton className={classes.playButton} onClick={clickHandlerPlay}>
        {currentSong?.id === item.track.id && playing ? <PauseButton /> : <PlayButton />}
      </IconButton>
    </Card>
  );
};

export default Type3Card;
