import * as yup from 'yup';

import { stringRequired, passwordSecure, regex } from 'utils/formUtils';

export const schema = yup.object().shape({
  name: stringRequired.required('Please enter your name'),
  email: stringRequired.required('Please enter your email address').matches(regex.email, 'Invalid e-mail.'),
  username: stringRequired.required('Please enter a username').min(4),
  password: passwordSecure,
  password2: stringRequired.oneOf([yup.ref('password'), null], "Passwords don't match"),
  terms: yup.boolean().oneOf([true], 'Please accept the terms and conditions'),
  policy: yup.boolean().oneOf([true], 'Please accept the privacy policy'),
});

export const defaultValues = {
  name: '',
  email: '',
  username: '',
  password: '',
  terms: false,
  policy: false,
};
