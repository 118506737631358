import React, { FC, useEffect } from 'react';
// * 3rd party libs
// import { useNavigate } from 'react-router-dom';

import { Grid, Typography } from '@mui/material';
// * ASSETS
import { ReactComponent as NoData } from 'assets/imgs/library_empty.svg';

// * COMPONENTS
import HorizontalList from 'components/HorizontalList';
import useResponsive from 'hooks/useResponsive';

// * TYPES
import { useTranslation } from 'react-i18next';
// * LOCAL IMPORTS
import Loading from 'components/Loading';
import { useStyles } from './styles';
import { ReduxProps } from '.';

const Library: FC<ReduxProps> = ({ moods, getFavSongs, getFavAlbums, getFavPlaylists }) => {
  const classes = useStyles();

  const { loading, error, favoritesTracks, favoritesAlbums, favoritesPlaylists } = moods;

  const { reduceItemsInList } = useResponsive();

  const { t } = useTranslation();

  useEffect(
    () => () => {
      getFavSongs(true);
      getFavAlbums(true);
      getFavPlaylists(true);
    },
    [],
  );

  useEffect(() => {
    if (!favoritesTracks && !error) {
      getFavSongs();
    }
  }, [favoritesTracks]);

  useEffect(() => {
    if (!favoritesAlbums && !error) {
      getFavAlbums();
    }
  }, [favoritesAlbums]);

  useEffect(() => {
    if (!favoritesPlaylists && !error) {
      getFavPlaylists();
    }
  }, [favoritesPlaylists]);

  const noData =
    favoritesTracks?.items?.length === 0 &&
    favoritesAlbums?.items?.length === 0 &&
    favoritesPlaylists?.items?.length === 0;

  return (
    <>
      {loading && <Loading />}
      <Grid
        container
        spacing={2}
        direction="row"
        justifyContent="flex-start"
        alignItems="flex-start"
        className={classes.root}>
        {noData && (
          <Grid item xs={12} className={classes.searchNotFound}>
            <NoData />
            <Typography
              variant="listAuthor"
              sx={{
                textAlign: 'center',
              }}>
              {t('library_empty') /* Your library is empty. */}
              <br />
              <br />
              {t('library_addingfavorites') /* When you start adding favorites they will show */}
              <br />
              {t('library_uphere') /* up here! */}!
            </Typography>
          </Grid>
        )}
        {favoritesTracks?.items?.length !== 0 && (
          <HorizontalList
            title={t('library_favoritetracks')}
            // "Your favorite tracks"
            list={reduceItemsInList(favoritesTracks?.items.map(e => ({ ...e, id: e.track.id })) || [], 'type3')}
            type="type3"
            favoriteList={favoritesTracks}
          />
        )}

        {favoritesAlbums?.items && favoritesAlbums?.items.length >= 0 && (
          <HorizontalList
            halfSize
            title={t('library_yourfavalbums')}
            // "Your favorite albums"
            // rightAction="See all"
            list={favoritesAlbums?.items.map(e => e.playlist) || []}
            type="recentList"
          />
        )}

        {favoritesPlaylists?.items && favoritesPlaylists?.items.length >= 0 && (
          <HorizontalList
            halfSize
            title={t('library_yourfavplaylists')}
            // "Your favorite playlists"
            // rightAction="See all"
            list={favoritesPlaylists?.items.map(e => e.playlist) || []}
            type="recentList"
          />
        )}
        {/* <HorizontalList
						title="Guided Meditations"
						// rightAction="See all"
						list={reduceItemsInList(data.guidedMeditations, 'mood')}
						type="mood"
					/>
					<HorizontalList
						title="Moods tailored for you"
						// rightAction="See all"
						list={reduceItemsInList(data.guidedMeditations.slice(4, 7), 'mood')}
						type="mood"
					/> */}
      </Grid>
    </>
  );
};

export default Library;
