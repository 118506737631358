import * as yup from 'yup';

import { numberPositiveRequired } from 'utils/formUtils';

export const schema = (availableDevices: number) =>
  yup.object().shape({
    zones: numberPositiveRequired.max(availableDevices, 'Max number of zones reached.'),
  });

export const defaultValues = {
  zones: 1,
};
