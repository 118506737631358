import React from 'react';

const SVG = ({ disabled }: { disabled: boolean }) => (
  <svg
    width="26px"
    height="26px"
    viewBox="0 0 26 26"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        transform="translate(4.073344, 5.053197)"
        stroke={disabled ? '#8d949a' : '#FFFFFF'}
        strokeDasharray="0,0"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5">
        <path
          d="M0,13.1359663 C0,15.2592997 2.3074854,16.5917904 4.14915212,15.5301237 L8.64497884,12.9409412 L13.1408386,10.3409478 C14.9825053,9.2792811 14.9825053,6.62511102 13.1408386,5.56344434 L8.64497884,2.96345095 L4.14915212,0.374301409 C2.3074854,-0.687365279 0,0.634292185 0,2.76845888 L0,13.1359663 Z"
          id="Vector"
          fillRule="nonzero"
        />
        <line x1="17.8535767" y1="14.6417948" x2="17.8535767" y2="1.25181071" id="Vector" />
      </g>
      <path
        d="M26,0 L26,26 L0,26 L0,0 L26,0 Z"
        id="Vector"
        opacity="0"
        transform="translate(13.000000, 13.000000) rotate(-180.000000) translate(-13.000000, -13.000000) "
      />
    </g>
  </svg>
);

export default SVG;
