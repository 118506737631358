import React, { FC, useState, useEffect } from 'react';
// * 3rd party libs

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import { Box, Grid, Typography } from '@mui/material';

// * ASSETS
import { notifications } from 'components/Page';
// * TYPES

import { ISubscription } from 'store/@types/user';

// * COMPONENTS
import TabPanel from 'components/TabPanel';
import { useTranslation } from 'react-i18next';
// * LOCAL IMPORTS
import Loading from 'components/Loading';
import useAuth from 'hooks/useAuth';
import useResponsive from 'hooks/useResponsive';
import { UserBusiness } from 'store/@types/b2b';
import { toast } from 'react-toastify';
import BusinessInformation from './TabViews/BusinessInformation/BusinessInformation';
import SubscriptionBilling from './TabViews/SubscriptionBilling/SubscriptionBilling';

import TeamManagement from './TabViews/TeamManagement';
import { useStyles } from './styles';
import { ReduxProps } from '.';

const CustomerPanel: FC<ReduxProps> = ({
  b2b,
  user: reduxUser,
  refreshToken,
  updatePlan,
  getBusinessInfo,
  updateBusinessInfo,
}) => {
  const { loading, error, userBusiness } = b2b;
  const { loading: userLoading } = reduxUser;
  const classes = useStyles();
  const [tabSelected, setTabSelected] = useState(0);
  const { user } = useAuth();
  const { isMobile } = useResponsive();

  const { t } = useTranslation();

  useEffect(() => {
    if (!userBusiness && !error && !loading) {
      getBusinessInfo();
    }
  }, [userBusiness]);

  const handleTabChanged = (event: React.SyntheticEvent, newValue: number): void => {
    setTabSelected(newValue);
  };

  const buisinessTabLabel = isMobile ? (
    <>
      {t('Business') /* Business */}
      <br />
      {t('information') /* information */}
    </>
  ) : (
    `${t('Business')} ${t('information')}`
    // 'Business information'
  );

  const teamManagementTanLabel = isMobile ? (
    <>
      {user?.subscription?.type === 'locations' ? t('Zone') : t('Team')}
      <br />
      {t('management') /* management */}
    </>
  ) : (
    `${user?.subscription?.type === 'locations' ? t('Zone') : t('Team')} ${t('management')}` // `${user?.subscription?.type === 'locations' ? 'Zone' : 'Team'} management`
  );

  const subscriptionTabLabel = isMobile ? (
    <>
      {t('Subscription') /* Subscription */}
      <br />
      &#38;
      {t('Billing') /* Billing */}
    </>
  ) : (
    // 'Subscription & Billing'
    `${t('Subscription')} & ${t('Billing')}`
  );

  return (
    <>
      {(loading || userLoading) && <Loading />}
      <Grid container justifyContent="flex-start" alignItems="flex-start" flexDirection="row" className={classes.root}>
        <Grid item xs={12} className={classes.navTabWrapper}>
          <Box className={classes.tabsButtonsContainer}>
            <Tabs
              sx={{ borderRadius: '25px' }}
              value={tabSelected}
              onChange={handleTabChanged}
              aria-label="customerPanelTabs">
              <Tab wrapped className={classes.tabsButtons} label={buisinessTabLabel} />
              <Tab wrapped className={classes.tabsButtons} label={teamManagementTanLabel} />
              <Tab wrapped className={classes.tabsButtons} label={subscriptionTabLabel} />
            </Tabs>
          </Box>
        </Grid>
        <Grid item xs={12}>
          {/* BUSINESS INFORMATION */}
          <TabPanel value={tabSelected} index={0}>
            <BusinessInformation
              userBusiness={userBusiness}
              updateBusinessInfo={async data => {
                await updateBusinessInfo(data, (res?: UserBusiness) => {
                  if (res) {
                    toast('Business information successfully updated.', {
                      ...notifications.success,
                      toastId: 'updateBusinessInfo',
                    });
                  } else {
                    toast('Something went wrong, please try again.', {
                      ...notifications.success,
                      toastId: 'updateBusinessInfoError',
                    });
                  }
                });
                await refreshToken();
              }}
            />
          </TabPanel>
          {/* TEAM MANAGEMENT */}
          <TabPanel value={tabSelected} index={1}>
            <TeamManagement
              plan={user?.subscription as ISubscription}
              maxDevices={user?.subscription.devices}
              userBusiness={userBusiness}
            />
          </TabPanel>
          {/* SUBSCRIPTION & BILLING */}
          <TabPanel value={tabSelected} index={2}>
            <SubscriptionBilling
              plan={user?.subscription as ISubscription}
              userBusiness={userBusiness}
              updatePlan={updatePlan}
            />
          </TabPanel>
        </Grid>
      </Grid>
    </>
  );
};

export default CustomerPanel;
