import { Dispatch, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { StoreState } from 'store/@types';
import { deleteUser, logout, updatePlan } from 'store/user/thunks';

import { redirect } from 'react-router-dom';
import { getLSField } from 'utils/cookies';
import DefaultComponent from './UserMenuPage';

const mapStateToProps = ({ user }: StoreState) => ({ user });

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({ deleteUser, logout, updatePlan }, dispatch);

export type ReduxProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(DefaultComponent);

export async function loader() {
  const userToken = getLSField('token');
  if (userToken) {
    return redirect('/home');
  }
  return null;
}
