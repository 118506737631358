import { FC, ReactNode, useEffect, useMemo, useState } from 'react';
// * 3rd party libs
import { Box, Button, Dialog, IconButton, ListItemIcon, ListItemText, MenuItem, Typography } from '@mui/material';
import clsx from 'clsx';
import { useNavigate } from 'react-router-dom';

// * ASSETS
import QRCode from 'assets/imgs/QRCode.png';
import AppStore from 'assets/imgs/appStore.png';
import { ReactComponent as BackChevron } from 'assets/imgs/backchevron.svg';
import { ReactComponent as CloseMenu } from 'assets/imgs/closemenu.svg';
import ProfileUserPhoto from 'assets/imgs/profile_userphoto.png';
import { ReactComponent as SeeMore } from 'assets/imgs/seemore.svg';
import { ReactComponent as SinesLogoMix } from 'assets/imgs/sinesLogoMix.svg';

import { ReactComponent as SocialFacebook } from 'assets/imgs/social_failbook.svg';
import { ReactComponent as SocialInsta } from 'assets/imgs/social_instagram_final.svg';
import { ReactComponent as SocialSite } from 'assets/imgs/social_site.svg';
import { ReactComponent as SocialTwitter } from 'assets/imgs/social_twitter.svg';
import LanguagePicker from 'components/LanguagePicker';
// * COMPONENTS
import { envConfig } from 'config/config';
import useAuth from 'hooks/useAuth';
import useResponsive from 'hooks/useResponsive';
// * TYPES
import { useLanguage } from 'context/LanguageProvider';

// * LOCAL IMPORTS
import ConfirmActionModal from 'components/ConfirmActionModal';
import DeleteBody from 'components/DeleteBody';
import Scrollbars from 'components/Scrollbars';
import { useTranslation } from 'react-i18next';
import { CssTextField } from 'components/forms/Input';
import { capitalizeFirstLetter } from 'utils/utils';
import { ReduxProps } from '.';
import FaqsPage from './faqs/index';
import { useStyles } from './styles';

export interface IUserMenuProps extends ReduxProps {
  setModalOpen: (value: string | null) => void;
  activeItem: string;
}
const defaultProps = {};

export const menuItem = (
  title: string,
  {
    action,
    width,
    backIcon,
    ...others
  }: { action?: () => void; width?: '85%' | string; backIcon?: boolean; [other: string]: unknown },
) => (
  <MenuItem
    sx={{ width, minHeight: '60px', marginBottom: '10px', ...others, whiteSpace: 'normal', textAlign: 'left' }}
    onClick={action}>
    {backIcon && (
      <ListItemIcon>
        <BackChevron />
      </ListItemIcon>
    )}
    <ListItemText>{title}</ListItemText>
    {!backIcon && (
      <Typography variant="body2" color="text.secondary">
        <SeeMore />
      </Typography>
    )}
  </MenuItem>
);

const UserMenu: FC<IUserMenuProps> = ({ setModalOpen, activeItem, deleteUser, logout, updatePlan }) => {
  const { isMobile } = useResponsive();
  const { auth, user } = useAuth();

  const { t } = useTranslation();
  const classes = useStyles();
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [contact, setContact] = useState('');
  const [description, setDescription] = useState('');

  const { language, changeLanguage } = useLanguage();

  const navigate = useNavigate();
  useEffect(() => {
    if (!isMobile && activeItem === 'menu') {
      setModalOpen('profile');
    }
  }, [isMobile]);

  const faqDetailsBackMenuItem = menuItem(
    t('FAQs'), // "FAQ's"
    {
      action: () => setModalOpen('faq'),
      backIcon: true,
    },
  );

  const mobileBackMenu =
    isMobile &&
    menuItem(
      t('Menu'), // 'Menu'
      {
        action: () => setModalOpen('menu'),
        backIcon: true,
        width: '100%',
      },
    );

  const handleDeleteUser = async () => {
    setConfirmDelete(true);
    deleteUser(auth.sub, logout);
  };

  const pages: { [item: string]: ReactNode } = useMemo(
    () => ({
      menu: (
        <span className={classes.faqsContainer}>
          {menuItem(
            t('Profile'), // 'Profile'
            {
              action: () => setModalOpen('profile'),
            },
          )}
          {menuItem(
            t('Guidebook'), // 'Guidebook'
            {
              action: () => setModalOpen('guidebook'),
            },
          )}
          {menuItem(
            t('Our Ethos'), // 'Our Ethos'
            {
              action: () => setModalOpen('ethos'),
            },
          )}
          {menuItem(
            t('FAQs'), // "FAQ's"
            {
              action: () => setModalOpen('faq'),
            },
          )}
          {menuItem(
            t('SupportandFeedback'), // 'Support & Feedback'
            {
              // eslint-disable-next-line no-restricted-globals
              action: () => open('mailto:support@sines.app', '_blank'),
            },
          )}
          <MenuItem
            className={clsx(classes.menuItem, {
              [classes.windowItem]: window,
            })}
            onClick={logout}>
            {t('Signout') /* Sign out */}
          </MenuItem>
          <MenuItem
            disabled
            className={clsx(classes.menuItem, {
              [classes.activeItem]: activeItem === 'contact',
              [classes.windowItem]: window,
            })}>
            {t('Stayintouch') /* Stay in touch */}
          </MenuItem>
          <span
            style={{
              ...(window && {
                display: 'flex',
                justifyContent: 'flex-start',
              }),
            }}>
            <IconButton
              href="https://www.facebook.com/sinesglobal/"
              target="_blank"
              style={{ marginRight: '5px', marginLeft: '5px' }}
              onClick={() => {}}>
              <SocialFacebook />
            </IconButton>
            <IconButton
              href="https://twitter.com/sines_app"
              target="_blank"
              style={{ marginRight: '5px', marginLeft: '5px' }}
              onClick={() => {}}>
              <SocialTwitter />
            </IconButton>
            <IconButton
              href="https://www.instagram.com/sines.app/"
              target="_blank"
              style={{ marginRight: '5px', marginLeft: '5px' }}
              onClick={() => {}}>
              <SocialInsta />
            </IconButton>
            <IconButton
              href={envConfig.REACT_APP_WEBSITE_URL}
              target="_blank"
              style={{ marginRight: '5px', marginLeft: '5px' }}
              onClick={() => {}}>
              <SocialSite />
            </IconButton>
            <LanguagePicker />
          </span>
          <Box className={classes.qrCodeWrapper}>
            <img src={QRCode} style={{ width: 64, height: 64 }} alt="qr-code" />
            <a
              href="https://apps.apple.com/app/sines-tuned-to-wellness/id6443797659"
              target="_blank"
              rel="noreferrer"
              className={classes.appStore}>
              <img src={AppStore} style={{ height: '100%', width: '100%' }} alt="app-store" />
            </a>
            {/* <Button
              onClick={() => changeLanguage('en')}
              startIcon={<img src={flagUK} alt="UK Flag" style={{ width: 24, height: 'auto' }} />}
            />
            <Button
              onClick={() => changeLanguage('es')}
              startIcon={<img src={flagES} alt="UK Flag" style={{ width: 24, height: 'auto' }} />}
            /> */}
          </Box>
        </span>
      ),
      profile: (
        <span className={classes.faqsContainer} style={{ alignItems: 'center' }}>
          {mobileBackMenu}
          <img src={ProfileUserPhoto} style={{ width: 142, height: 142 }} alt="success-img" />
          <CssTextField
            fullWidth
            inputProps={{ min: 0, style: { textAlign: 'center' } }}
            sx={{ maxWidth: '350px', color: 'white', textAlign: 'center' }}
            value={user?.name || 'Display Name'}
          />
          <CssTextField
            fullWidth
            inputProps={{ min: 0, style: { textAlign: 'center' } }}
            sx={{ maxWidth: '350px', color: 'white', textAlign: 'center' }}
            value={user?.email || 'Email'}
          />
          <span>
            <Typography variant="listAuthor" sx={{ marginLeft: '15px', width: '350px' }}>
              {t('Yourplan') /* Your plan: */}
            </Typography>
            {(!user?.businessUser || user?.businessUser.role === 'app-admin') &&
              menuItem(capitalizeFirstLetter(user?.subscription?.type || 'Your Subscription'), {
                width: '350px',
                action: () => {
                  updatePlan(data => {
                    if (data.session.url) {
                      // eslint-disable-next-line no-restricted-globals
                      if (data.session.url) open(data.session.url);
                    }
                  });
                },
              })}
            {user?.businessUser && user?.businessUser.role === 'app-manager' && (
              <Typography style={{ width: '350px', padding: '6px 16px' }}>
                {capitalizeFirstLetter(user?.subscription?.type || 'Your Subscription')}
              </Typography>
            )}
            {/* {menuItem('Password reset', { width: '350px' })} */}
            {menuItem(
              t('Deleteyouraccount'), // 'Delete your account'
              {
                width: '350px',
                action: () => setConfirmDelete(true),
                marginTop: '50px',
                color: '#B66481',
              },
            )}
          </span>
        </span>
      ),
      guidebook: (
        <span className={classes.faqsContainer}>
          {mobileBackMenu}
          <Typography className={classes.guidebookTypo}>
            {
              t(
                'guidebook_line_1',
              ) /* We are here to elevate and support wellness in your life and business. The way to understand SINES is to
            understand how you, your loved ones or your client currently feel and then connect that to your/their
            desired feeling ie: depression seeks joy, memory challenges need focus, anxiety needs balance. */
            }
            <br />
            <br />
            {t('guidebook_line_2') /* Consistency is key in establishing a practice of harmony within the body. */}
            <br />
            <br />
            {
              t(
                'guidebook_line_3',
              ) /* While the feeling is immediate when listening, we suggest a consistent 30 day practice to establish a
            foundation. From there feel free to integrate us into your daily life—while you meditate, work, work-out,
            commute and more. We suggest sleeping every night to our sleep tracks on a very low volume. Even if you
            already sleep well, you may find you awaken more rested and more energized while allowing SINES to harmonize
            during the night. */
            }
            <br />
            <br />
            <b>{t('guidebook_line_4') /* Live your truth and live joyful. May all beings know peace. */}</b>
            <br />
          </Typography>
        </span>
      ),
      ethos: (
        <span className={classes.faqsContainer}>
          {mobileBackMenu}
          <Typography className={classes.ethosTypo}>
            {
              t(
                'ethos_line_1',
              ) /* Our music and visuals are based on studies by leading neuroscientists and doctors in sound, color + light
            therapy with brainwave synchronization. */
            }
            <br />
            <br />
            <br />
            {
              t(
                'ethos_line_2',
              ) /* All of our music and visuals are created by our founder and a tight-knit collective of music
            producers and artists with decades of experience in the science, metaphysics, tunings + resonances of music
            and visuals aligned with mental health, wellness, and performance optimization. */
            }
            <br />
            <br />
            <br />
            {
              t(
                'ethos_line_3',
              ) /* We create our sounds and textures from the finest analog and acoustic instruments we’ve collected
            from our world travels along with resonances, nature sounds, and natural visuals we’ve recorded from special
            sites and sanctuaries around the world. */
            }
            <br />
            <br />
            <br />
            {
              t(
                'ethos_line_4',
              ) /* Our color + light-based visuals are created with practices aligned with phototherapy, chromotherapy
            and EMDR. */
            }
            <br />
            <br />
            <br />
            {
              t(
                'ethos_line_5',
              ) /* Every creation is made with the deepest care and intention in the highest standards of music + visual
            production quality and wellness practices. */
            }
            <br />
            <br />
            <br />
            {
              t(
                'ethos_line_6',
              ) /* Our teachers and guides are certified and trained in all aspects of their expertise for trauma,
            wellness, healing, and support. Many of our teachers hold doctorates in general medicine, metaphysics,
            chiropractic, psychology, psychiatry, and therapy. Anyone lending their voice in guidance for others have
            all personally experienced the events they teach in their own lives. */
            }
          </Typography>
        </span>
      ),
      support: (
        <span className={classes.faqsContainer} style={{ alignItems: 'center' }}>
          {mobileBackMenu}
          <Typography variant="listAuthor" sx={{ marginLeft: '15px', width: '350px' }}>
            {t('support_question_1') /* How can we help you? Please choose the most suitable subject below. */}
          </Typography>
          <CssTextField
            fullWidth
            sx={{ maxWidth: '350px' }}
            value={contact}
            onChange={e => setContact(e.target.value)}
            placeholder={t('contact_subject')} // "Contact subject"
          />
          <CssTextField
            fullWidth
            sx={{ maxWidth: '350px' }}
            multiline
            minRows={12}
            maxRows={12}
            value={description}
            onChange={e => setDescription(e.target.value)}
            placeholder={t('support_placeholder')} // "Please describe your issue or feedback. Our team will do their best to reply as briefly as possible."
          />
          <Button
            variant={(contact.length > 0 && description.length > 0 && 'primary') || 'disabled'}
            fullWidth
            sx={{ maxWidth: '350px' }}>
            {t('Sendform') /* Send form */}
          </Button>
        </span>
      ),
      faq: <FaqsPage mobileBackMenu={mobileBackMenu} setModalOpen={setModalOpen} userType={user?.subscription.type} />,
      'faq-individual-1': (
        <span className={classes.faqsSpan}>
          {faqDetailsBackMenuItem}
          {t('faq_line_1') /* Does my subscription work for multiple users? */}
          <br />
          <br />
          {t('faq_line_2') /* Only if you have multiple individual subscriptions. */}
        </span>
      ),
      'faq-individual-2': (
        <span className={classes.faqsSpan}>
          {faqDetailsBackMenuItem}
          {t('faq_line_3') /* Can I sign in on multiple devices? */}
          <br />
          <br />
          {
            t(
              'faq_line_4',
            ) /* If you attempt to sign in on more than one device, your previous device session will automatically log out to
          accommodate your new sign in. Only one device can stream at a time. */
          }
        </span>
      ),
      'faq-individual-3': (
        <span className={classes.faqsSpan}>
          {faqDetailsBackMenuItem}
          {t('faq_line_5') /* What happens when my free trial ends? */}
          <br />
          <br />
          {
            t(
              'faq_line_6',
            ) /* If you would like to continue to a paid subscription, sign in to your account on the web and click the prompt
          that appears or navigate to Profile &gt; Your Plan. If you don’t want to continue, your account will
          automatically deactivate at the end of your trial period. */
          }
        </span>
      ),
      'faq-individual-4': (
        <span className={classes.faqsSpan}>
          {faqDetailsBackMenuItem}
          {t('faq_line_7') /* What happens if I cancel my subscription during the trial? */}
          <br />
          <br />
          {
            t(
              'faq_line_8',
            ) /* Your trial is completely risk-free. Once you cancel, your account will be deleted. You will not be charged any
          fees. */
          }
        </span>
      ),
      'faq-individual-5': (
        <span className={classes.faqsSpan}>
          {faqDetailsBackMenuItem}
          {t('faq_line_9') /* What happens if I cancel my subscription? */}
          <br />
          <br />
          {
            t(
              'faq_line_10',
            ) /* If you cancel a monthly or annual subscription, your subscription and access will end once your current
          subscription period has completed. Your subscription will not be renewed and you will not be charged for any
          upcoming or future payment periods. */
          }
        </span>
      ),
      'faq-individual-6': (
        <span className={classes.faqsSpan}>
          {faqDetailsBackMenuItem}
          {t('faq_line_11') /* What does the renewal process look like? */}
          <br />
          <br />
          {
            t(
              'faq_line_12',
            ) /* Subscriptions automatically renew for the same subscription type and period unless you change or cancel your
          plan before your renewal date. */
          }
        </span>
      ),
      'faq-individual-7': (
        <span className={classes.faqsSpan}>
          {faqDetailsBackMenuItem}
          {t('faq_line_13') /* How do I cancel or change billing information? */}
          <br />
          <br />
          {
            t(
              'faq_line_14',
            ) /* Sign into your account on our website and go to Profile &gt; Your Plan. From there, you can modify your
          subscription. */
          }
        </span>
      ),
      'faq-locations-1': (
        <span className={classes.faqsSpan}>
          {faqDetailsBackMenuItem}
          {t('faq_line_15') /* Does my subscription work for multiple locations? */}
          <br />
          <br />
          {
            t(
              'faq_line_16',
            ) /* Each business address needs at least one zone subscription. You need multiple zones to play music at different
          addresses or to play different playlists in a single location at the same time. You can add and edit zones
          from your SINES admin portal inside your account. */
          }
        </span>
      ),
      'faq-locations-2': (
        <span className={classes.faqsSpan}>
          {faqDetailsBackMenuItem}
          {t('faq_line_17') /* Can I play more than one stream at the same time in my business? */}
          <br />
          <br />
          {
            t(
              'faq_line_18',
            ) /* Yes, but you’ll need to subscribe to an additional zone for each independent music stream, and each business
          address requires at least one zone. */
          }
        </span>
      ),
      'faq-locations-3': (
        <span className={classes.faqsSpan}>
          {faqDetailsBackMenuItem}
          {t('faq_line_19') /* Can more than one team member or administrator use the account? */}
          <br />
          <br />
          {
            t(
              'faq_line_20',
            ) /* Yes. You can assign a manager to the account to control the zones at specific locations. */
          }
          <br />
          {
            t(
              'faq_line_21',
            ) /* If you or a team member is using your email address for a SINES individual, practitioner or team subscription,
          you will need to use another unique email address for location user access. Each type of SINES subscription,
          login, and account is tied to a unique email address. */
          }
        </span>
      ),
      'faq-locations-4': (
        <span className={classes.faqsSpan}>
          {faqDetailsBackMenuItem}
          {t('faq_line_22') /* What happens when my free trial ends? */}
          <br />
          <br />
          {
            t(
              'faq_line_23',
            ) /* If you would like to continue to a paid subscription, sign in to your account on the web and click the prompt
          that appears or navigate to Profile &gt; Your Plan. If you don’t want to continue, your account will
          automatically deactivate at the end of your trial period. */
          }
        </span>
      ),
      'faq-locations-5': (
        <span className={classes.faqsSpan}>
          {faqDetailsBackMenuItem}
          {t('faq_line_24') /* What happens if I cancel my subscription during the trial? */}
          <br />
          <br />
          {
            t(
              'faq_line_25',
            ) /* Your trial is completely risk-free. Once you cancel, your account will be deleted. You will not be charged any
          fees. */
          }
        </span>
      ),
      'faq-locations-6': (
        <span className={classes.faqsSpan}>
          {faqDetailsBackMenuItem}
          {t('faq_line_26') /* What happens if I cancel my subscription? */}
          <br />
          <br />
          {
            t(
              'faq_line_27',
            ) /* If you cancel a monthly or annual subscription, your subscription and access will end once your current
          subscription period has completed. Your subscription will not be renewed and you will not be charged for any
          upcoming or future payment periods. */
          }
        </span>
      ),
      'faq-locations-7': (
        <span className={classes.faqsSpan}>
          {faqDetailsBackMenuItem}
          {t('faq_line_28') /* What does the renewal process look like? */}
          <br />
          <br />
          {
            t(
              'faq_line_29',
            ) /* Subscriptions automatically renew for the same subscription type and period unless you change or cancel your
          plan before your renewal date. */
          }
        </span>
      ),
      'faq-locations-8': (
        <span className={classes.faqsSpan}>
          {faqDetailsBackMenuItem}
          {t('faq_line_30') /* How do I cancel or change billing information? */}
          <br />
          <br />
          {
            t(
              'faq_line_31',
            ) /* Sign into your account on our website and go to Profile &gt; Your Plan. From there, you can modify your
          subscription. */
          }
        </span>
      ),
      'faq-locations-9': (
        <span className={classes.faqsSpan}>
          {faqDetailsBackMenuItem}
          {
            t(
              'faq_line_32',
            ) /* Does my SINES subscription give me all the licenses I need to play background music in my business? */
          }
          <br />
          <br />
          {
            t(
              'faq_line_33',
            ) /* Yes, SINES provides all of the performance and reproduction licenses that cover business use and the song's
          composition, lyrics, and particular sound recording. No additional PRO performance rights licenses are needed
          because SINES creates and/or direct licenses all of its music. */
          }
        </span>
      ),
      'faq-locations-10': (
        <span className={classes.faqsSpan}>
          {faqDetailsBackMenuItem}
          {t('faq_line_34') /* Can I use SINES to play music on my website or in advertisements? */}
          <br />
          <br />
          {
            t(
              'faq_line_35',
            ) /* Yes, please contact us for a specific license and plan for this usage. Location plans do not include usage of
          SINES in online videos, radio, live-streaming, advertising or social media posts */
          }
        </span>
      ),
      'faq-locations-11': (
        <span className={classes.faqsSpan}>
          {faqDetailsBackMenuItem}
          {t('faq_line_36') /* Can I use SINES to play music in my gym or fitness center? */}
          <br />
          <br />
          {
            t(
              'faq_line_37',
            ) /* Yes, you can use SINES in training areas, lobby, locker room, and any common areas. You can even play SINES in
          exercise, yoga, and meditation classes with no additional special licenses. */
          }
        </span>
      ),
      'faq-locations-12': (
        <span className={classes.faqsSpan}>
          {faqDetailsBackMenuItem}
          {t('faq_line_38') /* Can I use SINES in a DJ set or other live music performance? */}
          <br />
          <br />
          {
            t(
              'faq_line_39',
            ) /* Yes, please contact us for a specific license and plan for this usage. Location plans do not include usage of
          SINES for live music events and music played in establishments where customers pay an entry fee and SINES is
          the focus point of the live experience. */
          }
        </span>
      ),
      'faq-practitioner-1': (
        <span className={classes.faqsSpan}>
          {faqDetailsBackMenuItem}
          {t('faq_line_40') /* Does my subscription work for multiple practitioners? */}
          <br />
          <br />
          {t('faq_line_41') /* Yes, if you have multiple practitioner subscriptions. */}
        </span>
      ),
      'faq-practitioner-2': (
        <span className={classes.faqsSpan}>
          {faqDetailsBackMenuItem}
          {t('faq_line_42') /* Can I sign in on multiple devices? */}
          <br />
          <br />
          {
            t(
              'faq_line_43',
            ) /* Yes, you can sign in on as many devices as you like. However, only one device per practitioner login will stay
          active. That means if you sign in on a first device and then sign in on a second device, your first device
          will be automatically logged out. Each account can only provide one music stream at a time. */
          }
        </span>
      ),
      'faq-practitioner-3': (
        <span className={classes.faqsSpan}>
          {faqDetailsBackMenuItem}
          {t('faq_line_44') /* I’m a traveling practitioner, can I use SINES in all the locations I practice in? */}
          <br />
          <br />
          {t('faq_line_45') /* Absolutely! SINES goes where you and your devices go. */}
        </span>
      ),
      'faq-practitioner-4': (
        <span className={classes.faqsSpan}>
          {faqDetailsBackMenuItem}
          {t('faq_line_46') /* What happens when my free trial ends? */}
          <br />
          <br />
          {
            t(
              'faq_line_47',
            ) /* If you would like to continue to a paid subscription, sign in to your account on the web and click the prompt
          that appears or navigate to Profile &gt; Your Plan. If you don’t want to continue, your account will
          automatically deactivate at the end of your trial period. */
          }
        </span>
      ),
      'faq-practitioner-5': (
        <span className={classes.faqsSpan}>
          {faqDetailsBackMenuItem}
          {t('faq_line_48') /* What happens if I cancel my subscription during the trial? */}
          <br />
          <br />
          {
            t(
              'faq_line_49',
            ) /* Your trial is completely risk-free. Once you cancel, your account will be deleted. You will not be charged any
          fees. */
          }
        </span>
      ),
      'faq-practitioner-6': (
        <span className={classes.faqsSpan}>
          {faqDetailsBackMenuItem}
          {t('faq_line_50') /* What happens if I cancel my subscription? */}
          <br />
          <br />
          {
            t(
              'faq_line_51',
            ) /* If you cancel a monthly or annual subscription, your subscription and access will end once your current
          subscription period has completed. Your subscription will not be renewed and you will not be charged for any
          upcoming or future payment periods. */
          }
        </span>
      ),
      'faq-practitioner-7': (
        <span className={classes.faqsSpan}>
          {faqDetailsBackMenuItem}
          {t('faq_line_52') /* What does the renewal process look like? */}
          <br />
          <br />
          {
            t(
              'faq_line_53',
            ) /* Subscriptions automatically renew for the same subscription type and period unless you change or cancel your
          plan before your renewal date. */
          }
        </span>
      ),
      'faq-practitioner-8': (
        <span className={classes.faqsSpan}>
          {faqDetailsBackMenuItem}
          {t('faq_line_54') /* How do I cancel or change billing information? */}
          <br />
          <br />
          {
            t(
              'faq_line_55',
            ) /* Sign into your account on our website and go to Profile &gt; Your Plan. From there, you can modify your
          subscription. */
          }
        </span>
      ),
      'faq-teams-1': (
        <span className={classes.faqsSpan}>
          {faqDetailsBackMenuItem}
          {t('faq_line_56') /* Can I change who is on my Team in the middle of a subscription or add new members? */}
          <br />
          <br />
          {
            t(
              'faq_line_57',
            ) /* Absolutely. In your admin panel you can remove team members and add new members. When doing this it will end
          usage for the previous member and activate usage for the new member. Registration details will automatically
          be sent to the new members. The new member’s usage cycle will still remain on the same overall organization
          subscription timeline. From your admin panel you can also add new team subscriptions as your team expands. */
          }
        </span>
      ),
      'faq-teams-2': (
        <span className={classes.faqsSpan}>
          {faqDetailsBackMenuItem}
          {t('faq_line_58') /* What happens when my free trial ends? */}
          <br />
          <br />
          {
            t(
              'faq_line_59',
            ) /* If you would like to continue to a paid subscription, sign in to your account on the web and click the prompt
          that appears or navigate to Profile &gt; Your Plan. If you don’t want to continue, your account will
          automatically deactivate at the end of your trial period. */
          }
        </span>
      ),
      'faq-teams-3': (
        <span className={classes.faqsSpan}>
          {faqDetailsBackMenuItem}
          {t('faq_line_60') /* What happens if I cancel my subscription during the trial? */}
          <br />
          <br />
          {
            t(
              'faq_line_61',
            ) /* Your trial is completely risk-free. Once you cancel, your account will be deleted. You will not be charged any
          fees. */
          }
        </span>
      ),
      'faq-teams-4': (
        <span className={classes.faqsSpan}>
          {faqDetailsBackMenuItem}
          {t('faq_line_62') /* What happens if I cancel my subscription? */}
          <br />
          <br />
          {
            t(
              'faq_line_63',
            ) /* If you cancel a monthly or annual subscription, your subscription and access will end once your current
          subscription period has completed. Your subscription will not be renewed and you will not be charged for any
          upcoming or future payment periods. */
          }
        </span>
      ),
      'faq-teams-5': (
        <span className={classes.faqsSpan}>
          {faqDetailsBackMenuItem}
          {t('faq_line_64') /* What does the renewal process look like? */}
          <br />
          <br />
          {
            t(
              'faq_line_65',
            ) /* Subscriptions automatically renew for the same subscription type and period unless you change or cancel your
          plan before your renewal date. */
          }
        </span>
      ),
      'faq-teams-6': (
        <span className={classes.faqsSpan}>
          {faqDetailsBackMenuItem}
          {t('faq_line_66') /* How do I cancel or change billing information? */}
          <br />
          <br />
          {
            t(
              'faq_line_67',
            ) /* Sign into your account on our website and go to Profile &gt; Your Plan. From there, you can modify your
          subscription. */
          }
        </span>
      ),
    }),
    [activeItem, user, contact, description, isMobile, language],
  );

  return (
    <Scrollbars>
      <div className={classes.menuContainer}>
        {confirmDelete && (
          <Dialog fullWidth className={classes.dialog} open={confirmDelete} onClose={() => setConfirmDelete(false)}>
            <ConfirmActionModal
              title={t('Weresorrytoseeyougo') /* "We’re sorry to see you go" */}
              body={<DeleteBody />}
              cancelAction={() => setConfirmDelete(false)}
              confirmText={t('Deleteaccount')} /* "Delete" */
              confirmAction={handleDeleteUser}
            />
          </Dialog>
        )}
        {isMobile && (
          <span style={{ display: 'flex', width: '100%', marginTop: '14px', marginLeft: '10px' }}>
            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
              <SinesLogoMix
                onClick={() => {
                  navigate('/');
                }}
                className={classes.logo}
              />
            </Typography>
            <IconButton style={{ marginRight: '10px' }} onClick={() => setModalOpen(null)}>
              <CloseMenu />
            </IconButton>
          </span>
        )}
        {pages[activeItem]}
      </div>
    </Scrollbars>
  );
};

UserMenu.defaultProps = { ...defaultProps };

export default UserMenu;
