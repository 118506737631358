import { FC } from 'react';
// * 3rd party libs
import { Grid, IconButton, Typography } from '@mui/material';

// * ASSETS
import FallbackImage from 'assets/imgs/missing_image.svg';
import { ReactComponent as MinPlayIcon } from 'assets/imgs/player/listview-playcircle.svg';
import { ReactComponent as MinPauseIcon } from 'assets/imgs/player/listview-pausecircle.svg';
// * HOOKS - COMPONENTS

// * TYPES

// * LOCAL IMPORTS+
import useMusicPlayer from 'hooks/useMusicPlayer';
import { Track } from 'store/@types/moods';
import { useStyles } from './styles';

interface IAlbumViewSong {
  song: Track;
  playing: boolean;
  selectMusicHandler: (song: Track) => void;
}
const AlbumViewSong: FC<IAlbumViewSong> = ({ song, playing, selectMusicHandler }) => {
  const classes = useStyles();
  const { currentSong, formatTime, pause, play } = useMusicPlayer();

  if (!song) return null;

  const activeSong = currentSong?.id === song.id;

  return (
    <Grid item xs={12} className={classes.root} key={song.id}>
      <div className={classes.coverImageWrapper}>
        {song?.image ? (
          <object className={classes.coverImgMobile} data={song.image} type="image/png">
            <img className={classes.coverImgMobileDefault} src={FallbackImage} alt="coverImage" />
          </object>
        ) : (
          <object className={classes.coverImgMobile}>
            <img className={classes.coverImgMobileDefault} src={FallbackImage} alt="coverImage" />
          </object>
        )}
      </div>
      <Grid container>
        <Grid item xs={12} className={classes.clickableMusicDisplay} onClick={() => selectMusicHandler(song)}>
          <div className={classes.musicTextPlayer}>
            <Typography
              variant="listTrack"
              component="p"
              style={{ color: activeSong ? '#B66481' : '#F4F4F4', maxWidth: '60vw' }}>
              {song.name}
            </Typography>
            <Typography noWrap variant="listAuthor" component="p" style={{ maxWidth: '60vw' }}>
              {song.artist?.name}
            </Typography>
          </div>

          {activeSong ? (
            <IconButton
              id="button-play-pause"
              className={classes.playerButton}
              onClick={() => (playing ? pause() : play())}>
              {playing ? <MinPauseIcon id="button-play" /> : <MinPlayIcon id="button-play-pause" />}
            </IconButton>
          ) : (
            <Typography style={{ alignSelf: 'center' }}>{formatTime(Math.floor(song.duration))}</Typography>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AlbumViewSong;
