import { Dispatch, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { StoreState } from 'store/@types';
import { inviteUsers, updateUserInfo, deleteB2BUser } from 'store/b2b/thunks';
import { updatePlan } from 'store/user/thunks';

import DefaultComponent from './TeamManagement';

const mapStateToProps = ({ b2b }: StoreState) => ({ b2b });

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ inviteUsers, updateUserInfo, deleteB2BUser, updatePlan }, dispatch);

export type ReduxProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(DefaultComponent);
