import { restCall } from 'utils/restHandler';
import { GET, PUT } from 'utils/requestTypes';
import { UserHistory, UserHistoryGetProps, UserHistoryResponse } from 'store/@types/user';

export const sendGetUserHistory = (params: UserHistoryGetProps) =>
  restCall<{ items: UserHistory[] }>(GET, '/user/me/track-history', { params });

export const sendGetUserLastHistory = (params: UserHistoryGetProps) =>
  restCall<{ items: UserHistory[] }>(GET, '/user/me/track-history', { params });

export const sendNewUserHistory = (track: string, playlistId?: string) =>
  restCall<UserHistoryResponse>(PUT, `/track-history/${track}`, playlistId ? { playlist: { id: playlistId } } : {});
