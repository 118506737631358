import { CustomReactTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: CustomReactTheme) => ({
  root: {
    height: 4,
    borderRadius: 5,
    backgroundColor: '#181818',
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  bar: ({ progress }: any) => ({
    borderRadius: 5,
    background: `linear-gradient(90deg, #775DA8 ${100 - progress}%, #F06B5D 100%)`,
  }),

  //* TABLET - landScape
  [theme.breakpoints.down('lg')]: {},
  //* TABLET - portrait
  [theme.breakpoints.down('md')]: {},
  //* MOBILE
  [theme.breakpoints.down('sm')]: {},
}));

export const useStylesNewPassword = makeStyles((theme: CustomReactTheme) => ({
  backToLoginGrid: {
    display: 'flex',
    justifyContent: 'flex-start',
    color: 'white',
    '&:hover': {
      backgroundColor: '#181818',
    },
  },
  //* TABLET - landScape
  [theme.breakpoints.down('lg')]: {},
  //* TABLET - portrait
  [theme.breakpoints.down('md')]: {},
  //* MOBILE
  [theme.breakpoints.down('sm')]: {},
}));
