import { CustomReactTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: CustomReactTheme) => ({
  centeredContent: {
    padding: '0 20px',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    marginTop: '20px',
  },
  //* TABLET - landScape

  [theme.breakpoints.down('lg')]: {},
  //* TABLET - portrait
  [theme.breakpoints.down('md')]: {},
  //* MOBILE
  [theme.breakpoints.down('sm')]: {},
}));

export { useStyles };
