import { Dispatch, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getSpaces, getMoods, getGuided } from 'store/moods/thunks';
import { StoreState } from 'store/@types';
import { refreshToken, getUserPlaylistHistory, getUserLastPlaylistHistory } from 'store/user/thunks';
import PageComponente from './HomePage';

const mapStateToProps = ({ user, moods }: StoreState) => ({ user, moods });

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    { refreshToken, getSpaces, getMoods, getGuided, getUserPlaylistHistory, getUserLastPlaylistHistory },
    dispatch,
  );

export type ReduxProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(PageComponente);
