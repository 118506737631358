import { CustomReactTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: CustomReactTheme) => ({
  qrGrid: {
    flexDirection: 'column',
    justifyContent: 'end',
    paddingBottom: '30px',
    paddingTop: '30px',
  },
  qrCodeWrapper: {
    display: 'flex',
    paddingTop: '10px',
    alignItems: 'end',
    justifyContent: 'center',
  },
  footerWrapper: {
    marginLeft: 28,
  },
  appStore: {
    width: '120px',
    height: '40px',
    cursor: 'pointer',
  },
  poweredWrapper: {
    paddingTop: '8px',
  },
  poweredTypo: {
    fontSize: '10px',
    lineHeight: '12px',
    // textAlign: 'left',
    color: '#FFFFFF',
  },
  nearTypo: {
    color: '#B66481',
    fontFamily: 'Montserrat',
    fontSize: '14px',
    lineHeight: '18px',
    // textAlign: 'left'
  },
  qrCodeImage: {
    width: '90px',
    height: '90px',
  },
  //* MOBILE
  [theme.breakpoints.down('sm')]: {
    qrCodeImage: {
      display: 'none',
    },
    poweredWrapper: {
      textAlign: 'center',
    },
    qrGrid: {
      paddingBottom: 10,
      paddingTop: 10,
    },
    footerWrapper: {
      marginLeft: 0,
      textAlign: 'center',
    },
  },
}));
