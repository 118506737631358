import React from 'react';

const SVG = ({ disabled }: { disabled: boolean }) => (
  <svg
    width="38px"
    height="38px"
    viewBox="0 0 38 38"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink">
    <g id="Album-and-Player" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Desktop-Medium_Minified-Paused" transform="translate(-570.000000, -1016.000000)">
        <g id="Group-14" transform="translate(320.000000, 920.000000)">
          <g id="music_controls" transform="translate(44.000000, 96.000000)">
            <g id="Group-12" transform="translate(136.000000, 0.000000)">
              <g id="play" transform="translate(70.000000, 0.000000)">
                <path
                  d="M6.33333333,13.3633598 C6.33333333,6.36502639 11.2891668,3.4991941 17.3533334,6.99836082 L22.2458336,9.81669392 L27.1383324,12.6350272 C33.2024989,16.1341939 33.2024989,21.8658612 27.1383324,25.3650279 L22.2458336,28.1833593 L17.3533334,31.0016937 C11.2891668,34.5008605 6.33333333,31.635028 6.33333333,24.6366945 L6.33333333,19.0000272 L6.33333333,13.3633598 Z"
                  id="Vector"
                  stroke={disabled ? '#8d949a' : '#FFFFFF'}
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeDasharray="0,0"
                  fillRule="nonzero"
                />
                <path d="M38,0 L38,38 L0,38 L0,0 L38,0 Z" id="Vector" opacity="0" />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default SVG;
