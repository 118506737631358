import { CustomReactTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: CustomReactTheme) => ({
  root: {
    zIndex: theme.zIndex.drawer + 2,
    height: '74px',
    maxHeight: '74px',
    width: '100%',
    position: 'fixed',
    bottom: 0,
    right: 0,
    display: 'flex',
  },
  containerImage: {
    '&:after': {
      // eslint-disable-next-line quotes
      content: '""',
      position: 'absolute',
      width: '100%',
      height: '100%',
      backdropFilter: 'blur(6px)' /* apply the blur */,
      pointerEvents: 'none' /* make the overlay click-through */,
    },
    position: 'absolute',
    boxShadow: 'inset 0 0 0 500px rgb(18 18 18 / 70%)',
    background: 'linear-gradient(75deg, #213748 0%, #213748 60%, #484849 70%, #43343d 85%, #3e1f2e 100%)',
    backgroundPosition: 'left',
    backgroundSize: 'cover',
    width: '100%',
    height: '100%',
  },
  container: {
    height: '100%',
    width: '100%',
    position: 'absolute',
    display: 'flex',
  },
  text: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  textTitle: {
    textOverflow: 'clip',
    whiteSpace: 'nowrap',
    overflowX: 'auto',
    position: 'relative',
    marginRight: 'auto',
  },

  playerButton: {
    color: '#fff',
  },
  slider: {
    width: 285,
    color: 'white',
    paddingTop: 0,
    '& .MuiSlider-rail': {
      height: 1,
    },
    '& .MuiSlider-track': {
      height: 2,
    },
  },
  sliderMobile: {
    width: '100%',
    color: 'white',
    '& .MuiSlider-rail': {
      height: 1,
    },
    '& .MuiSlider-track': {
      height: 2,
    },
    position: 'absolute',
    bottom: 0,
    padding: 0,
    height: 0,
  },
  volumeContainer: {
    height: '74px',
    flexWrap: 'wrap',
    alignItems: 'center',
    alignContent: 'center',
    justifyContent: 'flex-end',
    marginRight: '40px',
  },
  volumeSlider: {
    width: 140,
    color: 'white',
    paddingTop: 0,
    '& .MuiSlider-rail': {
      height: 1,
    },
    '& .MuiSlider-track': {
      height: 2,
    },
  },
  volumeSliderTablet: {
    width: '100%',
    color: 'white',
    '& .MuiSlider-rail': {
      height: 1,
    },
    '& .MuiSlider-track': {
      height: 2,
    },
    position: 'fixed',
    bottom: '74px',
    padding: 0,
    height: 0,
    marginLeft: '-22px',
  },
  heartDesktop: {
    left: '120px',
    bottom: '50px',
  },
  coverImg: {
    borderRadius: '10px',
    boxShadow: '0 0 10px 0 rgba(31, 31, 31, 0.5)',
    width: '140px',
    height: '140px',
    bottom: '20px',
    left: '24px',
    cursor: 'pointer',
    position: 'absolute',
  },
  coverImgDefault: {
    width: '40px',
    background: '#292929',
    height: '40px',
    boxShadow: '0 0 10px 0 rgb(31 31 31 / 50%)',
    borderRadius: '10px',
    padding: '50px',
  },
  coverImgMobile: {
    borderRadius: '10px',
    boxShadow: '0 0 10px 0 rgba(31, 31, 31, 0.5)',
    width: '48px',
    height: '48px',
    bottom: '15px',
    left: '5px',
    position: 'absolute',
  },
  coverImgMobileDefault: {
    width: '48px',
    background: '#292929',
    height: '48px',
    boxShadow: '0 0 10px 0 rgb(31 31 31 / 50%)',
    borderRadius: '10px',
    padding: '12px',
  },
  playPauseMobileButton: {
    position: 'absolute',
    right: '0',
    top: '10px',
  },
  musicInfoWrapper: {
    padding: '15px 15px 15px 170px',
    cursor: 'pointer',
  },
  musicInfoWrapperMobile: {
    maxWidth: '72%',
    marginLeft: '55px',
    padding: 9,
    flexGrow: 1,
  },
  //* TABLET - landScape
  [theme.breakpoints.down('lg')]: {
    coverImgMobile: {
      bottom: '12px',
      left: '11px',
    },

    container: {
      position: 'relative',
    },
  },
  //* TABLET - portrait
  [theme.breakpoints.down('md')]: {
    root: {
      cursor: 'pointer',
      height: '74px',
      bottom: '74px',
    },
    musicInfoWrapper: {
      padding: '20px 5px 5px 70px',
    },
    volumeContainer: {
      marginRight: '0px',
    },
  },
  //* MOBILE
  [theme.breakpoints.down('sm')]: {
    coverImgMobile: {
      bottom: '12px',
      left: '12px',
    },
    musicInfoWrapperMobile: {
      marginLeft: '62px',
      padding: '12px',
    },
    playPauseMobileButton: {
      right: '0',
      top: '14px',
    },
  },
}));

export { useStyles };
