import { Box, Grid, Typography } from '@mui/material';
import QRCode from 'assets/imgs/QRCode.png';
import AppStore from 'assets/imgs/appStore.png';
import { useStyles } from './styles';

const QrCodeBottom = () => {
  const classes = useStyles();
  const handleClick = () => {
    window.open('https://apps.apple.com/app/sines-tuned-to-wellness/id6443797659', '_blank');
  };
  return (
    <Grid container id="qrCode" className={classes.qrGrid}>
      <Grid item className={classes.qrCodeWrapper}>
        <img src={QRCode} width={90} height={90} className={classes.qrCodeImage} alt="qr-code" />
        <Box className={classes.footerWrapper}>
          <Box onClick={handleClick} className={classes.appStoreImg}>
            <img className={classes.appStore} src={AppStore} alt="app-store" />
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default QrCodeBottom;
