import React, { FC } from 'react';
// * 3rd party libs

import { Typography, CardActionArea } from '@mui/material';

// * ASSETS

// * TYPES
import { Category } from 'store/@types/moods';

// * COMPONENTS
import useMusicPlayer from 'hooks/useMusicPlayer';
import DivImage from 'components/DivImage';

// * LOCAL IMPORTS
import { useStyles } from './styles';

export interface MoodsMenuCardProps {
  item: Category;
}

const MoodsMenuCard: FC<MoodsMenuCardProps> = ({ item }) => {
  const classes = useStyles();

  const { setIsMoodsResult, setIsPlaylist, setAlbumView } = useMusicPlayer();

  const clickHandler = (): void => {
    const { playlists } = item;

    if (!playlists) return;

    if (playlists.length > 1) {
      setIsMoodsResult(item);
    } else {
      setAlbumView(playlists[0]);
      setIsPlaylist('open');
    }
  };

  return (
    <span className={classes.squareContainer}>
      <DivImage customClasses={classes.square} src={item.image} smallSrc={item.smallImage}>
        <CardActionArea className={classes.clickableMenu} onClick={clickHandler} />
      </DivImage>
      <Typography className={classes.squareTitle} variant="carrouselItem" component="div" marginTop="7px">
        {item.name}
      </Typography>
    </span>
  );
};

export default MoodsMenuCard;
