import React, { FC } from 'react';
// * 3rd party libs
import { Button, Grid, IconButton, Typography } from '@mui/material';
import { useForm, SubmitHandler } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

// * ASSETS
import { ReactComponent as RemoveUser } from 'assets/imgs/remove_user.svg';

// * TYPES
import { BusinessUser, IEditManagerPost } from 'store/@types/b2b';
import { useTranslation } from 'react-i18next';
// * COMPONENTS
import ManagerCard from 'components/Cards/ManagerCard';
import Input from 'components/forms/Input';

// * LOCAL IMPORTS
import { schema } from './yupSchema';
import { useStyles } from './styles';

interface IManagerDetails {
  showZones?: boolean;
  user: BusinessUser;
  deleteUser: () => void;
  closeModal: (payload: IEditManagerPost | null) => void;
  availableDevices: number;
}

const ManagerDetails: FC<IManagerDetails> = ({ showZones, user, deleteUser, closeModal, availableDevices }) => {
  const classes = useStyles();
  const editAvailableDevices = availableDevices + user.devices;
  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors, isValid },
  } = useForm<IEditManagerPost>({
    mode: 'onTouched',
    resolver: yupResolver(schema(editAvailableDevices)),
    reValidateMode: 'onChange',
    defaultValues: { zones: user.devices },
    shouldUnregister: false,
  });

  const formSubmitHandler: SubmitHandler<IEditManagerPost> = ({ zones }) => {
    closeModal({ userId: user.user.id, zones });
  };
  const handleInputZones = (zones: string, name: 'zones'): void => {
    if (+zones < 1) {
      setValue(name, 1);
    }
  };

  const { t } = useTranslation();

  return (
    <form id="manager-form" onSubmit={handleSubmit(formSubmitHandler)} key="managerForm">
      <Grid container spacing={2} className={classes.mainGridWrapper}>
        <IconButton className={classes.closeButton} onClick={() => closeModal(null)}>
          <RemoveUser />
        </IconButton>
        <Grid item xs={12}>
          <Typography variant="section">
            {showZones ? t('Location') : t('Manager')}
            {t('details') /* details */}
          </Typography>
        </Grid>
        <ManagerCard user={user} removeAction={deleteUser} />
        <Grid container spacing={6}>
          <Grid item xs={12} className={classes.inputWrapper}>
            <Input
              required
              name="zones"
              placeholder={t('Zones') /* Zones */}
              // "Zones"
              type="number"
              control={control}
              errors={errors}
              additionalOnChange={value => handleInputZones(value, 'zones')}
            />
          </Grid>
        </Grid>
        <Button
          className={classes.saveChangesButton}
          variant={isValid ? 'primary' : 'disabled'}
          type="submit"
          form="manager-form">
          <Typography>{t('SaveChanges') /* Save Changes */}</Typography>
        </Button>
      </Grid>
    </form>
  );
};

ManagerDetails.defaultProps = { showZones: false };

export default ManagerDetails;
