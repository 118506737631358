import { Dispatch, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getPlaylistDetails, sendFavPlaylist } from 'store/moods/thunks';
import { StoreState } from 'store/@types';
import PageComponente from './AlbumView';

const mapStateToProps = ({ moods }: StoreState) => ({ moods });

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ getPlaylistDetails, sendFavPlaylist }, dispatch);

export type ReduxProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(PageComponente);
