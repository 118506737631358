import { CustomReactTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles((theme: CustomReactTheme) => ({
  dialog: {
    '& .MuiDialog-paper': {
      boxShadow: '0 0 50px 0 #A1628E',
      border: '1px solid',
      borderColor: '#775DA8',
      background: 'linear-gradient(130deg, #775DA8 0%, #A1628E 50%, #C96675 100%)',
      height: 'calc(100vh - 40px)',
      maxHeight: 800,
      width: 'calc(100% - 40px)',
      maxWidth: '698px',
      backgroundAttachment: 'fixed',
      backgroundSize: 'cover',
      borderRadius: '26px',
      display: 'flex',
      flexDirection: 'row',
    },
  },
  dialogDeleteAccount: {
    '& .MuiDialog-paper': {
      width: '286px',
      minHeight: '286px',
      backgroundColor: '#181818',
      borderRadius: '20px',
      display: 'flex',
      flexDirection: 'row',
    },
  },
  dialogBg: {
    overflow: 'hidden',
    padding: '50px 100px',
    borderRadius: '20px',
    width: 'calc(100% - 2px)',
    // height: 'calc(100% - 2px)',
    margin: '1px',
    backgroundColor: '#303030 !important',
  },
  dialogContainer: {
    '& >div': {
      width: '100%',
      textAlign: 'center !important',
    },
    '& >div>p': {
      width: '100%',
      textAlign: 'center !important',
    },
  },
  title: {
    color: '#FFFFFF',
    fontFamily: 'Montserrat',
    fontSize: '38px',
    fontWeight: '700',
    lineHeight: '46px',
    width: '188px',
    textAlign: 'center',
  },
  subtitleGrid: {
    marginBottom: '20px',
    display: 'flex',
    alignItems: 'center',
  },
  subTitle: {
    color: '#FFFFFF',
    fontFamily: 'Montserrat',
    fontSize: '16px',
    letterSpacing: '0.3px',
    lineHeight: '22px',
    width: '420px',
    textAlign: 'center',
  },
  buttonApple: {
    width: '100%',
    backgroundColor: '#FFF',
    color: 'black',
    height: '46px',
    display: 'flex',
    margin: '30px auto',
    flexDirection: 'row',
    borderRadius: '5px',
    maxWidth: '142px',
    fontWeight: 600,
    justifyContent: 'space-around',
    textAlign: 'left',
    '&:hover': {
      background: '#b2b2b2',
    },
  },
  dowloadAppText: {
    margin: 0,
    marginBottom: '-10px',
    fontSize: 8,
    letterSpacing: 0.5,
  },
  appleButtonText: {
    margin: 0,
    fontSize: 18,
    letterSpacing: -0.6,
    whiteSpace: 'nowrap',
  },
  button1: {
    width: '100%',
    height: '50px',
    display: 'flex',
    margin: '0 auto',
    flexDirection: 'column',
    maxWidth: '275px',
    textAlign: 'center',
  },
  button2: {
    position: 'relative',
    height: '50px',
    display: 'flex',
    margin: '0 auto',
    padding: '0',
    flexDirection: 'column',
    width: '276px',
    textAlign: 'center',
  },
  buttonLogout: {
    backgroundImage: 'linear-gradient(68.02deg, #775DA8 0%, #F06B5D 100%)',
    borderRadius: '26px',
    width: '276px',
    height: '52px',
    cursor: 'pointer',
  },
  buttonDelete: {
    color: '#FFFFFF',
    fontFamily: 'Montserrat',
    fontSize: '16px',
    lineHeight: '19px',
    fontWeight: '600',
    textAlign: 'center',
    cursor: 'pointer',
  },
  buttonSpan1: {
    position: 'absolute',
    background: '#252626',
    width: '274px',
    height: '48px',
    borderRadius: '30px',
    textAlign: 'center',
    padding: '15px',
  },
  buttonSpan2: {
    position: 'absolute',
    background: 'linear-gradient(135deg, #323232 0%, #252525 100%)',
    width: '274px',
    height: '48px',
    borderRadius: '30px',
    textAlign: 'center',
    padding: '15px',
  },
  feedback1: {
    color: '#FFFFFF',
    fontFamily: 'Montserrat',
    fontSize: '14px',
    letterSpacing: '0.3px',
    lineHeight: '22px',
    width: '420px',
    textAlign: 'center',
  },
  feedback2: {
    color: '#FFFFFF',
    fontFamily: 'Montserrat',
    fontSize: '14px',
    letterSpacing: '0.3px',
    fontWeight: 'bold',
    lineHeight: '22px',
    width: '420px',
    textAlign: 'center',
    cursor: 'pointer',
  },
  descriptionGrid: {
    marginBottom: '85x',
    height: '132px',
  },
  descriptionGridQrCode: {
    marginBottom: '50px',
    height: '132px',
  },

  expiredDescription: {
    marginBottom: '40px',
    height: '132px',
  },
  expiredButtonGrid: {
    marginBottom: '40px',
    height: '100px',
  },
  //* MOBILE
  [theme.breakpoints.down('sm')]: {
    dialog: {
      '& .MuiDialog-paper': {
        maxHeight: 'calc(85vh - 20px)',
      },
    },
    bottomGridQR: {
      bottom: '50px',
      position: 'fixed',
    },
    dialogBg: {
      overflow: 'auto',
      padding: '10px 20px',
    },
    descriptionGrid: {
      marginBottom: '30px',
      height: '132px',
    },
    descriptionGridQrCode: {
      marginBottom: '10px',
      height: '100px',
    },
    expiredDescription: {
      marginBottom: '10px',
      height: '112px',
    },
    expiredButtonGrid: {
      marginBottom: '20px',
      height: '50px',
    },
  },
}));
