import { CustomReactTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: CustomReactTheme) => ({
  playerButton: {
    color: '#fff',
  },
  actionControlersMobile: {
    width: '100%',
    bottom: 0,
    position: 'fixed',
    zIndex: 4,
    padding: '15px',
    backgroundColor: '#161616',
  },
  //* TABLET - landScape
  [theme.breakpoints.down('lg')]: {},
  //* TABLET - portrait
  [theme.breakpoints.down('md')]: {},
  //* MOBILE
  [theme.breakpoints.down('sm')]: {},
}));

export { useStyles };
