import React, { FC, useEffect, useMemo, useState } from 'react';
// * 3rd party libs
// import { useNavigate } from 'react-router-dom';

import { Button, Grid, Typography } from '@mui/material';

// * ASSETS
import { ReactComponent as BackChevron } from 'assets/imgs/backchevron.svg';
import { ReactComponent as SearchNotFound } from 'assets/imgs/search_results_notfound.svg';

// * COMPONENTS
import HorizontalList from 'components/HorizontalList';
import useMusicPlayer from 'hooks/useMusicPlayer';

// * TYPES
import { useTranslation } from 'react-i18next';
// * LOCAL IMPORTS
import useResponsive from 'hooks/useResponsive';
import { Playlist } from 'store/@types/moods';
import { useStyles } from './styles';
import { ReduxProps } from '.';

const MoodResults: FC<ReduxProps> = () => {
  const classes = useStyles();
  const { isMoodsResult: category, setIsMoodsResult } = useMusicPlayer();
  const { scrollRef } = useResponsive();
  const [{ playlists, albums }, setItems] = useState<{ playlists: Playlist[]; albums: Playlist[] }>({
    playlists: [],
    albums: [],
  });

  const { t } = useTranslation();

  useEffect(() => {
    scrollRef.current?.scrollToTop();
  }, []);

  useEffect(() => {
    if (!category) return;
    setItems({
      playlists: category.playlists.filter(e => e.type === 'PLAYLIST'),
      albums: category.playlists.filter(e => e.type === 'ALBUM'),
    });
  }, [category]);

  const playlistsCards = useMemo(
    () => category && playlists?.length !== 0 && <HorizontalList list={playlists} type="square" />,
    [category, playlists],
  );

  const albumsCards = useMemo(
    () => category && albums?.length !== 0 && <HorizontalList list={albums} type="square" />,
    [category, albums],
  );

  const searchNotFound = useMemo(
    () =>
      category &&
      category.playlists.length === 0 && (
        <Grid item xs={12} className={classes.searchNotFound}>
          <SearchNotFound />
          <Typography variant="listAuthor">
            {t('moodresults_noresults') /* Your search didn’t match any results */}.
          </Typography>
        </Grid>
      ),
    [category],
  );

  if (!category) return null;

  return (
    <div className={classes.headerBox}>
      <div
        className={classes.headerBox1}
        style={{
          backgroundImage: `linear-gradient(135deg, #18181887, rgb(24, 24, 24, 75%) 0, rgb(24, 24, 24) 50%), url(${category?.image})`,
        }}
      />
      <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start" className={classes.root}>
        <Grid item xs={12}>
          <Button onClick={() => setIsMoodsResult(null)} sx={{ padding: '10px', margin: '10px -10px' }}>
            <BackChevron className={classes.backArrow} />
          </Button>
        </Grid>
        <Typography variant="h6" marginBottom="30px">
          {category.name}
        </Typography>

        {playlistsCards}
        {albumsCards}
        {searchNotFound}
      </Grid>
    </div>
  );
};

export default MoodResults;
