import React, { FC, ReactNode } from 'react';
// * 3rd party libs

import { Grid, Typography, Divider } from '@mui/material';

// * ASSETS
import { ReactComponent as RemoveUserLogo } from 'assets/imgs/remove_user_logo.svg';

// * COMPONENTS

// * LOCAL IMPORTS
import { useStyles } from './styles';

// * TYPES
interface ConfirmActionProps {
  title: string;
  body: ReactNode;
  cancelText?: string;
  cancelAction: () => void;
  confirmText?: string;
  confirmAction: () => void;
}

const ConfirmActionModal: FC<ConfirmActionProps> = ({
  title,
  body,
  cancelText,
  cancelAction,
  confirmText,
  confirmAction,
}) => {
  const classes = useStyles();

  return (
    <Grid container spacing={2} className={classes.mainGridWrapper}>
      <Grid item xs={12} className={classes.alignContentCenter}>
        <RemoveUserLogo />
      </Grid>
      <Grid item xs={12} className={classes.alignContentCenter}>
        <Typography variant="section">{title}</Typography>
      </Grid>
      <Grid item xs={10} className={classes.infoWrapper}>
        <Typography variant="carrouselItem">{body}</Typography>
      </Grid>
      <Grid item xs={5} className={classes.buttonWrapper}>
        <Typography variant="section" onClick={confirmAction} className={classes.removeButton}>
          {confirmText}
        </Typography>
      </Grid>
      <Grid item className={classes.dividerWrapper}>
        <Divider orientation="vertical" flexItem className={classes.divider} />
      </Grid>
      <Grid item xs={5} className={classes.buttonWrapper}>
        <Typography variant="section" onClick={cancelAction} className={classes.cancelButton}>
          {cancelText}
        </Typography>
      </Grid>
    </Grid>
  );
};

ConfirmActionModal.defaultProps = {
  cancelText: 'Cancel',
  confirmText: 'Confirm',
};

export default ConfirmActionModal;
