import { createReducer } from 'typesafe-actions';
import { UserActions as Actions, UserState } from 'store/@types/user';

const INITIAL_STATE: UserState = {
  loading: false,
  error: null,
  userHistory: null,
  userLastHistory: null,
  userInfo: null,
};

const userReducer = createReducer(INITIAL_STATE, {
  [Actions.SET_LOADING]: (state, { payload }) => ({ ...state, loading: payload }),
  [Actions.SET_ERROR]: (state, { payload }) => ({ ...state, loading: false, error: payload }),
  [Actions.SET_LOGIN]: (state, { payload }) => ({ ...state, loading: false, userInfo: payload }),
  [Actions.SET_USER_HISTORY]: (state, { payload }) => ({ ...state, loading: false, userHistory: payload }),
  [Actions.SET_USER_LAST_HISTORY]: (state, { payload }) => ({ ...state, loading: false, userLastHistory: payload }),
});

export default userReducer;
