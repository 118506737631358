import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
// * 3rd party libs
import { Typography } from '@mui/material';

// * LOCAL IMPORTS
import { menuItem } from '../UserMenuPage';

interface IndividualsFaqProps {
  setModalOpen: (value: string | null) => void;
}

const IndividualsFaq: FC<IndividualsFaqProps> = ({ setModalOpen }) => {
  const { t } = useTranslation();

  return (
    <>
      <Typography variant="heroTitle" sx={{ marginLeft: '15px', marginTop: '15px', width: '350px' }}>
        {t('Individuals')}
      </Typography>
      <span style={{ paddingLeft: '15px' }}>
        {menuItem(
          t('faq-indiviual-1'), // 'Does my subscription work for multiple users?'
          {
            action: () => {
              setModalOpen('faq-individual-1');
            },
          },
        )}
        {menuItem(
          t('faq-indiviual-2'),
          // 'Can I sign in on multiple devices?'
          {
            action: () => {
              setModalOpen('faq-individual-2');
            },
          },
        )}
        {menuItem(
          // 'What happens when my free trial ends?'
          t('faq-individual-3'),
          {
            action: () => {
              setModalOpen('faq-individual-3');
            },
          },
        )}
        {menuItem(
          // 'What happens if I cancel my subscription during the trial?'
          t('faq-individual-4'),
          {
            action: () => {
              setModalOpen('faq-individual-4');
            },
          },
        )}
        {menuItem(
          t('faq-individual-5'), // 'What happens if I cancel my subscription?'
          {
            action: () => {
              setModalOpen('faq-individual-5');
            },
          },
        )}
        {menuItem(
          t('faq-individual-6'), // 'What does the renewal process look like?'
          {
            action: () => {
              setModalOpen('faq-individual-6');
            },
          },
        )}
        {menuItem(
          // 'How do I cancel or change billing information?'
          t('faq-individual-7'),
          {
            action: () => {
              setModalOpen('faq-individual-7');
            },
          },
        )}
      </span>
    </>
  );
};

export default IndividualsFaq;
