/* eslint-disable indent */
import { Dispatch } from 'redux';
import { sendGetMood, sendGetSpaces, sendGetGuided, sendGetCategoryPlaylists } from 'api/moods';
import {
  sendGetPLaylistDetails,
  apiGetFavAlbums,
  apiGetFavPlaylists,
  apiSendFavTrack,
  apiSendFavPlaylist,
  apiGetAllTracksFavorites,
  apiGetAllPlaylistsFavorites,
  apiGetSearh,
} from 'api/playlists';

import { Track } from 'store/@types/moods';

import {
  setLoading,
  setLoadingSpaces,
  setLoadingMoods,
  setLoadingGuided,
  setError,
  setMoods,
  setCategoryPlaylists,
  setSpaces,
  setGuided,
  setPLaylistDetails,
  setFavAlbums,
  setFavPlaylists,
  addFavTrack,
  addFavPlaylist,
  setAllUserFavs,
  setFavSongs,
  setSearch,
  setLoadingSearch,
} from './actions';

export const getSpaces =
  () =>
  async (dispatch: Dispatch): Promise<void> => {
    try {
      dispatch(setLoadingSpaces());
      // api request
      const { data } = await sendGetSpaces();
      dispatch(setSpaces(data));
    } catch (error) {
      dispatch(setError(error));
    }
  };
export const getMoods =
  () =>
  async (dispatch: Dispatch): Promise<void> => {
    try {
      dispatch(setLoadingMoods());
      // api request
      const { data } = await sendGetMood();
      dispatch(setMoods(data));
    } catch (error) {
      dispatch(setError(error));
    }
  };
export const getGuided =
  () =>
  async (dispatch: Dispatch): Promise<void> => {
    try {
      dispatch(setLoadingGuided());
      // api request
      const { data } = await sendGetGuided();
      dispatch(setGuided(data));
    } catch (error) {
      dispatch(setError(error));
    }
  };

export const getCategoryPlaylists =
  (id?: string) =>
  async (dispatch: Dispatch): Promise<void> => {
    if (!id) {
      dispatch(setCategoryPlaylists(null));
    } else {
      try {
        dispatch(setLoading());
        // api request
        const { data } = await sendGetCategoryPlaylists(id);
        dispatch(setCategoryPlaylists({ [id]: data }));
      } catch (error) {
        dispatch(setError(error));
      }
    }
  };

export const getPlaylistDetails =
  (id?: string) =>
  async (dispatch: Dispatch): Promise<void> => {
    if (!id) {
      dispatch(setPLaylistDetails(null));
    } else {
      try {
        dispatch(setLoading());
        const { data } = await sendGetPLaylistDetails(id);
        const parentImages = { image: data.image, smallImage: data.smallImage };

        const tracks =
          data.tracks?.map(({ image, imagePath, smallImage, smallImagePath, ...item }) => {
            const newTrack: Track = { ...item } as unknown as Track;
            if (imagePath && smallImagePath) return { image, imagePath, smallImage, smallImagePath, ...item };
            if (!imagePath) {
              newTrack.image = parentImages.image;
            }
            if (!smallImage) {
              newTrack.smallImage = parentImages.smallImage;
            }
            return newTrack;
          }) || data.tracks;
        dispatch(setPLaylistDetails({ [id]: { ...data, tracks } }));
      } catch (error) {
        dispatch(setError(error));
      }
    }
  };

export const getAllFavorites =
  () =>
  async (dispatch: Dispatch): Promise<void> => {
    try {
      dispatch(setLoading());
      const [tracks, playlists] = await Promise.all([apiGetAllTracksFavorites(), apiGetAllPlaylistsFavorites()]);
      if (tracks.data || playlists.data) {
        const favs = {
          tracks: tracks.data.items?.map(({ track }) => ({ id: track.id, name: track.name })) || [],
          playlists: playlists.data.items?.map(({ playlist }) => ({ id: playlist.id, name: playlist.name })) || [],
        };
        dispatch(setAllUserFavs(favs));
      }
    } catch (error) {
      dispatch(setError(error));
    }
  };

export const getFavAlbums =
  (clearData = false) =>
  async (dispatch: Dispatch): Promise<void> => {
    if (clearData) {
      dispatch(setFavAlbums(null));
    } else {
      try {
        dispatch(setLoading());
        const { data } = await apiGetFavAlbums(50);
        dispatch(setFavAlbums(data));
      } catch (error) {
        dispatch(setError(error));
      }
    }
  };

export const getFavPlaylists =
  (clearData = false) =>
  async (dispatch: Dispatch): Promise<void> => {
    if (clearData) {
      dispatch(setFavPlaylists(null));
    } else {
      try {
        dispatch(setLoading());
        const { data } = await apiGetFavPlaylists(50);
        dispatch(setFavPlaylists(data));
      } catch (error) {
        dispatch(setError(error));
      }
    }
  };

export const getFavSongs =
  (clearData = false) =>
  async (dispatch: Dispatch): Promise<void> => {
    if (clearData) {
      dispatch(setFavSongs(null));
    } else {
      try {
        dispatch(setLoading());
        const { data } = await apiGetAllTracksFavorites(20);
        dispatch(setFavSongs(data));
      } catch (error) {
        dispatch(setError(error));
      }
    }
  };

export const sendFavTrack =
  (assetId: string, update: boolean) =>
  async (dispatch: Dispatch): Promise<void> => {
    try {
      const { data } = await apiSendFavTrack(assetId, update);
      dispatch(addFavTrack({ ...data, deleted: !update }));
    } catch (error) {
      dispatch(setError(error));
    }
  };

export const sendFavPlaylist =
  (assetId: string, update: boolean) =>
  async (dispatch: Dispatch): Promise<void> => {
    try {
      const { data } = await apiSendFavPlaylist(assetId, update);
      dispatch(addFavPlaylist({ ...data, deleted: !update }));
    } catch (error) {
      dispatch(setError(error));
    }
  };

export const getSearch =
  (text?: string) =>
  async (dispatch: Dispatch): Promise<void> => {
    if (text) {
      try {
        dispatch(setLoadingSearch());
        const { data } = await apiGetSearh(text);
        dispatch(setSearch(data));
      } catch (error) {
        dispatch(setError(error));
      }
    } else {
      dispatch(setSearch(null));
    }
  };
