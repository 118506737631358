import { FC, useEffect, useState } from 'react';
// * 3rd party libs
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useNavigate, useSearchParams } from 'react-router-dom';
import useAuth from 'hooks/useAuth';
import TagManager from 'react-gtm-module';

import { Button, Grid, Typography } from '@mui/material';
import { getCookie } from 'utils/cookies';
// * ASSETS
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import SuccessGeneric from 'assets/imgs/success_heart.png';
import { useTranslation } from 'react-i18next';
// * COMPONENTS
import Loading from 'components/Loading';
import NoAuthPage from 'components/NoAuthPage/NoAuthPage';
import Checkbox from 'components/forms/Checkbox';
import Input from 'components/forms/Input';
import { envConfig } from 'config/config';
import AppleLogin from 'react-apple-login';
import { GoogleLogin } from '@react-oauth/google';
// * LOCAL IMPORTS
import { ErrorData, ErrorResponse, GoogleSignupPost, LoginResponse, SignUpPost, UserProps } from 'store/@types/user';
import { ReduxProps } from '.';
import { useStyles } from './styles';
import { defaultValues, schema } from './yupSchema';

// * TYPES
const SignUp: FC<ReduxProps> = ({ user, signUp, gSignUp }) => {
  const { loading } = user;
  const classes = useStyles();
  const [match] = useSearchParams();
  const [submitParams, setSubmitParams] = useState({});

  const [googleAuthError, setGoogleAuthError] = useState('');
  // const classes = useStyles();
  const navigate = useNavigate();
  const { setAuth, setTokenData } = useAuth();

  const successOperation = match.get('success') === 'true';
  const isGoogle = match.get('gauth') === 'true';
  const priceId = match.get('priceId');
  const currency = match.get('currency') || 'usd';
  const devices = match.get('devices');

  const { t } = useTranslation();

  useEffect(() => {
    const payload: { [field: string]: string | number } = {};
    priceId && (payload.priceId = priceId);
    currency && (payload.currency = currency);
    devices && (payload.devices = Number(devices));
    setSubmitParams(payload);
  }, [priceId, devices]);

  const {
    handleSubmit,
    control,
    setError,
    setValue,
    formState: { errors, isValid },
  } = useForm<SignUpPost>({
    mode: 'onTouched',
    resolver: yupResolver(schema),
    reValidateMode: 'onChange',
    defaultValues,
    shouldUnregister: false,
  });

  const trackCookie = getCookie('trakdesk_cid');
  const trackDeskCid = trackCookie ? JSON.parse(trackCookie).cid : null;

  const onSubmit = ({ password2, terms, policy, ...payload }: SignUpPost) =>
    signUp({ ...submitParams, ...payload, lmData: trackDeskCid }, (res?: UserProps, errorResponse?: ErrorResponse) => {
      if (res) {
        TagManager.dataLayer({
          dataLayer: {
            event: 'sign_up',
          },
        });
        navigate('/signup?success=true');
      } else if (errorResponse) {
        if ((errorResponse.data as ErrorData)?.errorMessage?.includes('username'))
          setError('username', {
            type: 'custom',
            message: `${(errorResponse.data as ErrorData)?.errorMessage}`,
          });
        if ((errorResponse.data as ErrorData)?.errorMessage?.includes('email'))
          setError('email', {
            type: 'custom',
            message: `${(errorResponse.data as ErrorData)?.errorMessage}`,
          });
      }
    });

  return (
    <>
      {loading && <Loading />}
      <form id="register-user" onSubmit={handleSubmit(onSubmit)}>
        <NoAuthPage>
          {!successOperation ? (
            <>
              <Grid
                item
                xs={12}
                style={{ justifyContent: 'center', display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                <GoogleLogin
                  shape="pill"
                  theme="filled_black"
                  width="200px"
                  size="large"
                  text="signup_with"
                  containerProps={{ style: { marginBottom: '10px' } }}
                  onSuccess={async credentialResponse => {
                    console.log(credentialResponse);
                    gSignUp(
                      { ...(credentialResponse as GoogleSignupPost), ...submitParams, lmData: trackDeskCid },
                      (res?: LoginResponse, errorResponse?: ErrorResponse) => {
                        if (res) {
                          TagManager.dataLayer({
                            dataLayer: {
                              event: 'sign_up',
                            },
                          });
                          setTokenData(res);
                          setAuth(res.access_token);
                          navigate('/home');
                        } else if (errorResponse) {
                          console.log(errorResponse);
                          if ((errorResponse.data as ErrorData)?.errorMessage?.includes('username'))
                            setGoogleAuthError(t('signup_google_account_exists' /* 'Account already exists!' */));

                          if ((errorResponse.data as ErrorData)?.errorMessage?.includes('email'))
                            setGoogleAuthError(
                              t('signup_google_signin_email'),
                              // 'Please sign-in using your email and password.'
                            );
                        }
                      },
                    );
                  }}
                />
                <Typography style={{ marginTop: '5px', color: '#e78989' }}>{googleAuthError}</Typography>
                <AppleLogin
                  clientId={envConfig.REACT_APP_APPLE_CLIENTID}
                  scope="email name"
                  redirectURI={envConfig.REACT_APP_BACKEND_URL.concat('/user/apple')}
                  responseType="code id_token"
                  responseMode="form_post"
                  state={JSON.stringify({ ...submitParams, trackDeskCid })}
                  designProp={{ scale: 1, height: 40, width: 193, border: true, border_radius: 50 }}
                />
              </Grid>
              <Typography style={{ marginTop: '20px' }}>{t('OR') /* OR */}</Typography>
              <Input
                grid
                gridProps={{
                  style: { marginTop: '40px' },
                }}
                required
                name="name"
                placeholder="Display Name"
                control={control}
                errors={errors}
              />
              <Input grid required name="username" placeholder="Username" control={control} errors={errors} />
              <Input grid required name="email" placeholder="Email address" control={control} errors={errors} />
              <Input
                grid
                required
                name="password"
                type="password"
                helperText={t('newpassword_at_least_8') /* 'Use at least 8 characters.' */}
                placeholder={t('signup_password') /* 'Choose a password' */}
                control={control}
                errors={errors}
              />
              <Input
                grid
                required
                name="password2"
                type="password"
                placeholder={t('signup_repeat_password') /* 'Repeat your password' */}
                control={control}
                errors={errors}
              />
              <Grid item xs={12} style={{ textAlign: 'left', display: 'flex' }}>
                <Checkbox
                  name="terms"
                  label={
                    <Typography className={classes.checkboxLabel}>
                      {t('signup_iaccept') /* I accept the{' '} */}{' '}
                      <a href={`${envConfig.REACT_APP_WEBSITE_URL}/terms`} target="_blank" rel="noreferrer">
                        {t('signup_termsconditions') /* Terms + Conditions */}
                      </a>
                    </Typography>
                  }
                  additionalOnChange={val => setValue('terms', val, { shouldValidate: true })}
                  control={control}
                  errors={errors}
                />
              </Grid>
              <Grid item xs={12} style={{ textAlign: 'left' }}>
                <Checkbox
                  name="policy"
                  label={
                    <Typography className={classes.checkboxLabel}>
                      {t('signup_iagree') /* I agree to the{' '} */}{' '}
                      <a href={`${envConfig.REACT_APP_WEBSITE_URL}/privacy`} target="_blank" rel="noreferrer">
                        {t('signup_PrivacyPolicy') /* Privacy Policy */}
                      </a>
                    </Typography>
                  }
                  additionalOnChange={val => setValue('policy', val, { shouldValidate: true })}
                  control={control}
                  errors={errors}
                />
              </Grid>
              <Grid item xs={12}>
                <Button variant={isValid ? 'primary' : 'disabled'} fullWidth type="submit">
                  {t('signup_CreateAccount') /* Create Account */}
                </Button>
              </Grid>

              <Grid item xs={12}>
                <Typography variant="loginSection" component="p">
                  {t('signup_alreadyhave') /* Already have an account? */}
                </Typography>
                <Typography variant="loginSection" component="p">
                  {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                  <a href="/login" style={{ color: '#B66481' }}>
                    {t('Signin') /* Sign in */}
                  </a>
                </Typography>
              </Grid>
            </>
          ) : (
            <>
              <Grid item xs={12} style={{ marginTop: '40px', padding: 0 }}>
                <Button
                  variant="text"
                  className={classes.backToLoginGrid}
                  onClick={() => {
                    navigate('/login');
                  }}>
                  <ChevronLeft />
                  <Typography variant="carrouselItem">{t('signup_backtosignin') /* Back to Sign in */}</Typography>
                </Button>
              </Grid>
              <Grid item xs={12}>
                <img src={SuccessGeneric} style={{ width: 142, height: 142 }} alt="success-img" />
              </Grid>
              <Grid item xs={12}>
                <Typography gutterBottom variant="heroTitle" component="p" textAlign="center">
                  {t('Success') /* Success */}!
                </Typography>
                <Typography variant="listAuthor" component="p" textAlign="center">
                  {
                    t(
                      'signup_sentemail',
                    ) /* We’ve sent you an email to verify your account. Check your inbox to activate it. */
                  }
                </Typography>
              </Grid>
              <Grid item xs={12} marginTop="60px" sx={{ marginBottom: 50 }}>
                <Typography variant="loginSection" component="p">
                  {t('signup_didntreceive') /* Didn’t receive the email? */}
                </Typography>
                <Typography variant="loginSection" component="p">
                  {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                  <a href="/recover" style={{ color: '#B66481' }}>
                    {t('signup_sendnewconf') /* Send new confirmation */}
                  </a>
                </Typography>
              </Grid>
            </>
          )}
        </NoAuthPage>
      </form>
    </>
  );
};

export default SignUp;
