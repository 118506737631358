import React, { FC } from 'react';
// * 3rd party libs
import clsx from 'clsx';

import { Box, Button, ButtonGroup, Icon, IconButton, MenuItem, Typography } from '@mui/material';

// * ASSETS
import { ReactComponent as SocialFacebook } from 'assets/imgs/social_failbook.svg';
import { ReactComponent as SocialInsta } from 'assets/imgs/social_instagram_final.svg';
import { ReactComponent as SocialSite } from 'assets/imgs/social_site.svg';
import { ReactComponent as SocialTwitter } from 'assets/imgs/social_twitter.svg';
import QRCode from 'assets/imgs/QRCode.png';
import AppStore from 'assets/imgs/appStore.png';

import LanguagePicker from 'components/LanguagePicker';
// * COMPONENTS
import useResponsive from 'hooks/useResponsive';
import { envConfig } from 'config/config';

// * TYPES
import { useLanguage } from 'context/LanguageProvider';
import { useTranslation } from 'react-i18next';
// * LOCAL IMPORTS
import { useStyles } from './styles';
import { ReduxProps } from '.';

export interface IUserMenuItemProps {
  id: string;
  src: string;
  title: string;
}
export interface IUserMenuProps extends ReduxProps {
  setModalOpen: (value: string | null) => void;
  handleClose?: () => void;
  window?: boolean;
  activeItem?: string;
}
const defaultProps = {
  activeItem: undefined,
  window: true,
  handleClose: () => {},
};

const UserMenu: FC<IUserMenuProps> = ({ window, setModalOpen, handleClose, activeItem, logout }) => {
  const { isMobile } = useResponsive();
  const classes = useStyles();

  const { t } = useTranslation();

  const { changeLanguage } = useLanguage();

  if (isMobile) {
    return null;
  }

  return (
    <div className={window ? classes.windowMenu : classes.userMenu}>
      <MenuItem
        className={clsx(classes.menuItem, {
          [classes.activeItem]: activeItem === 'profile',
          [classes.windowItem]: window,
        })}
        onClick={() => {
          if (!window && setModalOpen && handleClose) {
            setModalOpen('profile');
            handleClose();
          } else {
            setModalOpen('profile');
          }
        }}>
        {t('Profile')}
      </MenuItem>
      <MenuItem
        className={clsx(classes.menuItem, {
          [classes.activeItem]: activeItem === 'guidebook',
          [classes.windowItem]: window,
        })}
        onClick={() => {
          if (!window) {
            setModalOpen && setModalOpen('guidebook');
            handleClose && handleClose();
          } else {
            setModalOpen('guidebook');
          }
        }}>
        {t('Guidebook') /* Guidebook */}
      </MenuItem>
      <MenuItem
        className={clsx(classes.menuItem, {
          [classes.activeItem]: activeItem === 'ethos',
          [classes.windowItem]: window,
        })}
        onClick={() => {
          if (!window) {
            setModalOpen && setModalOpen('ethos');
            handleClose && handleClose();
          } else {
            setModalOpen('ethos');
          }
        }}>
        {t('Ourethos') /* Our ethos */}
      </MenuItem>
      <MenuItem
        className={clsx(classes.menuItem, {
          [classes.activeItem]: activeItem?.startsWith('faq'),
          [classes.windowItem]: window,
        })}
        onClick={() => {
          if (!window) {
            setModalOpen && setModalOpen('faq');
            handleClose && handleClose();
          } else {
            setModalOpen('faq');
          }
        }}>
        {t('faqs') /* FAQ's */}
      </MenuItem>
      <MenuItem
        className={clsx(classes.menuItem, {
          [classes.activeItem]: activeItem === 'support',
          [classes.windowItem]: window,
        })}
        onClick={() => {
          // eslint-disable-next-line no-restricted-globals
          open('mailto:support@sines.app', '_blank');
        }}>
        {t('SupportandFeedback') /* Support & Feedback */}
      </MenuItem>
      <MenuItem
        className={clsx(classes.menuItem, {
          [classes.windowItem]: window,
        })}
        onClick={logout}>
        {t('Signout') /* Sign out */}
      </MenuItem>
      <MenuItem
        disabled
        className={clsx(classes.menuItem, {
          [classes.activeItem]: activeItem === 'contact',
          [classes.windowItem]: window,
        })}
        style={{ marginTop: '20px' }}>
        {t('Stayintouch') /* Stay in touch */}
      </MenuItem>
      <span
        style={{
          ...(window && {
            display: 'flex',
            justifyContent: 'flex-start',
          }),
        }}>
        {!window && <LanguagePicker style={{ marginTop: '-10px' }} />}
        <IconButton
          href="https://www.facebook.com/sinesglobal/"
          target="_blank"
          style={{ marginRight: '5px', marginLeft: '5px' }}
          onClick={() => {
            if (!window) {
              handleClose && handleClose();
            }
          }}>
          <SocialFacebook />
        </IconButton>
        <IconButton
          href="https://twitter.com/sines_app"
          target="_blank"
          style={{ marginRight: '5px', marginLeft: '5px' }}
          onClick={() => {
            if (!window) {
              handleClose && handleClose();
            }
          }}>
          <SocialTwitter />
        </IconButton>
        <IconButton
          href="https://www.instagram.com/sines.app/"
          target="_blank"
          style={{ marginRight: '5px', marginLeft: '5px' }}
          onClick={() => {
            if (!window) {
              handleClose && handleClose();
            }
          }}>
          <SocialInsta />
        </IconButton>
        <IconButton
          href={envConfig.REACT_APP_WEBSITE_URL}
          target="_blank"
          style={{ marginRight: '5px', marginLeft: '5px' }}
          onClick={() => {
            if (!window) {
              handleClose && handleClose();
            }
          }}>
          <SocialSite />
        </IconButton>
      </span>

      <Box className={window ? classes.qrCodeWrapper : classes.qrCodeWrapperSmall}>
        <img src={QRCode} style={{ width: 64, height: 64 }} alt="qr-code" />
        <a
          href="https://apps.apple.com/app/sines-tuned-to-wellness/id6443797659"
          target="_blank"
          rel="noreferrer"
          className={classes.appStore}
          style={{ marginRight: window ? '0px' : '10px' }}>
          <img src={AppStore} style={{ height: '100%', width: '100%' }} alt="app-store" />
        </a>
      </Box>

      {/* <Button
        onClick={() => changeLanguage('en')}
        startIcon={<img src={flagUK} alt="UK Flag" style={{ width: 24, height: 'auto' }} />}
      />
      <Button
        onClick={() => changeLanguage('es')}
        startIcon={<img src={flagES} alt="UK Flag" style={{ width: 24, height: 'auto' }} />}
      /> */}
    </div>
  );
};

UserMenu.defaultProps = { ...defaultProps };

export default UserMenu;
