/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC, useMemo, useState, useEffect, createContext, useCallback } from 'react';
import { CustomTheme, useMediaQuery, useTheme } from '@mui/material';
import { cardSizes } from 'utils/cards';
import { Playlist } from 'store/@types/moods';

export type CardsTypes =
  | 'recent'
  | 'square'
  | 'searchItems'
  | 'type3'
  | 'mood'
  | 'recentHalf'
  | 'searchItemsHalf'
  | 'type3Half'
  | 'moodHalf';

export interface ResponsiveProps {
  theme: CustomTheme;
  isLarge: boolean;
  isTablet: boolean;
  isMobile: boolean;
  reduceItemsInList: <T = any>(list: T[], type: CardsTypes, customItemNumber?: number[]) => T[];
  history: Playlist[];
  setHistory: (newHistory: Playlist[]) => void;
  search: string;
  setSearch: (str: string) => void;
  scrollRef: React.RefObject<any>;
}

const ResponsiveContext = createContext<ResponsiveProps>({} as ResponsiveProps);

const getSizes = (index: string, item: any, multiplier = 1): number => item[index][0] * multiplier;
const parseSizes = (index: string, multiplier = 1) =>
  Object.keys(cardSizes[index]).map(e => getSizes(e, cardSizes[index], multiplier));

const sizes = {
  recent: parseSizes('recent'),
  square: parseSizes('square'),
  searchItems: parseSizes('searchItems', 2),
  type3: parseSizes('type3', 2),
  mood: parseSizes('mood'),
  recentHalf: parseSizes('recentHalf'),
  searchItemsHalf: parseSizes('searchItemsHalf', 2),
  type3Half: parseSizes('type3Half', 2),
  moodHalf: parseSizes('moodHalf'),
};

export const ResponsiveProvider: FC = ({ children }) => {
  const theme = useTheme<CustomTheme>();
  const isXL = useMediaQuery(theme.breakpoints.down('xl'));
  const isLarge = useMediaQuery(theme.breakpoints.down('lg'));
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [history, setHistory] = useState<Playlist[]>([]);
  const [search, setSearch] = useState('');

  const reduceItemsInList = useCallback(
    <T = any,>(list: T[], type: CardsTypes, customItemNumber?: number[]) => {
      if (isMobile) {
        return list.slice(0, (customItemNumber && customItemNumber[4]) || sizes[type][4] || 3);
      }
      if (isTablet) {
        return list.slice(0, (customItemNumber && customItemNumber[3]) || sizes[type][3] || 4);
      }
      if (isLarge) {
        return list.slice(0, (customItemNumber && customItemNumber[2]) || sizes[type][2] || 5);
      }
      if (isXL) {
        return list.slice(0, (customItemNumber && customItemNumber[1]) || sizes[type][1] || 6);
      }
      return list.slice(0, (customItemNumber && customItemNumber[0]) || sizes[type][0] || 7);
    },
    [isXL, isLarge, isTablet, isMobile],
  );

  useEffect(() => {
    const oldHistory = localStorage.getItem('searchHistory');
    if (oldHistory) {
      const newArray: Playlist[] = JSON.parse(oldHistory);
      const uniqueArray = newArray.filter((v, i, a) => a.findIndex(v2 => v2.id === v.id) === i);
      setHistory(uniqueArray);
    }
  }, []);

  useEffect(() => {
    history && history.length > 0 && localStorage.setItem('searchHistory', JSON.stringify(history));
  }, [history]);

  useEffect(() => {
    const oldSearch = localStorage.getItem('searchQuery');
    setSearch(oldSearch && oldSearch.length > 0 ? oldSearch : '');
  }, []);

  useEffect(() => {
    search && search.length > 0 && localStorage.setItem('searchQuery', search);
  }, [search]);

  const contextValue = useMemo<ResponsiveProps>(
    () => ({
      theme,
      isXL,
      isLarge,
      isTablet,
      isMobile,
      reduceItemsInList,
      history,
      setHistory: (newHistory: Playlist[]) => {
        const uniqueArray = newHistory.filter((v, i, a) => a.findIndex(v2 => v2.id === v.id) === i);
        setHistory(uniqueArray);
      },
      search,
      setSearch: (str: string) => {
        localStorage.setItem('searchQuery', str);
        setSearch(str);
      },
      scrollRef: React.createRef<any>(),
    }),
    [isLarge, isTablet, isMobile, isXL, search, history],
  );

  return <ResponsiveContext.Provider value={contextValue}>{children}</ResponsiveContext.Provider>;
};

export default ResponsiveContext;
