import { Dispatch, bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { StoreState } from 'store/@types';
import { logout } from 'store/user/thunks';

import DefaultComponent from './UserMenu';

const mapStateToProps = ({ user }: StoreState) => ({ user });

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({ logout }, dispatch);

export type ReduxProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(DefaultComponent);
