import React from 'react';

import { MenuItem, Select, FormControl, SelectChangeEvent, Typography } from '@mui/material';
import { useLanguage } from 'context/LanguageProvider';

type LanguagePickerProps = {
  style?: React.CSSProperties;
};

const LanguagePicker: React.FC<LanguagePickerProps> = ({ style }) => {
  const { language, changeLanguage } = useLanguage();
  const handleChange = (event: SelectChangeEvent<'es' | 'en'>) => {
    const newLanguage = event.target.value as 'es' | 'en';
    changeLanguage(newLanguage);
  };

  return (
    <FormControl>
      <Select
        sx={{
          color: 'white',
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: 'white', // Change this to your desired color
            display: 'none',
          },
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: 'none', // Color on hover
            display: 'none',
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: 'none', // Color when focused
            display: 'none',
          },
          '& .MuiSelect-icon': {
            color: 'white', // Change the arrow color
          },
        }}
        style={style}
        displayEmpty
        value={language}
        onChange={handleChange}
        inputProps={{ 'aria-label': 'Language Picker' }}>
        <MenuItem value="en">
          <Typography>English</Typography>
        </MenuItem>
        <MenuItem value="es">
          <Typography>Spanish</Typography>
        </MenuItem>
      </Select>
    </FormControl>
  );
};

LanguagePicker.defaultProps = {
  style: {},
};
export default LanguagePicker;
